/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying the research area.
 * @export
 */
export const EventKind = {
    ADVANCED_ANALYTICS: 'ADVANCED_ANALYTICS',
    ASSISTANT: 'ASSISTANT',
    ASSISTANT_CHAT: 'ASSISTANT_CHAT',
    ASSISTANT_DRAFT: 'ASSISTANT_DRAFT',
    AUS_BREACH_REPORTING: 'AUS_BREACH_REPORTING',
    AUSTRALIA_TAX_QA: 'AUSTRALIA_TAX_QA',
    CALL_SUMMARIZATION: 'CALL_SUMMARIZATION',
    COMPANY_PROFILE: 'COMPANY_PROFILE',
    COMPARATIVE_ANALYSIS: 'COMPARATIVE_ANALYSIS',
    COMPARATOR_FINDER: 'COMPARATOR_FINDER',
    COMPETITIVE_ANALYSIS: 'COMPETITIVE_ANALYSIS',
    CONTRACTS: 'CONTRACTS',
    CORPUS_QA: 'CORPUS_QA',
    CREATE_OUTLINE: 'CREATE_OUTLINE',
    CUATRECASAS: 'CUATRECASAS',
    DILIGENCE_TRANSCRIPTS: 'DILIGENCE_TRANSCRIPTS',
    DOCUMENT_COMPARISON: 'DOCUMENT_COMPARISON',
    DOCUMENT_INVENTORY: 'DOCUMENT_INVENTORY',
    DOCUMENT_QA: 'DOCUMENT_QA',
    DRAFTING: 'DRAFTING',
    EURLEX_QA: 'EURLEX_QA',
    FRANCE_CASELAW: 'FRANCE_CASELAW',
    FROM_COUNSEL: 'FROM_COUNSEL',
    HELP_ME: 'HELP_ME',
    HMRC_QA: 'HMRC_QA',
    ICERTIS_FIELD_EXTRACTION: 'ICERTIS_FIELD_EXTRACTION',
    ICERTIS_LAUNCH_BATCH_FIELD_EXTRACTION: 'ICERTIS_LAUNCH_BATCH_FIELD_EXTRACTION',
    ICERTIS_REVIEW_DOCUMENT: 'ICERTIS_REVIEW_DOCUMENT',
    INDIA_TAX_QA: 'INDIA_TAX_QA',
    INTERNET_BROWSING: 'INTERNET_BROWSING',
    IRELAND_TAX_QA: 'IRELAND_TAX_QA',
    ISSUES_LIST: 'ISSUES_LIST',
    ITALY_TAX_QA: 'ITALY_TAX_QA',
    HONG_KONG_TAX_QA: 'HONG_KONG_TAX_QA',
    JAPAN_TAX_QA: 'JAPAN_TAX_QA',
    KSA_TAX_QA: 'KSA_TAX_QA',
    KOREA_TAX_QA: 'KOREA_TAX_QA',
    MEMOS_QA: 'MEMOS_QA',
    MICROSOFT_COPILOT: 'MICROSOFT_COPILOT',
    MICROSOFT_CUSTOM_ENGINE_COPILOT: 'MICROSOFT_CUSTOM_ENGINE_COPILOT',
    MOTION_RESPONSE: 'MOTION_RESPONSE',
    MULTI_DOC_QA: 'MULTI_DOC_QA',
    NETHERLANDS_TAX_QA: 'NETHERLANDS_TAX_QA',
    OGC_REVIEW: 'OGC_REVIEW',
    OPEN_ENDED: 'OPEN_ENDED',
    PILLAR_TWO_TAX_QA: 'PILLAR_TWO_TAX_QA',
    PLAYBOOK_REVIEW: 'PLAYBOOK_REVIEW',
    PWC_DEALS: 'PWC_DEALS',
    REDLINES: 'REDLINES',
    RESEARCH: 'RESEARCH',
    RESEARCH_MEMO: 'RESEARCH_MEMO',
    SEC_EDGAR_QA: 'SEC_EDGAR_QA',
    SUMMARIZE: 'SUMMARIZE',
    SWEDEN_TAX_QA: 'SWEDEN_TAX_QA',
    SWITZERLAND_TAX_QA: 'SWITZERLAND_TAX_QA',
    TAX: 'TAX',
    TRANSCRIPTS: 'TRANSCRIPTS',
    TRANSLATION: 'TRANSLATION',
    UAE_TAX_QA: 'UAE_TAX_QA',
    USA_CASELAW: 'USA_CASELAW',
    VAULT: 'VAULT',
    VAULT_REVIEW: 'VAULT_REVIEW',
    WORD_DOCUMENT: 'WORD_DOCUMENT',
    WORD_EVENT: 'WORD_EVENT',
    WWTS_QA: 'WWTS_QA'
} as const;
export type EventKind = typeof EventKind[keyof typeof EventKind];


export function instanceOfEventKind(value: any): boolean {
    for (const key in EventKind) {
        if (Object.prototype.hasOwnProperty.call(EventKind, key)) {
            if (EventKind[key as keyof typeof EventKind] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EventKindFromJSON(json: any): EventKind {
    return EventKindFromJSONTyped(json, false);
}

export function EventKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventKind {
    return json as EventKind;
}

export function EventKindToJSON(value?: EventKind | null): any {
    return value as any;
}

export function EventKindToJSONTyped(value: any, ignoreDiscriminator: boolean): EventKind {
    return value as EventKind;
}

