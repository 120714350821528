import React from 'react'

import { Card } from 'antd'
import _ from 'lodash'

import { Workspace } from 'models/workspace'

import { EM_DASH, intervalToReadable } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import MigrateWorkspaceToPermBundlesComponent from 'components/settings/user-management/migrate-workspace-to-perm-bundles'
import { Tabs, TabsList, TabsContent } from 'components/ui/tabs'

import PlaybookManagement from './playbook-management/playbook-management'
import WorkspaceDetailsQueryCaps from './vault-management/query-cap/workspace-details-query-caps'
import VaultManagement from './vault-management/vault-management'
import WorkspaceDetailsApi from './workspace-details-api'
import WorkspaceDetailsStatusBar from './workspace-details-status-bar'
import WorkspaceOffboard from './workspace-offboard/workspace-offboard'
import WorkspacePermMigrationStatus, {
  isWorkspaceCutoverToPermBundles,
} from './workspace-perm-migration-status'
import WorkspaceStateManagement from './workspace-state-management'
import { WSTabsTrigger } from './workspace-tabs'

interface WorkspaceDetailsImplementationProps {
  workspace: Workspace
  fetchData: () => void
}

const WorkspaceDetailsImplementation = ({
  workspace,
  fetchData,
}: WorkspaceDetailsImplementationProps) => {
  const userInfo = useAuthUser()
  const WorkspaceDataPrivacy = (
    <Card>
      <div className="space-y-2">
        <div className="*:text-sm">
          <p className="text-muted">Azure Storage account name</p>
          <p>
            {_.isNil(workspace.storageAccount)
              ? EM_DASH
              : workspace.storageAccount}
          </p>
        </div>
        <div className="*:text-sm">
          <p className="text-muted">Bucket name</p>
          <p className="font-semibold ">
            {_.isNil(workspace.bucket) ? EM_DASH : workspace.bucket}
          </p>
        </div>
        <div>
          <p className="text-sm text-muted">Retention period</p>
          <div className="mt-1 flex max-w-md flex-wrap gap-x-1 gap-y-2">
            <p className="text-sm">
              {!_.isNil(workspace.settings.retentionPeriod)
                ? intervalToReadable(workspace.settings.retentionPeriod, true)
                : 'Unlimited'}
            </p>
          </div>
        </div>
      </div>
    </Card>
  )

  return (
    <Tabs defaultValue={userInfo.isUserManagement ? '1' : '2'}>
      <TabsList variant="minimal" className="w-full">
        <WSTabsTrigger
          value="1"
          disabled={
            !userInfo.IsInternalAdminReader || workspace.deletedAt != null
          }
        >
          API Tokens
        </WSTabsTrigger>
        <WSTabsTrigger value="2">Data Privacy</WSTabsTrigger>
        <WSTabsTrigger
          value="3"
          disabled={!userInfo.isReadEngInternal || workspace.deletedAt != null}
        >
          Query caps
        </WSTabsTrigger>
        <WSTabsTrigger
          value="4"
          disabled={
            !userInfo.IsInternalAdminReader || workspace.deletedAt != null
          }
        >
          Vault Mgmt
        </WSTabsTrigger>
        <WSTabsTrigger
          value="5"
          disabled={!userInfo.isUserManagement || workspace.deletedAt != null}
        >
          State Mgmt
        </WSTabsTrigger>
        <WSTabsTrigger value="6" disabled={!userInfo.IsInternalAdminReader}>
          Offboard
        </WSTabsTrigger>
        <WSTabsTrigger value="7" disabled={!userInfo.IsInternalAdminWriter}>
          Status Bar
        </WSTabsTrigger>
        {userInfo.IsInternalAdminWriter && (
          <WSTabsTrigger value="8">Perm Bundles Cutover</WSTabsTrigger>
        )}
        {userInfo.isPlaybookReviewUser && (
          <WSTabsTrigger value="9">Playbook Management</WSTabsTrigger>
        )}
      </TabsList>

      <TabsContent value="1">
        <WorkspaceDetailsApi workspace={workspace} />
      </TabsContent>
      <TabsContent value="2">{WorkspaceDataPrivacy}</TabsContent>
      <TabsContent value="3">
        <WorkspaceDetailsQueryCaps workspace={workspace} />
      </TabsContent>
      <TabsContent value="4">
        <VaultManagement workspace={workspace} />
      </TabsContent>
      <TabsContent value="5">
        <WorkspaceStateManagement workspace={workspace} fetchData={fetchData} />
      </TabsContent>
      <TabsContent value="6">
        <WorkspaceOffboard workspace={workspace} />
      </TabsContent>
      <TabsContent value="7">
        <WorkspaceDetailsStatusBar workspace={workspace} />
      </TabsContent>
      {userInfo.IsInternalAdminWriter && (
        <TabsContent value="8" className="space-y-4">
          {!isWorkspaceCutoverToPermBundles(workspace) && (
            <MigrateWorkspaceToPermBundlesComponent workspace={workspace} />
          )}
          <WorkspacePermMigrationStatus workspace={workspace} />
        </TabsContent>
      )}
      {userInfo.isPlaybookReviewUser && (
        <TabsContent value="9">
          <PlaybookManagement />
        </TabsContent>
      )}
    </Tabs>
  )
}

export default WorkspaceDetailsImplementation
