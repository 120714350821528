import * as React from 'react'
import { useRef } from 'react'
import { Control, Controller } from 'react-hook-form'

import { instanceOfTitle, Title } from 'openapi/models/Title'

import { getTitleText } from 'utils/user-profile-helpers'

import Combobox from 'components/ui/combobox/combobox'

interface ProfileTitleFormProps {
  control: Control<any>
  size?: 'md' | 'lg'
}

const isTitle = (value: unknown): value is Title => {
  return instanceOfTitle(value)
}

const OPTIONS = Object.values(Title).map((title) => ({
  value: title,
  label: getTitleText(title),
}))

export const FORM_HEADING = 'What is your job title?'
export const FORM_DESCRIPTION =
  'Select the title that most closely matches your current position. You will still have access to all content, regardless of your selection.'

const ProfileTitleForm: React.FC<ProfileTitleFormProps> = ({ control }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={containerRef}>
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <Combobox
            className="w-full max-w-96"
            value={field.value}
            setValue={(value) => {
              if (value === '') {
                field.onChange(null)
              } else if (isTitle(value)) {
                field.onChange(value)
              } else {
                console.error('Invalid title value:', value)
              }
            }}
            options={OPTIONS}
            defaultText="Select job title"
            emptyStateText="Title not found"
            containerRef={containerRef}
            ariaLabel="Job title"
            id="job-title-select"
          />
        )}
      />
    </div>
  )
}

export default ProfileTitleForm
