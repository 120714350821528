import { useRef } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import useHarveySocket, {
  InitSocketAndSendQueryParams,
} from 'utils/use-harvey-socket'
import { ToBackendKeys } from 'utils/utils'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  AssistantDraftInlineEditQuery,
  AssistantDraftMessage,
} from 'components/assistant/types'
import { isVaultKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { useAuthUser } from 'components/common/auth-context'

import { useSocketCompletedCallback } from './use-assistant-socket-completed-callback'

export type AssistantWorkflowDraftStreamHandler = {
  createDraftEditRequest: (
    query: string,
    prevMessageId: string | undefined,
    draftId: string,
    onAuthCallback?: (eventId: string) => void
  ) => void
  createDraftInlineEditRequest: (
    params: AssistantDraftInlineEditQuery & {
      prevMessageId?: string
      draftId: string
    }
  ) => void
  sendCancelRequest: () => void
  regenerateMessage: (message: AssistantDraftMessage, draftId: string) => void
}

export function useAssistantWorkflowDraftStreamHandler(): AssistantWorkflowDraftStreamHandler {
  const isCancelled = useRef<boolean>(false)
  const [
    setTask,
    setStreamingMessage,
    getDocumentFileIds,
    messages,
    getCurrentThreadMessages,
    setCurrentMessageId,
    documentsUploading,
    preserveState,
    currentMessageId,
    documents,
    knowledgeSource,
  ] = useAssistantStore(
    useShallow((s) => [
      s.setTask,
      s.setStreamingMessage,
      s.getDocumentFileIds,
      s.messages,
      s.getCurrentThreadMessages,
      s.setCurrentMessageId,
      s.documentsUploading,
      s.preserveState,
      s.currentMessageId,
      s.documents,
      s.knowledgeSource,
    ])
  )

  const userInfo = useAuthUser()
  const queryAnalytics = useQueryAnalytics(EventKind.ASSISTANT_DRAFT)

  const { socketCompletedCallback } = useSocketCompletedCallback()

  const socket = useHarveySocket({
    path: 'assistant/workflow_draft',
    setter: setTask,
    endCallback: socketCompletedCallback,
    closeOnUnmount: false,
  })

  function createBaseRequest(
    query: string,
    draftId: string,
    onAuthCallback?: (eventId: string) => void
  ): InitSocketAndSendQueryParams {
    const baseRecordFields = {
      thread_number: getCurrentThreadMessages().length + 1,
      message_number: messages.length + 1,
      num_documents: documents.length + documentsUploading.length,
      knowledge_source_type: knowledgeSource?.type ?? '',
    }

    const recordFields = userInfo.workspace.isEyesOff
      ? baseRecordFields
      : {
          ...baseRecordFields,
          document_names: documents.map((doc) => doc.name),
          query: query,
        }
    return {
      query,
      recordFields,
      onAuthCallback,
      ...queryAnalytics,
      additionalAuthParams: {
        draft_id: draftId,
      },
      additionalRequestParams: {},
    }
  }

  async function createDraftInlineEditRequest({
    query,
    selectedText,
    prevMessageId,
    draftId,
  }: AssistantDraftInlineEditQuery & {
    prevMessageId?: string
    draftId: string
  }) {
    setStreamingMessage({
      query,
      requestType: 'inlineEdit',
      selectedText,
      isLoading: true,
      prevMessageId,
      sources: [],
      annotations: {},
    })

    const req = createBaseRequest(query, draftId)
    if (isVaultKnowledgeSource(knowledgeSource)) {
      const knowledgeSourceInput = ToBackendKeys(knowledgeSource)
      req.additionalRequestParams = {
        ...req.additionalRequestParams,
        knowledge_sources: [knowledgeSourceInput],
        vault_project_id: knowledgeSource.folderId,
      }
    } else {
      const fileIds = await getDocumentFileIds()
      if (fileIds.length > 0) {
        req.additionalRequestParams = {
          ...req.additionalRequestParams,
          knowledge_sources: [{ type: 'files', file_ids: fileIds }],
        }
      }
    }
    req.additionalAuthParams = {
      ...req.additionalAuthParams,
      draft_id: draftId,
      prev_message_id: prevMessageId,
    }
    req.additionalRequestParams = {
      ...req.additionalRequestParams,
      selected_text: selectedText,
    }

    preserveState(query, currentMessageId)
    socket.initSocketAndSendQuery(req)
  }

  // eslint-disable-next-line max-params
  async function createDraftEditRequest(
    query: string,
    prevMessageId: string | undefined,
    draftId: string,
    onAuthCallback?: (draftId: string) => void
  ) {
    setStreamingMessage({
      query,
      requestType: 'edit',
      isLoading: true,
      sources: [],
      annotations: {},
      prevMessageId,
    })

    const req = createBaseRequest(query, draftId, onAuthCallback)
    if (isVaultKnowledgeSource(knowledgeSource)) {
      const knowledgeSourceInput = ToBackendKeys(knowledgeSource)
      req.additionalRequestParams = {
        ...req.additionalRequestParams,
        knowledge_sources: [knowledgeSourceInput],
        vault_project_id: knowledgeSource.folderId,
      }
    } else {
      const fileIds = await getDocumentFileIds()
      if (fileIds.length > 0) {
        req.additionalRequestParams = {
          ...req.additionalRequestParams,
          knowledge_sources: [{ type: 'files', file_ids: fileIds }],
        }
      }
    }

    req.additionalAuthParams = {
      ...req.additionalAuthParams,
      draft_id: draftId,
      prev_message_id: prevMessageId,
    }

    preserveState(query, currentMessageId)
    socket.initSocketAndSendQuery(req)
  }

  async function regenerateMessage(
    message: AssistantDraftMessage,
    draftId: string
  ) {
    setCurrentMessageId(message.prevMessageId || null)

    if (message.selectedText) {
      await createDraftInlineEditRequest({
        query: message.query,
        selectedText: message.selectedText,
        prevMessageId: message.prevMessageId || undefined,
        draftId,
      })
    } else {
      await createDraftEditRequest(
        message.query,
        message.prevMessageId || undefined,
        draftId
      )
    }
  }

  function sendCancelRequest() {
    isCancelled.current = true
    queryAnalytics.recordQueryCancel({
      num_documents: documents.length + documentsUploading.length,
    })
    socket.sendCancelRequest()
  }

  return {
    createDraftInlineEditRequest,
    createDraftEditRequest,
    sendCancelRequest,
    regenerateMessage,
  }
}
