import React from 'react'

import ExampleCardList from 'components/assistant/workflows/components/example-card-list/example-card-list'
import { useWorkflowData } from 'components/assistant/workflows/hooks/use-workflow-data'
import Skeleton from 'components/ui/skeleton'

const AssistantWorkflowExamples = () => {
  const { workflows, categorizedWorkflows, isLoading } = useWorkflowData()

  if (isLoading) {
    return <Skeleton className="py-4" rows={3} rowHeight="h-4" />
  }

  if (!workflows?.length) {
    return (
      <p className="pt-6 text-center text-muted">
        Example workflows will appear here
      </p>
    )
  }

  const {
    transactional,
    litigation,
    general,
    allen_and_overy,
    financial_services,
  } = categorizedWorkflows

  return (
    <div className="mt-6 flex flex-col gap-6">
      <ExampleCardList items={allen_and_overy} title="A&O Shearman" />
      <ExampleCardList items={general} title="For General Work" />

      <ExampleCardList items={transactional} title="For Transactional Work" />
      <ExampleCardList items={litigation} title="For Litigation Work" />
      <ExampleCardList
        items={financial_services}
        title="For Financial Services"
      />
    </div>
  )
}
export default AssistantWorkflowExamples
