/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SynclyIntegration = {
    SYNCLY: 'syncly',
    I_MANAGE: 'iManage',
    I_MANAGE_CLOUD: 'iManageCloud'
} as const;
export type SynclyIntegration = typeof SynclyIntegration[keyof typeof SynclyIntegration];


export function instanceOfSynclyIntegration(value: any): boolean {
    for (const key in SynclyIntegration) {
        if (Object.prototype.hasOwnProperty.call(SynclyIntegration, key)) {
            if (SynclyIntegration[key as keyof typeof SynclyIntegration] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SynclyIntegrationFromJSON(json: any): SynclyIntegration {
    return SynclyIntegrationFromJSONTyped(json, false);
}

export function SynclyIntegrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SynclyIntegration {
    return json as SynclyIntegration;
}

export function SynclyIntegrationToJSON(value?: SynclyIntegration | null): any {
    return value as any;
}

export function SynclyIntegrationToJSONTyped(value: any, ignoreDiscriminator: boolean): SynclyIntegration {
    return value as SynclyIntegration;
}

