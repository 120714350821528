import React, { useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { ReviewWorkflowCobrand } from 'openapi/models/ReviewWorkflowCobrand'
import { ReviewWorkflowKind } from 'openapi/models/ReviewWorkflowKind'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import {
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogContent,
  Dialog,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Switch } from 'components/ui/switch'
import { TagInput } from 'components/ui/tag-input/tag-input'
import { SimpleDocumentClassificationPill } from 'components/vault/components/vault-document-classification'
import { useDocumentClassificationStore } from 'components/vault/utils/use-document-classification-store'
import { CreateDraftWorkflow } from 'components/vault/utils/vault-fetcher'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'
import { convertKindToTabLabel } from 'components/workflows/workflow-browser-dialog'

const VaultNewWorkflow: React.FC = () => {
  const [documentClassificationTags] = useDocumentClassificationStore(
    useShallow((state) => [state.documentClassificationTags])
  )

  const [workflowModalState, setWorkflowModalState, setWorkflow] =
    useVaultWorkflowStore(
      useShallow((state) => [
        state.workflowModalState,
        state.setWorkflowModalState,
        state.setWorkflow,
      ])
    )

  const [newWorkflowName, setNewWorkflowName] = useState('')
  const [newWorkflowDescription, setNewWorkflowDescription] = useState('')
  const [newWorkflowKind, setNewWorkflowKind] = useState<
    ReviewWorkflowKind | ''
  >('')
  const [newWorkflowCsvFile, setNewWorkflowCsvFile] = useState<File | null>(
    null
  )
  const [
    shouldBeStrictDocumentClassification,
    setShouldBeStrictDocumentClassification,
  ] = useState(false)
  const [newWorkflowCobrand, setNewWorkflowCobrand] = useState<
    ReviewWorkflowCobrand | undefined
  >(undefined)
  const [
    selectedDocumentClassificationTags,
    setSelectedDocumentClassificationTags,
  ] = useState<string[]>([])
  const [newWorkflowCsvFileName, setNewWorkflowCsvFileName] = useState<
    string | null
  >(null)
  const [isSubmittingNewWorkflow, setIsSubmittingNewWorkflow] = useState(false)

  const handleNewWorkflowSubmit = async () => {
    setIsSubmittingNewWorkflow(true)
    try {
      const response = await CreateDraftWorkflow({
        name: newWorkflowName,
        description: newWorkflowDescription,
        kind: newWorkflowKind,
        csvFile: newWorkflowCsvFile!,
        documentClassificationTagsIds: selectedDocumentClassificationTags,
        cobrand: newWorkflowCobrand,
        shouldBeStrictDocumentClassification:
          shouldBeStrictDocumentClassification,
      })
      displaySuccessMessage('Draft workflow created successfully')
      setWorkflow(response.workflow)
      setWorkflowModalState(WorkflowModalState.Selector)
    } catch (e) {
      displayErrorMessage('Failed to create workflow')
    } finally {
      setIsSubmittingNewWorkflow(false)
    }
  }

  return (
    <Dialog
      open={workflowModalState === WorkflowModalState.New}
      onOpenChange={(open) => {
        if (!open) {
          setWorkflowModalState(WorkflowModalState.Selector)
        }
      }}
    >
      <DialogContent className="bg-secondary sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Create New Workflow</DialogTitle>
          <DialogDescription className="text-left">
            Fill in the details to create a new workflow.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <div>
            <label
              htmlFor="newWorkflowName"
              className="block text-xs font-medium"
            >
              Name
            </label>
            <Input
              id="newWorkflowName"
              value={newWorkflowName}
              onChange={(e) => setNewWorkflowName(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="newWorkflowDescription"
              className="block text-xs font-medium"
            >
              Description
            </label>
            <Input
              id="newWorkflowDescription"
              value={newWorkflowDescription}
              onChange={(e) => setNewWorkflowDescription(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="newWorkflowKind"
              className="block text-xs font-medium"
            >
              Kind
            </label>
            <Select
              value={newWorkflowKind}
              onValueChange={(value) =>
                setNewWorkflowKind(value as ReviewWorkflowKind)
              }
            >
              <SelectTrigger className="w-full" size="sm">
                <SelectValue placeholder="Select a workflow kind" />
              </SelectTrigger>
              <SelectContent>
                {Object.values(ReviewWorkflowKind).map((kind) => (
                  <SelectItem key={kind} value={kind}>
                    {convertKindToTabLabel(kind)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div>
            <label
              htmlFor="newWorkflowCsvFile"
              className="block text-xs font-medium"
            >
              CSV File
            </label>
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() =>
                  document.getElementById('newWorkflowCsvFile')?.click()
                }
              >
                Select CSV
              </Button>
              {newWorkflowCsvFileName && (
                <span className="text-muted-foreground text-sm">
                  {newWorkflowCsvFileName}
                </span>
              )}
              <Input
                id="newWorkflowCsvFile"
                type="file"
                accept=".csv"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setNewWorkflowCsvFile(e.target.files[0])
                    setNewWorkflowCsvFileName(e.target.files[0].name)
                  }
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className="mb-1 text-xs font-medium">
              Strict Document Classification
            </p>
            <Switch
              id="shouldBeStrictDocumentClassification"
              checked={shouldBeStrictDocumentClassification}
              onCheckedChange={setShouldBeStrictDocumentClassification}
            />
          </div>

          <div>
            <label
              htmlFor="newWorkflowCobrand"
              className="block text-xs font-medium"
            >
              Cobrand
            </label>
            <Select
              value={newWorkflowCobrand}
              onValueChange={(value) =>
                setNewWorkflowCobrand(value as ReviewWorkflowCobrand)
              }
            >
              <SelectTrigger className="w-full" size="sm">
                <SelectValue placeholder="Select Cobrand" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={ReviewWorkflowCobrand.AOS}>
                  AOS (Allen & Overy)
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div>
            <label
              htmlFor="newWorkflowDocumentClassificationTags"
              className="block text-xs font-medium"
            >
              Document Classification Tags
            </label>
            <TagInput
              placeholder="Select document classification tags"
              shouldShowSuggestedTags
              allowCreatingNewTags={false}
              selectedTagValues={selectedDocumentClassificationTags}
              setSelectedTagValues={setSelectedDocumentClassificationTags}
              sortedTags={documentClassificationTags.map((tag) => ({
                badgeDisplayText: tag.name,
                value: tag.id,
                component: (
                  <SimpleDocumentClassificationPill
                    tagName={tag.name}
                    tagId={tag.id}
                  />
                ),
              }))}
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={() => setWorkflowModalState(WorkflowModalState.Selector)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleNewWorkflowSubmit}
              isLoading={isSubmittingNewWorkflow}
              disabled={
                isSubmittingNewWorkflow ||
                !newWorkflowName ||
                !newWorkflowCsvFile
              }
            >
              {isSubmittingNewWorkflow ? 'Creating…' : 'Create Workflow'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultNewWorkflow
