import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { EventKind } from 'openapi/models/EventKind'
import { WorkflowDefinition } from 'openapi/models/WorkflowDefinition'
import { WorkflowEventStatus } from 'openapi/models/WorkflowEventStatus'
import Services from 'services'

import { HarveySocketTask } from 'utils/use-harvey-socket'
import { ToFrontendKeys } from 'utils/utils'

import { mergeSteps } from 'components/assistant/workflows/utils/utils'

interface AssistantWorkflowState {
  workflowDefinition: WorkflowDefinition | null
  currentEvent: (WorkflowEventStatus & { isPending?: boolean }) | null
  blockStores: Record<number, any>
  pendingMessage: boolean
  streamInProgress: boolean
}

interface AssistantWorkflowAction {
  setCurrentWorklow: (
    definition: WorkflowDefinition,
    event: WorkflowEventStatus | null
  ) => void
  workflowSetter: (socketState: Partial<HarveySocketTask>) => void
  reset: () => void
  addBlockStore: (stepIdx: number, blockStore: any) => void
  setPendingEventId: (eventId: number) => void
  getEventId: () => Promise<number>
  setPendingMessage: (pending: boolean) => void
  setStreamInProgress: (inProgress: boolean) => void
}

const initialState: AssistantWorkflowState = {
  workflowDefinition: null,
  currentEvent: null,
  blockStores: {},
  // Whether a socket message is getting sent
  pendingMessage: false,
  // Whether the socket is currently streaming
  streamInProgress: false,
}

export const useAssistantWorkflowStore = create(
  devtools(
    immer<AssistantWorkflowState & AssistantWorkflowAction>((set, get) => ({
      ...initialState,

      reset: () => {
        // TODO: Is there a better way to purge zudstand stores for blockStores instead
        // of just orphaning them?
        set(() => initialState)
      },

      getEventId: async (): Promise<number> => {
        const currEventId = get().currentEvent?.eventId
        if (!currEventId) {
          const { id } = await Services.Backend.Post<{ id: number }>('event', {
            kind: EventKind.ASSISTANT,
          })
          get().setPendingEventId(id)
          return id
        }
        return currEventId
      },

      setPendingEventId: (eventId: number) => {
        set((state) => {
          state.currentEvent = {
            eventId,
            isPending: true,
            isCompleted: false,
            steps: state.currentEvent?.steps || [],
          }
        })
      },

      addBlockStore: (stepIdx, blockStore) => {
        set((state) => {
          state.blockStores[stepIdx] = blockStore
        })
      },

      setCurrentWorklow: (
        definition: WorkflowDefinition,
        event: WorkflowEventStatus | null
      ) => {
        set((state) => {
          state.workflowDefinition = definition

          if (
            !state.currentEvent ||
            (event && state.currentEvent.eventId !== event.eventId)
          ) {
            state.currentEvent = event
          }
        })
      },

      workflowSetter: (socketState: Partial<HarveySocketTask>) => {
        const newWorkflow = socketState.workflow
          ? ToFrontendKeys(socketState.workflow)
          : null
        if (!newWorkflow) return

        set((state) => {
          // If it's a full workflow update, just set it
          // If there's no existing workflow, just set it
          if (!newWorkflow.isPartialUpdate || !state.currentEvent) {
            state.currentEvent = newWorkflow
            return
          }

          // Otherwise merge top-level fields + steps
          const mergedSteps = mergeSteps(
            get().currentEvent?.steps ?? [],
            newWorkflow.steps ?? []
          )

          // Merge everything else (isCompleted, eventId, etc.)
          state.currentEvent = {
            ...newWorkflow,
            steps: mergedSteps,
          }
        })
      },

      setPendingMessage: (pending: boolean) => {
        set((state) => ({
          ...state,
          pendingMessage: pending,
        }))
      },

      setStreamInProgress: (inProgress: boolean) => {
        set((state) => {
          state.streamInProgress = inProgress
        })
      },
    }))
  )
)
