import * as React from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'
import { z } from 'zod'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import Modal from 'components/common/flows/profile-setup/components/modal'
import ModalClose from 'components/common/flows/profile-setup/components/modal-close'
import {
  PROFILE_SETUP_STEP_SKIPPED_EVENT,
  useSkipOnboarding,
} from 'components/common/flows/profile-setup/hooks/use-skip-onboarding'
import { ProfileSetupFlowType } from 'components/common/flows/profile-setup/profile-setup'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import ProfileLanguageForm, {
  FORM_DESCRIPTION,
  FORM_HEADING,
} from 'components/common/profile/profile-preferred-language-form'
import { profileDataIsComplete } from 'components/common/user-profile-store'

import { JobTitleStepType } from './job-title-step'
import { YearsExpStepType } from './years-exp-step'

export type PrimaryLanguageStepType = Step<
  YearsExpStepType['stepOutput'] | JobTitleStepType['stepOutput'],
  {
    preferredLanguage: string
  }
>

export const zPreferredLanguage = z.string().max(100, 'Language is too long')
const primaryLanguageSchema = z.object({
  preferredLanguage: zPreferredLanguage,
  showOther: z.boolean().optional(),
})

export type PrimaryLanguageSchema = z.infer<typeof primaryLanguageSchema>

const PrimaryLanguageStep: React.FC = () => {
  const { goBack, navigateToStep, exit } =
    useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<PrimaryLanguageStepType>()
  const form = useForm<PrimaryLanguageSchema>({
    resolver: zodResolver(primaryLanguageSchema),
  })
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const handleSkipOnboarding = useSkipOnboarding({ stepInput, exit })
  const canSkipSteps = userInfo.isCreateUserProfilesOptionalStepsUser

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent(
      'profile_setup_primary_language_step_viewed',
      preparedEventProperties
    )
  }, [stepInput, trackEvent])

  const handleContinue = form.handleSubmit((data) => {
    const stepOutput = {
      ...stepInput,
      preferredLanguage: data.preferredLanguage,
    }
    if (profileDataIsComplete(stepOutput)) {
      navigateToStep('profile-complete-step', stepOutput)
    } else {
      navigateToStep('profile-complete-later-step', stepOutput)
    }
  })

  const handleSkip = () => {
    navigateToStep('profile-complete-later-step', {
      ...stepInput,
    })
    trackEvent(PROFILE_SETUP_STEP_SKIPPED_EVENT, {
      ...prepareEventProperties(stepInput),
      step: 'primary_language',
    })
  }

  return (
    <Modal
      goBack={goBack}
      handleContinue={handleContinue}
      handleSkip={canSkipSteps ? handleSkip : undefined}
      currentStep={stepInput.stepCounter}
      totalSteps={stepInput.totalSteps}
      nextDisabled={!form.formState.isValid}
      heading={FORM_HEADING}
      description={FORM_DESCRIPTION}
    >
      {userInfo.isCreateUserProfilesOptionalUser ? (
        <ModalClose onClick={handleSkipOnboarding} />
      ) : null}
      <ProfileLanguageForm control={form.control} />
    </Modal>
  )
}

export default PrimaryLanguageStep
