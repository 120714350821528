import { NavigateOptions } from 'react-router-dom'

import { QueryClient } from '@tanstack/react-query'
import { GridApi } from 'ag-grid-community'
import CryptoJS from 'crypto-js'
import {
  addMinutes,
  addSeconds,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  max,
} from 'date-fns'
import _, { isUndefined } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import { FileSpreadsheet, FileText } from 'lucide-react'
import pluralize from 'pluralize'
import { v4 as uuidv4 } from 'uuid'

import { Event } from 'models/event'
import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { EventKind } from 'openapi/models/EventKind'
import { EventStatus } from 'openapi/models/EventStatus'
import { FileContainerType } from 'openapi/models/FileContainerType'
import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { ReviewEventRunType } from 'openapi/models/ReviewEventRunType'
import { SourceType } from 'openapi/models/SourceType'
import { TagScope } from 'openapi/models/TagScope'
import type { VaultFile } from 'openapi/models/VaultFile'
import type { VaultFolder } from 'openapi/models/VaultFolder'
import type { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'
import { VaultFolderMetadataAllOfDescendantFiles } from 'openapi/models/VaultFolderMetadataAllOfDescendantFiles'
import { Maybe } from 'types'
import { FileType, FileTypeReadableName } from 'types/file'
import { HistoryItem } from 'types/history'

import { createFileName } from 'utils/file-utils'
import { parsedName } from 'utils/file-utils'
import { SafeRecord } from 'utils/safe-types'
import { Source, TaskStatus, TaskType } from 'utils/task'
import {
  LONG_TOAST_DURATION,
  displayErrorMessage,
  displaySuccessMessage,
  displayWarningMessage,
} from 'utils/toast'
import { EM_DASH, parseIsoString, ToBackendKeys } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import {
  cellRendererMap,
  CompoundCellRenderer,
} from 'components/vault/query-detail/data-grid/cells/cell-renderer/cell-renderer'
import { ReviewHistoryItem } from 'components/vault/query-detail/vault-query-detail-store'
import {
  ColumnDataType,
  DocumentClassificationAnalyticsData,
  MAX_QUESTION_CHAR_LENGTH,
  MIN_QUESTION_CHAR_LENGTH,
  projectsPath,
  queriesPath,
  QueryQuestion,
  REMOVE_PARAMS,
  ReviewAnswer,
  ReviewCellStatus,
  ReviewColumn,
  ReviewEvent,
  VaultItem,
  VaultItemStatus,
  VaultItemType,
  VaultItemWithIndex,
} from 'components/vault/utils/vault'
import { FileHierarchy, FileToUpload } from 'components/vault/utils/vault'
import { WorkflowModalState } from 'components/vault/workflows/vault-workflow-store'

import { DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING } from './use-document-classification-store'
import {
  ClearQueryErrors,
  CreateVaultFolder,
  CreateVaultReviewQuery,
  FetchVaultFoldersMetadata,
  ReviewWorkflow,
  UploadVaultFiles,
  VaultProjectMetadata,
} from './vault-fetcher'
import {
  VaultCurrentStreamingState,
  VaultExtraSocketState,
  VaultReviewSocketState,
  VaultSocketSetter,
  VaultState,
} from './vault-store'
import { pluralizeDocuments, pluralizeFiles } from './vault-text-utils'

export const getNumSelectedRows = (
  gridApi: GridApi
): { numSelectedRows: number; numRows: number } => {
  let numRows = 0
  let numSelectedRows = 0
  gridApi.forEachLeafNode((node) => {
    numRows++
    if (node.isSelected()) numSelectedRows++
  })
  return { numSelectedRows, numRows }
}

/**
 * Retrieves a list of folders starting from a specified folder and moving up the hierarchy to the root.
 * Each folder is found by its ID, starting with the `startingFolderId`, and moving up the hierarchy by accessing
 * each folder's `parentId` until no parent is found (i.e., the root folder is reached).
 */
export const getFoldersOnPath = (
  startingFolderId: string,
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
) => {
  const foldersOnPath = []
  let folderId: Maybe<string> = startingFolderId
  while (folderId) {
    const folder: VaultFolder | undefined = folderIdToVaultFolder[folderId]
    if (!folder) return undefined
    foldersOnPath.push(folder)
    folderId = folder.parentId
  }
  return foldersOnPath.reverse()
}

type GetFileCountHeaderStringProps = {
  numFiles: number
  shouldPollForHistoryItem: boolean
  processedFileIds?: string[]
  isFilterActive?: boolean
  visibleChildrenLength?: number
}

export const getFileCountHeaderString = ({
  numFiles,
  shouldPollForHistoryItem,
  processedFileIds,
  isFilterActive,
  visibleChildrenLength,
}: GetFileCountHeaderStringProps): string => {
  const totalFileText = pluralizeDocuments(numFiles)
  if (isFilterActive && !isNil(visibleChildrenLength)) {
    return `Viewing ${visibleChildrenLength} of ${totalFileText}`
  }
  if (numFiles === 0) return ''

  // When shouldPollForHistoryItem is false, it means we are loading data from the SSE endpoint
  if (processedFileIds && processedFileIds.length < numFiles) {
    const action = shouldPollForHistoryItem ? 'Processed' : 'Loaded'
    return `${action} ${processedFileIds.length} of ${totalFileText}`
  }

  return totalFileText
}

export const getEtaDisplayString = (
  eta: Date,
  strict: boolean = false,
  prefix: string | null = 'Estimated'
): string => {
  // We use Date.now() to avoid timezone issues by ensuring time is in UTC
  const now = new Date(Date.now())
  const etaText =
    eta < addMinutes(now, 1)
      ? formatDistanceToNow(addSeconds(now, 1))
      : strict
      ? formatDistanceToNowStrict(eta, { unit: 'minute' })
      : formatDistanceToNow(eta)
  return _.upperFirst([prefix, etaText, 'left'].filter(Boolean).join(' '))
}

export const isUrlExpired = (url: string, expirationKey: string): boolean => {
  if (isEmpty(url)) return true

  const urlObj = new URL(url)
  const expirationParam = urlObj.searchParams.get(expirationKey)
  if (!expirationParam) return true

  // Decode URL-encoded characters and convert to a Date object
  const expirationDate = new Date(decodeURIComponent(expirationParam))

  // Get the current date and time in UTC
  const currentDate = new Date()

  // Check if the current date is past the expiration date
  return currentDate > expirationDate
}

export const sumFileSizesInBytes = async (files: File[]): Promise<number> => {
  const getFileSize = async (file: File) => {
    return file.size
  }

  const fileSizes = await Promise.all(files.map(getFileSize))
  return fileSizes.reduce((a, b) => a + b, 0)
}

export const getVaultProjects = ({
  allFolderIdToVaultFolder,
  rootVaultFolderIds,
  userId,
  exampleProjectIds,
  sharedProjectIds,
}: {
  allFolderIdToVaultFolder: SafeRecord<string, VaultFolder>
  rootVaultFolderIds: Set<string>
  userId: string
  exampleProjectIds?: Set<string>
  sharedProjectIds?: Set<string>
}): VaultFolder[] => {
  return (
    Array.from(rootVaultFolderIds)
      .map((folderId) => allFolderIdToVaultFolder[folderId])
      .filter(Boolean) as VaultFolder[]
  )
    .filter(
      (folder) =>
        sharedProjectIds?.has(folder.id) ||
        folder.userId === userId ||
        (exampleProjectIds && exampleProjectIds.has(folder.id))
    )
    .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
}

export const getVaultFileIds = ({
  folderId,
  parentIdToVaultFolderIds,
  folderIdToVaultFileIds,
}: {
  folderId: string
  parentIdToVaultFolderIds: SafeRecord<string, string[]>
  folderIdToVaultFileIds: SafeRecord<string, string[]>
}): string[] => {
  const folderFileIds: string[] = folderIdToVaultFileIds[folderId] ?? []

  const subFolderIds = parentIdToVaultFolderIds[folderId] ?? []
  const subfolderFileIds = subFolderIds.flatMap((subFolderId) =>
    getVaultFileIds({
      folderId: subFolderId,
      parentIdToVaultFolderIds,
      folderIdToVaultFileIds,
    })
  )

  return [...folderFileIds, ...subfolderFileIds]
}

export const filterAndSortFolders = ({
  folderIdToVaultFolder,
  parentIdToVaultFolderIds,
  userId,
  parentId,
  projectId,
  exampleProjectIds,
  isSharedProject,
}: {
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  parentIdToVaultFolderIds: SafeRecord<string, string[]>
  userId: string
  parentId: string
  projectId: string
  exampleProjectIds: Set<string>
  isSharedProject: boolean
}): VaultFolder[] => {
  return (
    (parentIdToVaultFolderIds[parentId] ?? [])
      .map((folderId) => folderIdToVaultFolder[folderId])
      .filter(Boolean) as VaultFolder[]
  )
    .filter(
      (folder) =>
        folder.userId === userId ||
        exampleProjectIds.has(projectId) ||
        isSharedProject
    )
    .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
}

export const computeQueryDisabledReason = (
  currentProjectMetadata: VaultFolderMetadata,
  pendingQueryFileIds: string[] | null
) => {
  if (currentProjectMetadata.totalFiles === 0) {
    return 'No files uploaded'
  }
  if (pendingQueryFileIds) {
    const pendingQueryFiles = currentProjectMetadata.descendantFiles?.filter(
      (file) => pendingQueryFileIds.includes(file.id)
    )
    if (
      pendingQueryFiles?.every(
        (file) => !file.readyToQuery && file.failureReason
      )
    ) {
      if (pendingQueryFiles.length > 1) {
        return 'All selected files failed to be processed'
      } else {
        return 'The selected file failed to be processed'
      }
    }
    if (pendingQueryFiles?.some((file) => !file.readyToQuery)) {
      if (pendingQueryFiles.length > 1) {
        return 'Selected files are still processing…'
      } else {
        return 'The selected file is still processing…'
      }
    }
  } else {
    if (
      currentProjectMetadata.failedFiles === currentProjectMetadata.totalFiles
    ) {
      if (currentProjectMetadata.totalFiles > 1) {
        return 'All files failed to be processed'
      } else {
        return 'The file failed to be processed'
      }
    }
    if (
      currentProjectMetadata.completedFiles !==
      currentProjectMetadata.totalFiles
    ) {
      if (currentProjectMetadata.totalFiles > 1) {
        return 'Files are still processing…'
      } else {
        return 'The file is still processing…'
      }
    }
  }
  return undefined
}

export const isFileFinishProcessing = (file: VaultFile): boolean => {
  const status = determineFileStatus(file)
  return (
    status === VaultItemStatus.readyToQuery ||
    status === VaultItemStatus.failedToUpload ||
    status === VaultItemStatus.recoverableFailure ||
    status === VaultItemStatus.unrecoverableFailure
  )
}

export const determineFolderStatus = (
  folder: VaultFolder,
  projectsMetadata: SafeRecord<string, VaultFolderMetadata>
): VaultItemStatus => {
  const folderMetadata = projectsMetadata[folder.id] as
    | VaultFolderMetadata
    | undefined
  if (!folderMetadata) {
    return VaultItemStatus.default
  }
  const { completedFiles, totalFiles, failedFiles } = folderMetadata
  if (completedFiles === totalFiles) {
    if (failedFiles > 0) {
      if (failedFiles === totalFiles) {
        // We don't differentiate between recoverable and unrecoverable failures for folders
        return VaultItemStatus.allFilesFailed
      } else {
        // If there are some failed files, but not all, we show the folder as ready to query with failed files
        return VaultItemStatus.readyToQueryWithFailedFiles
      }
    }
    return VaultItemStatus.readyToQuery
  }
  if (
    folderMetadata.descendantFiles?.some(
      (file) => !file.path && !file.failureReason
    )
  ) {
    return VaultItemStatus.uploading
  }
  return VaultItemStatus.processing
}

export const determineFileStatus = (file: VaultFile): VaultItemStatus => {
  if (file.readyToQuery) {
    return VaultItemStatus.readyToQuery
  }
  if (file.failureReason && !isEmpty(file.failureReason.trim())) {
    if (!file.path) {
      // If the file has no path, it's not uploaded yet
      return VaultItemStatus.failedToUpload
    } else if (file.failureRecoverable) {
      return VaultItemStatus.recoverableFailure
    } else {
      return VaultItemStatus.unrecoverableFailure
    }
  }
  if (file.path.length === 0) {
    // If the file has no path, it's still uploading
    return VaultItemStatus.uploading
  }
  return VaultItemStatus.processing
}

const findOrCreateNode = (
  current: FileHierarchy,
  part: string,
  prefix: string
) => {
  let child = current.children.find((c) => c.name === part)
  if (!child) {
    child = {
      id: uuidv4(),
      prefix: prefix,
      name: part,
      files: [],
      children: [],
    }
    current.children.push(child)
  }
  return child
}

type HandleDroppedFilesOrDeletedFilesProps = {
  latestDroppedFiles: File[]
  hasFolderName: boolean
  currentTotalSizeInBytes: number
  setTotalFileSizeInBytes: (size: number) => void
  setFilesToUpload: (files: FileToUpload[]) => void
  checkForDuplicates?: boolean
  namesForExistingVaultFilesAndFilesToUpload?: string[]
  existingFilesToUpload?: FileToUpload[]
  fileSource?: FileUploadSource
}

export const handleDroppedFilesOrDeletedFiles = async ({
  latestDroppedFiles,
  hasFolderName,
  currentTotalSizeInBytes,
  setTotalFileSizeInBytes,
  setFilesToUpload,
  namesForExistingVaultFilesAndFilesToUpload = [],
  existingFilesToUpload = [],
  fileSource,
}: HandleDroppedFilesOrDeletedFilesProps): Promise<{
  duplicates: FileToUpload[]
}> => {
  setTotalFileSizeInBytes(
    currentTotalSizeInBytes + (await sumFileSizesInBytes(latestDroppedFiles))
  )
  const existingNamesSet = new Set(
    hasFolderName
      ? existingFilesToUpload.map((file) => file.name)
      : namesForExistingVaultFilesAndFilesToUpload
  )

  const duplicates: FileToUpload[] = []
  const newFilesToUpload: FileToUpload[] = []
  latestDroppedFiles.forEach((file: File) => {
    const parsedFileName = parsedName(
      (file as { path?: string; name: string }).path ?? file.name
    )
    if (existingNamesSet.has(parsedFileName)) {
      // Duplicate found
      duplicates.push({
        file,
        name: parsedFileName,
        fileSource,
      })
    } else {
      existingNamesSet.add(parsedFileName)
      newFilesToUpload.push({
        file,
        name: parsedFileName,
        fileSource,
      })
    }
  })

  setFilesToUpload([...newFilesToUpload, ...existingFilesToUpload])

  return { duplicates }
}

type HandleFolderNameChangeProps = {
  hasFolderName: boolean
  existingVaultFileNames: string[]
  existingFilesToUpload: FileToUpload[]
  setFilesToUpload: (files: FileToUpload[]) => void
}

export const handleFolderNameChange = async ({
  hasFolderName,
  existingVaultFileNames,
  existingFilesToUpload,
  setFilesToUpload,
}: HandleFolderNameChangeProps) => {
  const filesToUpload = existingFilesToUpload.map((file) => ({
    file: file.file,
    name: file.file.name,
  }))
  const existingNamesSet = new Set<string>()
  if (hasFolderName) {
    existingVaultFileNames.forEach((name) => existingNamesSet.add(name))
  }
  for (let i = 0; i < filesToUpload.length; i++) {
    const currentFile = filesToUpload[i]
    filesToUpload.slice(0, i).forEach((file) => {
      existingNamesSet.add(file.name)
    })
    currentFile.name = createFileName(currentFile.file.name, existingNamesSet)
    existingNamesSet.add(currentFile.name)
  }

  setFilesToUpload(filesToUpload)
}

export const computeFileHierarchy = (files: FileToUpload[]): FileHierarchy => {
  const delimiterCharacter = '/'
  const children: FileHierarchy[] = []
  const root = {
    id: uuidv4(),
    name: '',
    prefix: '',
    files: files.filter(
      (file) => file.name.split(delimiterCharacter).length === 1
    ),
    children: children,
  }
  files
    .filter((file) => file.name.split(delimiterCharacter).length > 1)
    .map((file: FileToUpload) => {
      const parts = file.name.split(delimiterCharacter)
      let current = root
      let prefix = root.prefix

      // Process all parts except the last one as directories
      parts.slice(0, -1).map((part: string) => {
        prefix += part + delimiterCharacter
        current = findOrCreateNode(current, part, prefix)
      })

      current.files.push(file)
    })

  return root
}

export const createVaultFoldersHierarchy = async (
  fileHierarchy: FileHierarchy,
  rootFolderId: string
): Promise<{
  folderIdToVaultFolderId: Record<string, string>
  createdFolders: VaultFolder[]
}> => {
  const folderIdToVaultFolderId: Record<string, string> = {}
  folderIdToVaultFolderId[fileHierarchy.id] = rootFolderId

  const currChildrenStack = fileHierarchy.children.map((child) => ({
    id: child.id,
    name: child.name,
    parentVaultFolderId: rootFolderId,
    children: child.children,
  }))

  const createdFolders = []

  while (currChildrenStack.length > 0) {
    const currChild = currChildrenStack.shift()
    if (currChild) {
      const newFolder = await CreateVaultFolder(
        currChild.name,
        currChild.parentVaultFolderId
      )
      createdFolders.push(newFolder)
      const newFolderId = newFolder.id
      folderIdToVaultFolderId[currChild.id] = newFolderId
      currChild.children.map((child) => {
        currChildrenStack.push({
          id: child.id,
          name: child.name,
          parentVaultFolderId: newFolderId,
          children: child.children,
        })
      })
    }
  }

  return { folderIdToVaultFolderId, createdFolders }
}

type OptimisticallyCreateVaultFilesProps = {
  files: FileToUpload[]
  folderId: string
  prefix: string
  projectId: string | undefined
  upsertVaultFiles: (files: VaultFile[], projectId: string | undefined) => void
  updateProjectMetadata: () => void
}
export const optimisticallyCreateVaultFiles = ({
  files,
  folderId,
  prefix,
  projectId,
  upsertVaultFiles,
  updateProjectMetadata,
}: OptimisticallyCreateVaultFilesProps) => {
  // if we're still submitting, we'll close the dialog and continue in the background
  // optimistically update the UI to show the files being uploaded
  upsertVaultFiles(
    files.map((file: FileToUpload) => {
      const newFileName = file.name.replace(prefix, '')
      return {
        id: `${folderId}-${newFileName}`, // we don't have a file id, so we'll use the folder id + file name
        name: newFileName,
        vaultProjectId: projectId,
        vaultFolderId: folderId,
        containerType: FileContainerType.VAULT,
        path: '', // we don't have a path, so we'll leave it empty
        url: '', // we don't have a url, so we'll leave it empty
        size: file.file.size,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        contentType: file.file.type,
        tags: [],
      }
    }) as VaultFile[],
    projectId
  )
  updateProjectMetadata()
}

type OptimisticallyCreateVaultHierarchyFilesProps = {
  rootFolderId: string
  fileHierarchy: FileHierarchy
  folderIdToVaultFolderId: Record<string, string>
  projectId: string | undefined
  upsertVaultFiles: (files: VaultFile[], projectId: string | undefined) => void
  updateProjectMetadata: () => void
}

export const optimisticallyCreateVaultHierarchyFiles = ({
  rootFolderId,
  fileHierarchy,
  folderIdToVaultFolderId,
  projectId,
  upsertVaultFiles,
  updateProjectMetadata,
}: OptimisticallyCreateVaultHierarchyFilesProps) => {
  const filesGroupedByVaultFolderId: Record<string, FileToUpload[]> = {}
  const vaultFolderIdToPrefix: Record<string, string> = {}
  vaultFolderIdToPrefix[rootFolderId] = ''

  const stack = [fileHierarchy]
  while (stack.length > 0) {
    const currNode = stack.shift()
    if (currNode) {
      const vaultFolderId = folderIdToVaultFolderId[currNode.id]
      // we are not really creating files here
      // instead we are saving them to the local zustand store so that we can optimistically update UI later
      optimisticallyCreateVaultFiles({
        files: currNode.files,
        folderId: vaultFolderId,
        prefix: currNode.prefix,
        projectId,
        upsertVaultFiles,
        updateProjectMetadata,
      })
      if (currNode.files.length > 0) {
        filesGroupedByVaultFolderId[vaultFolderId] = currNode.files
        vaultFolderIdToPrefix[vaultFolderId] = currNode.prefix
      }
      currNode.children.map((child: FileHierarchy) => {
        stack.push(child)
      })
    }
  }
  return { filesGroupedByVaultFolderId, vaultFolderIdToPrefix }
}

const upsertFailedFilesAndDisplayError = (
  folderId: string,
  projectId: string,
  failedFileInfos: {
    name: string
    error: string
    backingFile?: FileToUpload
  }[],
  successCount: number
  // eslint-disable-next-line max-params
) => {
  const failedFiles: VaultFile[] = failedFileInfos.map((failedFileInfo) => {
    return {
      id: `${folderId}-${failedFileInfo.name}`, // we don't have a file id, so we'll use the folder id + file name
      name: failedFileInfo.name,
      vaultFolderId: folderId,
      vaultProjectId: projectId,
      containerType: FileContainerType.VAULT,
      path: '', // we don't have a path, so we'll leave it empty
      url: '', // we don't have a url, so we'll leave it empty
      size: failedFileInfo.backingFile?.file.size ?? 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      contentType: failedFileInfo.backingFile?.file.type ?? '',
      failureReason: failedFileInfo.error,
      failureRecoverable: false,
      tags: [],
    }
  })

  if (successCount === 0) {
    displayErrorMessage(
      `Failed to upload ${pluralizeFiles(
        failedFiles.length
      )}, please refresh the page and try again`,
      LONG_TOAST_DURATION
    )
  } else {
    displayWarningMessage(
      `${pluralizeFiles(successCount)} uploaded, ${pluralizeFiles(
        failedFiles.length
      )} failed to upload, please refresh the page and try again`,
      LONG_TOAST_DURATION
    )
  }
  return failedFiles
}

type UploadVaultFilesProps = {
  accessToken: string
  files: FileToUpload[]
  folderId: string
  projectId: string
  prefix: string
  shouldSetUploadTimestamp: boolean
  uploadedAt: string
}

type UploadVaultFilesResult = {
  files: VaultFile[]
  failedFiles: VaultFile[]
}

export const uploadVaultFiles = async ({
  accessToken,
  files,
  folderId,
  projectId,
  prefix,
  shouldSetUploadTimestamp,
  uploadedAt,
}: UploadVaultFilesProps) => {
  try {
    const response = await UploadVaultFiles({
      accessToken,
      files,
      vaultFolderId: folderId,
      prefix,
      shouldSetUploadTimestamp,
      uploadedAt,
    })
    const result: UploadVaultFilesResult = {
      files: response.files as VaultFile[],
      failedFiles: [],
    }

    if (
      response.failedFilesWithErrors &&
      !isEmpty(response.failedFilesWithErrors)
    ) {
      const failedFiles = upsertFailedFilesAndDisplayError(
        folderId,
        projectId,
        response.failedFilesWithErrors.map((failedFile) => ({
          name: failedFile.name,
          error: failedFile.error,
          backingFile: files.find(
            (file) => file.name === prefix + failedFile.name
          ),
        })),
        response.files.length
      )
      result.failedFiles = failedFiles as VaultFile[]
    }
    return result
  } catch (error) {
    const failedFiles = upsertFailedFilesAndDisplayError(
      folderId,
      projectId,
      files.map((file) => ({
        name: file.name.replace(prefix, ''),
        error: 'Failed to upload',
        backingFile: file,
      })),
      0
    )
    return {
      files: [],
      failedFiles: failedFiles as VaultFile[],
    }
  }
}

type CreateFoldersAndFilesProps = {
  accessToken: string
  filesToUpload: FileToUpload[]
  rootFolderId: string
  projectId: string
  prefix: string
  areAllFilesProcessed: boolean
  upsertVaultFolders: (
    folders: VaultFolder[],
    currentUserId: string,
    isExampleProject?: boolean,
    projectId?: string
  ) => void
  upsertVaultFiles: (files: VaultFile[], projectId: string | undefined) => void
  updateProjectMetadata: () => void
  addFolderIdToFilesUploading: (folderId: string) => void
  removeFolderIdFromFilesUploading: (folderId: string) => void
  navigateHandler: (hierarchyRootFolderId: string) => void
  currentUserId: string
}

export const createFoldersAndFiles = async ({
  accessToken,
  filesToUpload,
  rootFolderId,
  projectId,
  prefix,
  areAllFilesProcessed,
  upsertVaultFolders,
  upsertVaultFiles,
  updateProjectMetadata,
  addFolderIdToFilesUploading,
  removeFolderIdFromFilesUploading,
  navigateHandler,
  currentUserId,
}: CreateFoldersAndFilesProps) => {
  const fileHierarchy = computeFileHierarchy(filesToUpload)
  const uploadedAt = new Date(Date.now()).toISOString()

  if (fileHierarchy.children.length === 0) {
    optimisticallyCreateVaultFiles({
      files: fileHierarchy.files,
      folderId: rootFolderId,
      prefix,
      projectId,
      upsertVaultFiles,
      updateProjectMetadata,
    })
    addFolderIdToFilesUploading(rootFolderId)
    navigateHandler(rootFolderId)
    const { files, failedFiles } = await uploadVaultFiles({
      accessToken,
      files: fileHierarchy.files,
      folderId: rootFolderId,
      projectId,
      prefix,
      shouldSetUploadTimestamp: areAllFilesProcessed,
      uploadedAt,
    })
    removeFolderIdFromFilesUploading(rootFolderId)
    upsertVaultFiles([...files, ...failedFiles], projectId)
  } else {
    // going to do two passes through the tree because
    // 1 - we need to create folders for the files to upload to first (we need the folder.id) & preserve the file hierarchy when uploading later
    // 2 - we want to optimistically upload the files in the background

    // first lets traverse the hierarchy and create the necessary vault folders
    // then we are able to map the local folderIds to the actual vault folder ids
    const { folderIdToVaultFolderId, createdFolders } =
      await createVaultFoldersHierarchy(fileHierarchy, rootFolderId)
    upsertVaultFolders(createdFolders, currentUserId, false, projectId)

    // next traverse the hierarchy to create necessary files
    // return the files grouped by vault folder id - needed to then upload files to the right vault folder
    // returns the prefix for each vault folder id - needed to clean up the file name before uploading
    const { filesGroupedByVaultFolderId, vaultFolderIdToPrefix } =
      optimisticallyCreateVaultHierarchyFiles({
        rootFolderId,
        fileHierarchy,
        folderIdToVaultFolderId,
        projectId,
        upsertVaultFiles,
        updateProjectMetadata,
      })
    addFolderIdToFilesUploading(rootFolderId)
    navigateHandler(rootFolderId)
    const vaultFolderIds = Object.keys(filesGroupedByVaultFolderId)
    let isFirstRequest = true
    const files: VaultFile[] = []
    const failedFiles: VaultFile[] = []
    await Promise.all(
      vaultFolderIds.map(async (vaultFolderId) => {
        // Only set the upload timestamp on the first request
        const shouldSetUploadTimestamp = isFirstRequest && areAllFilesProcessed
        isFirstRequest = false

        const {
          files: uploadedFileIdsForFolder,
          failedFiles: failedFilesForFolder,
        } = await uploadVaultFiles({
          accessToken,
          files: filesGroupedByVaultFolderId[vaultFolderId],
          folderId: vaultFolderId,
          projectId,
          prefix: vaultFolderIdToPrefix[vaultFolderId],
          shouldSetUploadTimestamp,
          uploadedAt,
        })
        files.push(...uploadedFileIdsForFolder)
        failedFiles.push(...failedFilesForFolder)
      })
    )
    upsertVaultFiles([...files, ...failedFiles], projectId)
    removeFolderIdFromFilesUploading(rootFolderId)
  }
}

export const fetchFoldersMetadata = async (vaultFolder: VaultFolder) => {
  const metadata: Record<string, VaultFolderMetadata> = {}
  const folderId = vaultFolder.id
  const response = await FetchVaultFoldersMetadata(folderId)
  if (!Array.isArray(response) || response.length === 0) {
    metadata[folderId] = generateEmptyMetadata(folderId)
  } else {
    response.map((item) => {
      metadata[item.id] = item
    })
  }
  return metadata
}

export const generateEmptyMetadata = (
  folderId: string
): VaultFolderMetadata => {
  return {
    id: folderId,
    vaultProjectId: '',
    userId: '',
    userEmail: undefined,
    shareStatus: undefined,
    workspaceId: '',
    totalFiles: 0,
    folderSize: 0,
    completedFiles: 0,
    failedFiles: 0,
    latestUnprocessedFileUpdatedAt: undefined,
    fileNames: [],
    descendantFolders: [],
    descendantFiles: [],
    name: '',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  }
}

export const isEmptyMetadata = (metadata: VaultFolderMetadata): boolean => {
  return (
    metadata.userId === '' &&
    metadata.userEmail === undefined &&
    metadata.shareStatus === undefined &&
    metadata.workspaceId === '' &&
    metadata.totalFiles === 0 &&
    metadata.folderSize === 0 &&
    metadata.completedFiles === 0 &&
    metadata.failedFiles === 0
  )
}

const mergeProjectsMetadata = ({
  state,
  allFolderIdToVaultFolder,
  fileIdToVaultFile,
  localFileIds,
}: {
  state: VaultState
  allFolderIdToVaultFolder: SafeRecord<string, VaultFolder>
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  localFileIds: Set<string>
}) => {
  // first flatten all of the filenames to be used later for deduplication check
  const optimisticFileIds: Set<string> = new Set()
  Object.keys(state.allFoldersMetadata).forEach((folderId) => {
    // @ts-expect-error: we know that folderId is defined
    state.allFoldersMetadata[folderId]?.fileNames.forEach(
      (fileName: string) => {
        optimisticFileIds.add(`${folderId}-${fileName}`)
      }
    )
  })

  localFileIds.forEach((localFileId) => {
    const vaultFile = fileIdToVaultFile[localFileId]
    if (!vaultFile) return
    // do a deduplication check - if it doesn't exist then its an optimistically created file and should be counted
    const hasNoFilePath = !vaultFile.path
    const isFileNotUploaded = !optimisticFileIds.has(localFileId)

    // first let's handle the case where the folder exists in our project metadata
    // happens when uploading files to an existing project
    if (isFileNotUploaded && hasNoFilePath) {
      // This file is optimistically uploaded and not yet in the database
      let folderId: string | undefined = vaultFile.vaultFolderId
      state.allFoldersMetadata[folderId] ??= generateEmptyMetadata(folderId)
      // @ts-expect-error: we know that folderId is defined
      state.allFoldersMetadata[folderId]!.fileNames.push(vaultFile.name)

      // Update metadata for all parent folders
      while (folderId) {
        state.allFoldersMetadata[folderId] ??= generateEmptyMetadata(folderId)
        state.allFoldersMetadata[folderId]!.descendantFiles ??= []
        state.allFoldersMetadata[folderId]!.descendantFiles!.push(
          vaultFile as VaultFolderMetadataAllOfDescendantFiles
        )
        state.allFoldersMetadata[folderId]!.totalFiles += 1
        if (vaultFile.failureReason) {
          // If the file has a failure reason, it's failed to upload
          state.allFoldersMetadata[folderId]!.failedFiles += 1
          state.allFoldersMetadata[folderId]!.completedFiles += 1
        }
        state.allFoldersMetadata[folderId]!.folderSize += vaultFile.size || 0
        const previousLatestUnprocessedFileUpdatedAt =
          state.allFoldersMetadata[folderId]!.latestUnprocessedFileUpdatedAt
        state.allFoldersMetadata[folderId]!.latestUnprocessedFileUpdatedAt =
          previousLatestUnprocessedFileUpdatedAt
            ? max([
                parseIsoString(previousLatestUnprocessedFileUpdatedAt),
                parseIsoString(vaultFile.updatedAt),
              ]).toISOString()
            : vaultFile.updatedAt
        // Preserve lastFileUploadedAt for current project in case it has been optimistically set
        if (state.currentProjectMetadata.id === folderId) {
          state.allFoldersMetadata[folderId]!.lastFileUploadedAt =
            state.currentProjectMetadata.lastFileUploadedAt
        }

        const currentFolder: VaultFolder | undefined =
          allFolderIdToVaultFolder[folderId]
        folderId = currentFolder?.parentId
      }
    }
  })
}

export function updateProjectMetadata(state: VaultState) {
  mergeProjectsMetadata({
    state,
    allFolderIdToVaultFolder: state.allFolderIdToVaultFolder,
    fileIdToVaultFile: state.allFileIdToVaultFile,
    localFileIds: state.localFileIds,
  })

  if (state.currentProject) {
    if (
      !isEqual(
        state.currentProjectMetadata,
        state.allFoldersMetadata[state.currentProject.id]
      )
    ) {
      state.currentProjectMetadata =
        state.allFoldersMetadata[state.currentProject.id] ??
        generateEmptyMetadata(state.currentProject.id)
    }
    state.projectIdToFolderIds[state.currentProject.id]?.forEach((folderId) => {
      if (
        state.allFoldersMetadata[folderId] &&
        !isEqual(
          state.allFoldersMetadata[folderId],
          state.foldersMetadata[folderId]
        )
      ) {
        state.foldersMetadata[folderId] = state.allFoldersMetadata[folderId]
      }
    })
  }
  if (!state.currentProject) {
    state.currentProjectMetadata = generateEmptyMetadata('')
  }

  if (
    state.currentProject &&
    (state.currentProjectMetadata.completedFiles <
      state.currentProjectMetadata.totalFiles ||
      state.currentProjectMetadata.failedFiles > 0)
  ) {
    // Show processing progress if the project has files that are not processed
    state.showProcessingProgress[state.currentProject.id] = true
  }
}

export const generateEmptyVaultCurrentStreamingState = (
  queryId: string,
  isFromHistory?: boolean
): VaultCurrentStreamingState => {
  return {
    query: '',
    title: '',
    isLoading: false,
    headerText: '',
    response: '',
    queryId: queryId,
    progress: 0,
    sources: [],
    annotations: {},
    isFromHistory: isFromHistory ?? false,
    taskType: null,
    vaultFolderId: null,
    numFiles: 0,
    numQuestions: 0,
    fileIds: [],
    sourcedFileIds: [],
    createdAt: null,
    startedAt: null,
    completedAt: null,
    pausedAt: null,
    failedAt: null,
    maxTokenLimitReached: false,
    creatorUserEmail: null,
  }
}

export const generateEmptyVaultReviewSocketState =
  (): VaultReviewSocketState => {
    return {
      followUpQueries: [],
      questions: [],
      questionsNotAnswered: [],
      maxQuestionCharacterLength: 0,
      minQuestionCharacterLength: 0,
      columnHeaders: [],
      answers: {},
      errors: {},
      fileIdToSources: {},
      processedFileIds: [],
      suppressedFileIds: [],
      eta: null,
      columnOrder: [],
      isWorkflowRepsWarranties: false,
    }
  }

export const isAnswerEmpty = (answer: string): boolean => {
  return answer.trim() === '' || answer.trim().toLocaleLowerCase() === 'n/a'
}

export const getDisplayAnswer = (answer?: ReviewAnswer): string => {
  if (!answer || isAnswerEmpty(answer.text)) {
    return EM_DASH
  }

  if (answer.columnDataType && answer.rawResponse) {
    const cellRenderer = cellRendererMap[answer.columnDataType]
    if (cellRenderer instanceof CompoundCellRenderer) {
      return cellRenderer.renderCell(answer.rawResponse)
    }
    return cellRenderer.renderCell(answer.rawResponse[0])
  }

  if (answer.columnDataType === ColumnDataType.date) {
    const utcDate = parseIsoString(answer.text)
    if (isNaN(utcDate.getTime()) || utcDate.getTime() === 0) {
      // TODO: we should return the original text if the date is invalid
      // change this once we have a better date parsing solution
      return answer.text ? answer.text : EM_DASH
    }
    const dateFormat = new Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    }).format(utcDate)
    return dateFormat
  }
  // render EM_DASH if the answer text is empty
  return answer.text ? answer.text : EM_DASH
}

type Row = {
  [key: string]: any
}

export const computeRowDataDiff = (gridApi: GridApi, rowData: Row[]): Row[] => {
  const rowsToUpdate: Row[] = []
  rowData.forEach((row) => {
    const currentRowInGrid = gridApi.getRowNode(row.id)
    if (isEmpty(currentRowInGrid)) {
      rowsToUpdate.push(row)
      return
    }
    const currentRowInGridData = currentRowInGrid.data
    if (!isEqual(currentRowInGridData, row)) {
      rowsToUpdate.push(row)
    }
  })
  return rowsToUpdate
}

export const updateQueryStateForHistoryItem = (
  historyItem: HistoryItem,
  setReviewTask: VaultSocketSetter
) => {
  const taskData = {
    isFromHistory: true,
    query: historyItem.query,
    isLoading: historyItem.status === 'IN_PROGRESS',
    headerText: historyItem.headerText ?? '',
    response: historyItem.response,
    queryId: historyItem.id,
    progress:
      historyItem.status === 'IN_PROGRESS'
        ? 50
        : historyItem.status === 'COMPLETED'
        ? 100
        : 0,
    sources: historyItem.sources ?? [],
    annotations: historyItem.annotations,
    taskType: historyItem.kind as TaskType,
    vaultFolderId: historyItem.vaultFolderId,
    numFiles: historyItem.numFiles ?? 0,
    numQuestions: historyItem.numQuestions ?? 0,
    fileIds: historyItem.fileIds ?? [],
    createdAt: historyItem.created,
    startedAt: historyItem.startedAt ?? historyItem.created,
    completedAt:
      historyItem.status === 'COMPLETED' ? historyItem.updatedAt : null,
    failedAt: historyItem.status === 'ERRORED' ? historyItem.updatedAt : null,
    pausedAt: historyItem.status === 'CANCELLED' ? historyItem.updatedAt : null,
    metadata: historyItem.metadata,
  }
  if (historyItem.kind === TaskType.VAULT_REVIEW) {
    setReviewTask(taskData)
  }
}

export const updateQueryStateForEvent = ({
  event,
  setTask,
  setReviewTask,
}: {
  event: Event
  setTask: VaultSocketSetter
  setReviewTask: VaultSocketSetter
}) => {
  const historyItem = {
    ...event,
    created: new Date(event.created),
    updatedAt: new Date(event.updatedAt),
    // The response for event is sanitized in the user/history endpoint, we don't want to use this to override
    // markdown response version from the history item in the user/history/:id endpoint.
    response: undefined,
    // The following fields will not be returned from the user/history endpoint, we don't want to use these
    // to override the history item in the user/history/:id endpoint.
    sources: undefined,
    annotations: undefined,
    fileIds: undefined,
  }
  const taskData = {
    isFromHistory: true,
    query: historyItem.query,
    isLoading: historyItem.status === 'IN_PROGRESS',
    // This is a number in the backend, but we're using it as a string for the query ID
    queryId: historyItem.id.toString(),
    progress:
      historyItem.status === 'IN_PROGRESS'
        ? 50
        : historyItem.status === 'COMPLETED'
        ? 100
        : 0,
    taskType: historyItem.kind as TaskType,
    vaultFolderId: historyItem.vaultFolderId,
    numFiles: historyItem.numFiles ?? 0,
    numQuestions: historyItem.numQuestions ?? 0,
    createdAt: historyItem.created,
    startedAt: historyItem.created,
    completedAt:
      historyItem.status === 'COMPLETED' ? historyItem.updatedAt : null,
    failedAt: historyItem.status === 'ERRORED' ? historyItem.updatedAt : null,
    pausedAt: historyItem.status === 'CANCELLED' ? historyItem.updatedAt : null,
    metadata: historyItem.metadata,
    creatorUserEmail: historyItem.userId,
  }
  // TODO(@Nami) - Remove assistant chat task type pending product requirements
  if (
    historyItem.kind === TaskType.VAULT ||
    historyItem.kind === TaskType.ASSISTANT_CHAT ||
    historyItem.kind === TaskType.ASSISTANT_DRAFT
  ) {
    setTask(taskData)
  } else if (historyItem.kind === TaskType.VAULT_REVIEW) {
    setReviewTask(taskData)
  }
}

export const convertVaultStateToEvent = (
  state: VaultCurrentStreamingState
): Event => {
  const createdAt = state.createdAt ?? state.startedAt
  if (!createdAt) {
    throw new Error('Query createdAt is missing')
  }

  return {
    id: Number(state.queryId!),
    status: state.isLoading
      ? TaskStatus.IN_PROGRESS
      : state.failedAt
      ? TaskStatus.ERRORED
      : state.pausedAt
      ? TaskStatus.CANCELLED
      : TaskStatus.COMPLETED,
    // Trim query and response for each event
    query: _.trim(state.query),
    response: _.trim(state.response),
    kind: state.taskType!,
    created: createdAt.toISOString(),
    updatedAt: (getQueryUpdatedAt(state) ?? createdAt).toISOString(),
  }
}

export const getInProgressQuery = (
  queryIdToState: SafeRecord<string, VaultCurrentStreamingState>,
  taskType: TaskType
) => {
  return Object.values(queryIdToState).find(
    (state) =>
      state &&
      !state.isFromHistory &&
      state.queryId !== '' &&
      state.isLoading &&
      state.taskType === taskType
  )
}

export const getQueryUpdatedAt = (query: Maybe<VaultExtraSocketState>) => {
  if (!query) return null
  const allDates = [
    query.completedAt,
    query.failedAt,
    query.pausedAt,
    query.startedAt,
    query.createdAt,
  ].filter(Boolean) as Date[]
  if (allDates.length === 0) return null
  // Gets the latest date, sometimes completedAt/failedAt/pausedAt might be the latest,
  // sometimes startedAt might be the latest (retry or add more files)
  return max(allDates)
}

export const hasReviewErrors = (
  projectFileIds: Set<string>,
  reviewState?: VaultReviewSocketState
) => {
  if (!reviewState) return false

  const errorFileIds = Object.keys(reviewState.errors)
  const suppressedFileIds = reviewState.suppressedFileIds
  const processedFileIds = reviewState.processedFileIds
  return errorFileIds.some((id) =>
    isError({ projectFileIds, processedFileIds, suppressedFileIds, id })
  )
}

export const isError = ({
  projectFileIds,
  processedFileIds,
  suppressedFileIds,
  id,
}: {
  projectFileIds: Set<string>
  processedFileIds: string[]
  suppressedFileIds: string[]
  id: string
}) =>
  projectFileIds.has(id) &&
  processedFileIds.includes(id) &&
  !suppressedFileIds.includes(id)

interface FileQueryInfo {
  file: VaultFile
  folderPath: string
}

export const getSelectedFiles = (
  selectedRows: VaultItemWithIndex[],
  existingFileIds?: Set<string>
) => {
  return selectedRows
    .filter((row) => row.type === VaultItemType.file)
    .filter((row) => !existingFileIds?.has(row.id))
    .map((item) => item.data as VaultFile)
    .filter(Boolean)
}

export const getSortedFilesBasedOnReviewQueryOrder = (
  readyToQueryFiles: VaultFile[],
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
) => {
  const fileQueryInfos: FileQueryInfo[] = []
  readyToQueryFiles.forEach((file) => {
    const foldersOnPath =
      getFoldersOnPath(file.vaultFolderId, folderIdToVaultFolder) ?? []
    const folderPath = foldersOnPath
      .slice(1)
      .map((f) => f.name)
      .join('/')
    fileQueryInfos.push({
      file,
      folderPath,
    })
  })

  // sort the files by the folderPath because that is how the group will be determined in the result table
  // we want files in the root directory to be at the bottom
  fileQueryInfos.sort((a, b) => {
    if (a.folderPath === '' && b.folderPath !== '') return 1
    if (a.folderPath !== '' && b.folderPath === '') return -1
    if (a.folderPath === b.folderPath)
      return a.file.name.localeCompare(b.file.name)
    return a.folderPath.localeCompare(b.folderPath)
  })

  return fileQueryInfos.map((fileQueryInfo) => fileQueryInfo.file)
}

export const getReadyToQueryFileIds = (
  projectId: string,
  projectIdToFileIds: SafeRecord<string, string[]>,
  pendingQueryFileIds: string[] | null,
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>,
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  // eslint-disable-next-line max-params
): string[] => {
  const descendantFiles = getDescendantFilesForProject(
    projectId,
    projectIdToFileIds,
    fileIdToVaultFile
  )
  const readyToQueryFiles = descendantFiles.filter(
    // If pendingQueryFileIds is not null, we should only query the files that are in the pendingQueryFileIds
    // Otherwise, we should query all the files that are ready to query
    (file) =>
      file.readyToQuery &&
      (!pendingQueryFileIds || pendingQueryFileIds.includes(file.id))
  )
  return getSortedFilesBasedOnReviewQueryOrder(
    readyToQueryFiles,
    folderIdToVaultFolder
  ).map((file) => file.id)
}

export const getExistingCompletedVaultReviewQueries = (
  events: Event[],
  selectedReadyToQueryFileIds: Set<string>
) =>
  events
    .filter((event) => event.status === EventStatus.COMPLETED)
    .filter((event) => event.kind === EventKind.VAULT_REVIEW)
    .filter((event) => !_.isEmpty(event.metadata))
    .filter((event) => (event.metadata as VaultExtraSocketState).fileIds)
    .filter((event) => {
      const fileIds = (event.metadata as VaultExtraSocketState).fileIds
      return !fileIds.some((fileId) => selectedReadyToQueryFileIds.has(fileId))
    })
    .filter((event) => {
      const questions = (event.metadata as VaultReviewSocketState).questions
      return !isUndefined(questions) && questions.length > 0
    })
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    )

export const projectAsItem = (
  project: VaultFolder,
  folderSize: number | undefined,
  totalFiles: number | undefined
): VaultItem => ({
  type: VaultItemType.project,
  ...project,
  data: project,
  status: VaultItemStatus.default,
  size: folderSize,
  totalFiles: totalFiles,
})

export const folderAsItem = ({
  folder,
  foldersMetadata,
  shouldExpand,
  folderIdToVaultFolder,
  parentIdToVaultFolderIds,
  fileIdToVaultFile,
  folderIdToVaultFileIds,
  existingSelectedFileIds,
  isAddingFilesToQuery,
}: {
  folder: VaultFolder
  foldersMetadata: SafeRecord<string, VaultFolderMetadata>
  shouldExpand: boolean
  folderIdToVaultFolder?: SafeRecord<string, VaultFolder>
  parentIdToVaultFolderIds?: SafeRecord<string, string[]>
  fileIdToVaultFile?: SafeRecord<string, VaultFile>
  folderIdToVaultFileIds?: SafeRecord<string, string[]>
  existingSelectedFileIds?: Set<string>
  isAddingFilesToQuery?: boolean
}): VaultItem => {
  const subFolders = (parentIdToVaultFolderIds?.[folder.id] ?? [])
    .map((id) => folderIdToVaultFolder?.[id])
    .filter(Boolean) as VaultFolder[]
  const subFiles = (folderIdToVaultFileIds?.[folder.id] ?? [])
    .map((fileId) => fileIdToVaultFile?.[fileId])
    .filter(Boolean) as VaultFile[]
  const children = shouldExpand
    ? [
        ...subFolders.map((subFolder) =>
          folderAsItem({
            folder: subFolder,
            foldersMetadata,
            shouldExpand,
            folderIdToVaultFolder,
            parentIdToVaultFolderIds,
            fileIdToVaultFile,
            folderIdToVaultFileIds,
            existingSelectedFileIds,
            isAddingFilesToQuery,
          })
        ),
        ...subFiles.map((file) =>
          fileAsItem(file, existingSelectedFileIds, isAddingFilesToQuery)
        ),
      ]
    : undefined

  return {
    type: folder.parentId ? VaultItemType.folder : VaultItemType.project,
    ...folder,
    data: folder,
    status: determineFolderStatus(folder, foldersMetadata),
    size: foldersMetadata[folder.id]?.folderSize,
    children: children,
    isAlreadySelected:
      children &&
      children.length > 0 &&
      children.every((child) => child.isAlreadySelected),
    isSomeAlreadySelected:
      children &&
      children.length > 0 &&
      children.some((child) => child.isAlreadySelected),
  }
}

export const fileAsItem = (
  file: VaultFile,
  existingSelectedFileIds?: Set<string>,
  isAddingFilesToQuery?: boolean
): VaultItem => {
  const isAlreadySelected = existingSelectedFileIds?.has(file.id) ?? false
  return {
    type: VaultItemType.file,
    ...file,
    parentId: file.vaultFolderId,
    data: file,
    status: determineFileStatus(file),
    failureReason: file.failureReason,
    disabled: isAlreadySelected || (isAddingFilesToQuery && !file.readyToQuery),
    isAlreadySelected,
    tags: file.tags,
  }
}

export const getFileIcon = (file: VaultFile) => {
  if (
    file.contentType === FileType.EXCEL ||
    file.contentType === FileType.EXCEL_LEGACY ||
    file.contentType === FileType.CSV
  ) {
    return FileSpreadsheet
  }
  return FileText
}

export const getContentTypeDisplayString = (vaultItem: VaultItem) => {
  const rowType = vaultItem.type
  return rowType === VaultItemType.folder || rowType === VaultItemType.project
    ? _.upperFirst(rowType)
    : FileTypeReadableName[
        vaultItem.data.contentType as keyof typeof FileTypeReadableName
      ] || EM_DASH
}

export const getRandomSkeletonSize = (string1: string, string2: string) => {
  const fileIdHashValue = CryptoJS.SHA256(string1).toString(CryptoJS.enc.Hex)
  const numericHash =
    parseInt(fileIdHashValue.slice(0, 8), 16) +
    parseInt(string2.slice(0, 8), 16)
  const randomWidth = (numericHash % 61) + 20
  return `${randomWidth}%`
}

export const getDisplayedRows = (gridApi: GridApi) => {
  const displayedRows: string[] = []
  gridApi.forEachNodeAfterFilterAndSort((node) => {
    if (node.data && node.data.file) {
      displayedRows.push(node.data.file.id)
    } else if (node.id) {
      displayedRows.push(node.id)
    }
  })
  return displayedRows
}

export const columnToQueryQuestion = (column: ReviewColumn): QueryQuestion => ({
  id: String(column.displayId),
  text: column.fullText,
  header: column.header,
  columnDataType: column.dataType,
  options: column.options?.map((option) => option.trim()).join(', '),
  backingReviewColumn: column,
})

const mapReviewSourcesToSourcesV1 = (reviewEvent: ReviewEvent) => {
  const rowIdToFileId = new Map(
    reviewEvent.rows.map((row) => [row.id, row.fileId])
  )
  const cellIdToRowId = new Map(
    reviewEvent.cells.map((cell) => [cell.id, cell.reviewRowId])
  )
  const columnIdToQuestionId = new Map(
    reviewEvent.columns.map((column) => [column.id, String(column.displayId)])
  )
  const cellIdToColumnId = new Map(
    reviewEvent.cells.map((cell) => [cell.id, cell.reviewColumnId])
  )
  return reviewEvent.sources.map((source) => ({
    ...source,
    documentId: rowIdToFileId.get(cellIdToRowId.get(source.cellId) ?? '') ?? '',
    questionId:
      columnIdToQuestionId.get(cellIdToColumnId.get(source.cellId) ?? '') ?? '',
    sourceType: SourceType.PDF_KIT,
    annotations: [],
    page: source.pageNumber,
    documentUrl: '', // We don't have the document URL for event v2 sources
  }))
}

export const mapReviewEventToEventV1Metadata = (
  event: ReviewEvent
): VaultExtraSocketState & VaultReviewSocketState => {
  const sources = mapReviewSourcesToSourcesV1(event)
  const runs = [...event.runs]
  const rows = [...event.rows].sort((a, b) => a.order - b.order)
  const columns = [...event.columns].sort((a, b) => a.displayId - b.displayId)
  const latestRun = runs.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )[0]
  const columnIdToQuestionId = new Map(
    columns.map((column) => [column.id, String(column.displayId)])
  )
  const hiddenColumnIds = new Set(
    columns.filter((column) => column.isHidden).map((column) => column.id)
  )
  const fileIds = rows.map((row) => row.fileId)
  const rowIdToFileId = new Map(rows.map((row) => [row.id, row.fileId]))
  const hiddenRowIds = new Set(
    rows.filter((row) => row.isHidden).map((row) => row.id)
  )
  let earliestRun =
    runs.length > 0
      ? runs.find((run) => run.runType === ReviewEventRunType.NEW)
      : undefined
  if (!earliestRun && runs.length > 0) {
    earliestRun = runs.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    )[0]
  }
  return {
    isFromHistory: true,
    title: event.title || earliestRun?.query || 'Untitled query',
    taskType: event.eventKind,
    vaultFolderId: event.vaultFolderId,
    numFiles: fileIds.length,
    numQuestions: columns.length,
    fileIds: fileIds,
    sourcedFileIds: fileIds,
    createdAt: parseIsoString(event.eventCreatedAt),
    startedAt: parseIsoString(earliestRun?.createdAt ?? event.eventCreatedAt),
    completedAt: parseIsoString(event.eventUpdatedAt),
    pausedAt:
      event.eventStatus === TaskStatus.CANCELLED
        ? parseIsoString(event.eventUpdatedAt)
        : null,
    failedAt:
      event.eventStatus === TaskStatus.ERRORED
        ? parseIsoString(event.eventUpdatedAt)
        : null,
    maxTokenLimitReached: false,
    creatorUserEmail: event.eventCreatorEmail,
    followUpQueries: runs
      .filter(
        (run) =>
          run.runType === ReviewEventRunType.EXTRA_COLUMNS ||
          run.runType === ReviewEventRunType.EXTRA_FILES_AND_COLUMNS
      )
      .map((run) => ({
        query: run.query,
        questionIds: columns
          .filter((column) => column.reviewEventRunId === run.id)
          .map((column) => String(column.displayId)),
      })),
    questions: columns.map(columnToQueryQuestion),
    questionsNotAnswered: [],
    maxQuestionCharacterLength: MAX_QUESTION_CHAR_LENGTH,
    minQuestionCharacterLength: MIN_QUESTION_CHAR_LENGTH,
    columnHeaders: columns.map((column) => ({
      id: String(column.displayId),
      text: column.header,
      columnDataType: column.dataType,
    })),
    answers: event.cells
      .filter((cell) => cell.status === ReviewCellStatus.COMPLETED)
      .filter((cell) => !hiddenColumnIds.has(String(cell.reviewColumnId)))
      .filter((cell) => !hiddenRowIds.has(cell.reviewRowId))
      .reduce((acc: SafeRecord<string, ReviewAnswer[]>, cell) => {
        const fileId = rowIdToFileId.get(cell.reviewRowId) ?? ''
        acc[fileId] ??= []
        acc[fileId]!.push({
          columnId: columnIdToQuestionId.get(cell.reviewColumnId) ?? '',
          long: false,
          text:
            cell.columnDataType === ColumnDataType.date
              ? cell.rawShortResponse?.[0].value ?? cell.shortResponse ?? ''
              : cell.shortResponse ?? '',
          columnDataType: cell.columnDataType,
          rawResponse: cell.rawShortResponse,
        })
        acc[fileId]!.push({
          columnId: columnIdToQuestionId.get(cell.reviewColumnId) ?? '',
          long: true,
          text: cell.response,
          columnDataType: cell.longColumnDataType,
          rawResponse: cell.rawResponse,
        })
        return acc
      }, {}),
    errors: event.cells
      .filter((cell) => cell.error)
      .filter((cell) => !hiddenColumnIds.has(String(cell.reviewColumnId)))
      .filter((cell) => !hiddenRowIds.has(cell.reviewRowId))
      .reduce(
        (
          acc: SafeRecord<string, { columnId: string; text: string }[]>,
          cell
        ) => {
          const fileId = rowIdToFileId.get(cell.reviewRowId) ?? ''
          acc[fileId] ??= []
          acc[fileId]!.push({
            columnId: columnIdToQuestionId.get(cell.reviewColumnId) ?? '',
            text:
              cell.error && !isAnswerEmpty(cell.error)
                ? cell.error
                : 'Failed to process',
          })
          return acc
        },
        {}
      ),
    fileIdToSources: sources.reduce(
      (acc, source) => {
        if (!source.documentId) {
          return acc
        }
        acc[source.documentId] ??= []
        acc[source.documentId]!.push(source)
        return acc
      },
      {} as SafeRecord<string, Source[]>
    ),
    processedFileIds: Array.from(
      new Set(
        event.cells
          .filter((cell) => cell.status !== ReviewCellStatus.EMPTY)
          .filter((cell) => !hiddenRowIds.has(cell.reviewRowId))
          .map((cell) => rowIdToFileId.get(cell.reviewRowId) ?? '')
      )
    ),
    suppressedFileIds: Array.from(
      new Set(
        event.cells
          .filter((cell) => cell.status === ReviewCellStatus.ERRORED_IGNORED)
          .filter((cell) => !hiddenRowIds.has(cell.reviewRowId))
          .map((cell) => rowIdToFileId.get(cell.reviewRowId) ?? '')
      )
    ),
    eta: latestRun?.eta ?? null,
    columnOrder: columns.map((column) => String(column.order)),
    isWorkflowRepsWarranties: false,
  }
}

export const retryReviewErrors = async ({
  queryFileIds,
  reviewEvent,
  currentProjectMetadata,
  setIsRunButtonLoading,
  setQueryId,
  recordQuerySubmitted,
  queryClient,
}: {
  queryFileIds: string[]
  reviewEvent: ReviewHistoryItem
  currentProjectMetadata: VaultFolderMetadata
  setIsRunButtonLoading: (isRunButtonLoading: boolean) => void
  setQueryId: (queryId: string) => void
  recordQuerySubmitted: (
    fields?: Record<string, string | number | string[]>
  ) => void
  queryClient: QueryClient
}) => {
  setIsRunButtonLoading(true)
  const projectId = currentProjectMetadata.id
  const queryQuestions = reviewEvent.questions
  recordQuerySubmitted({
    event_kind: EventKind.VAULT_REVIEW,
    event_id: reviewEvent.id,
    query_length: 0,
    num_files: queryFileIds.length,
  })

  if (queryFileIds.length === 0) {
    displayErrorMessage('Please select files to retry')
    setIsRunButtonLoading(false)
    return
  }

  try {
    const response = await CreateVaultReviewQuery({
      // TODO: handle workflow
      workflowId: null,
      // TODO: handle selected workflow column ids
      selectedWorkflowColumnIds: null,
      eventId: Number(reviewEvent.id),
      query: `Review on ${currentProjectMetadata.name}`,
      clientMatterId: undefined,
      taskType: EventKind.VAULT_REVIEW,
      vaultFolderId: projectId,
      fileIds: queryFileIds,
      questions: queryQuestions,
      requestType: 'retry_error',
    })
    const reviewQueryJobEventId = response.reviewQueryJobEventId
    setQueryId(reviewQueryJobEventId)

    // We need to invalidate the query so that the new query is fetched
    // This will update historyItem to reflect the right state in the document cells
    await queryClient.invalidateQueries({
      queryKey: [
        HarvQueryKeyPrefix.VaultHistoryItemQuery,
        reviewQueryJobEventId,
        { type: 'diff' },
      ],
    })
  } catch (error) {
    console.error(error)
    displayErrorMessage('Failed to retry errors. Please try again later.')
  } finally {
    setIsRunButtonLoading(false)
  }
}

export const clearReviewErrors = async ({
  queryId,
  fileIds,
  historyItem,
  setIsRunButtonLoading,
  setHistoryItem,
  queryClient,
}: {
  queryId: string
  fileIds: string[]
  historyItem: ReviewHistoryItem
  setIsRunButtonLoading: (isRunButtonLoading: boolean) => void
  setHistoryItem: (historyItem: ReviewHistoryItem) => void
  queryClient: QueryClient
}) => {
  setIsRunButtonLoading(true)
  if (fileIds.length === 0) {
    displayErrorMessage('Please select files to clear errors')
    setIsRunButtonLoading(false)
    return
  }

  try {
    const response = await ClearQueryErrors(queryId, fileIds)
    const clearedFileIds = response.clearedFileIds
    setHistoryItem({
      ...historyItem,
      suppressedFileIds: [...historyItem.suppressedFileIds, ...clearedFileIds],
    })
    // We need to invalidate the query so that the new query is fetched
    // This will update historyItem to reflect the right state in the document cells
    await queryClient.invalidateQueries({
      queryKey: [
        HarvQueryKeyPrefix.VaultHistoryItemQuery,
        queryId,
        { type: 'diff' },
      ],
    })
    if (response.clearedFileIds.length === fileIds.length) {
      displaySuccessMessage(
        `${fileIds.length} ${pluralize('error', fileIds.length)} cleared`
      )
    } else {
      const clearedFileCount = response.clearedFileIds.length
      const remainingFileCount = fileIds.length - clearedFileCount
      displayWarningMessage(
        `${clearedFileCount} ${pluralize(
          'error',
          clearedFileCount
        )} cleared, ${remainingFileCount} ${pluralize(
          'error',
          remainingFileCount
        )} failed to clear`
      )
    }
  } catch (e) {
    console.error(e)
    displayErrorMessage(`Failed to clear ${pluralize('error', fileIds.length)}`)
  } finally {
    setIsRunButtonLoading(false)
  }
}

export const computeDocumentClassificationAnalyticsData = (
  fileIdsToProcess: string[],
  fileIdToVaultFile: SafeRecord<string, VaultFile>
): DocumentClassificationAnalyticsData[] => {
  const documentClassificationTags = fileIdsToProcess.flatMap((fileId) => {
    const file = fileIdToVaultFile[fileId]
    return (
      file?.tags.filter(
        (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
      ) ?? []
    )
  })
  const documentClassificationData = documentClassificationTags.reduce(
    (acc, tag) => {
      const existingEntry = acc.find(
        (entry) => entry.typeDocumentClassification === tag.name
      )
      const tagKey =
        tag.name as keyof typeof DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING
      const groupingDocumentClassification =
        DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING[tagKey]
      if (existingEntry) {
        existingEntry.numDocuments++
      } else {
        acc.push({
          numDocuments: 1,
          typeDocumentClassification: tag.name,
          groupingDocumentClassification: groupingDocumentClassification,
        })
      }
      return acc
    },
    [] as DocumentClassificationAnalyticsData[]
  )
  return documentClassificationData
}

export const runReviewQuery = async ({
  currentProject,
  currentProjectMetadata,
  folderIdToVaultFolder,
  fileIdToVaultFile,
  hasSelectedFiles,
  hasNewFilesToRun,
  hasNewQuestionsToRun,
  hasEmptyCells,
  selectedRows,
  pendingQueryFileIds,
  pendingQueryQuestions,
  workflow,
  queryId,
  query,
  isNewQuery,
  isRetry,
  allQueryFileIds,
  allQueryQuestions,
  clientMatterId,
  setIsRunButtonLoading,
  recordQuerySubmitted,
  setQueryId,
  setWorkflow,
  clearSelectedRows,
  queryClient,
}: {
  currentProject: VaultFolder | null
  currentProjectMetadata: VaultFolderMetadata
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  hasSelectedFiles: boolean
  hasNewFilesToRun: boolean
  hasNewQuestionsToRun: boolean
  hasEmptyCells: boolean
  selectedRows: string[]
  pendingQueryFileIds: string[] | null
  pendingQueryQuestions: QueryQuestion[] | null
  workflow: ReviewWorkflow | null
  queryId: string
  query?: string
  isNewQuery: boolean
  isRetry: boolean
  allQueryFileIds: string[]
  allQueryQuestions: QueryQuestion[]
  clientMatterId: string | undefined
  setIsRunButtonLoading: (isRunButtonLoading: boolean) => void
  recordQuerySubmitted: (
    fields?: Record<string, string | number | string[]>
  ) => void
  setQueryId: (queryId: string) => void
  setWorkflow: (workflow: ReviewWorkflow | null) => void
  clearSelectedRows: () => void
  queryClient: QueryClient
}): Promise<string | null> => {
  setIsRunButtonLoading(true)
  const projectId = currentProject?.id ?? ''
  const readyToQueryFiles: VaultFile[] =
    currentProjectMetadata.descendantFiles?.filter((file) => {
      return (
        file.readyToQuery &&
        allQueryFileIds.includes(file.id) &&
        // If we have selected files, we need to filter down
        (hasSelectedFiles ? selectedRows.includes(file.id) : true) &&
        // Otherwise, if we have pendingQueryFileIds, we need to filter down
        (!hasSelectedFiles && pendingQueryFileIds
          ? pendingQueryFileIds.includes(file.id)
          : true)
      )
    }) ?? []

  const sortedReadyToQueryFileIds = getSortedFilesBasedOnReviewQueryOrder(
    readyToQueryFiles,
    folderIdToVaultFolder
  ).map((file) => file.id)

  // TODO: handle case where we have files but no questions (all of the questions will not be in pendingColumnIds)
  const requestTypeGetter = () => {
    if (isNewQuery) {
      return 'new'
    }
    if (isRetry) {
      return 'retry'
    }
    if (hasNewFilesToRun && hasNewQuestionsToRun) {
      return 'extra_files_and_columns'
    }
    if (hasNewQuestionsToRun) {
      return 'extra_columns'
    }
    if (hasNewFilesToRun) {
      return 'extra_files'
    }
    if (hasSelectedFiles) {
      return 'retry'
    }
    if (hasEmptyCells) {
      return 'retry_empty'
    }
    throw new Error('Invalid request type')
  }
  const requestType = requestTypeGetter()

  const documentClassificationAnalyticsData =
    computeDocumentClassificationAnalyticsData(
      sortedReadyToQueryFileIds,
      fileIdToVaultFile
    )
  recordQuerySubmitted({
    event_kind: EventKind.VAULT_REVIEW,
    event_id: queryId,
    query_length: 0,
    num_files: sortedReadyToQueryFileIds.length,
    document_classification: ToBackendKeys(documentClassificationAnalyticsData),
  })

  if (sortedReadyToQueryFileIds.length === 0) {
    displayErrorMessage('No files to review or files are not processed yet')
    setIsRunButtonLoading(false)
    return null
  }

  const questions = pendingQueryQuestions
    ? allQueryQuestions.filter((question) =>
        pendingQueryQuestions.map((q) => q.id).includes(question.id)
      )
    : allQueryQuestions
  if (questions.length === 0) {
    displayErrorMessage('No questions to review')
    setIsRunButtonLoading(false)
    return null
  }

  try {
    const workflowId = isNewQuery && workflow?.id ? String(workflow.id) : null
    const questionColumnIds = allQueryQuestions
      .map((question) => question.backingReviewColumn?.id)
      .filter(Boolean)
    const selectedWorkflowColumnIds =
      isNewQuery && workflow?.columns
        ? workflow.columns
            .filter((column) => questionColumnIds.includes(column.id))
            .map((column) => column.id)
        : null

    const fileIds = sortedReadyToQueryFileIds
    const response = await CreateVaultReviewQuery({
      workflowId: workflowId,
      selectedWorkflowColumnIds: selectedWorkflowColumnIds,
      eventId: !isNewQuery ? Number(queryId) : null,
      query: query ?? `Review on ${currentProjectMetadata.name}`,
      clientMatterId: clientMatterId,
      taskType: TaskType.VAULT_REVIEW,
      vaultFolderId: projectId,
      fileIds: fileIds,
      questions: questions,
      requestType: requestType,
      isNewQuery: isNewQuery,
    })
    const reviewQueryJobEventId = response.reviewQueryJobEventId
    setQueryId(reviewQueryJobEventId)
    setWorkflow(null)
    clearSelectedRows()

    // We need to invalidate the query so that the new query is fetched
    // This will update historyItem to reflect the right state in the document cells
    await queryClient.invalidateQueries({
      queryKey: [
        HarvQueryKeyPrefix.VaultHistoryItemQuery,
        reviewQueryJobEventId,
        { type: 'diff' },
      ],
    })
    setIsRunButtonLoading(false)
    return reviewQueryJobEventId
  } catch (error) {
    console.error(error)
    displayErrorMessage('Failed to run review query. Please try again later.')
    setIsRunButtonLoading(false)
    return null
  }
}

export async function runReviewQueryFromWorkflow({
  currentProject,
  currentProjectMetadata,
  folderIdToVaultFolder,
  fileIdToVaultFile,
  selectedRows,
  pendingQueryFileIds,
  pendingQueryQuestions,
  workflow,
  clientMatterId,
  queryClient,
  setIsRunButtonLoading,
  recordQuerySubmitted,
  setQueryId,
  setWorkflow,
  setWorkflowModalState,
  setIsSubmitting,
  navigate,
}: {
  currentProject: VaultFolder | null
  currentProjectMetadata: VaultFolderMetadata
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  selectedRows: string[]
  pendingQueryFileIds: string[]
  pendingQueryQuestions: QueryQuestion[]
  workflow: ReviewWorkflow
  clientMatterId: string | undefined
  queryClient: QueryClient
  setIsRunButtonLoading: (isRunButtonLoading: boolean) => void
  recordQuerySubmitted: (
    fields?: Record<string, string | number | string[]>
  ) => void
  setQueryId: (queryId: string) => void
  setWorkflow: (workflow: ReviewWorkflow | null) => void
  setWorkflowModalState: (state: WorkflowModalState) => void
  setIsSubmitting: (isSubmitting: boolean) => void
  navigate: (
    path: string,
    options?: NavigateOptions | undefined,
    removeParams?: string[]
  ) => void
}) {
  const queryId = await runReviewQuery({
    currentProject,
    currentProjectMetadata,
    folderIdToVaultFolder,
    fileIdToVaultFile,
    hasSelectedFiles: false,
    hasNewFilesToRun: true,
    hasNewQuestionsToRun: true,
    hasEmptyCells: false,
    selectedRows,
    pendingQueryFileIds,
    pendingQueryQuestions,
    workflow,
    queryId: '',
    query: workflow.name,
    isNewQuery: true,
    isRetry: false,
    allQueryFileIds: pendingQueryFileIds,
    allQueryQuestions: pendingQueryQuestions,
    clientMatterId,
    setIsRunButtonLoading,
    recordQuerySubmitted,
    setQueryId,
    setWorkflow,
    clearSelectedRows: () => {},
    queryClient,
  })
  setIsSubmitting(false)
  if (queryId && currentProject) {
    const newPath = `${BaseAppPath.Vault}${projectsPath}${currentProject.id}${queriesPath}${queryId}`
    navigate(newPath, {}, REMOVE_PARAMS)
    setWorkflowModalState(WorkflowModalState.None)
  }
}

export function getProjectMetadataFromVaultFolderMetadata(
  folderMetadata: VaultFolderMetadata
): VaultProjectMetadata {
  return {
    projectId: folderMetadata.vaultProjectId,
    totalSize: folderMetadata.folderSize,
    filesCount: folderMetadata.totalFiles,
    completedFilesCount: folderMetadata.completedFiles,
    failedFilesCount: folderMetadata.failedFiles,
    projectCreatorEmail: folderMetadata.userEmail || '',
  }
}

export function getDescendantFilesForProject(
  projectId: string,
  projectIdToFileIds: SafeRecord<string, string[]>,
  fileIdToVaultFile: SafeRecord<string, VaultFile>
): VaultFile[] {
  return (projectIdToFileIds[projectId] ?? [])
    .map((fileId) => fileIdToVaultFile[fileId])
    .filter(Boolean) as VaultFile[]
}

export function getDescendantFoldersForProject(
  projectId: string,
  projectIdToFolderIds: SafeRecord<string, string[]>,
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
): VaultFolder[] {
  return (projectIdToFolderIds[projectId] ?? [])
    .map((folderId) => folderIdToVaultFolder[folderId])
    .filter(Boolean) as VaultFolder[]
}
