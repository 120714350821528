import React, { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import useState from 'react-usestateref'

import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import * as Sentry from '@sentry/browser'
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Checkbox,
  Radio,
  RadioChangeEvent,
} from 'antd'
import type { CheckboxProps } from 'antd'
import type { SelectProps } from 'antd'
import { InputNumber } from 'antd/lib'
import cidrRegex from 'cidr-regex'
import ipRegex from 'ip-regex'
import _ from 'lodash'
import { InfoIcon } from 'lucide-react'
import pluralize from 'pluralize'
import isFQDN from 'validator/lib/isFQDN'

import { FetchAllPerms } from 'models/perms'
import {
  DEFAULT_BASE_PERMS,
  Permission,
  isInternalOnlyPerm,
  isRestrictedPerm,
  isSensitivePerm,
} from 'models/user-info'
import {
  Workspace,
  type RawWorkspace,
  WorkspaceKind,
  CreateWorkspace,
  UpdateWorkspace,
  WelcomeScreenType,
  isHarveyWorkspace,
  isPwcWorkspace,
  StorageRegions,
  eyesOffTierToReadableName,
} from 'models/workspace'
import { ChannelPartner } from 'openapi/models/ChannelPartner'
import { DataPrivacy } from 'openapi/models/DataPrivacy'
import { Industry } from 'openapi/models/Industry'
import { WorkspaceEyesOffTier } from 'openapi/models/WorkspaceEyesOffTier'
import Services from 'services'
import { Maybe } from 'types'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { generateRandomAlphaNumericId } from 'utils/id'
import { PWD_AUTH_CONN } from 'utils/routing'
import { site, environment } from 'utils/server-data'
import { getSiteUrl } from 'utils/site'
import { getSlug } from 'utils/string'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { intervalToReadable, SECONDS_TO_READABLE } from 'utils/utils'

import { BaseAppPath, SettingsPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import DeprecatedTagInput from 'components/common/deprecated-tag-input/deprecated-tag-input'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import WorkspaceDropdown from 'components/common/workspace-dropdown'
import useWorkspaceOrgDetail from 'components/settings/hooks/use-workspace-org-detail'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { Alert, AlertDescription, AlertTitle } from 'components/ui/alert'
import { Badge } from 'components/ui/badge'
import { Button as TailwindButton } from 'components/ui/button'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip'

// no concerns with bundle size yet, a little less than 100kb after minification probably
import domainsList from './domains-list.json'
import WorkspaceEyesOffTierUpdateModal from './workspace-update-eyes-off-tier-confirm-modal'
import WorkspaceDataPrivacyUpdateModal from './workspace-update-privacy-update-modal'

const { Option } = Select
const { TextArea } = Input

const DEFAULT_RETENTION_PERIOD = 0
const DEFAULT_LOGIN_TIMEOUT = 604800
const DEFAULT_LOGIN_TIMEOUTS = [3600, 86400, 604800]
const RANDOM_SLUG_ID_LENGTH = 12

interface FormValuesToWorkspaceProps {
  values: Record<string, any>
  parentWorkspace: Maybe<Workspace>
  slug: string
}

const AddEditWorkspace: React.FC = () => {
  const navigate = useNavigateWithQueryParams()
  const { id: workspaceId } = useParams()
  const {
    workspace,
    workspaces,
    isLoadingWorkspaces: isWorkspaceOrgDetailLoading,
  } = useWorkspaceOrgDetail({ workspaceId })
  const isAddWorkspace = _.isNil(workspace) // if workspace is null, then we are adding a new workspace

  const [form] = Form.useForm()
  const [
    dataPrivacyConfirmationModalOpen,
    setDataPrivacyConfirmationModalOpen,
  ] = useState(false)
  const [shouldPopConfirmationModal, setShouldPopConfirmationModal] =
    useState(false)

  const [eyesOffTierModalOpen, setEyesOffTierModalOpen] = useState(false)
  const [shouldPopEyesOffTierModal, setShouldPopEyesOffTierModal] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [customRetention, setCustomRetention] = useState(false)
  const [newEyesOffTier, setNewEyesOffTier] = useState<WorkspaceEyesOffTier>(
    workspace?.eyesOffTier ?? WorkspaceEyesOffTier.EYES_OFFBASIC
  )
  const [permOptionValues, setPermOptionValues] = useState<
    SelectProps['options']
  >([])
  const [customDateString, setCustomDateString] = useState<Maybe<string>>(
    workspace?.retentionPeriod &&
      !(workspace.retentionPeriod in SECONDS_TO_READABLE)
      ? intervalToReadable(workspace.retentionPeriod, true)
      : null
  )
  const [customRetentionPeriod, setCustomRetentionPeriod] = useState<
    Maybe<number>
  >(workspace?.retentionPeriod)
  const [customLogin, setCustomLogin] = useState(false)
  const [customLoginTimeoutString, setCustomLoginTimeoutString] = useState<
    Maybe<string>
  >(
    workspace?.loginTimeout &&
      !DEFAULT_LOGIN_TIMEOUTS.includes(workspace.loginTimeout)
      ? intervalToReadable(workspace.loginTimeout, true)
      : undefined
  )
  const [customLoginTimeout, setCustomLoginTimeout] = useState<Maybe<number>>(
    workspace?.loginTimeout
  )
  const [workspaceSlug, setWorkspaceSlug] = useState(workspace?.slug ?? '')
  const [workspaceKind, setWorkspaceKind] = useState<WorkspaceKind>(
    workspace?.kind ?? WorkspaceKind.ORGANIZATION
  )
  const [workspaceDomains, setWorkspaceDomains] = useState<string[]>(
    workspace?.domains ?? []
  )
  const [invalidWorkspaceDomains, setInvalidWorkspaceDomains] = useState<
    string[]
  >([])

  const [missingWorkspaceDomains, setMissingWorkspaceDomains] = useState<
    string[]
  >([]) // track when a domain is deleted without perms

  const [authConn, setAuthConn] = useState(workspace?.authConn ?? PWD_AUTH_CONN)
  const [authConnGroupId, setAuthConnGroupId] = useState<string | undefined>(
    workspace?.authConnGroupId
  )
  const [loginSlug, setLoginSlug] = useState<string | undefined>(
    workspace?.loginSlug
  )
  const [industry, setIndustry] = useState<Industry>(
    workspace?.industry ?? Industry.MULTIPLE
  )

  // Slug ID is used in create_workspace for segregated bucket name and needs to be kept in sync.
  const randomSlugId = useRef(
    generateRandomAlphaNumericId(RANDOM_SLUG_ID_LENGTH)
  ) // this will only have key collisions when we start hitting 1.8e+10 workspaces, which would be great

  const [parentWorkspace, setParentWorkspace] = useState<Maybe<Workspace>>()
  const [childrenWorkspaces, setChildrenWorkspaces] = useState<Workspace[]>([])

  const authConnGroupIdRequired = parentWorkspace?.settings.strictAdGroups
  const authConnGroupIdMissing =
    authConnGroupIdRequired && _.isEmpty(authConnGroupId)
  const canSave =
    invalidWorkspaceDomains.length === 0 && !authConnGroupIdMissing

  const [dataPrivacy, setDataPrivacy] = useState<DataPrivacy>(DataPrivacy.BASIC)

  const [region, setRegion] = useState<string>()
  const [regions, setRegions] = useState<string[]>([])

  const [defaultAuthWs, setDefaultAuthWs] = useState<Maybe<Workspace>>(null)
  const [defaultAuthWsChecked, setDefaultAuthWsChecked] = useState(false)

  const [strictAdGroupsChecked, setStrictAdGroupsChecked] = useState(false)

  const [workspaceAllowedCidrs, setWorkspaceAllowedCidrs] = useState<string[]>(
    workspace?.allowedCidrs ?? []
  )
  const [selectedDefaultPerms, setSelectedDefaultPerms] = useState<string[]>(
    Array.from(DEFAULT_BASE_PERMS)
  )

  useEffect(() => {
    if (_.isNil(workspace) || isWorkspaceOrgDetailLoading) {
      return
    }
    const defaultParentWorkspace = workspaces
      .filter((w) => w.kind === WorkspaceKind.ORGANIZATION)
      .find((w) => w.id === workspace.parentId)
    setParentWorkspace(defaultParentWorkspace)

    // find parent child workspaces for the current workspace
    // if any workspace except current has defaultAuthConn checked, then we can't modify defaultAuthWorkspace
    const parentChildWorkspaces: Workspace[] = [workspace]
    // if workspace is a child
    if (workspace.parentId) {
      parentChildWorkspaces.push(
        ...workspaces.filter((w) => w.parentId === workspace.parentId)
      )
      // add parent workspace
      if (defaultParentWorkspace)
        parentChildWorkspaces.push(defaultParentWorkspace)
    } else {
      // if workspace is parent
      const ws = workspaces.filter((w) => w.parentId === workspace.id)
      parentChildWorkspaces.push(...ws)
      setChildrenWorkspaces(ws)
    }
    const defaultAuthWorkspace = parentChildWorkspaces.find(
      (w) => w.settings.defaultAuthWorkspace
    )
    setDefaultAuthWs(defaultAuthWorkspace)
    if (workspace.settings.defaultAuthWorkspace) {
      setDefaultAuthWsChecked(true)
    }
    if (workspace.settings.strictAdGroups) {
      setStrictAdGroupsChecked(true)
    }
    setAuthConnGroupId(workspace.authConnGroupId)
  }, [workspaces, workspace, isWorkspaceOrgDetailLoading])

  useEffect(() => {
    if (_.isNil(workspace)) {
      return
    }

    setWorkspaceSlug(workspace.slug)
    setCustomDateString(
      workspace?.retentionPeriod &&
        !(workspace.retentionPeriod in SECONDS_TO_READABLE)
        ? intervalToReadable(workspace.retentionPeriod, true)
        : null
    )
    setCustomRetentionPeriod(workspace.retentionPeriod)

    setCustomLoginTimeoutString(
      workspace?.loginTimeout &&
        !DEFAULT_LOGIN_TIMEOUTS.includes(workspace.loginTimeout)
        ? intervalToReadable(workspace.loginTimeout, true)
        : undefined
    )

    setCustomLoginTimeout(workspace.loginTimeout)
    setWorkspaceKind(workspace.kind ?? WorkspaceKind.ORGANIZATION)
    setWorkspaceDomains(workspace.domains ?? [])
    setAuthConn(workspace.authConn ?? PWD_AUTH_CONN)
    setLoginSlug(workspace.loginSlug)
    setWorkspaceAllowedCidrs(workspace.allowedCidrs ?? [])
    setNewEyesOffTier(workspace.eyesOffTier)
    setIndustry(workspace.industry ?? Industry.MULTIPLE)
  }, [workspace])

  const formValuesToWorkspace = useCallback(
    ({
      values,
      parentWorkspace,
      slug,
    }: FormValuesToWorkspaceProps): Workspace => {
      let highestScreenId = Math.max(
        ...(values.welcomeScreens?.map((screen: any) => screen.id ?? 0) || []),
        0
      )

      const welcomeScreens = Object.fromEntries(
        (values.welcomeScreens ?? []).map((screen: any) => {
          const id = screen.id ?? ++highestScreenId
          return [id, { ...screen, id: id }]
        })
      )

      const parentId =
        values.kind === WorkspaceKind.ORGANIZATION
          ? null
          : parentWorkspace?.id ?? values.parentId ?? null

      let domains = workspaceDomains ?? []
      if (values.kind !== WorkspaceKind.ORGANIZATION) {
        domains = []
      }

      const allowedCidrs = workspaceAllowedCidrs ?? []

      const rawWorkspace: RawWorkspace = {
        slug,
        id: values.id,
        friendlyName: values.friendlyName,
        clientName: values.clientName,
        domains,
        kind: values.kind,
        eyesOffTier: newEyesOffTier,
        settings: Object.assign(values.settings ?? {}, {
          retentionPeriod: values.retentionPeriod ?? null,
          exportFeedback: values.exportFeedback,
          allowBypassDomainCheck: values.allowBypassDomainCheck,
          welcomeScreens,
          channelPartner: values.channelPartner,
          loginTimeout: values.loginTimeout,
          blockNonProvisionedSamlUsers: values.blockNonProvisionedSamlUsers,
          defaultAuthWorkspace: defaultAuthWsChecked,
          onlyCustomStatusMessage: values.onlyCustomStatusMessage,
          // if we delete auth conn group id, we should not set this as true
          strictAdGroups:
            !_.isNil(authConnGroupId) && authConnGroupId.length > 0
              ? strictAdGroupsChecked
              : false,
          industry,
          ...(isAddWorkspace
            ? {
                migratedToPermissionBundlesAt: new Date(0), // Stopgap to make new workspaces use bundles - update/rm later when we're all migrated
              }
            : {}),
        }),
        storageAccount: values.storageAccount,
        bucket: values.bucket,
        parentId,
        serviceAccountEmail: values.serviceAccountEmail,
        perms: isAddWorkspace ? selectedDefaultPerms : undefined,
        authConn: values.authConn,
        // values.authConnGroupId is what's set from initialValues and what controls the UI
        // authConnGroupId state is what's sent to the backend and how we compute other states
        authConnGroupId: authConnGroupId,
        allowedCidrs: allowedCidrs,
        loginSlug: values.loginSlug,
      }

      return new Workspace(rawWorkspace)
    },
    [
      workspaceDomains,
      workspaceAllowedCidrs,
      newEyesOffTier,
      defaultAuthWsChecked,
      authConnGroupId,
      strictAdGroupsChecked,
      isAddWorkspace,
      selectedDefaultPerms,
      industry,
    ]
  )

  const onSave = () => {
    // if should Pop modal then open it
    if (shouldPopConfirmationModal) {
      setDataPrivacyConfirmationModalOpen(true)
      return
    }

    if (shouldPopEyesOffTierModal) {
      setEyesOffTierModalOpen(true)
      setShouldPopEyesOffTierModal(false)
      return
    }

    // o/w submit
    void handleSubmit()
  }

  const handleSubmit = async (): Promise<void> => {
    let values
    try {
      values = await form.validateFields()
    } catch (error) {
      displayErrorMessage(
        'Invalid form values detected. Please fix and re-submit.',
        5
      )
      return
    }

    try {
      const updatedWorkspace = formValuesToWorkspace({
        values,
        parentWorkspace,
        slug: workspaceSlug,
      })

      setLoading(true)
      if (!updatedWorkspace.bucket && !!updatedWorkspace.storageAccount) {
        displayErrorMessage('Bucket must be set if storage account is set', 5)
      } else if (isAddWorkspace) {
        const createdId = await CreateWorkspace(
          updatedWorkspace,
          region ?? '',
          dataPrivacy
        )
        displaySuccessMessage('Workspace created successfully', 5)
        navigate(`${SettingsPath.InternalAdmin}/workspaces/${createdId}`)
      } else {
        await UpdateWorkspace(updatedWorkspace)
        displaySuccessMessage('Workspace updated successfully', 5)
        navigate(`${SettingsPath.InternalAdmin}/workspaces/${workspace.id}`)
      }
    } catch (error) {
      Sentry.captureException(error)
      const errMsg = error instanceof Error ? error.message : 'Unknown error'
      displayErrorMessage(errMsg, 5)
    }
    setLoading(false)
    setDataPrivacyConfirmationModalOpen(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      const allPerms = await FetchAllPerms()
      const options: SelectProps['options'] = Object.values(allPerms)
        .sort((a, b) => a.permId.localeCompare(b.permId))
        .map((perm) => ({
          label: `${perm.name} - ${perm.permId}`,
          value: perm.permId,
        }))

      // never assign sensitive perms to a workspace
      _.remove(options, (d) => isSensitivePerm(d.value as Permission))

      _.remove(options, (d) => isRestrictedPerm(d.value as Permission))

      if (isAddWorkspace || !isHarveyWorkspace(workspace.slug)) {
        _.remove(options, (d) => isInternalOnlyPerm(d.value as Permission))
      }

      setPermOptionValues(options)
    }
    void fetchData()
  }, [workspace, isAddWorkspace])

  useEffect(() => {
    const fetchData = async () => {
      if (isAddWorkspace) {
        const storageRegions = await Services.Backend.Get<StorageRegions>(
          `internal_admin/storage_regions`
        )

        setRegion(storageRegions.defaultRegion)
        setRegions(storageRegions.regions)
      }
    }
    fetchData()
  }, [isAddWorkspace])

  const initialValues = !isAddWorkspace
    ? {
        ...(workspace.settings ?? {}),
        ...workspace,
        welcomeScreens: workspace.welcomeScreens ?? [],
      }
    : {
        kind: WorkspaceKind.ORGANIZATION,
        retentionPeriod: null,
        storageRegion: region,
      }

  const userInfo = useAuthUser()
  if (!userInfo.IsInternalAdminWriter) return null

  let domainsHelpMessage = undefined
  if (workspaceKind !== WorkspaceKind.ORGANIZATION) {
    if (workspaceDomains.length === 0) {
      domainsHelpMessage = (
        <>
          Only workspace of kind ORGANIZATION can have domains. Please enter
          domains to whitelist in the parent workspace. <br />
        </>
      )
    } else {
      domainsHelpMessage = (
        <>
          Only workspace of kind ORGANIZATION can have domains. Above domains
          will be removed from the workspace on save. <br />
        </>
      )
    }
  }

  domainsHelpMessage = (
    <>
      {domainsHelpMessage}
      {invalidWorkspaceDomains.length > 0 && (
        <Alert className="mt-4 p-3" variant="destructive">
          Invalid domains detected:
          <br />
          <ScrollArea className="max-h-48">
            {invalidWorkspaceDomains.map((domain, idx) => (
              <Badge key={idx} variant="outline" className="mr-1 mt-1">
                {domain}
              </Badge>
            ))}
          </ScrollArea>
        </Alert>
      )}
      {missingWorkspaceDomains.length > 0 && (
        <Alert className="mt-4 p-3" variant="destructive">
          <AlertTitle>
            Existing {pluralize('domain', missingWorkspaceDomains.length)}{' '}
            deleted
          </AlertTitle>
          <AlertDescription>
            Deleting domains affects what history is fetched for this workspace.
            History for users with the following{' '}
            {pluralize('domain', missingWorkspaceDomains.length)} will not be
            shown in UI or API:
            <br />
            <ScrollArea className="max-h-48">
              {missingWorkspaceDomains.map((domain, idx) => (
                <Badge key={idx} variant="outline" className="mr-1 mt-1">
                  {domain}
                </Badge>
              ))}
            </ScrollArea>
          </AlertDescription>
        </Alert>
      )}
      <br />
    </>
  )

  const onClientNameChange = (name: string) => {
    if (!isAddWorkspace) {
      return
    }
    const clientNameSlug = getSlug(name)
    const slug = `${clientNameSlug}-${randomSlugId.current}`
    setWorkspaceSlug(slug)
  }

  const isValidDomain = (domain: string) => {
    return isFQDN(domain) && !domainsList.publicDomains.includes(domain)
  }

  const initialDomains: string[] = workspace?.domains ?? []

  // NOTE: if workspace has inavlid domains already, then we won't show error
  // until user starts changing the domains
  const onWorkspaceDomainsChange = (domains: string[]) => {
    const [, invalidDomains] = _.partition(domains, isValidDomain)

    // duplicate domain detection is done in TagInput component
    setWorkspaceDomains(domains)
    setInvalidWorkspaceDomains(invalidDomains)

    const missingDomains = initialDomains.filter(
      (domain) => !domains.includes(domain)
    )
    setMissingWorkspaceDomains(missingDomains)
  }

  const onDataPrivacyChange = (e: RadioChangeEvent): void => {
    const dataPrivacy = e.target.value as DataPrivacy
    setDataPrivacy(dataPrivacy)
  }

  const onWorkspaceAllowedCidrsChange = (cidrs: string[]) => {
    for (const cidr of cidrs) {
      if (
        !cidrRegex({ exact: true }).test(cidr) &&
        !ipRegex({ exact: true }).test(cidr)
      ) {
        displayErrorMessage(`${cidr} is not a valid IP address or CIDR`, 5)
        return
      }
    }
    setWorkspaceAllowedCidrs(cidrs)
  }

  const DataPrivacyButton = () => {
    if (!isAddWorkspace) {
      return null
    }

    return (
      <Form.Item label="Data privacy">
        <Radio.Group
          options={Object.values(DataPrivacy).map((value: DataPrivacy) => ({
            label: value,
            value: value,
          }))}
          onChange={onDataPrivacyChange}
          value={dataPrivacy}
          optionType="button"
        />
      </Form.Item>
    )
  }

  const StorageRegionSelector = () => {
    if (!isAddWorkspace || dataPrivacy !== DataPrivacy.SEGREGATED) {
      return null
    }

    return (
      <Form.Item name="storageRegion" label="Azure Storage region">
        <Select
          options={regions.map((region: string) => ({
            label: region,
            value: region,
          }))}
          defaultValue={region}
          value={region}
          onChange={(value: string) => setRegion(value)}
        />
      </Form.Item>
    )
  }

  const StorageAccountBucketForm = () => {
    if (isAddWorkspace && dataPrivacy != DataPrivacy.BYOK) {
      return null
    }
    return (
      <>
        {dataPrivacy === DataPrivacy.BYOK && (
          <div className="mb-4">
            <a
              className="text-sm font-semibold underline transition hover:underline"
              href="https://www.notion.so/harveyai/Azure-container-and-storage-account-setup-48cccc6ace054a5cac7b67ef4435ff13?pvs=4#bc7c23a4d5c244fdbd0ecdabe8a8f4e4"
            >
              Follow these BYOK Instructions
            </a>
          </div>
        )}
        <Form.Item
          name="storageAccount"
          label="Azure Storage account name"
          rules={
            dataPrivacy === DataPrivacy.BYOK
              ? [
                  {
                    required: true,
                    message: 'Please enter a valid Azure Storage account name',
                  },
                ]
              : [
                  {
                    required: false,
                  },
                ]
          }
        >
          <Input onChange={() => setShouldPopConfirmationModal(true)} />
        </Form.Item>
        <Form.Item
          name="bucket"
          label="Bucket name"
          rules={
            dataPrivacy === DataPrivacy.BYOK
              ? [
                  {
                    required: true,
                    message: 'Please enter a valid Azure Storage bucket name',
                  },
                ]
              : [
                  {
                    required: false,
                  },
                ]
          }
        >
          <Input onChange={() => setShouldPopConfirmationModal(true)} />
        </Form.Item>
      </>
    )
  }

  if (isWorkspaceOrgDetailLoading) {
    return null
  }

  const onDefaultAuthWsChange: CheckboxProps['onChange'] = (e) => {
    setDefaultAuthWsChecked(e.target.checked)
  }

  const onStrictAdGroupsChange: CheckboxProps['onChange'] = (e) => {
    setStrictAdGroupsChecked(e.target.checked)
  }

  // disabled if editing ws, defaultAuthWs is set and its not this ws
  const defaultAuthWsCheckDisabledBecauseDefaultExists =
    !_.isNil(workspace) &&
    !_.isNil(defaultAuthWs) &&
    workspace.id !== defaultAuthWs.id

  // strict AD groups should only be enabled at organization level
  // so only need to check this workspace if it's an organization or its parent if it's not
  const defaultAuthWsCheckDisabledBecauseStrictAdGroupsEnabled =
    strictAdGroupsChecked ||
    // this settings check will prevent someone from unchecking strict ad groups
    // and checking default auth ws at the same time; the workspace must be saved in between
    workspace?.settings.strictAdGroups ||
    (workspaceKind !== WorkspaceKind.ORGANIZATION &&
      !_.isNil(parentWorkspace) &&
      parentWorkspace.settings.strictAdGroups)

  const defaultAuthWsCheckDisabled =
    defaultAuthWsCheckDisabledBecauseDefaultExists ||
    defaultAuthWsCheckDisabledBecauseStrictAdGroupsEnabled

  const strictAdGroupsCheckDisabled =
    // if workspace is not an organization (no parent), then strict ad groups cannot be enabled
    workspaceKind !== WorkspaceKind.ORGANIZATION ||
    !_.isNil(parentWorkspace) ||
    // every child workspace must have auth conn group id set
    !childrenWorkspaces.every((ws) => ws.authConnGroupId) ||
    // if default auth workspace is set, then strict ad groups cannot be enabled
    !_.isNil(defaultAuthWs) ||
    // this settings check will prevent someone from default auth ws
    // and checking strict ad groups at the same time; the workspace must be saved in between
    defaultAuthWsChecked

  const routerBreadcrumbsPath = workspace
    ? `workspaces/${workspace.clientName}/Edit`
    : ''

  return (
    <>
      <SettingsAppHeader isInternalAdmin />
      <SettingsLayout>
        <RouterBreadcrumbs
          overridePath={routerBreadcrumbsPath}
          pathForIndexFunc={(index) => {
            if (index === 0) return '/settings/internal_admin/workspaces'
            else if (index === 1)
              return `/settings/internal_admin/workspaces/${workspace?.id}`
            return ''
          }}
          keepLastItem
        />
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          disabled={loading}
          onValuesChange={({ customRetentionPeriod, customLoginTimeout }) => {
            if (customRetentionPeriod) {
              setCustomDateString(
                intervalToReadable(customRetentionPeriod, true)
              )
              setCustomRetentionPeriod(customRetentionPeriod)
              form.setFieldsValue({
                retentionPeriod: customRetentionPeriod,
              })
            }
            if (customLoginTimeout) {
              setCustomLoginTimeoutString(
                intervalToReadable(customLoginTimeout, true)
              )
              setCustomLoginTimeout(customLoginTimeout)
              form.setFieldsValue({
                loginTimeout: customLoginTimeout,
              })
            }
          }}
        >
          <h1 className="mb-4 text-2xl">Edit workspace metadata</h1>
          <h2 className="mb-4 text-lg font-semibold">General information</h2>
          <Form.Item name="id" hidden />
          <Form.Item name="settings" hidden />
          <Form.Item name="parentId" hidden />
          <Form.Item name="serviceAccountEmail" hidden />
          <Form.Item
            name="clientName"
            label={
              <>
                Client Name
                <Tooltip>
                  <TooltipTrigger>
                    <InfoIcon
                      strokeWidth={1.5}
                      size={14}
                      className="ml-1 text-muted"
                    />
                  </TooltipTrigger>
                  <TooltipContent className="w-25">
                    <p>
                      Client facing name of this workspace. This name will be
                      displayed app wide to refer to this workspace.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </>
            }
            rules={[{ required: true, message: 'Please enter a client name' }]}
          >
            <Input onChange={(e) => onClientNameChange(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="friendlyName"
            label={
              <>
                Friendly Name
                <Tooltip>
                  <TooltipTrigger>
                    <InfoIcon
                      strokeWidth={1.5}
                      size={14}
                      className="ml-1 text-muted"
                    />
                  </TooltipTrigger>
                  <TooltipContent className="w-60">
                    <p>
                      Internal name of this workspace. This name can be an
                      abbreviation, old name etc and can be used to search for
                      this workspace. This can be same as client name but wont
                      be showed to clients.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </>
            }
            rules={[
              { required: true, message: 'Please enter a friendly name' },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="my-6">
            <div className="mb-2">
              <p>Slug</p>
            </div>
            <Tooltip>
              <TooltipTrigger className="w-full">
                <div className="flex flex-row justify-between">
                  <Input
                    value={workspaceSlug}
                    disabled
                    className="rounded-br-none rounded-tr-none"
                  />
                  <TailwindButton
                    className="rounded-bl-none rounded-tl-none"
                    onClick={async () => {
                      await navigator.clipboard.writeText(workspaceSlug)
                      displaySuccessMessage('Copied slug to clipboard', 3)
                    }}
                  >
                    Copy
                  </TailwindButton>
                </div>
              </TooltipTrigger>
              <TooltipContent className="w-full">
                {isAddWorkspace ? (
                  <p>
                    Workspace slug is auto generated based on the client name.
                    Please ensure that this slug is added in Salesforce to the
                    corresponding account.
                  </p>
                ) : (
                  <p>Workspace slug cannot be edited</p>
                )}
              </TooltipContent>
            </Tooltip>
          </div>

          <div className="mb-4 mt-6 w-full">
            <p className="mb-2">
              <span className="mr-1 text-destructive">*</span>Industry
            </p>
            <Select
              onChange={(value) => {
                setIndustry(value)
              }}
              className="w-full"
              value={industry}
            >
              {Object.entries(Industry).map(([value, label]) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <h2 className="mb-4 text-lg font-semibold">Data privacy</h2>
          <DataPrivacyButton />
          <StorageRegionSelector />
          <StorageAccountBucketForm />
          <Form.Item
            name="retentionPeriod"
            label="Retention"
            initialValue={workspace?.retentionPeriod}
          >
            <Select
              onChange={(value) => {
                setShouldPopConfirmationModal(true)
                if (value === 'custom') {
                  setCustomRetention(true)
                  form.setFieldsValue({
                    retentionPeriod: DEFAULT_RETENTION_PERIOD,
                  })
                } else {
                  setCustomRetention(false)
                }
              }}
            >
              {customDateString && (
                <Select.Option value={customRetentionPeriod}>
                  {customDateString}
                </Select.Option>
              )}
              <Select.Option value={0}>
                {intervalToReadable(0, true)}
              </Select.Option>
              <Select.Option value={604_800}>
                {intervalToReadable(604_800, true)}
              </Select.Option>
              <Select.Option value={1_209_600}>
                {intervalToReadable(1_209_600, true)}
              </Select.Option>
              <Select.Option value={2_592_000}>
                {intervalToReadable(2_592_000, true)}
              </Select.Option>
              <Select.Option value={15_768_000}>
                {intervalToReadable(15_768_000, true)}
              </Select.Option>
              <Select.Option value={null}>Unlimited</Select.Option>
              <Select.Option value="custom">Custom</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="eyesOffTier"
            label="Eyes off tier"
            initialValue={workspace?.eyesOffTier}
          >
            {userInfo.IsReadSensitiveDataUser ? (
              <Select
                onChange={(value) => {
                  setShouldPopEyesOffTierModal(true)
                  setNewEyesOffTier(value)
                }}
              >
                {Object.entries(eyesOffTierToReadableName).map(
                  ([value, label]) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  )
                )}
              </Select>
            ) : (
              <span>{workspace?.eyesOffTier}</span>
            )}
          </Form.Item>

          {customRetention && (
            <Form.Item
              name="customRetentionPeriod"
              label="Custom retention period (in seconds)"
              rules={[
                {
                  required: false,
                  message: 'Please enter a custom retention period',
                },
              ]}
            >
              <InputNumber min={0} max={31536000} defaultValue={0} />
            </Form.Item>
          )}
          <h2 className="mb-4 text-lg font-semibold">
            Workspace configuration
          </h2>
          <Form.Item
            name="kind"
            label="Type"
            rules={[
              {
                required: true,
                message:
                  'Please select a type of workspace; leave default if not sure',
              },
            ]}
          >
            <Select
              onChange={(value) => {
                setWorkspaceKind(value)
                setParentWorkspace(null) // important to reset!
              }}
            >
              {Object.values(WorkspaceKind).map((kind) => (
                <Option key={kind} value={kind}>
                  {kind}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="domains"
            label={
              <>
                Domains
                <Tooltip>
                  <TooltipTrigger>
                    <InfoIcon
                      strokeWidth={1.5}
                      size={14}
                      className="ml-1 text-muted"
                    />
                  </TooltipTrigger>
                  <TooltipContent className="w-25">
                    {userInfo.IsReadSensitiveDataUser
                      ? 'Only valid and non-public domains are allowed. Deleting existing domains will hide history for those domains'
                      : 'Only valid and non-public domains are allowed. Deleting existing domains is not permitted; please contact an admin to delete'}
                  </TooltipContent>
                </Tooltip>
              </>
            }
            help={domainsHelpMessage}
          >
            <DeprecatedTagInput
              tags={workspaceDomains}
              setTags={onWorkspaceDomainsChange}
              closeableTags={userInfo.IsReadSensitiveDataUser}
              allowReset={false} // too dangerous to allow reset of domains
              inputPlaceholder="Add a domain"
              allowDuplicates={false}
              disabled={workspaceKind !== WorkspaceKind.ORGANIZATION}
            />
          </Form.Item>

          {userInfo.IsReadSensitiveDataUser && (
            <Form.Item name="allowBypassDomainCheck" valuePropName="checked">
              <Checkbox>Allow users with other domains to be added</Checkbox>
            </Form.Item>
          )}
          {!_.isNil(domainsHelpMessage) && <br />}

          {workspaceKind !== WorkspaceKind.ORGANIZATION && (
            <Form.Item
              name="parentWorkspace"
              label="Parent workspace"
              rules={[
                {
                  // this Form.Item does not receive the value directly from the WorkspaceDropdown component (not antd),
                  // so this is the workaround to basically set required as true (and fail the form validation)
                  // if the user tries to submit the form without selecting a parent workspace
                  required: _.isNil(parentWorkspace),
                },
              ]}
            >
              <WorkspaceDropdown
                selected={parentWorkspace}
                setSelected={setParentWorkspace}
                workspaces={workspaces.filter(
                  (w) => w.kind === WorkspaceKind.ORGANIZATION
                )}
              />
            </Form.Item>
          )}
          <Form.Item
            name="channelPartner"
            label="Channel partner"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select>
              <Option value="">None</Option>
              {Object.values(ChannelPartner).map((partner) => (
                <Option key={partner} value={partner}>
                  {partner}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="exportFeedback"
            label="Export feedback details in query exports"
            valuePropName="checked"
          >
            <Checkbox>
              <p>Enable exporting feedback</p>
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="authConn"
            label={
              <div className="flex items-center space-x-1">
                <p>Auth Conn</p>
                <Tooltip>
                  <TooltipTrigger>
                    <InfoCircledIcon className="mt-0.5" />
                  </TooltipTrigger>
                  <TooltipContent className="w-72">
                    <p>
                      Auth Conn is the SAML connection string used in the
                      workspaces SAML setup.
                      <br /> This should be set to the default {
                        PWD_AUTH_CONN
                      }{' '}
                      for non-saml workspaces
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
            }
          >
            <Input
              value={authConn}
              onChange={(e) => setAuthConn(e.target.value)}
              defaultValue={PWD_AUTH_CONN}
            />
          </Form.Item>
          {authConn.trim().toLowerCase() !== PWD_AUTH_CONN.toLowerCase() && (
            <Form.Item
              name="blockNonProvisionedSamlUsers"
              label={
                <div className="flex items-center space-x-1">
                  <p>Require SAML users to be provisioned by an admin</p>
                  <Tooltip>
                    <TooltipTrigger>
                      <InfoCircledIcon className="mt-0.5" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72">
                      <p>
                        When enabled, only users that have been provisioned in
                        the workspace by internal admins or client admins will
                        be allowed to login.
                        <br />
                        This should not be checked unless specified by an admin.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </div>
              }
              valuePropName="checked"
            >
              <Checkbox>
                <p>Block non provisioned SAML users</p>
              </Checkbox>
            </Form.Item>
          )}
          {authConn.trim().toLowerCase() !== PWD_AUTH_CONN.toLowerCase() && (
            <Form.Item
              name="defaultAuthWorkspace"
              label={
                <div className="flex items-center space-x-1">
                  <p>Default SAML Auth Workspace</p>
                  <Tooltip>
                    <TooltipTrigger>
                      <InfoCircledIcon className="mt-0.5" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72">
                      <p>
                        {defaultAuthWsCheckDisabledBecauseDefaultExists && (
                          <>
                            Default auth workspace is {defaultAuthWs.clientName}
                            <br />
                            <br />
                          </>
                        )}
                        {defaultAuthWsCheckDisabledBecauseStrictAdGroupsEnabled && (
                          <>
                            {!_.isNil(parentWorkspace) ? (
                              <>
                                <span className="mr-1">
                                  Strict AD groups are enabled in parent
                                  workspace
                                </span>
                                {parentWorkspace.clientName}
                              </>
                            ) : (
                              <>Strict AD groups are enabled.</>
                            )}
                            <br />
                            <br />
                          </>
                        )}
                        When checked, users will be redirected to this workspace
                        when we cant match a workspace for the user using
                        users-workspaces or AD groups.
                        <br />
                        <br />
                        This can only be checked for one workspace in a
                        multi-workspace parent-child setup. If strict AD groups
                        are enabled, this option is NOT allowed.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </div>
              }
              valuePropName="checked"
            >
              <Checkbox
                disabled={defaultAuthWsCheckDisabled}
                onChange={onDefaultAuthWsChange}
              >
                <p>Mark workspace as default auth workspace</p>
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item
            name="authConnGroupId"
            label="Auth Conn Group Id"
            help={
              authConnGroupIdMissing ? (
                <Alert className="mb-4 mt-1 p-3" variant="destructive">
                  Auth conn group id is required because parent workspace has
                  strict ad groups enabled
                </Alert>
              ) : undefined
            }
          >
            <Input
              onChange={(e) => setAuthConnGroupId(e.target.value)}
              maxLength={64}
            />
          </Form.Item>
          {/* only allow strict ad groups to be enabled if the workspace is an organization (i.e. parent workspace is not set)
            and the workspace has auth conn group id set and all of its children have auth conn group id set
          */}
          {authConn.trim().toLowerCase() !== PWD_AUTH_CONN.toLowerCase() &&
            workspaceKind === WorkspaceKind.ORGANIZATION &&
            authConnGroupId && (
              <Form.Item
                name="strictAdGroups" // antd component relies on this name property, needs to match settings json
                className="hidden"
                label={
                  <div className="flex items-center space-x-1">
                    <p>Strict AD groups</p>
                    <Tooltip>
                      <TooltipTrigger>
                        <InfoCircledIcon className="mt-0.5" />
                      </TooltipTrigger>
                      <TooltipContent className="w-72">
                        <p>
                          {(!_.isNil(defaultAuthWs) ||
                            defaultAuthWsChecked) && (
                            <>
                              This cannot be enabled if a default auth workspace
                              is set.
                              <br />
                              <br />
                            </>
                          )}
                          {!childrenWorkspaces.every(
                            (ws) => ws.authConnGroupId
                          ) && (
                            <>
                              This cannot be enabled if all children workspaces
                              do not have auth conn group id set.
                              <br />
                              <br />
                            </>
                          )}
                          When enabled, users with mismatched groups will not be
                          able to login.
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                }
                valuePropName="checked"
              >
                <Checkbox
                  disabled={strictAdGroupsCheckDisabled}
                  // can't control antd checkbox checked property directly here
                  // this onchange updates component state for disabled property
                  onChange={onStrictAdGroupsChange}
                >
                  <p>Enable strict AD groups</p>
                </Checkbox>
              </Form.Item>
            )}
          <Form.Item name="loginTimeout" label="Automatic Logout">
            <Select
              onChange={(value) => {
                if (value === 'custom') {
                  setCustomLogin(true)
                  form.setFieldsValue({
                    loginTimeout: DEFAULT_LOGIN_TIMEOUT,
                  })
                } else {
                  setCustomLogin(false)
                }
              }}
            >
              {customLoginTimeoutString && (
                <Select.Option value={customLoginTimeout}>
                  {customLoginTimeoutString}
                </Select.Option>
              )}
              <Select.Option value={3600}>
                {intervalToReadable(3600, true)}
              </Select.Option>
              <Select.Option value={86_400}>
                {intervalToReadable(86_400, true)}
              </Select.Option>
              <Select.Option value={604_800}>
                {intervalToReadable(604_800, true)}
              </Select.Option>
              <Select.Option value={undefined}>Unlimited</Select.Option>
              <Select.Option value="custom">Custom</Select.Option>
            </Select>
          </Form.Item>
          {customLogin && (
            <Form.Item
              name="customLoginTimeout"
              label="Custom login timeout (in seconds)"
              rules={[
                {
                  required: false,
                  message: 'Please enter a custom login timeout',
                },
              ]}
            >
              <InputNumber min={3600} max={604800} defaultValue={604800} />
            </Form.Item>
          )}

          <Form.Item
            name="allowedCidrs"
            label={
              <>
                Allowed network IPs
                <Tooltip>
                  <TooltipTrigger>
                    <InfoIcon
                      strokeWidth={1.5}
                      size={14}
                      className="ml-1 text-muted"
                    />
                  </TooltipTrigger>
                  <TooltipContent className="w-25">
                    A list of IP addresses or CIDRs, representing the network
                    <br />
                    addresses users of this workspace must originate from.
                    <br />
                    Leave empty to apply no restriction.
                    <br />
                    <br />
                    Examples:
                    <br />
                    <pre>4.5.6.7, 1.2.3.0/24</pre>
                  </TooltipContent>
                </Tooltip>
              </>
            }
          >
            <DeprecatedTagInput
              tags={workspaceAllowedCidrs}
              setTags={onWorkspaceAllowedCidrsChange}
              closeableTags={userInfo.IsReadSensitiveDataUser}
              allowReset
              allowDuplicates={false}
              inputPlaceholder="Add an IP/CIDR"
            />
          </Form.Item>
          {isAddWorkspace && (
            <Form.Item
              name="perms"
              label={
                <>
                  Default Base and Admin Perms
                  <Tooltip>
                    <TooltipTrigger>
                      <InfoIcon
                        strokeWidth={1.5}
                        size={14}
                        className="ml-1 text-muted"
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      These permissions will be included for all base and admin
                      users in this workspace. Admin users will also have
                      additional management permissions.
                    </TooltipContent>
                  </Tooltip>
                </>
              }
            >
              <Select
                mode="multiple"
                loading={_.isEmpty(permOptionValues)}
                placeholder="Select base role permissions"
                defaultValue={Array.from(DEFAULT_BASE_PERMS)}
                value={selectedDefaultPerms}
                onChange={(value) => {
                  setSelectedDefaultPerms(value)
                }}
                options={permOptionValues}
                virtual={false}
                optionFilterProp="label"
                disabled={isPwcWorkspace(workspaceSlug)}
              />
            </Form.Item>
          )}

          <Form.Item
            name="loginSlug"
            rules={[
              {
                pattern: new RegExp(/^[a-z0-9-]*$/),
                message:
                  'Login slugs may only consist of lowercase letters and dashes',
              },
              {
                max: 64,
                message: 'Login slugs cannot exceed 64 characters',
              },
            ]}
            label={
              <div className="flex items-center space-x-1">
                <p>Login slug</p>
                <Tooltip>
                  <TooltipTrigger>
                    <InfoCircledIcon className="mt-0.5" />
                  </TooltipTrigger>
                  <TooltipContent className="w-72">
                    <p>
                      A login slug is the customer-specific suffix for a
                      customized login URL.
                      <br /> It has to be unique across all workspaces, but
                      typically can just be set to the name of the customer.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
            }
          >
            <Input
              addonBefore={
                getSiteUrl(site, environment) + BaseAppPath.Login + '/'
              }
              value={loginSlug}
              onChange={(e) => setLoginSlug(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="onlyCustomStatusMessage"
            valuePropName="checked"
            label={
              <div className="flex items-center space-x-1">
                <p>
                  Only allow setting custom status bar message for workspace
                </p>
                <Tooltip>
                  <TooltipTrigger>
                    <InfoCircledIcon className="mt-0.5" />
                  </TooltipTrigger>
                  <TooltipContent className="w-72">
                    <p>
                      When enabled, this workspace will not see status messages
                      set through the incident management portal. Instead you
                      can set a custom status message in workspace details if
                      required.
                      <br />
                      This should not be checked unless specified by an admin.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
            }
          >
            <Checkbox>
              <p>Only allow custom status message</p>
            </Checkbox>
          </Form.Item>
          <Form.List name="welcomeScreens">
            {(fields, { add, remove }, { errors }) => (
              <div
                style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}
              >
                {fields.map((field, index) => (
                  <Card
                    key={index}
                    size="small"
                    title="Edit custom message"
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name)
                        }}
                      />
                    }
                  >
                    <Form.Item required={false} key={field.key}>
                      <Form.Item name={[field.name, 'id']} hidden />
                      <Form.Item
                        name={[field.name, 'type']}
                        label="Type"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select>
                          {Object.values(WelcomeScreenType).map(
                            (screenType) => (
                              <Option key={screenType} value={screenType}>
                                {screenType}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                      <Form.Item name={[field.name, 'title']} label="Title">
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="HTML Content"
                        name={[field.name, 'content']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the html content.',
                          },
                        ]}
                      >
                        <TextArea rows={8} />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, 'taskType']}
                        label="Task type"
                      >
                        <Input />
                      </Form.Item>
                    </Form.Item>
                  </Card>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add()
                    }}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add custom message
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </div>
            )}
          </Form.List>
          <Form.Item>
            <TailwindButton
              onClick={onSave}
              data-testid="edit-workspace-submit"
              disabled={!canSave}
              tooltip={
                canSave ? undefined : 'Please make sure all inputs are valid'
              }
            >
              Save
            </TailwindButton>
          </Form.Item>
        </Form>
        <WorkspaceDataPrivacyUpdateModal
          modalOpen={dataPrivacyConfirmationModalOpen}
          setModalOpen={setDataPrivacyConfirmationModalOpen}
          onUpdateSubmit={handleSubmit}
          isUpdating={loading}
        />
        {workspace && userInfo.IsReadSensitiveDataUser && (
          <WorkspaceEyesOffTierUpdateModal
            modalOpen={eyesOffTierModalOpen}
            setModalOpen={setEyesOffTierModalOpen}
            onUpdateSubmit={handleSubmit}
            isUpdating={loading}
            workspace={workspace}
            newEyesOffTier={newEyesOffTier}
          />
        )}
      </SettingsLayout>
    </>
  )
}

export default AddEditWorkspace
