import React, { useMemo } from 'react'

import { ColumnDef } from '@tanstack/react-table'

import { TableSelect } from 'openapi/models/TableSelect'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { Maybe } from 'types'

import { Source } from 'utils/task'

import AssistantSources from 'components/assistant/components/assistant-sources'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
  AssistantWorkflowSourceExtractor,
} from 'components/assistant/workflows'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import RenderBlockToolbar from 'components/assistant/workflows/components/render-block-toolbar/render-block-toolbar'
import TableSelectTable, {
  generateColumns,
} from 'components/assistant/workflows/components/table-select/table-select'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useCalcTableMargin } from 'components/assistant/workflows/hooks/use-calc-table-margin'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

type RowData = TableSelect['rows'][number]

export const AssistantWorkflowTableRendererThread: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({ blockParams, loadingStates, paramStatus, feedback, stepId }) => {
  const { headerText, table } = blockParams
  const { handleSourceClick, getHrvyInfoMetadata } = useSourceUtils({
    sources: (blockParams.sources || []) as Source[],
  })

  const columns: ColumnDef<RowData>[] = table
    ? generateColumns(table, getHrvyInfoMetadata)
    : []

  const negativeMargin = useCalcTableMargin(
    blockParams.sources as Maybe<Source[]>
  )

  const Sidebar = useMemo(
    () =>
      (blockParams.sources?.length || 0) > 0 ? (
        <AssistantSources
          allDocuments={[]}
          sources={blockParams.sources as Maybe<Source[]>}
          onSetActiveFileId={handleSourceClick}
          // 42px makes it align with the content of the block
          className="absolute left-0 top-[42px] ml-4 w-72 sm:ml-4 sm:w-72"
          style={{ left: negativeMargin }}
        />
      ) : undefined,
    [blockParams.sources, handleSourceClick, negativeMargin]
  )

  const isCompleted = paramStatus === 'COMPLETED'

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <LoadingState isCompleted={isCompleted} states={loadingStates} />
        {paramStatus === 'COMPLETED' && headerText && (
          <AssistantWorkflowThreadText
            text={headerText}
            completionStatus={paramStatus}
          />
        )}
        {(table?.rows.length || 0) > 0 && (
          <div
            className="mt-5"
            style={{
              marginLeft: `-${negativeMargin}px`,
              marginRight: `-${negativeMargin}px`,
            }}
          >
            <TableSelectTable data={table!.rows} columns={columns} />
          </div>
        )}
        {isCompleted && (
          <div className="mt-6">
            <RenderBlockToolbar
              feedback={feedback}
              workflowStepId={stepId ?? ''}
            />
          </div>
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTableRendererInput: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({ blockParams, paramStatus }) => {
  const { headerText } = blockParams
  const renderedText = paramStatus !== 'COMPLETED' ? 'Working…' : headerText
  return (
    <WorkflowInput>
      <WorkflowInputFooter footer={renderedText} />
    </WorkflowInput>
  )
}

export const AssistantWorkflowTableRendererExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowRenderComponentBlocks.TABLE
> = ({ blockParams }) => {
  const { table } = blockParams
  const { getHrvyInfoMetadata } = useSourceUtils({
    sources: (blockParams.sources || []) as Source[],
  })

  const columns: ColumnDef<RowData>[] = table
    ? generateColumns(table, getHrvyInfoMetadata)
    : []

  return (
    <div className="mt-5">
      <TableSelectTable data={table?.rows ?? []} columns={columns} />
    </div>
  )
}

export const tableSourceExtractor: AssistantWorkflowSourceExtractor<
  typeof WorkflowRenderComponentBlocks.TABLE
> = (blockParams) => {
  return blockParams.sources as Source[]
}
