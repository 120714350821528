import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { WorkflowStreamRequest } from 'openapi/models/WorkflowStreamRequest'
import { WorkflowStreamRequestInputCompletion } from 'openapi/models/WorkflowStreamRequestInputCompletion'
import { useGeneralStore } from 'stores/general-store'

import { ToBackendKeys } from 'utils/utils'

import { useAssistantWorkflowStreamHandler } from 'components/assistant/workflows/hooks/use-assistant-workflow'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { useAssistantWorkflowComponents } from 'components/assistant/workflows/use-workflow-components'
import { AssistantWorkflowLayout } from 'components/assistant/workflows/workflow-layout'
import { loadWorkflow } from 'components/assistant/workflows/workflow-loader'

export const AssistantWorkflowPage: React.FC = () => {
  const { id: workflowId, eventId: eventIdParam } = useParams()
  const { sendWorkflowChat, closeSocket } = useAssistantWorkflowStreamHandler()
  const [
    setCurrentWorkflow,
    workflowDefinition,
    currentEvent,
    reset,
    pendingMessage,
  ] = useAssistantWorkflowStore(
    useShallow((state) => [
      state.setCurrentWorklow,
      state.workflowDefinition,
      state.currentEvent,
      state.reset,
      state.pendingMessage,
    ])
  )
  const navigate = useNavigate()
  const setIsSidebarOpenAndToggle = useGeneralStore(
    (state) => state.setIsSidebarOpenAndToggle
  )

  useMount(() => {
    // on mount we want to set the sidebar to false
    setIsSidebarOpenAndToggle(false)
  })

  useUnmount(() => {
    closeSocket()
    reset()
  })

  const handleCurrentStepCompletion = useCallback(
    (blockId: string, stepId: string | null, result: any) => {
      if (!workflowDefinition) {
        console.error('No workflow definition found')
        return
      }

      // If we've already sent a message and are waiting for a response,
      // don't send another one.
      if (pendingMessage) {
        return
      }

      const request: WorkflowStreamRequestInputCompletion = {
        requestType: 'new_input',
        updatedWorkflowStepId: stepId || undefined,
        workflowStepBlockId: blockId,
        workflowStepData: ToBackendKeys(result),
      }

      sendWorkflowChat({
        eventId:
          eventIdParam || (currentEvent?.eventId as string | undefined) || null,
        workflowId: workflowDefinition.id,
        request: request as WorkflowStreamRequest,
      })
    },
    [
      currentEvent?.eventId,
      eventIdParam,
      pendingMessage,
      sendWorkflowChat,
      workflowDefinition,
    ]
  )

  const componentsToRender = useAssistantWorkflowComponents(
    handleCurrentStepCompletion
  )
  const {
    completedStepsComponents,
    inProgressStepsComponents,
    InputComponent,
  } = componentsToRender
  const threadComponents = [
    ...completedStepsComponents,
    ...inProgressStepsComponents,
  ]

  useEffect(() => {
    if (
      !currentEvent?.eventId ||
      String(currentEvent.eventId) === eventIdParam ||
      currentEvent.isPending
    )
      return
    navigate(`/assistant/workflows/${workflowId}/${currentEvent.eventId}`)
  }, [currentEvent, eventIdParam, navigate, workflowId])

  useEffect(() => {
    if (!workflowId) return

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadWorkflow(workflowId, eventIdParam).then((workflow) => {
      setCurrentWorkflow(workflow.workflowDefinition, workflow.workflowStatus)
    })
  }, [workflowId, eventIdParam, setCurrentWorkflow])

  return (
    <AssistantWorkflowLayout
      input={InputComponent}
      thread={threadComponents}
      title={workflowDefinition?.name ?? 'Loading'}
    />
  )
}
