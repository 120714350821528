/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkflowTranscriptSummarizationBackendFunctionOutput } from './WorkflowTranscriptSummarizationBackendFunctionOutput';
import {
    WorkflowTranscriptSummarizationBackendFunctionOutputFromJSON,
    WorkflowTranscriptSummarizationBackendFunctionOutputFromJSONTyped,
    WorkflowTranscriptSummarizationBackendFunctionOutputToJSON,
    WorkflowTranscriptSummarizationBackendFunctionOutputToJSONTyped,
} from './WorkflowTranscriptSummarizationBackendFunctionOutput';

/**
 * 
 * @export
 * @interface WorkflowAnswerRenderBlockBlockParams
 */
export interface WorkflowAnswerRenderBlockBlockParams {
    /**
     * 
     * @type {WorkflowTranscriptSummarizationBackendFunctionOutput}
     * @memberof WorkflowAnswerRenderBlockBlockParams
     */
    answer: WorkflowTranscriptSummarizationBackendFunctionOutput;
}

/**
 * Check if a given object implements the WorkflowAnswerRenderBlockBlockParams interface.
 */
export function instanceOfWorkflowAnswerRenderBlockBlockParams(value: object): value is WorkflowAnswerRenderBlockBlockParams {
    if (!('answer' in value) || value['answer'] === undefined) return false;
    return true;
}

export function WorkflowAnswerRenderBlockBlockParamsFromJSON(json: any): WorkflowAnswerRenderBlockBlockParams {
    return WorkflowAnswerRenderBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowAnswerRenderBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowAnswerRenderBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'answer': WorkflowTranscriptSummarizationBackendFunctionOutputFromJSON(json['answer']),
    };
}

export function WorkflowAnswerRenderBlockBlockParamsToJSON(json: any): WorkflowAnswerRenderBlockBlockParams {
    return WorkflowAnswerRenderBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowAnswerRenderBlockBlockParamsToJSONTyped(value?: WorkflowAnswerRenderBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'answer': WorkflowTranscriptSummarizationBackendFunctionOutputToJSON(value['answer']),
    };
}

