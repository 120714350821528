import React from 'react'

import { Workspace } from 'models/workspace'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

import MultiUserAddRemove from './multi-user-add-remove'

interface AddUsersDialogProps {
  isOpen: boolean
  onClose: () => void
  workspace?: Workspace
  onAdd?: () => Promise<void>
  customerFacing?: boolean
}

const AddUsersDialog: React.FC<AddUsersDialogProps> = ({
  isOpen,
  onClose,
  workspace,
  onAdd,
  customerFacing = false,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Add users</DialogTitle>
        </DialogHeader>
        <MultiUserAddRemove
          type="add"
          workspace={workspace}
          customerFacing={customerFacing}
          onAdd={onAdd}
          displayDownloadButton={true}
        />
      </DialogContent>
    </Dialog>
  )
}

export default AddUsersDialog
