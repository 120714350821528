import React, { useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Trash } from 'lucide-react'
import pluralize from 'pluralize'

import { removeVaultAddOnUsers, VaultUser } from 'models/vault'
import { Workspace } from 'models/workspace'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { useVaultManagementStore } from 'components/settings/workspace/workspace-details/vault-management/vault-management-store'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import Icon from 'components/ui/icon/icon'

interface VaultAddOnUsersTableProps {
  vaultUsers: VaultUser[]
  isLoading: boolean
  workspace: Workspace
}

const VaultUsersTable = ({
  vaultUsers,
  isLoading,
  workspace,
}: VaultAddOnUsersTableProps) => {
  const userInfo = useAuthUser()

  const setVaultAddOnUsers = useVaultManagementStore(
    (state) => state.setVaultAddOnUsers
  )

  const onRemoveUser = async (userEmail: string) => {
    try {
      const resp = await removeVaultAddOnUsers(workspace.id, [userEmail])
      setVaultAddOnUsers(resp.users.filter((u) => u.hasSeat))
      displaySuccessMessage('Vault add-on user removed successfully', 5)
    } catch (error) {
      const errMsg =
        error instanceof Error
          ? error.message
          : 'Failed to remove vault add-on user'
      displayErrorMessage(errMsg, 10)
    }
  }

  const columns: ColumnDef<VaultUser>[] = [
    {
      accessorKey: 'userEmail',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Email" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 w-1/2 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'actions',
      header: '',
      size: 1,
      cell: ({ row }) => {
        return (
          <div className="float-right flex justify-end space-x-2">
            <Button
              disabled={workspace.vaultUserCountIsUnlimited}
              className="flex items-center justify-center rounded transition hover:bg-button-secondary-hover"
              variant="ghost"
              size="xsIcon"
              tooltip={
                workspace.vaultUserCountIsUnlimited
                  ? 'Workspaces has unlimited seats, so vault access is granted through roles.'
                  : `Remove ${row.getValue('userEmail')}`
              }
              tooltipSide="left"
              onClick={() => onRemoveUser(row.getValue('userEmail'))}
              aria-label={`Remove ${row.getValue('userEmail')}`}
            >
              <Icon icon={Trash} className="stroke-destructive" />
            </Button>
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  const [filter, setFilter] = useState<string>('')

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: vaultUsers,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setTablePaginationState,
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        rolePk: false,
        actions: userInfo.IsInternalAdminWriter,
      },
    },

    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'users',
    table.getFilteredRowModel().rows.length
  )}`

  return (
    <div className="-mt-10">
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
      />
      <div className="mt-4">
        <DataTable
          table={table}
          className={cn({ 'h-[420px]': !isLoading })}
          useVirtual={vaultUsers.length > 100}
          isLoading={isLoading}
          emptyStateText="No users found"
        />
      </div>
      <DataTableFooter table={table} isLoading={isLoading}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
    </div>
  )
}

export default VaultUsersTable
