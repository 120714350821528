import React from 'react'
import { useLocation } from 'react-router-dom'

import { getMergedQueryPath } from 'hooks/use-navigate-with-query-params'
import { cn } from 'utils/utils'

import Link from './link/link'

interface Props {
  label: string
  href: string
  removeParams?: string[]
  className?: string
  noUnderline?: boolean
  leadingIcon?: React.ReactNode
  trailingIcon?: React.ReactNode
  openInNewTab?: boolean
  linkClickCallback?: () => void
  innerClassName?: string
  isExternalLink?: boolean
}

export const TextLink = ({
  label,
  href,
  removeParams,
  className,
  noUnderline,
  leadingIcon,
  trailingIcon,
  openInNewTab,
  linkClickCallback,
  innerClassName,
  isExternalLink,
}: Props): JSX.Element => {
  const location = useLocation()
  const hrefWithParams = isExternalLink
    ? href
    : getMergedQueryPath(href, location, removeParams)

  const LinkText = (
    <p
      className={cn(
        'inline-flex items-center transition group-hover:text-primary',
        { underline: !noUnderline },
        innerClassName
      )}
    >
      {leadingIcon &&
        React.cloneElement(leadingIcon as React.ReactElement, {
          className: 'inline-block mr-1 w-4 h-4',
        })}
      {label}
      {trailingIcon &&
        React.cloneElement(trailingIcon as React.ReactElement, {
          className: 'inline-block ml-1 w-4 h-4',
        })}
    </p>
  )

  const handleLinkClick = (event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault()
    }
    if (linkClickCallback) {
      linkClickCallback()
    }
    if (openInNewTab) {
      window.open(hrefWithParams, '_blank') // Open the link in a new tab
    } else {
      window.location.href = hrefWithParams // Open the link in the same tab
    }
  }

  const handleLinkKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLinkClick() // Simulate a click
    }
  }

  return openInNewTab ? (
    <span
      onClick={handleLinkClick}
      onKeyDown={handleLinkKeyDown}
      className={className}
      style={{ cursor: 'pointer' }}
      role="button"
      tabIndex={0}
    >
      {LinkText}
    </span>
  ) : (
    <Link
      to={hrefWithParams}
      className={cn('group', className)}
      onKeyDown={handleLinkKeyDown}
      onClick={() => {
        if (linkClickCallback) {
          linkClickCallback()
        }
      }}
    >
      {LinkText}
    </Link>
  )
}
