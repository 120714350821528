import React from 'react'

import { WorkspaceRole } from 'models/roles'

import { MultiSelect } from 'components/ui/multi-select'

export const RolesMultiSelect: React.FC<{
  roles: WorkspaceRole[]
  selectedRolePks: string[]
  setSelectedRolePks: (selectedRolePks: string[]) => void
  placeholder?: string
  className?: string
}> = ({
  roles,
  selectedRolePks,
  setSelectedRolePks,
  placeholder = 'Role',
  className,
}) => {
  return (
    <MultiSelect
      className={className}
      placeholder={placeholder}
      sortedEntries={roles.map((role) => ({
        text: role.name,
        value: role.rolePk,
        values: [role.rolePk],
      }))}
      selectedValues={selectedRolePks}
      setSelectedValues={setSelectedRolePks}
    />
  )
}
