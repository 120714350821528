import React, { useRef, useState } from 'react'

import { Packer } from 'docx'
import { saveAs } from 'file-saver'
import { Download, Maximize2, Minimize2 } from 'lucide-react'
import * as XLSX from 'xlsx'

import { FileType } from 'types/file'

import { htmlToDocx } from 'utils/docx'
import { Source } from 'utils/task'
import { backendFormat, cn, s2ab } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import ExportDialog from 'components/common/export/export-dialog'
import {
  ExportOptionGroup,
  ExportOptionValues,
} from 'components/common/export/types'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

interface Props {
  children: React.ReactNode
  sources?: Source[]
  width?: string
  // children that only appear when the table is expanded
  expandedChildren?: React.ReactNode
  dialogClassName?: string
  hasContainer?: boolean
}
const Table = ({
  children,
  sources,
  width,
  expandedChildren,
  dialogClassName,
  hasContainer,
}: Props) => {
  const tableRef = useRef<HTMLTableElement>(null)
  const tablePreviewRef = useRef<HTMLTableElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const { trackEvent } = useAnalytics()

  const handleExpand = () => {
    setIsExpanded(true)
    trackEvent(`Markdown table expanded`)
  }
  const handleClose = () => setIsExpanded(false)
  const handleOpenChange = (isOpen: boolean) => {
    setIsExpanded(isOpen)
  }

  const exportOptions: ExportOptionGroup[] = [
    {
      id: 'format',
      name: 'Format',
      options: [
        {
          label: 'Microsoft Word',
          value: FileType.WORD,
        },
        {
          label: 'Microsoft Excel',
          value: FileType.EXCEL,
        },
        {
          label: 'CSV',
          value: FileType.CSV,
        },
      ],
      type: 'radio',
      defaultValue: FileType.WORD,
    },
  ]

  const handleExport = async (exportValues: ExportOptionValues) => {
    const includeAnnotation = !!exportValues.includeAnnotation
    const citationReplacement = includeAnnotation ? '[$1]' : ''
    const tableHtml =
      tableRef.current?.outerHTML ?? tablePreviewRef.current?.outerHTML ?? ''

    // By the time react-markdown gets to rendering our custom components,
    // we don't have access to the raw markdown anymore,
    // which is why we're pre-processing this HTML here.
    const cleanedHtml = tableHtml
      // Replace headings strong tags
      .replace(
        /<span\s+class="font-semibold">(.*?)<\/span>/g,
        '<strong>$1</strong>'
      )
      .replace(
        /<a\s+[^>]*data-state="closed"[^>]*>(\d+)<\/a>/g,
        citationReplacement
      )
      .replace(
        /<button\s+[^>]*class="source[^>]*>(\d+)<\/button>/g,
        citationReplacement
      )

    const dateTime = backendFormat(new Date())
    const fileName = `Table_${dateTime}`
    const format = exportValues.format
    trackEvent(`Markdown table exported`, { includeAnnotation, format })
    switch (format) {
      case FileType.WORD: {
        const doc = await htmlToDocx({
          html: cleanedHtml,
          injectTitle: null,
          sources: includeAnnotation && sources ? sources : null,
        })
        const blob = await Packer.toBlob(doc)
        return saveAs(blob, `${fileName}.docx`)
      }

      case FileType.EXCEL:
      case FileType.CSV: {
        // Write cleaned table back into an element for XLSX to process
        const tempContainer = document.createElement('div')
        tempContainer.innerHTML = cleanedHtml

        const xlsxTable = tempContainer.querySelector('table')
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.table_to_sheet(xlsxTable)
        XLSX.utils.book_append_sheet(wb, ws, 'Table')

        if (includeAnnotation && sources) {
          const references = sources.map((source) => ({
            Citation: source.footnote,
            Text: source.text.replace(/<\/?[^>]+(>|$)/g, ''), // Strip HTML tags
            Source: `${source.documentName || source.documentUrl}${
              source.page ? ' , Page ' + source.page : ''
            }`,
          }))
          const rs = XLSX.utils.json_to_sheet(references)
          XLSX.utils.book_append_sheet(wb, rs, 'Citations')
        }

        if (format === FileType.EXCEL) {
          const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
          return saveAs(
            new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
            `${fileName}.xlsx`
          )
        } else {
          const csvout = XLSX.write(wb, { bookType: 'csv', type: 'string' })
          return saveAs(
            new Blob([csvout], { type: 'text/csv' }),
            `${fileName}.csv`
          )
        }
      }

      default:
        console.error('Unsupported export type')
        return
    }
  }

  return (
    <div className="rounded-md bg-accent p-1" style={{ width }}>
      <div className="flex items-center justify-between px-1 pb-1">
        <p className="not-prose px-2 pt-1 text-xs font-medium text-secondary">
          Table
        </p>
        <div className="flex items-center">
          <ExportDialog
            disabled={false}
            hasSources={!!sources?.length}
            optionGroups={exportOptions}
            onExport={handleExport}
            title="Export table"
            trigger={
              <ActionButton tooltip="Export" dataTestId="table-export-button">
                <Icon icon={Download} size="small" />
              </ActionButton>
            }
          />
          <ActionButton
            onClick={handleExpand}
            tooltip="Expand"
            dataTestId="table-expand-button"
          >
            <Icon icon={Maximize2} size="small" />
          </ActionButton>
        </div>
      </div>
      <ScrollArea className="rounded-sm border bg-primary" hasHorizontalScroll>
        <table
          className="w-full [&_td:first-child]:pl-4 [&_td:last-child]:pr-4 [&_th:first-child]:pl-4 [&_th:last-child]:pr-4"
          ref={tablePreviewRef}
        >
          {children}
        </table>
      </ScrollArea>
      <Dialog open={isExpanded} onOpenChange={handleOpenChange}>
        <DialogContent
          className="size-full max-h-[calc(100vh-64px)] max-w-[calc(100vw-64px)]"
          innerClassName={cn('p-0', dialogClassName)}
          onOpenAutoFocus={(e) => e.preventDefault()}
          showCloseIcon={false}
          hasContainer={hasContainer}
        >
          <div className="flex shrink-0 items-center justify-between border-b py-3">
            <div className="px-8 text-xl font-medium">Table</div>
            <div className="flex items-center space-x-4">
              <ExportDialog
                disabled={false}
                hasSources={!!sources?.length}
                optionGroups={exportOptions}
                onExport={handleExport}
                title="Export table"
              />
              <Button
                className="text-secondary transition hover:text-primary"
                onClick={handleClose}
                variant="unstyled"
                size="smIcon"
              >
                <Icon icon={Minimize2} />
              </Button>
            </div>
          </div>
          <div className="virtualized-scrollbar prose prose-harvey w-full max-w-none grow pb-4">
            <table
              className="[&_td:first-child]:pl-8 [&_td:last-child]:pr-8 [&_td]:p-2.5 [&_th:first-child]:pl-8 [&_th:last-child]:pr-8 [&_th]:p-2.5"
              ref={tableRef}
            >
              {children}
            </table>
          </div>
          {expandedChildren}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Table

const ActionButton = ({
  children,
  onClick,
  tooltip,
  dataTestId,
}: {
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  tooltip: string
  dataTestId: string
}) => {
  return (
    <Tooltip disableHoverableContent>
      <TooltipTrigger asChild>
        <Button
          className="text-muted transition hover:bg-button-secondary-hover hover:text-muted"
          onClick={onClick}
          size="xsIcon"
          variant="ghost"
          data-testid={dataTestId}
        >
          {children}
        </Button>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  )
}
