import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { WorkflowDefinition } from 'openapi/models/WorkflowDefinition'
import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'
import { WorkflowEventStatus } from 'openapi/models/WorkflowEventStatus'
import Services from 'services'

export const loadWorkflow = async (workflowId: string, eventId?: string) => {
  const route = `assistant/workflows/${workflowId}${
    eventId ? `/${eventId}` : ''
  }`
  return Services.Backend.Get<{
    workflowDefinition: WorkflowDefinition
    workflowStatus: WorkflowEventStatus
  }>(route)
}

export const loadWorkflows = async (
  includeInternalAdminWorkflows: boolean = false
) => {
  const route = `assistant/workflows?include_internal_admin_workflows=${includeInternalAdminWorkflows}`
  return Services.Backend.Get<WorkflowDefinitionListItem[]>(route)
}

export const useLoadWorkflowsQuery = (isEnabled: boolean = true) => {
  return useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.AssistantWorkflowsQuery],
    queryFn: () => loadWorkflows(),
    enabled: isEnabled,
    refetchOnWindowFocus: false,
  })
}
