import * as React from 'react'

import { User } from 'lucide-react'

import { cn } from 'utils/utils'

interface CompleteProfileLaterProps {
  textAlign?: 'left' | 'center'
}

const CompleteProfileLater: React.FC<CompleteProfileLaterProps> = ({
  textAlign = 'left',
}: CompleteProfileLaterProps) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex size-16 items-center justify-center rounded-full border-2 p-2">
        <User className="size-9" />
      </div>
      <h1 className="text-xl font-medium">Complete your profile later</h1>
      <p
        className={cn(
          'font-sans text-sm text-inactive',
          textAlign === 'center' && 'text-center'
        )}
      >
        Your profile helps personalize your Harvey experience. You can change
        your answers anytime on your Profile page.
      </p>
    </div>
  )
}

export default CompleteProfileLater
