import { copyToClipboard as copyToClipboardUtil } from 'utils/copy-to-clipboard'
import { getDocxContentType } from 'utils/docx'
import { exportAsFormattedWordDocx } from 'utils/export'
import { displayErrorMessage } from 'utils/toast'
import { displaySuccessMessage } from 'utils/toast'
import { download } from 'utils/utils'

interface UseToolbarActionsProps {
  onDownload?: () => void
  metadata: any
  workflowName: string
  content: string
}
export const useToolbarActions = ({
  onDownload,
  metadata,
  workflowName,
  content,
}: UseToolbarActionsProps) => {
  const tryDownload = () => {
    if (onDownload) {
      return onDownload()
    }

    const hasTranslatedDocUrl = (
      metadata: any
    ): metadata is { translatedDocUrl: string } => {
      return metadata && typeof metadata.translatedDocUrl === 'string'
    }

    if (hasTranslatedDocUrl(metadata)) {
      download(metadata.translatedDocUrl)
        .then((message) => displaySuccessMessage(message))
        .catch((error) => displayErrorMessage(error))
      return
    }

    void exportAsFormattedWordDocx({
      title: workflowName,
      sections: [
        {
          content,
          type: getDocxContentType(content),
        },
      ],
      onSuccess: () => displaySuccessMessage('File successfully downloaded.'),
      onError: (message) => displayErrorMessage(message),
    })
  }

  const copyToClipboard = () => {
    copyToClipboardUtil(content)
      .then(() => displaySuccessMessage('Copied text to clipboard'))
      .catch(() => displayErrorMessage('Sorry, something went wrong'))
  }

  return { tryDownload, copyToClipboard }
}
