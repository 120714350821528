import React from 'react'

import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'

import WorkflowsList from './workflows-list'

const WorkflowsPage: React.FC = () => {
  return (
    <AppMain hasContainer data-testid="workflows-container">
      <AppHeader
        title="Workflows"
        subtitle="Use specialized workflows to tackle complex matters"
      />
      <WorkflowsList />
    </AppMain>
  )
}

export default WorkflowsPage
