/* tslint:disable */
/* eslint-disable */
/**
 * User Profiles
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LanguageCode } from './LanguageCode';
import {
    LanguageCodeFromJSON,
    LanguageCodeFromJSONTyped,
    LanguageCodeToJSON,
    LanguageCodeToJSONTyped,
} from './LanguageCode';
import type { Profession } from './Profession';
import {
    ProfessionFromJSON,
    ProfessionFromJSONTyped,
    ProfessionToJSON,
    ProfessionToJSONTyped,
} from './Profession';
import type { PracticeArea } from './PracticeArea';
import {
    PracticeAreaFromJSON,
    PracticeAreaFromJSONTyped,
    PracticeAreaToJSON,
    PracticeAreaToJSONTyped,
} from './PracticeArea';
import type { Title } from './Title';
import {
    TitleFromJSON,
    TitleFromJSONTyped,
    TitleToJSON,
    TitleToJSONTyped,
} from './Title';

/**
 * 
 * @export
 * @interface CreateUserProfileRequest
 */
export interface CreateUserProfileRequest {
    /**
     * 
     * @type {Profession}
     * @memberof CreateUserProfileRequest
     */
    primaryProfession?: Profession;
    /**
     * 
     * @type {Array<PracticeArea>}
     * @memberof CreateUserProfileRequest
     */
    practiceAreas?: Array<PracticeArea> | null;
    /**
     * 
     * @type {Title}
     * @memberof CreateUserProfileRequest
     */
    title?: Title | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUserProfileRequest
     */
    yoe?: number | null;
    /**
     * 
     * @type {LanguageCode}
     * @memberof CreateUserProfileRequest
     */
    preferredLanguage?: LanguageCode;
    /**
     * Custom language specification when preferred_language is not suitable
     * @type {string}
     * @memberof CreateUserProfileRequest
     */
    otherLanguage?: string | null;
}



/**
 * Check if a given object implements the CreateUserProfileRequest interface.
 */
export function instanceOfCreateUserProfileRequest(value: object): value is CreateUserProfileRequest {
    return true;
}

export function CreateUserProfileRequestFromJSON(json: any): CreateUserProfileRequest {
    return CreateUserProfileRequestFromJSONTyped(json, false);
}

export function CreateUserProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserProfileRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'primaryProfession': json['primary_profession'] == null ? undefined : ProfessionFromJSON(json['primary_profession']),
        'practiceAreas': json['practice_areas'] == null ? undefined : ((json['practice_areas'] as Array<any>).map(PracticeAreaFromJSON)),
        'title': json['title'] == null ? undefined : TitleFromJSON(json['title']),
        'yoe': json['yoe'] == null ? undefined : json['yoe'],
        'preferredLanguage': json['preferred_language'] == null ? undefined : LanguageCodeFromJSON(json['preferred_language']),
        'otherLanguage': json['other_language'] == null ? undefined : json['other_language'],
    };
}

export function CreateUserProfileRequestToJSON(json: any): CreateUserProfileRequest {
    return CreateUserProfileRequestToJSONTyped(json, false);
}

export function CreateUserProfileRequestToJSONTyped(value?: CreateUserProfileRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'primary_profession': ProfessionToJSON(value['primaryProfession']),
        'practice_areas': value['practiceAreas'] == null ? undefined : ((value['practiceAreas'] as Array<any>).map(PracticeAreaToJSON)),
        'title': TitleToJSON(value['title']),
        'yoe': value['yoe'],
        'preferred_language': LanguageCodeToJSON(value['preferredLanguage']),
        'other_language': value['otherLanguage'],
    };
}

