import { useEffect, useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { Draft } from 'openapi/models/Draft'
import { DraftRevision } from 'openapi/models/DraftRevision'
import Services from 'services'
import { useFileCache } from 'stores/file-cache'
import { HistoryDocument, HistoryItem } from 'types/history'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { Source, TaskStatus } from 'utils/task'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { useAuthUser } from 'components/common/auth-context'

export const useFetchWorkflowDraft = (draftId?: string) => {
  const userInfo = useAuthUser()
  const getFile = useFileCache(useShallow((s) => s.getFile))
  const { restoreHistoryItem, streamingMessage, messages, eventId } =
    useAssistantStore(
      useShallow((s) => ({
        restoreHistoryItem: s.restoreHistoryItem,
        streamingMessage: s.streamingMessage,
        messages: s.messages,
        eventId: s.eventId,
      }))
    )
  const navigate = useNavigateWithQueryParams()

  const {
    data,
    error: queryError,
    isFetching,
    isSuccess,
  } = useWrappedQuery({
    queryKey: ['workflow-draft', draftId],
    queryFn: async () => {
      if (!draftId) return null
      return await Services.Backend.Get<{
        draft: Draft
        revisions: DraftRevision[]
        documents: HistoryDocument[]
      }>(`assistant/workflows/draft/${draftId}`)
    },
    enabled: !!draftId && !streamingMessage,
  })

  useEffect(() => {
    if (isFetching || queryError || streamingMessage || !data) return

    if (data.draft.status === TaskStatus.ERRORED && !messages.length) {
      navigate('/assistant', { state: { skipReset: true } })
      return
    }
    const shouldRestoreHistoryItem =
      messages.length < (data.revisions.length || 1) ||
      eventId !== data.draft.id

    if (isSuccess && shouldRestoreHistoryItem) {
      const { draft, revisions } = data
      const annotations = revisions.reduce(
        (acc, revision) => {
          Object.entries(revision.sensitiveMeta?.annotations ?? {}).forEach(
            ([key, value]) => {
              acc[key] = value as Source
            }
          )
          return acc
        },
        {} as Record<string, Source>
      )
      const currentMessage = revisions.find(
        (revision) => revision.isCurrentRevision
      )
      const historyItem: HistoryItem = {
        messages: [
          ...revisions.map((revision) => ({
            sources: revision.sensitiveMeta?.annotations ?? [],
            annotations,
            caption: revision.sensitiveMeta?.caption ?? '',
            knowledgeSources: revision.sensitiveMeta?.knowledgeSources ?? [],
            query: revision.sensitiveMeta?.query ?? '',
            response: revision.sensitiveMeta?.response ?? '',
            messageId: revision.id,
            prevMessageId: revision.previousDraftRevisionId,
          })),
        ],
        status: draft.status as TaskStatus,
        created: draft.createdAt,
        updatedAt: draft.updatedAt,
        id: draft.id,
        currentMessageId: currentMessage?.id,
        userId: draft.userId,
        ...draft.sensitiveMeta,
      } as HistoryItem
      const fetchDocuments = async () => {
        const documents = (
          await Promise.all(
            Object.values(annotations)
              .filter((annotation) => !!annotation.documentId)
              .map((annotation) => getFile(annotation.documentId!))
          )
        ).filter((doc): doc is NonNullable<typeof doc> => doc !== undefined)
        const historyItemWithDocuments = {
          ...historyItem,
          documents,
        }
        restoreHistoryItem(historyItemWithDocuments, userInfo)
      }
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchDocuments()
    }
  }, [
    isFetching,
    data,
    isSuccess,
    restoreHistoryItem,
    messages.length,
    streamingMessage,
    navigate,
    userInfo,
    queryError,
    getFile,
    eventId,
  ])

  const error = useMemo(() => {
    if (queryError) return queryError
    if (!draftId) return new Error('No draft ID provided')
    return null
  }, [queryError, draftId])

  return {
    data,
    error,
    isFetching,
  }
}
