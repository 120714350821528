import React, { useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EventStatus } from 'openapi/models/EventStatus'

import { RenderWorkflowComponent } from './assistant-block-renderer'
import { useAssistantWorkflowStore } from './stores/assistant-workflow-store'
import { isFrontendBlock, stepBlockIdToWorkflowBlockDefinition } from './utils'

export const useAssistantWorkflowComponents = (
  handleCurrentStepCompletion: (
    blockId: string,
    stepId: string | null,
    result: any
  ) => void
) => {
  const [workflowDefinition, currentEvent] = useAssistantWorkflowStore(
    useShallow((state) => [state.workflowDefinition, state.currentEvent])
  )

  const completedSteps = useMemo(
    () =>
      currentEvent?.steps.filter(
        (step) => step.paramStatus === EventStatus.COMPLETED
      ) || [],
    [currentEvent]
  )

  const inProgressSteps = useMemo(
    () =>
      currentEvent?.steps.filter(
        (step) => step.paramStatus !== EventStatus.COMPLETED
      ) || [],
    [currentEvent]
  )

  /**
   * Build the completed steps' components, only depending on
   * completedSteps, workflowDefinition, and the handler.
   * If in-progress steps change, this memoized result will **not** update.
   */
  const completedStepsComponents = useMemo(() => {
    return completedSteps.map((step) => {
      const blockDefinition = stepBlockIdToWorkflowBlockDefinition(
        workflowDefinition,
        step.blockId
      )
      if (!isFrontendBlock(blockDefinition)) {
        console.warn('Unexpected block definition', blockDefinition)
        return null
      }

      return (
        <RenderWorkflowComponent
          key={`completed-${step.stepIdx}`}
          stepIdx={step.stepIdx}
          stepId={step.stepId || null}
          blockType={blockDefinition.blockId as any}
          blockParams={step.blockParams as any}
          outputData={step.outputData as any}
          renderType="thread"
          onCompleted={(result: unknown) =>
            handleCurrentStepCompletion(
              step.blockId,
              step.stepId || null,
              result
            )
          }
          completionStatus={step.completionStatus as EventStatus}
          paramStatus={step.paramStatus as EventStatus}
          loadingStates={step.loadingStates || []}
          workflowName={workflowDefinition?.name ?? ''}
          feedback={step.feedback}
        />
      )
    })
    // XXX: completedSteps is a new array every time, and will trigger a re-render every time. It's needed so that
    // step_id is populated once the step is completed. Find a more efficient way to do this.
  }, [completedSteps, workflowDefinition, handleCurrentStepCompletion])

  /**
   * Build the in-progress steps' components, which *will* re-render
   * if in-progress steps change, but that won't affect completedStepsComponents.
   *
   * Also prepare an InputComponent for the "current" or last in-progress step.
   */
  const inProgressStepsComponents = useMemo(() => {
    return inProgressSteps.map((step) => {
      const blockDefinition = stepBlockIdToWorkflowBlockDefinition(
        workflowDefinition,
        step.blockId
      )
      if (!isFrontendBlock(blockDefinition)) {
        console.warn('Unexpected block definition', blockDefinition)
        return
      }

      // Thread view for each in-progress step
      return (
        <RenderWorkflowComponent
          key={`in-progress-${step.stepIdx}`}
          stepId={step.stepId || null}
          stepIdx={step.stepIdx}
          blockType={blockDefinition.blockId as any}
          blockParams={step.blockParams as any}
          outputData={step.outputData as any}
          renderType="thread"
          onCompleted={(result: unknown) =>
            handleCurrentStepCompletion(
              step.blockId,
              step.stepId || null,
              result
            )
          }
          completionStatus={step.completionStatus as EventStatus}
          paramStatus={step.paramStatus as EventStatus}
          loadingStates={step.loadingStates}
          workflowName={workflowDefinition?.name ?? ''}
          feedback={undefined}
        />
      )
    })
  }, [inProgressSteps, workflowDefinition, handleCurrentStepCompletion])

  const InputComponent = useMemo(() => {
    const step =
      inProgressSteps.length > 0
        ? inProgressSteps[inProgressSteps.length - 1]
        : completedSteps[completedSteps.length - 1]

    if (!step) return null

    const blockDefinition = stepBlockIdToWorkflowBlockDefinition(
      workflowDefinition,
      step.blockId
    )

    return (
      <RenderWorkflowComponent
        key={`in-progress-input-${step.stepIdx}`}
        stepId={step.stepId || null}
        stepIdx={step.stepIdx}
        blockType={blockDefinition.blockId as any}
        blockParams={step.blockParams as any}
        outputData={step.outputData as any}
        renderType="input"
        onCompleted={(result: unknown) =>
          handleCurrentStepCompletion(step.blockId, step.stepId || null, result)
        }
        completionStatus={step.completionStatus as EventStatus}
        paramStatus={step.paramStatus as EventStatus}
        loadingStates={step.loadingStates}
        workflowName={workflowDefinition?.name ?? ''}
        feedback={undefined}
      />
    )
    // XXX: Doing completedSteps.length as a dependency rather than completedSteps
    // This is because completedSteps is a new array every time, so it would trigger
    // a re-render every time, which is not necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inProgressSteps,
    completedSteps.length,
    workflowDefinition,
    handleCurrentStepCompletion,
  ])

  return {
    completedStepsComponents,
    inProgressStepsComponents,
    InputComponent,
  }
}
