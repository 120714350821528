import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { DatabaseSource } from 'components/assistant/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'

export const useIsLefebvre = () => {
  const userInfo = useAuthUser()
  const knowledgeSource = useAssistantStore((s) => s.knowledgeSource)

  const isLefebvre =
    knowledgeSource && knowledgeSource.type === DatabaseSource.LEFEBVRE
  const isInternalOrLefebvreUser =
    userInfo.IsInternalUser || userInfo.IsLefebvreUser

  return isLefebvre && isInternalOrLefebvreUser
}
