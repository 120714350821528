import React, { useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { MessageFeedback } from 'openapi/models/MessageFeedback'
import { WorkflowEventStatusFeedback } from 'openapi/models/WorkflowEventStatusFeedback'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { CreateMessageFeedback } from 'components/assistant/utils/assistant-fetcher'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { useAuthUser } from 'components/common/auth-context'
import { FeedbackButton } from 'components/common/feedback/feedback'

interface Props {
  feedback: WorkflowEventStatusFeedback | undefined
  workflowStepId: string
}

const RenderBlockToolbar = ({ feedback, workflowStepId }: Props) => {
  const userInfo = useAuthUser()
  const trackEvent = useAssistantAnalytics()
  const [sentiment, setSentiment] = useState<number>(feedback?.sentiment || 0)
  const [getEventId] = useAssistantWorkflowStore(
    useShallow((state) => [state.getEventId])
  )
  const showFeedback = userInfo.IsFeedbackUser

  const handleSubmitFeedback = async (feedback: MessageFeedback) => {
    const eventId = await getEventId()

    trackEvent('Feedback Submitted', {
      sentiment: feedback.sentiment,
      comments: feedback.selectedComments,
      event_id: String(eventId),
      workflow_step_id: workflowStepId,
    })

    setSentiment(feedback.sentiment)

    try {
      await CreateMessageFeedback(String(eventId), workflowStepId, feedback)
      return true
    } catch {
      setSentiment(0)
      return false
    }
  }

  return (
    <div
      className="flex w-full justify-between space-x-0.5"
      id={`toolbar-${workflowStepId}`}
    >
      <div className="inline-flex items-center space-x-0.5">
        {showFeedback && (
          <>
            <FeedbackButton
              hasDocuments={false}
              onSubmit={handleSubmitFeedback}
              sentiment={sentiment}
              sentimentValue={1}
            />
            <FeedbackButton
              hasDocuments={false}
              onSubmit={handleSubmitFeedback}
              sentiment={sentiment}
              sentimentValue={-1}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default RenderBlockToolbar
