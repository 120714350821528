import { NavigateFunction } from 'react-router-dom'

import { IntegrationType } from 'openapi/models/IntegrationType'
import Services from 'services'
import { Maybe } from 'types'

interface ConnectOauthIntegrationResponse {
  authorizationUrl: string
}

export interface ConnectedIntegration {
  integrationType: IntegrationType
  expiresAt: string
  scopes: string[]
}

export interface ConnectedIntegrationToken extends ConnectedIntegration {
  accessToken: string
}

export const connectOauthIntegration = async (
  integrationType: IntegrationType
): Promise<void> => {
  const resp = await Services.Backend.Post<ConnectOauthIntegrationResponse>(
    `settings/integration/${integrationType}/authenticate`,
    {}, // body is empty, need this to set throwOnError in client options
    { throwOnError: true }
  )
  if (resp.authorizationUrl) {
    window.open(resp.authorizationUrl)
  } else {
    throw new Error('Failed to connect to integration')
  }
}

export const disconnectIntegration = async (
  integrationType: IntegrationType,
  authToken?: Maybe<string>
) => {
  await Services.Backend.Delete(
    `settings/integrations/${integrationType}/disconnect`,
    { authToken },
    { throwOnError: true }
  )
}

export const fetchIntegrationToken = async (
  integrationType: IntegrationType
): Promise<ConnectedIntegrationToken> => {
  return Services.Backend.Get(`settings/integration/${integrationType}/token`, {
    throwOnError: true,
  })
}

export const getIntegrationConnections = async (
  authToken?: Maybe<string>
): Promise<ConnectedIntegration[]> => {
  const resp = await Services.Backend.Post<ConnectedIntegration[]>(
    'settings/integrations/connections',
    { authToken },
    { throwOnError: true }
  )
  return resp
}

export const navigateToIntegrationsPage = (navigate: NavigateFunction) => {
  navigate('/settings/integrations')
}

const NETDOCS_MAIL_TO_SUBJECT = 'Request for NetDoc Extension Access in Harvey'
const NETDOCS_MAIL_TO_BODY = `Hi Harvey Support Team,

I would like to request access to the NetDocs extension for all users in my workspace.

Thanks,
[Your Name]`
export const openNetdocsMailTo = (email: string) => {
  const params = new URLSearchParams()
  params.append('subject', NETDOCS_MAIL_TO_SUBJECT)
  params.append('body', NETDOCS_MAIL_TO_BODY)

  const queryString = params.toString()
  const mailtoLink = `mailto:${encodeURIComponent(email)}${
    queryString ? '?' + queryString : ''
  }`

  const link = document.createElement('a')
  link.href = mailtoLink
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
