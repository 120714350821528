import React, { useState } from 'react'

import { API_USER_SENTINEL } from 'models/user-info'
import {
  BulkDeleteUsersResult,
  InternalAdminBulkDeleteUser,
} from 'models/users'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Alert } from 'components/ui/alert'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

interface DeleteUsersDialogProps {
  isOpen: boolean
  onClose: () => void
  selectedEmails: string[]
  onSuccess: () => Promise<void>
}

const DeleteUsersDialog: React.FC<DeleteUsersDialogProps> = ({
  isOpen,
  onClose,
  selectedEmails,
  onSuccess,
}) => {
  const [deleteAcknowledge, setDeleteAcknowledge] = useState<boolean>(false)

  const handleDelete = async () => {
    if (selectedEmails.length === 0) {
      displayErrorMessage('No users selected')
      return
    }

    const svcUsers = selectedEmails.filter((email) =>
      email.startsWith(API_USER_SENTINEL)
    )
    if (svcUsers.length > 0) {
      displayErrorMessage('Cannot delete svc users')
      return
    }

    const result: BulkDeleteUsersResult =
      await InternalAdminBulkDeleteUser(selectedEmails)

    if (!result) {
      displayErrorMessage('Failed to delete users')
      return
    }

    const { usersDeleted, usersFailed } = result
    if (usersDeleted.length > 0) {
      displaySuccessMessage(`${usersDeleted.length} users deleted`)
    }
    if (usersFailed.length > 0) {
      displayErrorMessage(
        `Failed to delete ${usersFailed.length} users: ${usersFailed.join(
          ', '
        )}`
      )
    }

    setDeleteAcknowledge(false)
    await onSuccess()
    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-destructive">Delete users</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <p>
            This is non-reversible. It will delete the users from Auth0 and
            remove their access from Harvey.
          </p>

          <div>
            <p className="mb-2 font-medium">Users to be deleted:</p>
            <div className="max-h-32 space-y-1 overflow-y-auto">
              {selectedEmails.map((email) => (
                <span key={email} className="mr-1">
                  <Badge variant="outline">{email}</Badge>
                </span>
              ))}
            </div>
          </div>

          <Alert variant="warning">
            <div className="flex items-center justify-between">
              <div>{`Please acknowledge you want to delete ${selectedEmails.length} users`}</div>
              <Checkbox
                checked={deleteAcknowledge}
                onCheckedChange={() => setDeleteAcknowledge(!deleteAcknowledge)}
              />
            </div>
          </Alert>
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            disabled={!deleteAcknowledge}
            onClick={handleDelete}
          >
            {`Delete ${selectedEmails.length} Users`}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteUsersDialog
