import { useState, useEffect, useCallback } from 'react'

import {
  fetchAllUsersEnriched,
  fetchAllUsersEnrichedInternalAdmin,
  RawUserEnriched,
} from 'models/users'

const useWorkspaceUsersEnriched = ({
  workspaceId,
  isInternalAdmin = false,
}: {
  workspaceId: number | null
  isInternalAdmin?: boolean
}) => {
  const [workspaceUsersEnriched, setWorkspaceUsersEnriched] = useState<
    RawUserEnriched[]
  >([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchWorkspaceUsersEnriched = useCallback(async () => {
    if (!workspaceId) return
    setIsLoading(true)
    const fetchUsers = isInternalAdmin
      ? fetchAllUsersEnrichedInternalAdmin
      : fetchAllUsersEnriched
    const allUsers = await fetchUsers(workspaceId)
    setWorkspaceUsersEnriched(allUsers)
    setIsLoading(false)
  }, [workspaceId, isInternalAdmin])

  useEffect(() => {
    void fetchWorkspaceUsersEnriched()
  }, [fetchWorkspaceUsersEnriched])
  return { workspaceUsersEnriched, fetchWorkspaceUsersEnriched, isLoading }
}

export default useWorkspaceUsersEnriched
