import React, { useEffect, useState } from 'react'

import _ from 'lodash'

import { instanceOfSynclyIntegration } from 'openapi/models/SynclyIntegration'
import { Maybe } from 'types'

import SynclySettings from 'components/common/integrations/syncly-settings'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsError from 'components/settings/settings-error'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'

import IntegrationAdmin from './integration-admin'
import IntegrationConnection from './integration-connection'
import { IntegrationDefinitions } from './integration-definitions'
import { authenticateSynclyAuthClient } from './syncly-utils'
import { ConnectedIntegration, getIntegrationConnections } from './utils'

const IntegrationsPage = () => {
  const [connections, setConnections] = useState<ConnectedIntegration[]>([])
  const settingsUser = useSettingsState((s) => s.settingsUser)

  useEffect(() => {
    const fetchConnections = async () => {
      if (!settingsUser) return
      const synclyIntegrationsAvailable = Object.entries(
        IntegrationDefinitions
      ).filter(
        ([key, integration]) =>
          integration.available(settingsUser) &&
          instanceOfSynclyIntegration(key)
      )

      let synclyClientAuthToken: Maybe<string> = null
      if (!_.isEmpty(synclyIntegrationsAvailable)) {
        synclyClientAuthToken = await authenticateSynclyAuthClient(
          settingsUser.id
        )
      }
      const resp = await getIntegrationConnections(synclyClientAuthToken)
      setConnections(resp)
    }
    fetchConnections()
  }, [settingsUser])

  if (!settingsUser?.isAnyIntegrationUser)
    return (
      <SettingsError
        description={`You don't have access to integrations for ${settingsUser?.workspace.clientName}`}
      />
    )

  return (
    <>
      <SettingsAppHeader />
      <SettingsLayout>
        <div className="space-y-10">
          {settingsUser.isIntegrationAdmin ? (
            <IntegrationAdmin
              userInfo={settingsUser}
              connections={connections}
            />
          ) : (
            <IntegrationConnection
              userInfo={settingsUser}
              connections={connections}
            />
          )}
        </div>
        <SynclySettings />
      </SettingsLayout>
    </>
  )
}

export default IntegrationsPage
