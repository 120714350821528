import * as React from 'react'

import { MODAL_HEIGHT } from 'providers/modal-flow-provider/modal-flow-navigator'

import NumberedProgress from 'components/common/numbered-progress/numbered-progress'
import { Button } from 'components/ui/button'
import { DialogFooter } from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'

interface ModalProps {
  children: React.ReactNode
  goBack: () => void
  handleContinue: () => void
  handleSkip?: () => void
  currentStep: number
  totalSteps: number
  nextDisabled?: boolean
  heading: string
  description: string
}

const MODAL_FOOTER_HEIGHT = 60

const Modal: React.FC<ModalProps> = ({
  children,
  goBack,
  handleContinue,
  handleSkip,
  currentStep,
  totalSteps,
  nextDisabled,
  heading,
  description,
}) => {
  return (
    <>
      <ScrollArea
        className="h-full"
        maxHeight={`${MODAL_HEIGHT - MODAL_FOOTER_HEIGHT}px`}
        overflowHintSide="both"
      >
        <div className="px-20 pt-14">
          <div className="flex flex-col gap-[2px]">
            <h1 className="font-sans text-lg font-medium text-primary">
              {heading}
            </h1>
            <p className="font-sans text-xs text-secondary">{description}</p>
          </div>

          <div className="py-8">{children}</div>
        </div>
      </ScrollArea>

      <DialogFooter className="flex w-full flex-row items-center justify-between border-t p-4">
        <div className="flex flex-1 justify-start">
          <NumberedProgress currentStep={currentStep} totalSteps={totalSteps} />
        </div>
        <div className="flex flex-1 items-center justify-end gap-3">
          {handleSkip && (
            <Button onClick={handleSkip} variant="ghost">
              Skip
            </Button>
          )}
          <Button onClick={goBack} variant="outline">
            Back
          </Button>
          <Button onClick={handleContinue} disabled={nextDisabled}>
            Next
          </Button>
        </div>
      </DialogFooter>
    </>
  )
}

export default Modal
