import { RowSelectionState } from '@tanstack/react-table'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'

import { TableSelect as OpenApiTableSelect } from 'openapi/models/TableSelect'
import { TableSelectRow as OpenApiTableSelectRow } from 'openapi/models/TableSelectRow'

import { useAssistantWorkflowStore } from './assistant-workflow-store'

export type TableSelectRow = OpenApiTableSelectRow & { isEditing?: boolean }
export type TableSelect = OpenApiTableSelect & { rows: TableSelectRow[] }

export interface TableSelectState {
  tableData: TableSelect | null
  selectedRows: RowSelectionState
  isLoading: boolean
  tempTableData: TableSelect | null
}

export interface TableSelectAction {
  setTableData: (tableData: TableSelect) => void
  setTempTableData: (tempTableData: TableSelect) => void
  setSelectedRows: (selection: RowSelectionState) => void
  setAllSelectedRows: (value: boolean) => void
  setIsLoading: (loading: boolean) => void
  updateTempTableDataRow: (rowIdx: number, key: string, value: string) => void
  replaceTableDataWithTempTableData: (rowIdx: number) => void
  replaceTempTableDataWithTableData: (rowIdx: number) => void
  addEmptyRowToTableData: () => void
}

const initialState: TableSelectState = {
  tableData: null,
  selectedRows: {},
  isLoading: false,
  tempTableData: null,
}

export const createTableSelectStore = () =>
  create(
    devtools(
      immer<TableSelectState & TableSelectAction>((set) => ({
        ...initialState,

        reset: () => set(() => initialState),
        setTableData: (tableData) =>
          set(() => ({
            tableData,
          })),
        setTempTableData: (tempTableData) =>
          set((state) => {
            state.tempTableData = tempTableData
          }),
        setAllSelectedRows: (value: boolean) => {
          set((state) => {
            state.selectedRows =
              state.tableData?.rows.reduce((acc, row, idx) => {
                acc[idx] = value
                return acc
              }, {} as RowSelectionState) ?? {}
          })
        },
        setSelectedRows: (selectedRows) => {
          set((state) => ({
            selectedRows: {
              ...state.selectedRows,
              ...selectedRows,
            },
          }))
        },
        setIsLoading: (loading) =>
          set((state) => {
            state.isLoading = loading
          }),
        updateTempTableDataRow: (rowIdx, key, value) => {
          set((state) => {
            const data = state.tempTableData ?? state.tableData
            if (!data) {
              return
            }
            data.rows[rowIdx].data[key] = value
            state.tempTableData = data
          })
        },
        replaceTableDataWithTempTableData: (rowIdx) => {
          set((state) => {
            const data = state.tableData
            if (!data) {
              return
            }
            data.rows[rowIdx] = state.tempTableData!.rows[rowIdx]
            data.rows[rowIdx].isEditing = false
            state.tableData = data
          })
        },
        replaceTempTableDataWithTableData: (rowIdx) => {
          set((state) => {
            state.tempTableData!.rows[rowIdx] = state.tableData!.rows[rowIdx]
          })
        },
        addEmptyRowToTableData: () => {
          set((state) => {
            const newRow = {
              isEditing: true,
              isSelected: true,
              data: Object.keys(state.tableData!.rows[0].data).reduce(
                (acc, key) => {
                  acc[key] = ''
                  return acc
                },
                {} as Record<string, string>
              ),
            }
            state.tableData!.rows.push(newRow)
            state.tempTableData!.rows.push(newRow)
          })
        },
      }))
    )
  )

export const useTableSelectStore = (
  stepIdx: number
): ReturnType<typeof createTableSelectStore> => {
  const [blockStores, addBlockStore] = useAssistantWorkflowStore(
    useShallow((state) => [state.blockStores, state.addBlockStore])
  )

  if (!blockStores[stepIdx]) {
    const blockStore = createTableSelectStore()
    addBlockStore(stepIdx, blockStore)
    return blockStore
  }

  return blockStores[stepIdx]
}
