import * as React from 'react'

import mitt from 'mitt'

type WorkflowEvents = {
  followUpClicked: string
  followUpHandlerRegistered: void
}

export const emitter = mitt<WorkflowEvents>()

export const useWorkflowEmitterEvents = <T extends keyof WorkflowEvents>(
  eventName: T,
  handler: (event: WorkflowEvents[T]) => void
) => {
  React.useEffect(() => {
    // Subscribe to mitt event
    emitter.on(eventName, handler)

    // Let listeners know that the handler is registered.
    emitter.emit('followUpHandlerRegistered')

    // Unsubscribe on unmount
    return () => {
      emitter.off(eventName, handler)
    }
  }, [eventName, handler])
}
