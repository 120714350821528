import { useCallback } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { WorkflowStreamRequest } from 'openapi/models/WorkflowStreamRequest'

import { displayWarningMessage } from 'utils/toast'
import useHarveySocket from 'utils/use-harvey-socket'
import { ToBackendKeys } from 'utils/utils'

import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'

export type AssistantWorkflowStreamHandler = {
  sendWorkflowChat: (params: {
    eventId: string | null
    workflowId: string
    request: WorkflowStreamRequest
  }) => void
  closeSocket: () => void
}

export const useAssistantWorkflowStreamHandler =
  (): AssistantWorkflowStreamHandler => {
    const [
      workflowSetter,
      setPendingMessage,
      streamInProgress,
      setStreamInProgress,
    ] = useAssistantWorkflowStore(
      useShallow((s) => [
        s.workflowSetter,
        s.setPendingMessage,
        s.streamInProgress,
        s.setStreamInProgress,
      ])
    )

    const { initSocketAndSendQuery, sendCancelRequest } = useHarveySocket({
      path: 'assistant/workflow',
      setter: workflowSetter,
      endCallback: () => {
        setStreamInProgress(false)
      },
      closeOnUnmount: false,
    })

    const sendWorkflowChat = useCallback(
      async (params: {
        eventId: string | null
        workflowId: string
        request: WorkflowStreamRequest
      }) => {
        if (streamInProgress) {
          displayWarningMessage(
            'This workflow is already in progress, please wait.'
          )
          return
        }
        setPendingMessage(true)
        setStreamInProgress(true)
        const { eventId, workflowId, request } = params
        const req: any = {
          query: '',
          additionalRequestParams: ToBackendKeys(request),
          additionalAuthParams: {
            workflow_id: workflowId,
          },
        }

        if (eventId) {
          req['additionalAuthParams']['event_id'] = eventId
        }

        initSocketAndSendQuery(req)
        setPendingMessage(false)
      },
      [
        initSocketAndSendQuery,
        setPendingMessage,
        setStreamInProgress,
        streamInProgress,
      ]
    )

    const closeSocket = useCallback(() => {
      sendCancelRequest()
    }, [sendCancelRequest])

    return { sendWorkflowChat, closeSocket }
  }
