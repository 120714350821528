import React from 'react'

import { Permission, UserInfo } from 'models/user-info'
import { FileUploadSource } from 'openapi/models/FileUploadSource'
import { IntegrationType } from 'openapi/models/IntegrationType'

import GoogleDriveLogo from 'components/common/integrations/google-drive-logo'
import IManageLogo from 'components/common/integrations/imanage-logo'
import SharepointOneDriveLogo from 'components/common/integrations/sharepoint-onedrive-logo'
import GoogleDriveIcon from 'components/ui/icons/google-drive-icon'
import IManageIcon from 'components/ui/icons/imanage-icon'
import SharePointIcon from 'components/ui/icons/sharepoint-icon'

import {
  SynclySettingsOpenState,
  SynclySettingsOpenStateType,
} from './syncly-utils'

export interface ResourceUrlProps {
  required: boolean
  tooltip: string
  inputPlaceholder?: string
}

export interface IntegrationDefinition {
  name: string
  permission: Permission
  icon: React.ComponentType<{ className?: string }>
  LogoComponent: React.ReactNode
  title: React.ComponentType<{ className?: string }>
  description: string
  available: (userInfo: UserInfo) => boolean
  enablementContent: string[]
  resourceUrlProps?: ResourceUrlProps
  fileUploadSource: FileUploadSource
  canAdminManage: boolean
  onSynclyEnable?: (
    setSynclySettingsOpenState?: (state: SynclySettingsOpenState) => void,
    onEnableSuccess?: () => Promise<void>
  ) => void
  onSynclyDisable?: (
    setSynclySettingsOpenState?: (state: SynclySettingsOpenState) => void,
    onEnableSuccess?: () => Promise<void>
  ) => void
  onSynclyConnect?: (
    setSynclySettingsOpenState?: (state: SynclySettingsOpenState) => void,
    onConnectSuccess?: () => void
  ) => void
  onSynclyDisconnect?: () => void
  onSynclyViewEdit?: (
    setSynclySettingsOpenState?: (state: SynclySettingsOpenState) => void,
    onEditSuccess?: () => void
  ) => void
}

export const IntegrationDefinitions: Record<
  IntegrationType,
  IntegrationDefinition
> = {
  [IntegrationType.SHAREPOINT]: {
    name: 'SharePoint',
    permission: Permission.SHAREPOINT_INTEGRATION,
    icon: SharePointIcon,
    LogoComponent: <SharepointOneDriveLogo wrap />,
    title: ({ className }) => (
      <p className={className}>
        <span className="font-semibold">SharePoint</span>{' '}
        <span className="text-secondary"> (with OneDrive)</span>
      </p>
    ),
    description:
      'Connect your work account to upload files directly from SharePoint or OneDrive.',
    available: (userInfo: UserInfo) => {
      return (
        userInfo.workspace.integrations.includes(IntegrationType.SHAREPOINT) &&
        userInfo.permissions.includes(Permission.SHAREPOINT_INTEGRATION)
      )
    },
    enablementContent: [
      'Authenticate into their SharePoint and OneDrive accounts from Harvey.',
      'Upload files from SharePoint and OneDrive directly into Harvey.',
    ],
    resourceUrlProps: {
      required: true,
      tooltip:
        'The resource URL is the URL of SharePoint or OneDrive site you want to connect to. It should start with https://',
      inputPlaceholder: 'https://acme.sharepoint.com/',
    },
    fileUploadSource: FileUploadSource.SHAREPOINT,
    canAdminManage: true,
  },
  [IntegrationType.GOOGLE_DRIVE]: {
    name: 'Google Drive',
    permission: Permission.GOOGLE_DRIVE_INTEGRATION,
    icon: GoogleDriveIcon,
    LogoComponent: <GoogleDriveLogo />,
    title: ({ className }) => (
      <p className={className}>
        <span className="font-semibold">Google Drive</span>
      </p>
    ),
    description: 'Users can upload files directly from Google Drive.',
    available: (userInfo: UserInfo) => {
      return (
        userInfo.workspace.integrations.includes(
          IntegrationType.GOOGLE_DRIVE
        ) && userInfo.permissions.includes(Permission.GOOGLE_DRIVE_INTEGRATION)
      )
    },
    enablementContent: [
      'Authenticate into their Google Drive account from Harvey.',
      'Upload files from Google Drive directly into Harvey.',
      'Use these files for collaboration or sharing.',
    ],
    fileUploadSource: FileUploadSource.GOOGLE_DRIVE,
    canAdminManage: true,
  },
  [IntegrationType.I_MANAGE]: {
    name: 'iManage',
    permission: Permission.IMANAGE_INTEGRATION,
    icon: IManageIcon,
    LogoComponent: <IManageLogo />,
    fileUploadSource: FileUploadSource.IMANAGE,
    title: ({ className }) => (
      <p className={className}>
        <span className="font-semibold">iManage</span>
      </p>
    ),
    description: 'Users can upload files directly from iManage.',
    available: (userInfo: UserInfo) => {
      return (
        userInfo.workspace.integrations.includes(IntegrationType.I_MANAGE) &&
        userInfo.permissions.includes(Permission.IMANAGE_INTEGRATION)
      )
    },
    enablementContent: [
      'Authenticate into their iManage account from Harvey via Syncly.',
      'Upload files from iManage directly into Harvey.',
    ],
    canAdminManage: true,
    onSynclyConnect: (setSynclySettingsOpenState, onClose) => {
      if (setSynclySettingsOpenState) {
        setSynclySettingsOpenState({
          integrationType: IntegrationType.I_MANAGE,
          settingsType: SynclySettingsOpenStateType.AUTH,
          onSuccess: onClose,
        })
      }
    },
    onSynclyViewEdit: (setSynclySettingsOpenState, onEditSuccess) => {
      if (setSynclySettingsOpenState) {
        setSynclySettingsOpenState({
          integrationType: IntegrationType.I_MANAGE,
          settingsType: SynclySettingsOpenStateType.EDIT,
          onSuccess: onEditSuccess,
        })
      }
    },
    onSynclyEnable: (setSynclySettingsOpenState, onEnableSuccess) => {
      if (setSynclySettingsOpenState) {
        setSynclySettingsOpenState({
          integrationType: IntegrationType.I_MANAGE,
          settingsType: SynclySettingsOpenStateType.EDIT,
          onSuccess: onEnableSuccess,
        })
      }
    },
  },
  [IntegrationType.I_MANAGE_CLOUD]: {
    name: 'iManage Cloud',
    permission: Permission.IMANAGE_CLOUD_INTEGRATION,
    icon: IManageIcon,
    LogoComponent: <IManageIcon />,
    fileUploadSource: FileUploadSource.IMANAGE,
    title: ({ className }) => (
      <p className={className}>
        <span className="font-semibold">iManage Cloud</span>
      </p>
    ),
    description: 'Users can upload files directly from iManage Cloud.',
    available: (userInfo: UserInfo) => {
      return (
        userInfo.workspace.integrations.includes(
          IntegrationType.I_MANAGE_CLOUD
        ) && userInfo.permissions.includes(Permission.IMANAGE_INTEGRATION)
      )
    },
    enablementContent: [
      'Authenticate into their iManage Cloud account from Harvey via Syncly.',
      'Upload files from iManage Cloud directly into Harvey.',
    ],
    canAdminManage: true,
  },
  [IntegrationType.SYNCLY]: {
    name: 'Syncly',
    permission: Permission.IMANAGE_INTEGRATION,
    icon: () => <></>,
    LogoComponent: <></>,
    fileUploadSource: FileUploadSource.COMPUTER, // this is default
    title: () => <>Syncly</>,
    description: 'Users can authenticate with Syncly for other integrations',
    available: () => false,
    enablementContent: [],
    canAdminManage: false,
  },
}
