import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'

import { PracticeArea } from 'openapi/models/PracticeArea'
import { Profession } from 'openapi/models/Profession'
import { Title } from 'openapi/models/Title'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { ProfileSetupFlowType } from 'components/common/flows/profile-setup/profile-setup'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import {
  markV1ProductTourAsCompleted,
  runHarveyV1ProductTour,
} from 'components/common/product-tours/run-product-tour'
import CompleteProfileLater from 'components/common/profile/complete-profile-later'
import {
  ProfileData,
  useUserProfileStore,
} from 'components/common/user-profile-store'
import { Button } from 'components/ui/button'
import { DialogFooter } from 'components/ui/dialog'

export interface ProfileCompleteLaterStepInput {
  primaryProfession?: Profession
  practiceAreas?: PracticeArea[]
  title?: Title
  yoe?: number
  preferredLanguage?: string
}

export type ProfileCompleteLaterStepType = Step<ProfileCompleteLaterStepInput>

const ProfileCompleteLaterStep: React.FC = () => {
  const { exit, goBack } = useFlowControls<ProfileSetupFlowType>()
  const { user } = useAuth0()
  const userInfo = useAuthUser()
  const stepInput = useFlowStepInput<ProfileCompleteLaterStepType>()
  const createUserProfile = useUserProfileStore((s) => s.createUserProfile)
  const { trackEvent } = useAnalytics()
  const navigate = useNavigate()

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent(
      'profile_setup_complete_later_step_viewed',
      preparedEventProperties
    )
  }, [stepInput, trackEvent])

  const handleContinue = React.useCallback(() => {
    if (!user) return

    const profileData: ProfileData = {
      primaryProfession: stepInput.primaryProfession,
      practiceAreas: stepInput.practiceAreas,
      title: stepInput.title,
      yoe: stepInput.yoe,
      preferredLanguage: stepInput.preferredLanguage,
    }

    createUserProfile(user, profileData)
      .then(() => {
        const preparedEventProperties = prepareEventProperties(stepInput)
        trackEvent('profile_setup_completed_later', preparedEventProperties)

        if (!userInfo.HasCompletedV1ProductTour) {
          runHarveyV1ProductTour(userInfo, navigate)
          markV1ProductTourAsCompleted().catch((error) => {
            console.error('Error marking V1 product tour as completed', error)
          })
        }

        exit()
      })
      .catch((error) => {
        console.error('Error creating user profile', error)
      })
  }, [createUserProfile, exit, navigate, stepInput, trackEvent, user, userInfo])

  return (
    <>
      <div className="mx-auto flex size-full max-w-[400px] flex-col items-center justify-center pb-3">
        <CompleteProfileLater textAlign="center" />
      </div>
      <DialogFooter className="flex w-full flex-row items-center justify-between border-t p-4">
        <div className="flex flex-1 items-center justify-end gap-3">
          <Button onClick={goBack} variant="outline">
            Back
          </Button>
          <Button onClick={handleContinue}>Continue to Harvey</Button>
        </div>
      </DialogFooter>
    </>
  )
}

export default ProfileCompleteLaterStep
