import { keyBy } from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UserInfo } from 'models/user-info'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'
import { HistoryItem } from 'types/history'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { filterSuccessfulPromises } from 'utils/typescript'
import { HarveySocketSetter, HarveySocketTask } from 'utils/use-harvey-socket'

import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import { AssistantMessage } from 'components/assistant/types'
import * as api from 'components/assistant/utils/assistant-api'
import { getMessageThreadFromMessages } from 'components/assistant/utils/assistant-helpers'
import {
  DatabaseSource,
  FileSource,
} from 'components/assistant/utils/assistant-knowledge-sources'
import { KnowledgeSourceItem } from 'components/assistant/utils/assistant-knowledge-sources'

interface AssistantState {
  // public state
  eventId: string | null
  folderId: Maybe<number>
  createdAt: Date | null
  // TODO: Might make sense to make this a map for faster lookups
  messages: AssistantMessage[]
  streamingMessage: AssistantMessage | null
  query: string
  currentMessageId: string | null
  pendingMessage: AssistantMessage | null
  documents: UploadedFile[]
  documentsUploading: FileUploadingState[]
  userCaption: string | null
  processingFilesBatchPromise: Promise<UploadedFile[]> | null
  preservedState: {
    query: string
    currentMessageId: string | null
  } | null
  isEventOwner: boolean
  eventOwnerUserEmail: string | null
  showRecentQueries: boolean
  knowledgeSource: KnowledgeSourceItem | null
  isReadOnly: boolean
  mode: AssistantMode
  isForking: boolean
  zipFiles: File[]
  pstFiles: File[]
  isAskHarveyDisabled: boolean
  researchDialogArea: DatabaseSource
  showResearchDialog: boolean
  showVaultDialog: boolean
}

export type FileUploadingState = {
  name: string
  size: number
  promise: Promise<UploadedFile>
  controller: AbortController
}

type AssistantLoadingState = {
  progressPercent: number
  message: string
}

interface AssistantAction {
  processFilesBatch: () => Promise<UploadedFile[]>
  setDocuments: (documents: UploadedFile[]) => void
  setDocumentsUploading: (value: FileUploadingState[]) => void
  setCreatedAt: (createdAt: Date) => void
  setTask: HarveySocketSetter
  reset: () => void
  setStreamingMessage: (message: Partial<AssistantMessage> | null) => void
  moveStreamingMessageToMessages: () => void
  restoreHistoryItem: (
    historyItem: HistoryItem,
    userInfo: UserInfo,
    isReadOnly?: boolean
  ) => void
  setEventId: (eventId: string) => void
  setFolderId: (folderId: Maybe<number>) => void
  getDocumentFileIds: () => Promise<string[]>
  setQuery: (query: string) => void
  getStreamingMessageLoadingState: () => AssistantLoadingState | null
  getCurrentThreadMessages: () => AssistantMessage[]
  setCurrentMessageId: (messageId: string | null) => void
  deleteMessage: (message: AssistantMessage) => void
  setPendingMessage: (message: Partial<AssistantMessage> | null) => void
  // Error/cancel handling, preserve state before streaming and restore it
  // if the streaming is cancelled or errored
  preserveState: (query: string, currentMessageId: string | null) => void
  restoreState: () => void
  setShowRecentQueries: (showRecentQueries: boolean) => void
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  setMode: (mode: AssistantMode) => void
  setIsForking: (isForking: boolean) => void
  setZipFiles: (zipFiles: File[]) => void
  setPstFiles: (pstFiles: File[]) => void
  setIsAskHarveyDisabled: (isAskHarveyDisabled: boolean) => void
  removeFilterIds: (filterIds: string[]) => void
  removeFileIds: (fileIds: string[]) => void
  setResearchDialogArea: (researchDialogArea: DatabaseSource) => void
  setShowResearchDialog: (showResearchDialog: boolean) => void
  setShowVaultDialog: (showVaultDialog: boolean) => void
}

const initialState: AssistantState = {
  eventId: null,
  folderId: undefined,
  createdAt: null,
  messages: [],
  streamingMessage: null,
  query: '',
  currentMessageId: null,
  pendingMessage: null,
  documents: [],
  documentsUploading: [],
  userCaption: null,
  processingFilesBatchPromise: null,
  preservedState: null,
  isEventOwner: true,
  eventOwnerUserEmail: null,
  showRecentQueries: true,
  knowledgeSource: null,
  isReadOnly: false,
  mode: AssistantMode.ASSIST,
  isForking: false,
  zipFiles: [],
  pstFiles: [],
  isAskHarveyDisabled: false,
  researchDialogArea: DatabaseSource.EDGAR,
  showResearchDialog: false,
  showVaultDialog: false,
}

export const useAssistantStore = create(
  devtools(
    immer<AssistantState & AssistantAction>((set, get) => ({
      ...initialState,
      /**
       * Process the files that have been uploaded. These need to be processed
       * one batch of new files at a time. So if one batch is being processed,
       * we wait until it's done before processing the next batch.
       */
      processFilesBatch: async () => {
        const processingDocumentBatch = get().processingFilesBatchPromise
        const documentsUploading = get().documentsUploading

        // Don't allow multiple concurrent file processing
        if (processingDocumentBatch) {
          return await processingDocumentBatch
        }

        // If there are no files to process, return the current list of documents
        if (documentsUploading.length === 0) {
          return get().documents
        }

        const processDocuments = async (
          documentsToProcess: FileUploadingState[]
        ) => {
          const uploadedDocuments = await Promise.allSettled(
            documentsToProcess.map((doc) => doc.promise)
          )

          const eventId = get().eventId

          let documentsUploadingByName = keyBy(get().documentsUploading, 'name')

          const successfulUploads = filterSuccessfulPromises(uploadedDocuments)
            .map((value, i) => ({
              ...value,
              name: documentsToProcess[i].name,
            }))
            .filter((upload) => !!documentsUploadingByName[upload.name])

          const documents = get().documents

          const fileIds = [
            ...documents.map((doc) => doc.id),
            ...successfulUploads.map((doc) => doc.id),
          ]

          if (eventId) await api.processFiles(eventId, fileIds)

          documentsUploadingByName = keyBy(get().documentsUploading, 'name')

          set({
            processingFilesBatchPromise: null,
            documentsUploading: get().documentsUploading.filter(
              (doc) => !documentsToProcess.includes(doc)
            ),
            documents: get().documents.concat(
              // Only add successful uploads that are still in the uploading state
              // This prevents adding files that were removed while in the uploading
              // state.
              successfulUploads.filter(
                (doc) => !!documentsUploadingByName[doc.name]
              )
            ),
          })
          return get().documents
        }

        const promise = processDocuments(documentsUploading)
        set({ processingFilesBatchPromise: promise })
        return await promise
      },
      getDocumentFileIds: async () => {
        const processingDocumentBatch = get().processingFilesBatchPromise

        if (processingDocumentBatch) {
          await processingDocumentBatch
        }

        return await get()
          .processFilesBatch()
          .then((files) => files.map((file) => file.id))
      },
      setDocumentsUploading: (value: FileUploadingState[]) => {
        set((state) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          state.documentsUploading = value
        })
      },
      setDocuments: (documents: UploadedFile[]) => {
        set((state) => {
          state.documents = documents
        })
      },
      setCreatedAt: (createdAt: Date) => {
        set((state) => {
          state.createdAt = createdAt
        })
      },
      setTask: (socketState: Partial<HarveySocketTask>) => {
        set((state) => {
          if (state.streamingMessage) {
            if (
              socketState.queryId &&
              (!state.eventId || state.eventId !== socketState.queryId)
            ) {
              state.eventId = socketState.queryId
              state.createdAt = new Date()
            }

            state.streamingMessage = {
              ...state.streamingMessage,
              ...(socketState as any),
            }
          }
        })
      },
      setStreamingMessage: (message: Partial<AssistantMessage> | null) => {
        set((state) => {
          state.streamingMessage = message as any
        })
      },
      getStreamingMessageLoadingState: () => {
        const streamingMessage = get().streamingMessage
        const pendingMessage = get().pendingMessage
        if (!streamingMessage) return null
        // pending message will show up as queued until streaming is done
        if (pendingMessage) {
          return {
            progressPercent: 20,
            message: 'Processing…',
          }
        }

        // Phase 1: Uploading files
        if (get().documentsUploading.length > 0) {
          return {
            progressPercent: 10,
            message: 'Uploading files…',
          }
        }

        // Phase 2: Loading, no progress updates
        if (!streamingMessage.progress) {
          return {
            progressPercent: 20,
            message: streamingMessage.headerText || 'Processing…',
          }
        }

        // Phase 3: Streamed progress updates
        return {
          // Prevent the progress bar from going back below 20%
          progressPercent: Math.max(20, streamingMessage.progress),
          message: streamingMessage.headerText,
        }
      },
      getCurrentThreadMessages: () => {
        const { currentMessageId, messages } = get()
        if (!currentMessageId) return []
        return getMessageThreadFromMessages(messages, currentMessageId)
      },
      setCurrentMessageId: (messageId: string | null) => {
        set((state) => {
          state.currentMessageId = messageId
        })
      },
      // Called on completion of a message, moves it to more "permanent" storage
      moveStreamingMessageToMessages: () => {
        set((state) => {
          const streamingMessage = state.streamingMessage
          if (!streamingMessage) return

          state.currentMessageId = streamingMessage.messageId
          state.messages.push(streamingMessage)
          state.streamingMessage = null
        })
      },
      reset: () => {
        set((state) => ({
          ...initialState,
          showRecentQueries: state.showRecentQueries,
        }))
      },
      restoreHistoryItem: (
        historyItem: HistoryItem,
        userInfo: UserInfo,
        isReadOnly: boolean = false
      ) => {
        set((state) => {
          // Reset everything first so no stale data from old history
          state.reset()

          // Update with new history item details
          return {
            eventId: historyItem.id,
            folderId: historyItem.folderId,
            createdAt: historyItem.created,
            userCaption: historyItem.userCaption || null,
            messages: historyItem.messages || [],
            documents: historyItem.documents || [],
            isEventOwner:
              (historyItem.userId === userInfo.id ||
                historyItem.userId === userInfo.dbId) &&
              !historyItem.isLibraryEvent,
            eventOwnerUserEmail: historyItem.userId || null,
            currentMessageId:
              historyItem.currentMessageId ||
              (historyItem.messages && historyItem.messages.length > 0
                ? historyItem.messages[historyItem.messages.length - 1]
                    .messageId
                : null),
            knowledgeSource: historyItem.knowledgeSources?.find((ks) =>
              [...Object.values(DatabaseSource), FileSource.VAULT].includes(
                ks.type
              )
            ),
            isReadOnly: isReadOnly,
          }
        })
      },
      setEventId: (eventId: string) => {
        set((state) => {
          state.eventId = eventId
        })
      },
      setFolderId: (folderId: Maybe<number>) => {
        set((state) => {
          state.folderId = folderId
        })
      },
      setQuery: (query: string) => {
        set((state) => {
          state.query = query
        })
      },
      deleteMessage: (message: AssistantMessage) => {
        const eventId = get().eventId
        if (!message.prevMessageId) {
          displayErrorMessage('Cannot delete the first message')
          return
        }
        if (!eventId) {
          displayErrorMessage('No event ID found')
          return
        }

        get().setCurrentMessageId(message.prevMessageId)
        return api.deleteMessage(eventId, message).then(() => {
          displaySuccessMessage('Message deleted')
        })
      },
      setPendingMessage: (message: Partial<AssistantMessage> | null) => {
        set((state) => {
          state.pendingMessage = message as any
        })
      },
      preserveState: (query: string, currentMessageId: string | null) => {
        set((state) => {
          state.preservedState = {
            query,
            currentMessageId,
          }
        })
      },
      restoreState: () => {
        set((state) => {
          if (!state.preservedState) return
          state.query = state.preservedState.query
          state.currentMessageId = state.preservedState.currentMessageId
          state.preservedState = null
        })
      },
      setShowRecentQueries: (showRecentQueries: boolean) =>
        set({ showRecentQueries }),
      setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => {
        set((state) => {
          state.knowledgeSource = knowledgeSource
        })
      },
      setMode: (mode: AssistantMode) => {
        set((state) => {
          state.mode = mode
        })
      },
      setIsForking: (isForking: boolean) => {
        set((state) => {
          state.isForking = isForking
        })
      },
      setZipFiles: (zipFiles: File[]) => {
        set((state) => {
          state.zipFiles = zipFiles
        })
      },
      setPstFiles: (pstFiles: File[]) => {
        set((state) => {
          state.pstFiles = pstFiles
        })
      },
      setIsAskHarveyDisabled: (isAskHarveyDisabled: boolean) => {
        set((state) => {
          state.isAskHarveyDisabled = isAskHarveyDisabled
        })
      },
      removeFilterIds: (filterIds: string[]) => {
        set((state) => {
          if (!state.knowledgeSource || !('filterIds' in state.knowledgeSource))
            return
          const newIds = state.knowledgeSource.filterIds.filter(
            (id) => !filterIds.includes(id)
          )
          if (newIds.length) {
            state.knowledgeSource.filterIds = newIds
          } else {
            state.knowledgeSource = null
          }
        })
      },
      removeFileIds: (fileIds: string[]) => {
        set((state) => {
          if (
            !state.knowledgeSource ||
            !('fileIds' in state.knowledgeSource) ||
            !state.knowledgeSource.fileIds
          )
            return
          const newIds = state.knowledgeSource.fileIds.filter(
            (id) => !fileIds.includes(id)
          )
          if (newIds.length) {
            state.knowledgeSource.fileIds = newIds
          } else {
            state.knowledgeSource = null
          }
        })
      },
      setResearchDialogArea: (researchDialogArea: DatabaseSource) => {
        set((state) => {
          state.researchDialogArea = researchDialogArea
        })
      },
      setShowResearchDialog: (showResearchDialog: boolean) => {
        set((state) => {
          state.showResearchDialog = showResearchDialog
        })
      },
      setShowVaultDialog: (showVaultDialog: boolean) => {
        set((state) => {
          state.showVaultDialog = showVaultDialog
        })
      },
    }))
  )
)
