import React from 'react'

import { DownloadIcon } from 'lucide-react'

import { isFileViewableInAssistant } from 'types/file'

import { download } from 'utils/utils'

import { FileToShow } from 'components/assistant/workflows/blocks/assistant-workflow-file-upload-input'
import DocumentCard from 'components/assistant/workflows/components/document-card/document-card'
import MultipleDocumentsCard from 'components/assistant/workflows/components/multiple-documents-card/multiple-documents-card'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

interface AssistantWorkflowFilesComponentProps {
  files: FileToShow[]
  onFileClick: (file: FileToShow) => void
  fileId: string | null
  showDownloadButton?: boolean
}

const renderFiles = ({
  files,
  onFileClick,
  fileId,
  showDownloadButton,
}: AssistantWorkflowFilesComponentProps) => {
  if (!files.length) return null
  if (files.length === 1) {
    return (
      <div className="mt-1 flex flex-row gap-2">
        <DocumentCard
          key={files[0].id}
          onClick={
            isFileViewableInAssistant(files[0]?.contentType ?? undefined)
              ? () => onFileClick(files[0])
              : undefined
          }
          fileType={files[0].fileType}
          title={
            files[0].name.substring(0, 30) +
            (files[0].name.length > 30 ? '..' : '')
          }
          description={files[0].description}
          selected={fileId === files[0].id}
        />
        {showDownloadButton && (
          <Button
            variant="secondary"
            size="smIcon"
            onClick={() => download(files[0].url!)}
            className="my-auto"
          >
            <Icon icon={DownloadIcon} />
          </Button>
        )}
      </div>
    )
  } else {
    // only pass on click if file is viewable in assistant
    const onClickDict = files.reduce(
      (acc, file) => {
        if (isFileViewableInAssistant(file.contentType ?? undefined)) {
          acc[file.id] = onFileClick
        }
        return acc
      },
      {} as Record<string, (file: FileToShow) => void>
    )
    return <MultipleDocumentsCard files={files} onClickDict={onClickDict} />
  }
}

export const AssistantWorkflowFilesComponent: React.FC<
  AssistantWorkflowFilesComponentProps
> = ({ files, onFileClick, fileId, showDownloadButton }) => {
  if (!files.length) return null

  return (
    <div className="w-fit">
      {renderFiles({ files, onFileClick, fileId, showDownloadButton })}
    </div>
  )
}
