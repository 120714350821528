import React, { useState, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { Plus, SquareAsterisk, LucideIcon, ArrowRight } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { ReviewWorkflowVisibilityKind } from 'openapi/models/ReviewWorkflowVisibilityKind'
import { Tag } from 'openapi/models/Tag'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Card } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import { SkeletonBlock } from 'components/ui/skeleton'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'
import VaultDeleteWorkflow from 'components/vault/workflows/vault-delete-workflow'
import VaultNewWorkflow from 'components/vault/workflows/vault-new-workflow'
import VaultPublishWorkflow from 'components/vault/workflows/vault-publish-workflow'
import VaultUnpublishWorkflow from 'components/vault/workflows/vault-unpublish-workflow'
import { useVaultWorkflowSetup } from 'components/workflows/hooks/use-vault-workflow-setup'
import WorkflowBrowserDialog from 'components/workflows/workflow-browser-dialog'
import WorkflowCard, {
  VaultWorkflowInfo,
  WorkflowCategory,
  WorkflowInfo,
} from 'components/workflows/workflow-card'
import { WorkflowSource } from 'components/workflows/workflows-helpers'

import { useVaultWorkflows } from './use-vault-workflows'
import VaultCreateBlank from './vault-create-blank'
import VaultEditWorkflow from './vault-edit-workflow'
import VaultWorkflowBuilder from './vault-workflow-builder'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from './vault-workflow-store'

interface WorkflowCardListProps {
  isFetching: boolean
  workflows: ReviewWorkflow[]
}

type WorkflowWithTagMatchInfo = ReviewWorkflow & {
  icon: LucideIcon
  matchCount?: number
  matchingTag?: Tag
}

const WorkflowCardList = ({ isFetching, workflows }: WorkflowCardListProps) => {
  const [currentProject, projectIdToFileIds, fileIdsToVaultFile] =
    useVaultStore(
      useShallow((state) => [
        state.currentProject,
        state.projectIdToFileIds,
        state.fileIdToVaultFile,
      ])
    )

  const sortedWorkflows = useMemo(() => {
    // If there are no workflows, return an empty array
    if (!currentProject || workflows.length === 0) return []
    const currentProjectId = currentProject.id
    const currentProjectFileIds = projectIdToFileIds[currentProjectId] ?? []

    // If there are no files in the project, return all workflows in order
    if (currentProjectFileIds.length === 0) {
      return workflows
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((workflow) => ({
          ...workflow,
          icon: SquareAsterisk,
        }))
    }
    const fileDocClassificationTags = currentProjectFileIds.map((fileId) => {
      const vaultFile = fileIdsToVaultFile[fileId]
      return vaultFile?.tags ?? []
    })

    // Create a map of workflow names to their total tag count in the project
    const workflowTagCounts = new Map<string, number>()
    // Create a map of workflow names to a matching tag name if there is one
    const workflowMatchingTags = new Map<string, Tag>()

    // Initialize counts for each workflow
    workflows.forEach((workflow) => {
      workflowTagCounts.set(workflow.id, 0)
    })

    // Count matching tags for each file
    fileDocClassificationTags.forEach((fileTags) => {
      if (fileTags.length === 0) return

      workflows.forEach((workflow) => {
        // Check if any of the workflow's tags match this file's tags
        const matchingTags =
          workflow.tags?.filter((workflowTag) =>
            fileTags.some((fileTag) => {
              // tagName is the old field, name is the new field
              // TODO: Remove this once all cached workflows have been updated
              // @ts-expect-error temporary backwards compatibility
              const workflowTagName = workflowTag.name ?? workflowTag.tagName
              return (
                fileTag.name.toLowerCase() === workflowTagName.toLowerCase()
              )
            })
          ) ?? []

        if (matchingTags.length > 0) {
          // Increment the count for this workflow
          workflowTagCounts.set(
            workflow.id,
            (workflowTagCounts.get(workflow.id) || 0) + 1
          )
          if (!workflowMatchingTags.has(workflow.id)) {
            // Set the matching tag name for this workflow
            workflowMatchingTags.set(workflow.id, matchingTags[0])
          }
        }
      })
    })

    // Sort workflows by tag count DESC, then alphabetically
    return workflows
      .sort((a, b) => {
        const aCount = workflowTagCounts.get(a.id) || 0
        const bCount = workflowTagCounts.get(b.id) || 0

        if (bCount !== aCount) {
          return bCount - aCount // Sort by count DESC
        }
        return a.name.localeCompare(b.name) // Secondary sort alphabeticallyretu
      })
      .map(
        (workflow) =>
          ({
            ...workflow,
            icon: SquareAsterisk,
            matchCount: workflowTagCounts.get(workflow.id) || 0,
            matchingTag: workflowMatchingTags.get(workflow.id),
          }) as WorkflowWithTagMatchInfo
      )
  }, [currentProject, projectIdToFileIds, fileIdsToVaultFile, workflows])

  if (isFetching) {
    return [0, 1].map((i) => <WorkflowCardSkeleton key={i} index={i} />)
  }

  if (workflows.length > 0) {
    return (
      <>
        {(sortedWorkflows as WorkflowWithTagMatchInfo[]).map(
          (workflow, index) => {
            return (
              <VaultWorkflowCard
                key={workflow.id}
                index={index}
                workflow={workflow}
                tagMatchCount={workflow.matchCount ?? 0}
                matchingTag={workflow.matchingTag}
              />
            )
          }
        )}
      </>
    )
  }
}

const VaultWorkflowCard = ({
  workflow,
  index,
  tagMatchCount,
  matchingTag,
}: {
  workflow: ReviewWorkflow
  index: number
  tagMatchCount: number
  matchingTag?: Tag
}) => {
  const { trackEvent } = useAnalytics()

  const [setWorkflowModalState, setSelectedWorkflow] = useVaultWorkflowStore(
    useShallow((state) => [
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )
  const workflowInfo: VaultWorkflowInfo = {
    id: workflow.id,
    name: workflow.name,
    description: workflow.description,
    category: WorkflowCategory.VAULT,
    numColumns: workflow.columns.length,
    documentTag: matchingTag,
    cobrand: workflow.cobrand,
  }

  const onClick = () => {
    trackEvent('Workflow Clicked', {
      workflow_id: workflow.id,
      workflow_name: workflow.name,
      workflow_type: workflowInfo.category,
    })
    setSelectedWorkflow(workflow)
    setWorkflowModalState(WorkflowModalState.Builder)
  }

  return (
    <WorkflowCard
      workflowInfo={workflowInfo}
      source={WorkflowSource.VAULT_PROJECT_PAGE}
      onClick={onClick}
      className={cn({
        // We want to show the All Workflows card in the same row as the workflow cards,
        // so we hide one additional workflow card than we would otherwise
        'hidden lg:flex': index === 0 && tagMatchCount > 0,
        'hidden xl:flex': index === 1 && tagMatchCount > 0,
        hidden: tagMatchCount === 0 || index > 1,
      })}
    />
  )
}

const WorkflowCardSkeleton = ({ index }: { index: number }) => {
  return (
    <Card
      className={cn(
        'h-32 w-full items-center justify-center rounded border-none bg-accent p-4 shadow-sm',
        {
          'hidden sm:flex': index === 0,
          'hidden md:flex': index === 1,
          'hidden lg:flex': index === 2,
        }
      )}
    >
      <div className="flex h-full w-full flex-col items-start justify-between gap-2">
        <div className="flex w-full flex-col justify-start gap-2">
          <SkeletonBlock className="h-4 w-1/2" />
          <SkeletonBlock className="h-4 w-full" />
        </div>
        <SkeletonBlock className="h-4 w-full" />
      </div>
    </Card>
  )
}

const NewWorkflowCard = () => {
  const { trackEvent } = useAnalytics()
  const [setWorkflowModalState, setSelectedWorkflow] = useVaultWorkflowStore(
    useShallow((state) => [
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )

  return (
    <Card
      onClick={() => {
        trackEvent('Vault Create Blank Clicked')
        setSelectedWorkflow(null)
        setWorkflowModalState(WorkflowModalState.CreateBlank)
      }}
      className="card-separator relative mr-4 flex h-32 w-auto flex-1 shrink-0 flex-col items-start justify-between rounded-lg border p-4 transition hover:cursor-pointer hover:border-input-focused"
      id="vault-new-workflow-card"
    >
      <div className="flex h-full flex-col items-start justify-between gap-y-4">
        <Icon icon={Plus} className="size-6 stroke-inactive" />
        <div className="flex flex-col items-start justify-start">
          <p className="text-sm font-medium">Start a query from scratch</p>
          <p className="text-xs text-muted">
            Choose either a review table or thread
          </p>
        </div>
      </div>
    </Card>
  )
}

const AllWorkflowsCard = () => {
  const { trackEvent } = useAnalytics()

  const setWorkflowModalState = useVaultWorkflowStore(
    (state) => state.setWorkflowModalState
  )
  // TODO(stella): redesign this once card design is finalized
  return (
    <Card
      className="flex h-32 w-auto shrink-0 flex-col justify-start rounded-lg border-none bg-secondary p-4 transition hover:cursor-pointer hover:bg-secondary-hover"
      onClick={() => {
        trackEvent('Vault Workflow Show More Clicked')
        setWorkflowModalState(WorkflowModalState.Selector)
      }}
    >
      <div className="flex items-center justify-start gap-1">
        <p className="text-sm font-medium">View all workflows</p>
        <Icon icon={ArrowRight} />
      </div>
    </Card>
  )
}

const VaultWorkflowList = () => {
  const { isFetching } = useVaultWorkflows()
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const [
    workflows,
    workflowModalState,
    setWorkflowModalState,
    setSelectedWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.workflows,
      state.workflowModalState,
      state.setWorkflowModalState,
      state.setSelectedWorkflow,
    ])
  )

  const { vaultWorkflowInfo } = useVaultWorkflowSetup()

  const filteredWorkflows = useMemo(() => {
    return (Object.values(workflows).filter(Boolean) as ReviewWorkflow[])
      .filter(
        (workflow) => workflow.visibility !== ReviewWorkflowVisibilityKind.DRAFT
      )
      .filter(
        (workflow) =>
          !(
            workflow.visibility === ReviewWorkflowVisibilityKind.WORKSPACE &&
            // Only check additionalVisibilitiesInfo if it exists
            workflow.additionalVisibilitiesInfo &&
            !workflow.additionalVisibilitiesInfo.some(
              (visibility) =>
                visibility.visibility ===
                  ReviewWorkflowVisibilityKind.WORKSPACE &&
                visibility.workspaceId === userInfo.workspace.id
            )
          )
      )
  }, [workflows, userInfo.workspace.id])

  const [isHotKeyPressed, setIsHotKeyPressed] = useState(false)
  useHotkeys(
    'Control+h',
    () => {
      setIsHotKeyPressed(!isHotKeyPressed)
    },
    [isHotKeyPressed]
  )

  const handleVaultWorkflowClick = (workflow: WorkflowInfo) => {
    trackEvent('Workflow Clicked', {
      workflow_id: workflow.id,
      workflow_name: workflow.name,
      workflow_type:
        workflow.category === WorkflowCategory.LEGACY
          ? 'other'
          : workflow.category,
    })
    setWorkflowModalState(WorkflowModalState.Builder)
    setSelectedWorkflow(workflows[workflow.id] ?? null)
  }

  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm font-semibold">Create new query</p>
      <div
        className="grid gap-3 gap-x-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        id="vault-workflow-list"
      >
        <NewWorkflowCard />
        <WorkflowCardList
          isFetching={isFetching && filteredWorkflows.length === 0}
          workflows={filteredWorkflows}
        />
        <AllWorkflowsCard />
        <WorkflowBrowserDialog
          source={WorkflowSource.VAULT_PROJECT_PAGE}
          workflowInfo={vaultWorkflowInfo}
          dialogTitle="Vault workflows"
          onWorkflowClick={handleVaultWorkflowClick}
          open={workflowModalState === WorkflowModalState.Selector}
          onOpenChange={(open) => {
            if (!open) {
              setWorkflowModalState(WorkflowModalState.None)
            }
          }}
        />
        <VaultNewWorkflow />
        <VaultPublishWorkflow />
        <VaultEditWorkflow />
        <VaultUnpublishWorkflow />
        <VaultDeleteWorkflow />
        <VaultWorkflowBuilder source={WorkflowSource.VAULT_PROJECT_PAGE} />
        <VaultCreateBlank />
      </div>
    </div>
  )
}

export default VaultWorkflowList
