import * as React from 'react'
import { useWindowSize } from 'react-use'

import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDown } from 'lucide-react'

import { cn } from 'utils/utils'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from 'components/ui/accordion'
import { Button } from 'components/ui/button'
import { ScrollArea } from 'components/ui/scroll-area'

const SCROLL_AREA_PADDING = 32
const SCROLL_AREA_HEIGHT = 600
const SCROLL_AREA_MIN_HEIGHT = 300

const ACCORDION_KEY = 'edit-answers'

interface ProfileDropdownProps {
  children: React.ReactNode
  defaultOpen?: boolean
  isResetable?: boolean
  isSavable?: boolean
  isSaving?: boolean
  onSave: () => void
  onReset: () => void
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  children,
  defaultOpen = false,
  onSave,
  onReset,
  isResetable = false,
  isSavable = false,
  isSaving = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen)
  const [isScrolled, setIsScrolled] = React.useState(false)
  const contentRef = React.useRef<HTMLDivElement>(null)
  const buttonsRef = React.useRef<HTMLDivElement>(null)
  const { height: windowHeight } = useWindowSize()

  /**
   * Adjusts the height of the scroll area based on the window height and the
   * position of the content. We want to ensure that the save/reset controls
   * underneath the scroll area are always visible.
   */
  const adjustHeight = React.useCallback(() => {
    if (!contentRef.current || !buttonsRef.current) return

    const rect = contentRef.current.getBoundingClientRect()
    const buttonHeight = buttonsRef.current.offsetHeight

    if (
      rect.top + SCROLL_AREA_HEIGHT >
      windowHeight - SCROLL_AREA_PADDING - buttonHeight
    ) {
      let height = windowHeight - rect.top - buttonHeight - SCROLL_AREA_PADDING
      if (height < SCROLL_AREA_MIN_HEIGHT) {
        height = SCROLL_AREA_MIN_HEIGHT
      }
      contentRef.current.style.height = `${height}px`
    } else {
      contentRef.current.style.height = `${SCROLL_AREA_HEIGHT}px`
    }
  }, [windowHeight])

  React.useEffect(() => {
    if (!isOpen) return
    adjustHeight()
  }, [isOpen, adjustHeight])

  const handleResetClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onReset()
  }

  const handleSaveClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onSave()
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget
    setIsScrolled(target.scrollTop > 0)
  }

  return (
    <Accordion
      type="single"
      collapsible
      value={isOpen ? ACCORDION_KEY : ''}
      onValueChange={(value) => {
        setIsOpen(value === ACCORDION_KEY)
        setIsScrolled(false)
      }}
    >
      <AccordionItem value={ACCORDION_KEY} className="border-none">
        <AccordionPrimitive.Header className="flex">
          <AccordionPrimitive.Trigger
            className={cn(
              'flex flex-1 items-center justify-start space-x-2 rounded-md border border-primary px-4 py-2 font-sans text-sm transition-[border-color] duration-200 [&[data-state=open]>svg]:rotate-180 [&[data-state=open]]:rounded-b-none',
              {
                'border-b-transparent': isOpen && !isScrolled,
              }
            )}
          >
            <p className="font-sans text-sm leading-4">Edit answers</p>
            <div className="flex flex-1 items-center justify-end space-x-2 py-2 transition-opacity duration-200">
              <span className="text-xs text-muted">
                {isSavable ? 'Unsaved changes' : 'Responses saved'}
              </span>
            </div>
            <ChevronDown className="size-4 shrink-0 transition-transform duration-200" />
          </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
        <AccordionContent className="-mt-px rounded-b-md border">
          <ScrollArea ref={contentRef} onScroll={handleScroll}>
            <div className="p-4">{children}</div>
          </ScrollArea>
          <div
            ref={buttonsRef}
            className="flex justify-end gap-2 border-t p-4 pb-0"
          >
            <Button
              variant="ghost"
              onClick={handleResetClick}
              disabled={!isResetable}
            >
              Reset
            </Button>
            <Button
              isLoading={isSaving}
              onClick={handleSaveClick}
              disabled={!isSavable}
            >
              Save changes
            </Button>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

export default ProfileDropdown
