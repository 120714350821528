import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { AnnotationById } from 'utils/task'
import { cn } from 'utils/utils'

import AssistantChangeLog from 'components/assistant/components/assistant-change-log'
import { AssistantErrorPage } from 'components/assistant/components/assistant-error-page'
import AssistantHighlightTip from 'components/assistant/components/assistant-highlight-tip'
import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import AssistantSources from 'components/assistant/components/assistant-sources'
import {
  AssistantThreadSidebar,
  AssistantThreadSidebarSection,
  AssistantThreadSidebarSubSection,
} from 'components/assistant/components/assistant-thread-layout'
import AssistantToolbar from 'components/assistant/components/assistant-toolbar'
import AssistantWorkflowReplyInput from 'components/assistant/components/assistant-workflow-draft-reply-input'
import AssistantWorkflowDraftThread, {
  ThreadMessage,
} from 'components/assistant/components/assistant-workflow-draft-thread'
import { DraftingMarkdownWithInlineEdits } from 'components/assistant/drafting-markdown-text-selection'
import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useFetchWorkflowDraft } from 'components/assistant/hooks/use-assistant-fetch-workflow-draft'
import { AssistantWorkflowDraftStreamHandler } from 'components/assistant/hooks/use-assistant-workflow-draft-stream'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  AssistantDraftInlineEditQuery,
  AssistantDraftMessage,
} from 'components/assistant/types'
import { deleteWorkflowMessage } from 'components/assistant/utils/assistant-api'
import {
  getIsFinalizingStream,
  getMessageQuery,
  REVISION_ID_PARAM,
} from 'components/assistant/utils/assistant-helpers'
import { isVaultKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'
import { getMessageDiff } from 'components/assistant/utils/assistant-message-diff'
import { ScrollArea } from 'components/ui/scroll-area'
import { Switch } from 'components/ui/switch'
import { getVaultFileIds } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import {
  AssistantDraftRevisionInput,
  DraftRevisionFocus,
} from './assistant-draft-page'

type AssistantWorkflowDraftPageProps = {
  useDraft: AssistantWorkflowDraftStreamHandler
}

export const AssistantWorkflowDraftPage = ({
  useDraft,
}: AssistantWorkflowDraftPageProps) => {
  const { draftId } = useParams()
  const { error } = useFetchWorkflowDraft(draftId)
  const navigate = useNavigateWithQueryParams()
  const queryClient = useQueryClient()

  const [
    streamingMessage,
    messages,
    documents,
    getCurrentThreadMessages,
    currentMessageId,
    restoreState,
    userCaption,
    knowledgeSource,
    setCurrentMessageId,
  ] = useAssistantStore(
    useShallow((s) => [
      s.streamingMessage,
      s.messages,
      s.documents,
      s.getCurrentThreadMessages,
      s.currentMessageId,
      s.restoreState,
      s.userCaption,
      s.knowledgeSource,
      s.setCurrentMessageId,
    ])
  )

  const [folderIdToVaultFileIds, fileIdToVaultFile, parentIdToVaultFolderIds] =
    useVaultStore(
      useShallow((s) => [
        s.folderIdToVaultFileIds,
        s.fileIdToVaultFile,
        s.parentIdToVaultFolderIds,
      ])
    )

  const sourceDocuments = useMemo(() => {
    if (isVaultKnowledgeSource(knowledgeSource) && knowledgeSource.folderId) {
      const vaultSourceFileIds = new Set(knowledgeSource.fileIds)
      const vaultFolderFileIds = getVaultFileIds({
        folderId: knowledgeSource.folderId,
        parentIdToVaultFolderIds,
        folderIdToVaultFileIds,
      })
      return vaultFolderFileIds
        .map((fileId) => fileIdToVaultFile[fileId] as UploadedFile)
        .filter((file) => vaultSourceFileIds.has(file.id))
    }
    return documents
  }, [
    documents,
    folderIdToVaultFileIds,
    fileIdToVaultFile,
    parentIdToVaultFolderIds,
    knowledgeSource,
  ])

  const trackEvent = useAssistantAnalytics()
  const [useDiffView, setUseDiffView] = useState(true)

  const handleShowEditsToggle = (useDiffView: boolean) => {
    setUseDiffView(useDiffView)
    trackEvent('Show Edits Toggled', {
      query_id: draftId,
      message_id: currentMessageId,
      thread_number: currentThreadMessages.length + 1,
      message_number: messages.length + 1,
      diff_view: useDiffView ? 'on' : 'off',
      knowledge_source_type: knowledgeSource?.type,
    })
  }

  const currentThreadMessages = getCurrentThreadMessages()
  const allMessages = useMemo(
    () =>
      streamingMessage
        ? [...currentThreadMessages, streamingMessage]
        : currentThreadMessages,
    [currentThreadMessages, streamingMessage]
  ) as AssistantDraftMessage[]

  const [searchParams, setSearchParams] = useSearchParams()
  const revisionId = searchParams.get(REVISION_ID_PARAM) ?? ''
  const setRevisionId = useCallback(
    (messageId: string) => {
      setSearchParams(
        (prevParams) => {
          const newParams = new URLSearchParams(prevParams)
          if (messageId) {
            newParams.set(REVISION_ID_PARAM, messageId)
          } else {
            newParams.delete(REVISION_ID_PARAM)
          }
          return newParams
        },
        { replace: true }
      )
    },
    [setSearchParams]
  )

  const handleRevisionClick = (messageId: string) => {
    const threadNumber =
      allMessages.findIndex((message) => message.messageId === messageId) + 1
    trackEvent('Revision Selected', {
      query_id: draftId,
      message_id: currentMessageId,
      thread_number: threadNumber,
      message_number: messages.length + 1,
      knowledge_source_type: knowledgeSource?.type,
    })
    setRevisionId(messageId)
  }

  useEffect(() => {
    if (!revisionId) {
      for (let i = allMessages.length - 1; i >= 0; i--) {
        if (allMessages[i]?.response?.length) {
          setRevisionId(allMessages[i]?.messageId || '')
          break
        }
      }
    }
  }, [allMessages, revisionId, setRevisionId])

  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [revisionFocus, setRevisionFocus] = useState<DraftRevisionFocus>({
    focused: false,
    visible: false,
  })
  const handleAddRevisionFocus = () => {
    setRevisionId(
      currentThreadMessages[currentThreadMessages.length - 1]?.messageId || ''
    )
    setRevisionFocus({ focused: true, visible: true })
    trackEvent('Add Revision Initiated', {
      query_id: draftId,
      message_id: currentMessageId,
      thread_number: currentThreadMessages.length + 1,
      message_number: messages.length + 1,
      knowledge_source_type: knowledgeSource?.type,
    })
  }
  const handleAddRevisionBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!e.relatedTarget?.closest('#assistant-input')) {
      setRevisionFocus({ focused: true, visible: false })
      trackEvent('Add Revision Cancelled', {
        query_id: draftId,
        message_id: currentMessageId,
        thread_number: currentThreadMessages.length + 1,
        message_number: messages.length + 1,
        knowledge_source_type: knowledgeSource?.type,
      })
    }
  }
  const handleAddRevisionHide = useCallback(() => {
    setRevisionFocus({ focused: false, visible: false })
  }, [])

  useEffect(() => {
    if (revisionFocus.focused) {
      setTimeout(() => inputRef.current?.focus(), 0)
    }
  }, [revisionFocus])

  const lastStreamedMessageId = useRef<string>('')
  useEffect(() => {
    if (
      streamingMessage &&
      streamingMessage.messageId &&
      streamingMessage.messageId !== lastStreamedMessageId.current
    ) {
      setRevisionId(lastStreamedMessageId.current)
      lastStreamedMessageId.current = streamingMessage.messageId
    } else if (streamingMessage) {
      lastStreamedMessageId.current = streamingMessage.messageId
    }
  }, [setRevisionId, streamingMessage])

  const selectedMessage = allMessages.length
    ? allMessages.find((m) => m.messageId === revisionId) ??
      allMessages[allMessages.length - 1]
    : undefined

  const markdownContent = useMemo(() => {
    return getMessageDiff(allMessages, selectedMessage, useDiffView)
  }, [allMessages, selectedMessage, useDiffView])

  const allAnnotations = useMemo(() => {
    return allMessages.reduce((acc, message) => {
      Object.values(message.annotations).forEach((annotation) => {
        acc[annotation.id] = annotation
      })
      return acc
    }, {} as AnnotationById)
  }, [allMessages])

  const firstMessage = allMessages[0]
  const threadTitle =
    userCaption ||
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    firstMessage?.caption ||
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (firstMessage?.isLoading ? getMessageQuery(firstMessage) : '')
  if (error || !draftId) {
    return <AssistantErrorPage error={error as Error} />
  }
  const handleInlineEdit = (params: AssistantDraftInlineEditQuery) => {
    useDraft.createDraftInlineEditRequest({
      query: params.query,
      selectedText: params.selectedText,
      prevMessageId: revisionId,
      draftId,
    })
  }

  const handleCancel = () => {
    useDraft.sendCancelRequest()
    if (!messages.length) {
      restoreState()
      navigate('/assistant', {
        state: { skipReset: true, mode: AssistantMode.DRAFT },
      })
    }
  }

  const handleRegenerateMessage = (message: AssistantDraftMessage) => {
    setRevisionId('')
    useDraft.regenerateMessage(message, draftId)
  }

  const handleDeleteMessage = async (message: AssistantDraftMessage) => {
    await deleteWorkflowMessage(draftId, message.prevMessageId || '')
    // Invalidate the query so the new revision is fetched.
    await queryClient.invalidateQueries({
      queryKey: ['workflow-draft', draftId],
    })
    setRevisionId(message.prevMessageId || '')
    setCurrentMessageId(message.prevMessageId || '')
  }

  const isFinalizingStream = getIsFinalizingStream(streamingMessage)

  const isLastMessage =
    revisionId === streamingMessage?.prevMessageId ||
    revisionId === streamingMessage?.messageId

  const shouldPulse =
    !!streamingMessage && isLastMessage && allMessages.length > 1

  const sidebarElement = (
    <AssistantThreadSidebar className="pb-8">
      <AssistantThreadSidebarSection title="Revisions">
        <div id="assistant-draft-revise" className="space-y-2 pb-1.5 last:pb-0">
          <div className="space-y-1">
            <AssistantChangeLog
              messages={allMessages}
              handleCancel={handleCancel}
              handleClick={handleRevisionClick}
              selectedMessageId={revisionId}
              handleRegenerateMessage={handleRegenerateMessage}
              handleDeleteMessage={handleDeleteMessage}
              showCaptionForInitialRevision
            />
          </div>

          <AssistantDraftRevisionInput
            handleHideInput={handleAddRevisionHide}
            handleShowInput={handleAddRevisionFocus}
            revisionFocus={revisionFocus}
          >
            <AssistantWorkflowReplyInput
              inputRef={inputRef}
              isStreaming={!!streamingMessage}
              onBlur={handleAddRevisionBlur}
              prevMessageId={currentMessageId || undefined}
              createDraftEditRequest={useDraft.createDraftEditRequest}
              isFinalizingStream={isFinalizingStream}
              isRevision
              onAsk={handleAddRevisionHide}
            />
          </AssistantDraftRevisionInput>
        </div>
        <AssistantThreadSidebarSubSection>
          <AssistantHighlightTip />
        </AssistantThreadSidebarSubSection>
        {allMessages.length > 1 && selectedMessage?.prevMessageId && (
          <AssistantThreadSidebarSubSection>
            <label
              className="flex w-full items-center justify-between"
              htmlFor="use-diff-view"
            >
              <p className="text-xs">Show edits</p>
              <Switch
                checked={useDiffView}
                onCheckedChange={() => handleShowEditsToggle(!useDiffView)}
                id="use-diff-view"
              />
            </label>
          </AssistantThreadSidebarSubSection>
        )}
      </AssistantThreadSidebarSection>
      {selectedMessage &&
        (!selectedMessage.isLoading || isFinalizingStream) &&
        selectedMessage.sources.length > 0 && (
          <AssistantSources
            allDocuments={sourceDocuments}
            isStreaming={selectedMessage.isLoading}
            message={selectedMessage}
            sources={selectedMessage.sources}
          />
        )}
    </AssistantThreadSidebar>
  )

  const viewingLatestRevision =
    selectedMessage?.messageId ===
    allMessages[allMessages.length - 1]?.messageId

  return (
    <AssistantWorkflowDraftThread
      messages={currentThreadMessages}
      sources={selectedMessage?.sources || []}
      title={threadTitle}
      useDiffView={useDiffView}
    >
      <ScrollArea className="h-full grow">
        <ThreadMessage
          message={selectedMessage}
          onCancel={handleCancel}
          sidebar={sidebarElement}
          allAnnotations={allAnnotations}
        >
          {(getHrvyInfoMetadata) => (
            <div
              className={cn({
                'animate-pulse': shouldPulse,
              })}
            >
              <DraftingMarkdownWithInlineEdits
                content={markdownContent}
                getHrvyInfoMetadata={getHrvyInfoMetadata}
                inlineEdit={{
                  onInlineEdit: handleInlineEdit,
                  disabled: !viewingLatestRevision,
                  disabledReason: !viewingLatestRevision
                    ? 'Revisions can only be made on the latest draft'
                    : undefined,
                  originalMarkdown: useDiffView
                    ? selectedMessage?.response
                    : undefined,
                }}
                currentMessageId={currentMessageId || ''}
                threadNumber={currentThreadMessages.length + 1}
                messageNumber={messages.length + 1}
              />
              {selectedMessage && !selectedMessage.isLoading && (
                <div className="mt-6 w-full">
                  <AssistantToolbar
                    documents={sourceDocuments}
                    message={selectedMessage}
                    threadTitle={
                      userCaption || currentThreadMessages[0]?.caption
                    }
                    threadNumber={currentThreadMessages.length + 1}
                    messageNumber={messages.length + 1}
                    showFeedbackIfOwner={false}
                  />
                </div>
              )}
            </div>
          )}
        </ThreadMessage>
      </ScrollArea>
    </AssistantWorkflowDraftThread>
  )
}
