import { useState, useCallback, useEffect, useRef } from 'react'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import { useIntegrationsStore } from 'stores/integrations-store'

import { displaySuccessMessage } from 'utils/toast'

import { connectOauthIntegration, fetchIntegrationToken } from './utils'

interface UseOauthConnectProps {
  integrationType: IntegrationType
  onConnectCallback?: () => void
}

const POLL_INTERVAL = 3000 // 3 seconds
const MAX_ATTEMPTS = 20 // 1 minute

export enum OauthConnectState {
  IDLE = 'idle',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  CONNECTION_TIMED_OUT = 'connection_timed_out',
  CONNECTION_FAILED = 'connection_failed',
  CONNECTION_CANCELLED = 'connection_cancelled',
}

export function useOauthConnect({
  integrationType,
  onConnectCallback,
}: UseOauthConnectProps) {
  const [connectionState, setConnectionState] = useState(OauthConnectState.IDLE)
  const abortPollingRef = useRef<boolean>(false)
  const isMountedRef = useRef(true)
  const [tokens, setAccessToken] = useIntegrationsStore(
    useShallow((state) => [state.tokens, state.setIntegrationToken])
  )

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  const pollForToken = useCallback(async () => {
    let attempts = 0

    return new Promise<void>((resolve, reject) => {
      const intervalId = setInterval(async () => {
        attempts += 1
        try {
          if (abortPollingRef.current) {
            setConnectionState(OauthConnectState.CONNECTION_CANCELLED)
            abortPollingRef.current = false
            clearInterval(intervalId)
            resolve()
            return
          }

          const token = await fetchIntegrationToken(integrationType)
          if (!_.isNil(token)) {
            setAccessToken(integrationType, token)
            setConnectionState(OauthConnectState.CONNECTED)
            clearInterval(intervalId)
            displaySuccessMessage(
              `Connected to ${_.startCase(integrationType)}`
            )
            onConnectCallback?.()
            resolve()
          } else if (attempts >= MAX_ATTEMPTS) {
            reject(new Error('Timed out waiting for token'))
            clearInterval(intervalId)
            setConnectionState(OauthConnectState.CONNECTION_TIMED_OUT)
          }
        } catch (err) {
          clearInterval(intervalId)
          setConnectionState(OauthConnectState.CONNECTION_FAILED)
          reject(new Error('Timed out waiting for token'))
          console.warn('Error fetching access token', err)
        }
      }, POLL_INTERVAL)
    })
  }, [integrationType, setAccessToken, onConnectCallback])

  const handleConnect = useCallback(async () => {
    setConnectionState(OauthConnectState.CONNECTING)
    try {
      await connectOauthIntegration(integrationType)
      await pollForToken()
    } catch (e) {
      console.error(e)
      if (isMountedRef.current) {
        setConnectionState(OauthConnectState.CONNECTION_FAILED)
      }
    }
  }, [integrationType, pollForToken])

  // stops polling for token
  const handleCancelConnect = () => {
    abortPollingRef.current = true
  }

  const isConnected = !!tokens[integrationType]

  return {
    connectionState,
    isConnected,
    handleConnect,
    handleCancelConnect,
  }
}
