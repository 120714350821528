import React from 'react'

import IManageIcon from 'components/ui/icons/imanage-icon'

const IManageLogo: React.FC = () => {
  return (
    <div className="flex items-center space-x-2 pl-px">
      <div className="flex items-center space-x-2">
        <IManageIcon className="size-4" />
        <p className="text-sm text-primary">iManage</p>
      </div>
    </div>
  )
}

export default IManageLogo
