import React, { useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'

import { cva } from 'class-variance-authority'
import { Plus, X } from 'lucide-react'

import { cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'components/ui/command'
import { Icon } from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'

export type Tag = {
  value: string
  badgeDisplayText: string
  component?: React.ReactNode // optional custom component to render in options list
}

export type TagGroup = {
  label: string
  tags: Tag[]
}

interface BaseTagInputProps {
  placeholder: string
  /** Title for ungrouped tags if both sortedTags and sortedGroups are provided */
  ungroupedHeader?: string
  selectedTagValues: string[]
  shouldShowSuggestedTags: boolean
  allowCreatingNewTags: boolean
  setSelectedTagValues: (tagValues: string[]) => void
  disabled?: boolean
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void
  size?: 'sm' | 'md'
  /** Optional message to display at the bottom of the dropdown */
  footerMessage?: string
}

interface WithSortedTags extends BaseTagInputProps {
  sortedTags: Tag[]
  sortedGroups?: TagGroup[]
}

interface WithSortedGroups extends BaseTagInputProps {
  sortedTags?: Tag[]
  sortedGroups: TagGroup[]
}

type TagInputProps = WithSortedTags | WithSortedGroups

const tagInputVariants = {
  badge: cva('flex items-center gap-1', {
    variants: {
      size: {
        sm: 'h-fit px-1',
        md: 'h-fit p-1 px-2',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  }),
  badgeText: cva('font-normal', {
    variants: {
      size: {
        sm: 'text-xs',
        md: 'text-sm',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  }),
  commandInput: cva('w-full px-1', {
    variants: {
      size: {
        sm: 'h-3 text-xs',
        md: 'h-3 py-4 text-sm',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  }),
  commandEmpty: cva('', {
    variants: {
      size: {
        sm: 'px-2 py-4',
        md: 'px-3 py-4',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  }),
  commandEmptyText: cva('text-muted', {
    variants: {
      size: {
        sm: 'text-xs',
        md: 'text-sm',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  }),
  itemText: cva('', {
    variants: {
      size: {
        sm: 'text-xs',
        md: 'text-sm',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  }),
}

const TagInput: React.FC<TagInputProps> = ({
  placeholder,
  sortedTags = [],
  sortedGroups = [],
  ungroupedHeader = 'Other Tags',
  footerMessage,
  selectedTagValues,
  shouldShowSuggestedTags = true,
  allowCreatingNewTags = false,
  disabled = false,
  setSelectedTagValues,
  onFocus,
  size = 'sm',
}: TagInputProps) => {
  const [isInputFocused, setIsInputFocused] = React.useState(false)
  const [searchInputValue, setSearchInputValue] = useState('')

  const ref = useRef<HTMLDivElement>(null)
  const commandInputRef = useRef<HTMLInputElement>(null)

  const badgeDisplayTextByValue = useMemo(() => {
    const allTags = [
      ...sortedTags,
      ...sortedGroups.flatMap((group) => group.tags),
    ]
    return allTags.reduce(
      (acc, tag) => {
        acc[tag.value] = tag.badgeDisplayText
        return acc
      },
      {} as Record<string, string>
    )
  }, [sortedTags, sortedGroups])

  const getBadgeDisplayText = (tagValue: string) => {
    if (shouldShowSuggestedTags && !allowCreatingNewTags) {
      return badgeDisplayTextByValue[tagValue]
    }
    return badgeDisplayTextByValue[tagValue] ?? tagValue
  }

  useClickAway(ref, () => {
    setIsInputFocused(false)
    setSearchInputValue('')
  })

  const handleSelect = (tagValue: string) => {
    setSelectedTagValues([...selectedTagValues, tagValue])
    // Clear search input
    setSearchInputValue('')

    // Refocus input
    setTimeout(() => {
      commandInputRef.current?.focus()
    }, 0)
  }

  const handleRemoveTag = (tagValue: string) => {
    setSelectedTagValues(
      selectedTagValues.filter((value) => value !== tagValue)
    )
  }

  const handleKeyDownOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Backspace' &&
      searchInputValue === '' &&
      selectedTagValues.length > 0
    ) {
      setSelectedTagValues(selectedTagValues.slice(0, -1))
    }
  }

  const allTags = useMemo(
    () => [...sortedTags, ...sortedGroups.flatMap((group) => group.tags)],
    [sortedTags, sortedGroups]
  )

  const allTagsSelectedAndNoCreate =
    allTags.every((tag) => selectedTagValues.includes(tag.value)) &&
    !allowCreatingNewTags

  const shouldShowCommandList =
    isInputFocused &&
    shouldShowSuggestedTags &&
    (searchInputValue.length > 0 ||
      allTagsSelectedAndNoCreate ||
      allTags.some((tag) => !selectedTagValues.includes(tag.value)))

  const shouldShowAddNewTag =
    allowCreatingNewTags &&
    searchInputValue.length > 0 &&
    !allTags.some(
      (tag) =>
        tag.badgeDisplayText.toLowerCase() === searchInputValue.toLowerCase()
    )

  const renderTagList = (tags: Tag[], titleMatchesSearch: boolean) => {
    return tags
      .filter((tag) => !selectedTagValues.includes(tag.value))
      .filter((tag) => {
        if (!searchInputValue) return true
        return (
          titleMatchesSearch ||
          tag.badgeDisplayText
            .toLowerCase()
            .includes(searchInputValue.toLowerCase())
        )
      })
      .map((tag) => (
        <CommandItem
          key={tag.value}
          onSelect={() => handleSelect(tag.value)}
          value={tag.badgeDisplayText}
          onMouseDown={(e) => {
            e.preventDefault() // Prevent blur, otherwise list will close without appending new tag
          }}
        >
          {/* Render custom component if it exists, otherwise show badgeDisplayText */}
          {tag.component ? (
            tag.component
          ) : (
            <span className={tagInputVariants.itemText({ size })}>
              {tag.badgeDisplayText}
            </span>
          )}
        </CommandItem>
      ))
  }

  const commandList = (
    <>
      {(searchInputValue.length > 0 || allTagsSelectedAndNoCreate) && (
        <CommandEmpty className={tagInputVariants.commandEmpty({ size })}>
          <p
            className={tagInputVariants.commandEmptyText({
              size,
            })}
          >
            No results found
          </p>
        </CommandEmpty>
      )}
      {sortedGroups.map((group, index) => {
        const titleMatchesSearch = group.label
          .toLowerCase()
          .includes(searchInputValue.toLowerCase())
        const groupItems = renderTagList(group.tags, titleMatchesSearch)
        if (groupItems.length === 0) return null
        return (
          <React.Fragment key={group.label}>
            <CommandGroup heading={group.label}>{groupItems}</CommandGroup>
            {index < sortedGroups.length - 1 && <CommandSeparator />}
          </React.Fragment>
        )
      })}
      {sortedTags.length > 0 && sortedGroups.length > 0 && <CommandSeparator />}
      {sortedTags.length > 0 && (
        <CommandGroup
          heading={sortedGroups.length > 0 ? ungroupedHeader : undefined}
        >
          {renderTagList(sortedTags, false)}
        </CommandGroup>
      )}
    </>
  )

  return (
    <div
      ref={ref}
      onFocus={onFocus}
      data-testid="vault-sharing-tag-input"
      className="relative"
    >
      <Command
        className="rounded"
        filter={(value, search) => {
          if (value.toLowerCase().includes(search.toLowerCase())) return 1
          return 0
        }}
      >
        <div
          className={cn(
            'flex max-h-48 cursor-text flex-wrap items-center gap-1 overflow-y-auto rounded border p-1',
            {
              'cursor-not-allowed': disabled,
            }
          )}
          onClick={() => commandInputRef.current?.focus()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              commandInputRef.current?.focus()
            }
          }}
          role="button"
          tabIndex={0}
        >
          {(selectedTagValues || []).map((tagValue) => (
            <Badge
              key={`${tagValue}-badge`}
              variant="secondary"
              className={cn(tagInputVariants.badge({ size }), {
                'hover:bg-button-secondary': disabled,
              })}
            >
              <span
                className={cn(tagInputVariants.badgeText({ size }), {
                  'text-muted': disabled,
                })}
              >
                {getBadgeDisplayText(tagValue)}
              </span>
              {!disabled && (
                <Button
                  variant="ghost"
                  className="h-3 w-3 p-0 text-muted"
                  onClick={() => {
                    handleRemoveTag(tagValue)
                  }}
                >
                  <Icon icon={X} size="small" />
                </Button>
              )}
            </Badge>
          ))}
          <CommandInput
            ref={commandInputRef}
            showSearchStyling={false}
            placeholder={
              selectedTagValues.length === 0 ? placeholder : undefined
            }
            value={searchInputValue}
            onValueChange={setSearchInputValue}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            onKeyDown={handleKeyDownOnInput}
            className={cn(tagInputVariants.commandInput({ size }), {
              'w-0': !isInputFocused && selectedTagValues.length > 0,
              'cursor-not-allowed': disabled,
            })}
            disabled={disabled}
          />
        </div>
        <CommandList
          className={cn(
            'absolute top-full z-20 mt-1 w-full overflow-hidden rounded border bg-primary',
            {
              hidden: !shouldShowCommandList && !shouldShowAddNewTag,
            }
          )}
        >
          {/* Flex wrapper fixes ScrollArea not being scrollable in CommandList */}
          <div className="flex flex-col">
            <ScrollArea className="flex-1">
              <div className="max-h-60">
                {shouldShowCommandList && commandList}
                {shouldShowAddNewTag && (
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => handleSelect(searchInputValue)}
                    >
                      <div className="flex w-full items-center justify-between">
                        <span
                          className={cn('text-xs', {
                            'text-sm': size === 'md',
                          })}
                        >
                          &quot;{searchInputValue}&quot;
                        </span>
                        <Icon icon={Plus} size="small" />
                      </div>
                    </CommandItem>
                  </CommandGroup>
                )}
              </div>
            </ScrollArea>
            {footerMessage && (
              <div className="border-t bg-primary p-3 text-xs text-muted">
                {footerMessage}
              </div>
            )}
          </div>
        </CommandList>
      </Command>
    </div>
  )
}

export { TagInput }
