import * as Sentry from '@sentry/browser'
import { SPItem } from 'harvey-sdk-pnptimeline'
import JSZip from 'jszip'
import _ from 'lodash'
import PSPDFKit from 'pspdfkit'

import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'
import { Maybe } from 'types'
import { FileType, FileTypeToExtension } from 'types/file'

import { getPdfKitConfig } from './pspdfkit'
import { googleDriveIntegrationApiKey } from './server-data'
import { AssistantDocument, TaskType } from './task'

export const GB_MB = 1024
export const MB_BYTES = 1024 * 1024
const KB_BYTES = 1024

export const isValidZipDocName = (filepath: string): boolean => {
  const allowedExtensions = ['.pdf', '.docx', '.xlsx']
  const hiddenFilesPrefix = '._' // Zip will contain metadata clones of each file with '._' as a prefix
  const fileName = filepath.split('/').pop()
  if (fileName === undefined) {
    return false
  }
  if (fileName.startsWith(hiddenFilesPrefix)) {
    return false
  }
  for (const allowedExtension of allowedExtensions) {
    if (fileName.toLowerCase().endsWith(allowedExtension)) {
      return true
    }
  }
  return false
}

export const getDocumentLimit = (taskType: TaskType): number => {
  switch (taskType) {
    case TaskType.MULTI_DOC_QA:
      return 5
    case TaskType.CORPUS_QA:
      return 100
  }
  return 1
}

export const getUploadSizeLimitMb = (taskType: TaskType): number => {
  switch (taskType) {
    case TaskType.CORPUS_QA:
      return 50
  }
  return 20
}

export const getFilesFromZip = async (zipFile: File): Promise<File[]> => {
  const jszip = new JSZip()
  const data = await zipFile.arrayBuffer()
  const zip = await jszip.loadAsync(data)
  const filePromises: Array<Promise<File>> = []
  zip.forEach((relativePath: string, zipObject: JSZip.JSZipObject) => {
    if (!isValidZipDocName(relativePath)) {
      return
    }

    if (zipObject.dir) {
      return
    }

    const filePromise = zipObject.async('blob').then((content: BlobPart) => {
      const fileExtension = '.' + relativePath.toLowerCase().split('.').pop()
      const match = Object.entries(FileTypeToExtension).find(([, extensions]) =>
        extensions.includes(fileExtension)
      )
      const type = (match?.at(0) || '') as string
      return new File([content], relativePath, { type })
    })
    filePromises.push(filePromise)
  })
  const files = await Promise.all(filePromises)
  return files
}

export const uploadAssistantFiles = (
  files: File[],
  currentFiles: AssistantDocument[],
  redlinesCheck = false
): AssistantDocument[] => {
  const existingNames = currentFiles.map((doc) => doc.name)
  const existingNamesSet = new Set(existingNames)
  const newDocuments = files.map((file) => {
    const name = createFileName(file.name, existingNamesSet)
    const doc: AssistantDocument = {
      id: '',
      name,
      file,
      path: '',
      url: URL.createObjectURL(file),
      mimeType: file.type,
    }
    doc.uploadPromise = uploadAssistantFile(doc, redlinesCheck)
    return doc
  })

  return newDocuments
}

export const uploadAssistantFile = async (
  document: AssistantDocument,
  redlinesCheck = false
): Promise<UploadedFile> => {
  const formData = new FormData()
  if (!document.file) {
    throw new Error('Cannot upload file, document.file is not set')
  }

  formData.append('file', document.file, document.name)
  if (redlinesCheck) {
    formData.append('redlines_check', 'true')
  }
  const result = Services.Backend.Post<UploadedFile>('upload_file', formData)
  return result
}

export const isPDFFilePasswordProtected = async (
  file: File
): Promise<boolean> => {
  if (file.type !== 'application/pdf') {
    return false
  }
  try {
    const fileBuffer = await file.arrayBuffer()
    const config = getPdfKitConfig()
    PSPDFKit.unload(config.container)
    return await PSPDFKit.load({
      ...config,
      document: fileBuffer,
      maxPasswordRetries: 0,
    })
      .then(() => false)
      .catch((error) => error.message.includes('INVALID_PASSWORD'))
  } catch (error) {
    Services.HoneyComb.RecordError(error)
    Sentry.captureException(error)
    return false
  }
}

export const createAcceptObject = (
  fileTypes: FileType[]
): Record<string, string[]> => {
  const acceptObject: Record<string, string[]> = {}

  for (const fileType of fileTypes) {
    if (!(fileType in FileTypeToExtension)) {
      continue
    }
    acceptObject[fileType] = FileTypeToExtension[fileType]
  }

  return acceptObject
}

export const mbToBytes = (mb: number): number => {
  return mb * MB_BYTES
}

const bytesToKb = (bytes: number, precision: number = 0): string => {
  if (precision === 0) {
    return Math.round(bytes / KB_BYTES).toString()
  }
  return (bytes / KB_BYTES).toFixed(precision)
}

export const bytesToMb = (bytes: number, precision: number = 0): string => {
  if (precision === 0) {
    return Math.round(bytes / MB_BYTES).toString()
  }
  return (bytes / MB_BYTES).toFixed(precision)
}

const mbToGb = (mb: number, precision: number = 0): string => {
  if (precision === 0) {
    return Math.round(mb / GB_MB).toString()
  }
  return (mb / GB_MB).toFixed(precision)
}

const bytesToGb = (bytes: number, precision: number = 0): string => {
  if (precision === 0) {
    return Math.round(bytes / GB_MB / MB_BYTES).toString()
  }
  return (bytes / GB_MB / MB_BYTES).toFixed(precision)
}

export const mbToReadable = (
  mb: number,
  precision: number = 0,
  withSpace: boolean = true
): string => {
  if (mb >= GB_MB) {
    return mbToGb(mb, precision) + (withSpace ? '\u00A0' : '') + 'GB'
  }
  return mb.toFixed(precision) + (withSpace ? '\u00A0' : '') + 'MB'
}

// Converts bytes to a readable string, with optional precision and space
// For example:
//  - bytesToReadable(1024 * 1024 * 1024, 2, true) returns "1.00 GB"
//  - bytesToReadable(1024 * 1024 * 1024, 0, false) returns "1GB"
//  - bytesToReadable(912 * 1024, 2, true) returns "0.91 MB"
//  - bytesToReadable(912 * 1024, 2, false) returns "0.91MB"
//  - bytesToReadable(9.123 * 1024, 2, true) returns "9.12 KB"
//  - bytesToReadable(9.123 * 1024, 0, false) returns "9KB"
// NOTE: for zero bytes, this function will return "0 KB" instead of "0 bytes"
export const bytesToReadable = (
  bytes: number,
  precision: number = 2,
  withSpace: boolean = true
): string => {
  if (bytes >= mbToBytes(GB_MB)) {
    return bytesToGb(bytes, precision) + (withSpace ? '\u00A0' : '') + 'GB'
  }
  if (bytes > 0 && bytes < Math.pow(10, -precision) * KB_BYTES) {
    // If the file size is less than 10^(-precision) KB, return the size in bytes instead
    // For example, if precision is 2:
    //   - 0.009 KB (9 bytes) will be displayed as "9 bytes"
    //   - 0.011 KB (11 bytes) will be displayed as "0.01 KB"
    // This improves readability for humans by avoiding displaying very small KB values
    return bytes + (withSpace ? '\u00A0' : '') + 'bytes'
  }
  if (bytes < Math.pow(10, -precision) * MB_BYTES) {
    // If the file size is less than 10^(-precision) MB, return the size in KB instead
    // For example, if precision is 2:
    //   - 0.009 MB (9 KB) will be displayed as "9 KB"
    //   - 0.011 MB (11.264 KB) will be displayed as "0.01 MB"
    // This improves readability for humans by avoiding displaying very small MB values
    return bytesToKb(bytes, precision) + (withSpace ? '\u00A0' : '') + 'KB'
  }
  return bytesToMb(bytes, precision) + (withSpace ? '\u00A0' : '') + 'MB'
}

// This is a more precise version of bytesToReadable, which prefer smaller units when possible.
// For example:
//  - bytesToPreciseReadable(1024 * 1024 * 1024, 2, true) returns "1.00 GB"
//  - bytesToPreciseReadable(1024 * 1024 * 1024, 0, false) returns "1GB"
//  - bytesToPreciseReadable(912 * 1024, 2, true) returns "912 KB"
//  - bytesToPreciseReadable(912 * 1024, 2, false) returns "912KB"
//  - bytesToPreciseReadable(912, 2, true) returns "912 bytes"
//  - bytesToPreciseReadable(912, 0, false) returns "912bytes"
// NOTE: for zero bytes, this function will return "0 KB" instead of "0 bytes"
export const bytesToPreciseReadable = (
  bytes: number,
  precision: number = 2,
  withSpace: boolean = true
): string => {
  if (bytes >= mbToBytes(GB_MB)) {
    return bytesToGb(bytes, precision) + (withSpace ? '\u00A0' : '') + 'GB'
  }
  if (bytes > 0 && bytes < KB_BYTES) {
    return bytes + (withSpace ? '\u00A0' : '') + 'bytes'
  }
  if (bytes < MB_BYTES) {
    return bytesToKb(bytes, precision) + (withSpace ? '\u00A0' : '') + 'KB'
  }
  return bytesToMb(bytes, precision) + (withSpace ? '\u00A0' : '') + 'MB'
}

export const parsedName = (name: string) => {
  // remove leading / in the name
  // a file will have a leading / in the path/name if the user drags a folder
  if (name.startsWith('/')) {
    name = name.substring(1)
  }
  return name
}

// Returns a new file name that is unique, ie. file name (1).pdf
export const createFileName = (
  newFileName: string,
  existingNamesSet: Set<string>
) => {
  let name = newFileName
  let suffix = 0

  while (existingNamesSet.has(name)) {
    suffix++
    const extensionIndex = newFileName.lastIndexOf('.')
    const [baseName, extension] =
      extensionIndex >= 0
        ? [
            newFileName.substring(0, extensionIndex),
            newFileName.substring(extensionIndex),
          ]
        : [newFileName, '']
    name = `${baseName} (${suffix})${extension}`
  }

  return name
}

// Compare function to sort file names in the correct order, taking into account numerical suffixes
// e.g. abc, abc (1), abc (2), abc (10), xyz, xyz (1), xyz (2), xyz (10)
export const compareFileName = (
  a: { name: string },
  b: { name: string }
): number => {
  // Regular expression to match the pattern " (number)"
  const regex = / \(\d+\)/
  const baseNameA = a.name.replace(regex, '')
  const baseNameB = b.name.replace(regex, '')

  // Compare base names without the numerical suffix
  if (baseNameA !== baseNameB) {
    return baseNameA.localeCompare(baseNameB, undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  } else {
    // If base names are equal, sort by the full name to get the correct order for numbered files
    if (a.name.match(regex) && !b.name.match(regex)) {
      return 1 // a has suffix, b does not, b should come first
    } else if (!a.name.match(regex) && b.name.match(regex)) {
      return -1 // a does not have suffix, a should come first
    } else {
      // If both have the suffix, sort by the suffix number
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    }
  }
}

export const removeSlashesInFileName = (fileName: string) => {
  return fileName.replace(/\//g, ':')
}

export const sanitizeFileName = (fileName: string) => {
  return (
    fileName
      .replace(/[^a-zA-Z0-9_]/g, '_')
      // Replace multiple consecutive underscores with a single underscore
      .replace(/_+/g, '_')
      // Remove leading and trailing underscores
      .replace(/^_|_$/g, '')
  )
}

export const getFileNameExtension = (fileName: Maybe<string>) => {
  if (!fileName) return ''

  const parts = fileName.match(/[ \S]+\.(\w+)$/)
  if (parts && parts.length === 2) {
    return parts[1].toLowerCase()
  }
  return ''
}

export const sanitizePdfFileName = (fileName: string): string => {
  let baseName = fileName.replace(/\.[^/.]+$/, '')
  baseName = sanitizeFileName(baseName)
  if (baseName.length === 0) {
    baseName = 'document'
  }
  return `${baseName}.pdf`
}

export const cloneFileWithNewName = (file: File, newName: string): File => {
  return new File([file], newName, {
    type: file.type,
    lastModified: file.lastModified,
  })
}

const MAX_RETRIES = 3
const RETRY_DELAY = 1000 // 1 second

export const downloadSharepointFiles = async (
  files: SPItem[],
  accessToken: string
): Promise<File[]> => {
  const downloadFileWithRetry = async (
    file: SPItem,
    retryCount = 0
  ): Promise<File | null> => {
    const driveId = file.parentReference.driveId
    const itemId = file.id
    const endpoint = `${file['@sharePoint.endpoint']}/drives/${driveId}/items/${itemId}/content`

    try {
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      if (!response.ok) {
        if (retryCount < MAX_RETRIES) {
          console.warn(`Retry ${retryCount + 1} for ${file.name}`)
          await new Promise((resolve) =>
            setTimeout(resolve, RETRY_DELAY * Math.pow(2, retryCount))
          )
          return downloadFileWithRetry(file, retryCount + 1)
        }

        // Get error details from response
        const errorText = await response.text()
        throw new Error(
          `Failed to download ${file.name} after ${MAX_RETRIES} retries. Status: ${response.status}. Error: ${errorText}`,
          { cause: response }
        )
      }

      const blob = await response.blob()
      return new File([blob], file.name, { type: blob.type })
    } catch (error) {
      console.error(`Error downloading ${file.name}:`, error)
      if (retryCount < MAX_RETRIES) {
        console.warn(`Retry ${retryCount + 1} for ${file.name}`)
        await new Promise((resolve) =>
          setTimeout(resolve, RETRY_DELAY * Math.pow(2, retryCount))
        )
        return downloadFileWithRetry(file, retryCount + 1)
      }
      return null
    }
  }

  const fileObjects = await Promise.all(
    files.map((file) => downloadFileWithRetry(file))
  )
  return fileObjects.filter((file): file is File => file !== null)
}

interface GoogleDriveFileMetadata {
  kind?: string
  id?: string
  name?: string
  mimeType: string
}

// https://developers.google.com/drive/api/guides/ref-export-formats
// https://developers.google.com/drive/api/guides/mime-types
export const GOOGLE_DRIVE_NATIVE_FILE_MIME_TYPE_MAPPING: Record<
  string,
  FileType
> = {
  'application/vnd.google-apps.document': FileType.WORD,
  'application/vnd.google-apps.spreadsheet': FileType.EXCEL,
  'application/vnd.google-apps.presentation': FileType.POWERPOINT,
}

const GOOGLE_DRIVE_NATIVE_FILE_MIME_TYPE_REGEX =
  /^application\/vnd\.google-apps/

const isGoogleDriveFileMetadata = (
  data: any
): data is GoogleDriveFileMetadata => {
  return (
    typeof data === 'object' &&
    'mimeType' in data &&
    typeof data.mimeType === 'string'
  )
}

const getGoogleDriveFileDownloadUrl = async (
  fileId: string,
  accessToken: string,
  acceptedFileTypes: string[] // mime types e.g. application/pdf
): Promise<[string, Maybe<string>]> => {
  // [export url, correct file extension for Google exports]
  const response = await fetch(
    `https://content.googleapis.com/drive/v3/files/${fileId}?key=${googleDriveIntegrationApiKey}&supportsAllDrives=true`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
  if (!response.ok) {
    throw new Error(
      `Http error while getting Google Drive file metadata, status: ${response.status}`
    )
  }
  const json = await response.json()
  if (!isGoogleDriveFileMetadata(json)) {
    throw new Error(
      `Unexpected response format while getting Google Drive file metadata: ${JSON.stringify(
        json
      )}`
    )
  }
  if (GOOGLE_DRIVE_NATIVE_FILE_MIME_TYPE_REGEX.test(json.mimeType)) {
    if (!(json.mimeType in GOOGLE_DRIVE_NATIVE_FILE_MIME_TYPE_MAPPING)) {
      throw new Error(
        `Unsupported Google Drive native file type ${json.mimeType}`
      )
    }

    const fileType = GOOGLE_DRIVE_NATIVE_FILE_MIME_TYPE_MAPPING[json.mimeType]
    const exportMimeTypeUrlEncoded = encodeURIComponent(fileType)
    const fileExtension = FileTypeToExtension[fileType][0]
    return [
      `https://content.googleapis.com/drive/v3/files/${fileId}/export?mimeType=${exportMimeTypeUrlEncoded}&key=${googleDriveIntegrationApiKey}&supportsAllDrives=true`,
      fileExtension,
    ]
  } else if (acceptedFileTypes.includes(json.mimeType)) {
    return [
      `https://content.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${googleDriveIntegrationApiKey}&supportsAllDrives=true`,
      null,
    ]
  } else {
    throw new Error(
      `Unsupported Google Drive file type ${json.mimeType} for ${fileId}`
    )
  }
}

export const downloadGoogleDriveFiles = async (
  files: google.picker.DocumentObject[],
  accessToken: string,
  acceptedFileTypes: string[] // mime types e.g. application/pdf
): Promise<File[]> => {
  const downloadFileWithRetry = async (
    file: google.picker.DocumentObject,
    retryCount = 0
  ): Promise<File | null> => {
    const fileId = file.id
    try {
      const [endpoint, fileExtensionMaybe] =
        await getGoogleDriveFileDownloadUrl(
          fileId,
          accessToken,
          acceptedFileTypes
        )
      const response = await fetch(endpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      if (!response.ok) {
        if (
          response.status < 400 &&
          response.status >= 500 &&
          retryCount < MAX_RETRIES
        ) {
          console.warn(
            `Retry ${retryCount + 1} for Google Drive file ${fileId}`
          )
          await new Promise((resolve) =>
            setTimeout(resolve, RETRY_DELAY * Math.pow(2, retryCount))
          )
          return downloadFileWithRetry(file, retryCount + 1)
        }
        console.error(
          `Http error while downloading Google Drive file ${fileId}, status: ${response.status}`
        )
        return null
      }

      const fileName = file.name ?? fileId

      const blob = await response.blob()
      if (!_.isNil(fileExtensionMaybe)) {
        if (!fileName.toLowerCase().endsWith(fileExtensionMaybe)) {
          return new File([blob], `${fileName}${fileExtensionMaybe}`, {
            type: blob.type,
          })
        }
      }
      return new File([blob], fileName, { type: blob.type })
    } catch (error) {
      console.error(
        `Unexpected error while downloading Google Drive file ${fileId}:`,
        error
      )
      return null
    }
  }

  const fileObjects = await Promise.all(
    files.map((file) => downloadFileWithRetry(file))
  )
  return fileObjects.filter((file): file is File => file !== null)
}
