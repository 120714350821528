import { useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useAuthUser } from 'components/common/auth-context'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import { useVaultWorkflows } from 'components/vault/workflows/use-vault-workflows'
import useVaultWorkflowStore from 'components/vault/workflows/vault-workflow-store'
import {
  VaultWorkflowInfo,
  WorkflowCategory,
} from 'components/workflows/workflow-card'

export const useVaultWorkflowSetup = () => {
  const userInfo = useAuthUser()

  const [vaultWorkflows] = useVaultWorkflowStore(
    useShallow((state) => [state.workflows])
  )

  const { isFetching } = useVaultWorkflows()

  const vaultWorkflowInfo: VaultWorkflowInfo[] = useMemo(
    () =>
      (Object.values(vaultWorkflows).filter(Boolean) as ReviewWorkflow[]).map(
        (workflow) => ({
          id: workflow.id,
          name: workflow.name,
          description: workflow.description,
          category: WorkflowCategory.VAULT,
          numColumns: workflow.columns?.length ?? 0,
          documentTags: workflow.tags,
          cobrand: workflow.cobrand,
        })
      ),
    [vaultWorkflows]
  )

  const hasVaultWorkflows = Object.values(vaultWorkflows).length > 0

  if (!userInfo.IsVaultUser) {
    return {
      isFetchingVaultWorkflows: false,
      vaultWorkflowInfo: [],
      hasVaultWorkflows: false,
    }
  }

  return {
    isFetchingVaultWorkflows: isFetching,
    vaultWorkflowInfo,
    hasVaultWorkflows,
  }
}
