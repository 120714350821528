import * as React from 'react'
import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'
import { z } from 'zod'

import { Profession } from 'openapi/models/Profession'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import Modal from 'components/common/flows/profile-setup/components/modal'
import ModalClose from 'components/common/flows/profile-setup/components/modal-close'
import {
  PROFILE_SETUP_STEP_SKIPPED_EVENT,
  useSkipOnboarding,
} from 'components/common/flows/profile-setup/hooks/use-skip-onboarding'
import { ProfileSetupFlowType } from 'components/common/flows/profile-setup/profile-setup'
import { WelcomeStepType } from 'components/common/flows/profile-setup/steps/welcome-step'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import ProfileProfessionForm, {
  FORM_DESCRIPTION,
  FORM_HEADING,
} from 'components/common/profile/profile-primary-profession-form'

export type ProfessionStepType = Step<
  WelcomeStepType['stepOutput'],
  {
    primaryProfession: Profession
  }
>

const professionSchema = z.object({
  primaryProfession: z.nativeEnum(Profession),
})

export type ProfileSchema = z.infer<typeof professionSchema>

const ProfessionStep: React.FC = () => {
  const { goBack, navigateToStep, exit } =
    useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<ProfessionStepType>()
  const userInfo = useAuthUser()
  const form = useForm<ProfileSchema>({
    resolver: zodResolver(professionSchema),
  })
  const { trackEvent } = useAnalytics()
  const handleSkipOnboarding = useSkipOnboarding({ stepInput, exit })
  const canSkipSteps = userInfo.isCreateUserProfilesOptionalStepsUser

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent('profile_setup_profession_step_viewed', preparedEventProperties)
  }, [stepInput, trackEvent])

  const handleContinue = form.handleSubmit((data) => {
    navigateToStep('practice-area-step', {
      primaryProfession: data.primaryProfession,
      stepCounter: stepInput.stepCounter + 1,
    })
  })

  const handleSkip = () => {
    navigateToStep('primary-language-step', {
      ...stepInput,
      stepCounter: stepInput.stepCounter + 1,
      totalSteps: stepInput.totalSteps - 2,
    })
    trackEvent(PROFILE_SETUP_STEP_SKIPPED_EVENT, {
      ...prepareEventProperties(stepInput),
      step: 'profession',
    })
  }

  return (
    <Modal
      goBack={goBack}
      handleContinue={handleContinue}
      handleSkip={canSkipSteps ? handleSkip : undefined}
      currentStep={stepInput.stepCounter}
      totalSteps={stepInput.totalSteps}
      nextDisabled={!form.formState.isValid}
      heading={FORM_HEADING}
      description={FORM_DESCRIPTION}
    >
      {userInfo.isCreateUserProfilesOptionalUser ? (
        <ModalClose onClick={handleSkipOnboarding} />
      ) : null}
      <ProfileProfessionForm control={form.control} />
    </Modal>
  )
}

export default ProfessionStep
