import { QueryClient } from '@tanstack/react-query'
import { GridApi } from 'ag-grid-community'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { ReviewEventRunType } from 'openapi/models/ReviewEventRunType'
import { ReviewEventStreamType } from 'openapi/models/ReviewEventStreamType'
import { VaultFile } from 'openapi/models/VaultFile'
import { HistoryItem } from 'types/history'

import { TaskStatus } from 'utils/task'
import { parseIsoString, ToFrontendKeys } from 'utils/utils'

import {
  QueryQuestion,
  ReviewColumn,
  ReviewEvent,
  ReviewSource,
  LOADING_QUERY_TITLE,
} from 'components/vault/utils/vault'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import { mapReviewEventToEventV1Metadata } from 'components/vault/utils/vault-helpers'
import {
  VaultCurrentStreamingState,
  VaultReviewSocketState,
} from 'components/vault/utils/vault-store'

import {
  setHistoryItemHelper,
  invalidateQuery,
  abortSSEQuery,
} from './data-grid-helpers'

export type CellViewerData = {
  fileId: string
  file: VaultFile
  colId: string
}

export interface HistoryItemFromSSE {
  type: ReviewEventStreamType
  data: any
}

export type ReviewHistoryItem = HistoryItem &
  ReviewEvent &
  VaultReviewSocketState &
  VaultCurrentStreamingState

export interface AddColumnCellState {
  isHoveringAddColumn: boolean
  currentPendingColumnId: string | null
  hasReachedQuestionsLimit: boolean
}

export interface VaultQueryDetailState {
  workflow: ReviewWorkflow | null
  queryId: string
  queryTitle: string
  isRunButtonLoading: boolean
  isQueryLoading: boolean
  isFetchingQuery: boolean
  historyItem: HistoryItem | ReviewHistoryItem | null
  fetchingFileIdsSources: string[]
  fileIdToSources: Record<string, ReviewSource[]>
}

export interface VaultDataGridState {
  gridApi: GridApi | null
  isGridDirty: boolean
  hasOnGridReadyExecuted: boolean
  shouldPollForHistoryItem: boolean
  initialHistoryItem: HistoryItem | ReviewHistoryItem | null
  initialPendingQueryFileIds: string[] | null
  initialPendingQueryQuestions: QueryQuestion[] | null
  pendingQueryFileIds: string[] | null
  pendingQueryQuestions: QueryQuestion[] | null
  cellViewerData: CellViewerData | null
}

export interface AddColumnCellAction {
  setIsHoveringAddColumn: (isHoveringAddColumn: boolean) => void
  setCurrentPendingColumnId: (columnId: string | null) => void
}

export interface SetHistoryItemFromSSEProps {
  event: HistoryItemFromSSE
  errorCallback: (notReady?: boolean) => void
  getAbortController: (key: string) => AbortController | undefined
  removeAbortController: (key: string) => void
}

export interface VaultQueryDetailAction {
  setQueryId: (queryId: string) => void
  setIsRunButtonLoading: (isRunButtonLoading: boolean) => void
  setHistoryItemFromSSE: (props: SetHistoryItemFromSSEProps) => void
  setHistoryItem: (historyItem: HistoryItem | ReviewHistoryItem | null) => void
  addFileIdToFetchingFileIdsSources: (fileId: string) => void
  addSourcesToFileId: (fileId: string, sources: ReviewSource[]) => void
  updateQueryTitle: (title: string) => void
}
export interface VaultDataGridAction {
  setWorkflow: (workflow: ReviewWorkflow | null) => void
  setGridApi: (gridApi: GridApi | null, queryClient?: QueryClient) => void
  setIsGridDirty: (isGridDirty: boolean) => void
  setHasOnGridReadyExecuted: (hasOnGridReadyExecuted: boolean) => void
  setShouldPollForHistoryItem: (shouldPollForHistoryItem: boolean) => void
  setPendingQueryFileIds: (fileIds: string[] | null) => void
  addToPendingQueryFileIds: (fileIds: string[]) => void
  removeFileIdsFromPendingQueryFileIds: (fileIds: string[]) => void
  setPendingQueryQuestions: (questions: QueryQuestion[] | null) => void
  addToPendingQueryQuestions: (
    question: QueryQuestion,
    shouldSetPendingColumnId?: boolean
  ) => void
  removeFromPendingQueryQuestions: (columnId: string) => void
  updatePendingQueryQuestion: (
    columnId: string,
    key: string,
    value: string
  ) => void
  updateColumnInHistoryItem: (column: ReviewColumn) => void
  setCellViewerData: (cellViewerData: CellViewerData | null) => void
}

const useVaultQueryDetailStore = create(
  immer(
    devtools(
      immer<
        VaultQueryDetailState &
          VaultQueryDetailAction &
          VaultDataGridState &
          VaultDataGridAction &
          AddColumnCellState &
          AddColumnCellAction
      >((set) => ({
        // Add Column State
        isHoveringAddColumn: false,
        currentPendingColumnId: null,
        hasReachedQuestionsLimit: false,
        // Vault Query Detail State
        workflow: null,
        queryId: 'new',
        queryTitle: LOADING_QUERY_TITLE,
        isRunButtonLoading: false,
        isQueryLoading: false,
        isFetchingQuery: true,
        historyItem: null,
        gridApi: null,
        isGridDirty: false,
        shouldPollForHistoryItem: false,
        hasOnGridReadyExecuted: false,
        initialHistoryItem: null,
        initialPendingQueryFileIds: null,
        initialPendingQueryQuestions: null,
        pendingQueryFileIds: null,
        pendingQueryQuestions: null,
        cellViewerData: null,
        fetchingFileIdsSources: [],
        fileIdToSources: {},
        // Add Column Action
        setIsHoveringAddColumn: (isHoveringAddColumn: boolean) => {
          set((state) => {
            state.isHoveringAddColumn = isHoveringAddColumn
          })
        },
        setCurrentPendingColumnId: (columnId: string | null) => {
          set((state) => {
            state.currentPendingColumnId = columnId
          })
        },
        setQueryId: (queryId: string) => {
          set((state) => {
            state.queryId = queryId
            state.isQueryLoading = true
            // if we are setting the queryId then we are not fetching the query
            // this is required to prevent vault-query-detail from flashing the null table
            state.isFetchingQuery = false
            state.shouldPollForHistoryItem = true
          })
        },
        setIsRunButtonLoading: (isRunButtonLoading: boolean) => {
          set((state) => {
            state.isRunButtonLoading = isRunButtonLoading
          })
        },
        setGridApi: (gridApi: GridApi | null, queryClient?: QueryClient) => {
          set((state) => {
            state.gridApi = gridApi
            if (state.isGridDirty && queryClient) {
              invalidateQuery(queryClient, state.queryId)
            }
            state.isGridDirty = false
          })
        },
        setIsGridDirty: (isGridDirty: boolean) => {
          set((state) => {
            state.isGridDirty = isGridDirty
          })
        },
        setHasOnGridReadyExecuted: (hasOnGridReadyExecuted: boolean) => {
          set((state) => {
            state.hasOnGridReadyExecuted = hasOnGridReadyExecuted
          })
        },
        setShouldPollForHistoryItem: (shouldPollForHistoryItem: boolean) => {
          set((state) => {
            state.shouldPollForHistoryItem = shouldPollForHistoryItem
          })
        },
        setHistoryItemFromSSE: ({
          event,
          errorCallback,
          getAbortController,
          removeAbortController,
        }: SetHistoryItemFromSSEProps) => {
          set((state) => {
            const eventType = event.type
            if (eventType === ReviewEventStreamType.ERROR) {
              errorCallback()
              return
            }
            if (eventType === ReviewEventStreamType.EVENT_COMPLETED) {
              state.shouldPollForHistoryItem = true
              state.isQueryLoading = false
              return
            }
            const eventData = event.data
            if (eventType === ReviewEventStreamType.EVENT_STATUS) {
              const eventStatus = eventData['event_status'] as TaskStatus
              const eventId = eventData['event_id'] as string
              if (
                eventStatus === TaskStatus.PENDING ||
                eventStatus === TaskStatus.IN_PROGRESS ||
                eventStatus === TaskStatus.ERRORED
              ) {
                state.shouldPollForHistoryItem = true
                abortSSEQuery({
                  queryId: eventId,
                  getAbortController,
                  removeAbortController,
                })

                if (
                  eventStatus === TaskStatus.PENDING ||
                  eventStatus === TaskStatus.ERRORED
                ) {
                  errorCallback(eventStatus === TaskStatus.PENDING)
                }
              }
              return
            }
            if (
              eventType === ReviewEventStreamType.EVENT_SKELETON ||
              eventType === ReviewEventStreamType.EVENT_DATA ||
              eventType === ReviewEventStreamType.EVENT_COMPLETED
            ) {
              const baseReviewEvent = ToFrontendKeys(eventData) as ReviewEvent
              const metadata = mapReviewEventToEventV1Metadata(baseReviewEvent)
              const reviewEvent = {
                ...baseReviewEvent,
                id: baseReviewEvent.eventId.toString(),
                userId: baseReviewEvent.eventCreatorEmail,
                status: baseReviewEvent.eventStatus,
                query:
                  baseReviewEvent.runs.find(
                    (run) => run.runType === ReviewEventRunType.NEW
                  )?.query ?? '',
                response: '',
                kind: baseReviewEvent.eventKind,
                created: parseIsoString(baseReviewEvent.eventCreatedAt),
                updatedAt: parseIsoString(baseReviewEvent.eventUpdatedAt),
                metadata: metadata,
                ...metadata,
              } as ReviewHistoryItem
              setHistoryItemHelper({
                state,
                historyItem: reviewEvent,
                newQueryId: reviewEvent.id,
                isStreamingCells: true,
              })
            }
          })
        },
        setHistoryItem: (
          historyItem: HistoryItem | ReviewHistoryItem | null
        ) => {
          set((state) => {
            const newQueryId = historyItem?.id ?? 'new'
            setHistoryItemHelper({
              state,
              historyItem,
              newQueryId,
              isStreamingCells: false,
            })
          })
        },
        setWorkflow: (workflow: ReviewWorkflow | null) => {
          set((state) => {
            state.workflow = workflow
          })
        },
        setPendingQueryFileIds: (fileIds: string[] | null) => {
          set((state) => {
            state.initialPendingQueryFileIds = fileIds
            state.pendingQueryFileIds = fileIds
          })
        },
        removeFileIdsFromPendingQueryFileIds: (fileIds: string[]) => {
          set((state) => {
            if (state.pendingQueryFileIds) {
              const filteredPendingQueryFileIds =
                state.pendingQueryFileIds.filter(
                  (fileId) => !fileIds.includes(fileId)
                )
              state.pendingQueryFileIds =
                filteredPendingQueryFileIds.length > 0
                  ? filteredPendingQueryFileIds
                  : null
            } else {
              state.pendingQueryFileIds = null
            }
          })
        },
        addToPendingQueryFileIds: (fileIds: string[]) => {
          set((state) => {
            state.pendingQueryFileIds = [
              ...(state.pendingQueryFileIds ?? []),
              ...fileIds,
            ]
          })
        },
        setPendingQueryQuestions: (questions: QueryQuestion[] | null) => {
          set((state) => {
            state.initialPendingQueryQuestions = questions
            state.pendingQueryQuestions = questions
          })
        },
        addToPendingQueryQuestions: (
          question: QueryQuestion,
          shouldSetPendingColumnId: boolean = true
        ) => {
          set((state) => {
            if (shouldSetPendingColumnId) {
              state.currentPendingColumnId = question.id
            }
            state.pendingQueryQuestions = [
              ...(state.pendingQueryQuestions ?? []),
              question,
            ]
          })
        },
        removeFromPendingQueryQuestions: (columnId: string) => {
          set((state) => {
            if (state.pendingQueryQuestions) {
              // Filter out the deleted question
              const filteredQuestions = state.pendingQueryQuestions.filter(
                (question) => question.id !== columnId
              )
              state.pendingQueryQuestions =
                filteredQuestions.length > 0 ? filteredQuestions : null
            } else {
              state.pendingQueryQuestions = null
            }
          })
        },
        updatePendingQueryQuestion: (
          columnId: string,
          key: string,
          value: string
        ) => {
          set((state) => {
            state.pendingQueryQuestions = state.pendingQueryQuestions
              ? state.pendingQueryQuestions?.map((question) => {
                  if (question.id === columnId) {
                    return {
                      ...question,
                      [key]: value,
                    }
                  }
                  return question
                })
              : null
          })
        },
        updateColumnInHistoryItem: (column: ReviewColumn) => {
          set((state) => {
            if (!state.historyItem) return
            const reviewEvent = JSON.parse(
              JSON.stringify(state.historyItem)
            ) as ReviewHistoryItem
            reviewEvent.columns = reviewEvent.columns.map((existingColumn) => {
              if (existingColumn.id === column.id) {
                return column
              }
              return existingColumn
            })
            const metadata = mapReviewEventToEventV1Metadata(reviewEvent)
            const newHistoryItem = {
              ...reviewEvent,
              metadata: metadata,
              ...metadata,
            } as ReviewHistoryItem
            state.historyItem = newHistoryItem
          })
        },
        setCellViewerData: (cellViewerData: CellViewerData | null) => {
          set((state) => {
            state.cellViewerData = cellViewerData
          })
        },
        addFileIdToFetchingFileIdsSources: (fileId: string) => {
          set((state) => {
            state.fetchingFileIdsSources = [
              ...state.fetchingFileIdsSources,
              fileId,
            ]
          })
        },
        addSourcesToFileId: (fileId: string, sources: ReviewSource[]) => {
          set((state) => {
            state.fetchingFileIdsSources = state.fetchingFileIdsSources.filter(
              (id) => id !== fileId
            )
            state.fileIdToSources = {
              ...state.fileIdToSources,
              [fileId]: sources,
            }
          })
        },
        updateQueryTitle: (title: string) => {
          set((state) => {
            state.queryTitle = title
            if (state.historyItem && 'title' in state.historyItem) {
              state.historyItem.title = title
            }
          })
        },
      }))
    )
  )
)

export default useVaultQueryDetailStore
