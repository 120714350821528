import * as React from 'react'

import indefinite from 'indefinite'
import pluralize from 'pluralize'

import { LanguageCode } from 'openapi/models/LanguageCode'
import { PracticeArea } from 'openapi/models/PracticeArea'
import { Profession } from 'openapi/models/Profession'
import { Title } from 'openapi/models/Title'

import { replaceAndWithAmpersand, toTitleCase } from 'utils/string'
import {
  getLanguageText,
  getPracticeAreaText,
  getTitleText,
} from 'utils/user-profile-helpers'
import { cn } from 'utils/utils'

import { isLanguageCode } from 'components/common/user-profile-store'

interface ProfileMadlibsProps {
  primaryProfession?: Profession
  title?: Title
  practiceAreas?: PracticeArea[]
  yoe?: number
  preferredLanguage?: LanguageCode | string
  textAlign?: 'left' | 'center'
}

const formatPracticeAreas = (practiceAreas: PracticeArea[]) => {
  const formattedPracticeAreas = practiceAreas.map((practiceArea) =>
    replaceAndWithAmpersand(getPracticeAreaText(practiceArea))
  )
  if (formattedPracticeAreas.length === 0) return ''
  if (formattedPracticeAreas.length === 1) return formattedPracticeAreas[0]
  if (formattedPracticeAreas.length === 2)
    return formattedPracticeAreas.join(' and ')

  const lastPracticeAreaText =
    formattedPracticeAreas[formattedPracticeAreas.length - 1]
  return `${formattedPracticeAreas
    .slice(0, -1)
    .join(', ')}, and ${lastPracticeAreaText}`
}

const EmphasizedText: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <span className="text-primary">{children}</span>

const ProfileMadlibs: React.FC<ProfileMadlibsProps> = ({
  primaryProfession,
  title,
  practiceAreas,
  yoe,
  preferredLanguage,
  textAlign = 'left',
}: ProfileMadlibsProps) => {
  const languageText = preferredLanguage ? (
    <>
      My preferred language is{' '}
      <EmphasizedText>
        {isLanguageCode(preferredLanguage)
          ? getLanguageText(preferredLanguage)
          : preferredLanguage}
      </EmphasizedText>
      .
    </>
  ) : null

  const professionText = primaryProfession ? (
    <>
      I work in{' '}
      <EmphasizedText>{toTitleCase(primaryProfession)}</EmphasizedText>
    </>
  ) : null

  const titleText = title ? (
    <>
      as {indefinite(getTitleText(title), { articleOnly: true })}{' '}
      <EmphasizedText>{getTitleText(title)}</EmphasizedText>
    </>
  ) : null

  const yoeText = yoe ? (
    <>
      with{' '}
      <EmphasizedText>
        {yoe} {pluralize('year', yoe)} of experience
      </EmphasizedText>
    </>
  ) : null

  const titleOrYoeText = titleText || yoeText

  const optionalSpecialtiesText =
    !!practiceAreas && practiceAreas.length > 0 ? (
      <>
        specializing in{' '}
        <EmphasizedText>{formatPracticeAreas(practiceAreas)}</EmphasizedText>
      </>
    ) : null

  const makeMainText = () => {
    const textNodes = [
      professionText,
      optionalSpecialtiesText,
      titleOrYoeText,
    ].filter(Boolean)
    if (textNodes.length === 0) return null

    return (
      <>
        {textNodes.map((node, index) => (
          <React.Fragment key={index}>
            {index > 0 && ' '}
            {node}
          </React.Fragment>
        ))}
        .{' '}
      </>
    )
  }

  return (
    <p
      className={cn(
        'font-sans text-sm text-inactive',
        textAlign === 'center' && 'text-center'
      )}
    >
      {makeMainText()}
      {languageText}
    </p>
  )
}

export default ProfileMadlibs
