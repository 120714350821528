import React from 'react'
import { useParams } from 'react-router-dom'

import { CopyIcon, DownloadIcon, Maximize2Icon } from 'lucide-react'

import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { Maybe } from 'types'

import { Source } from 'utils/task'
import { cn } from 'utils/utils'

import AssistantSources from 'components/assistant/components/assistant-sources'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
  AssistantWorkflowSourceExtractor,
} from 'components/assistant/workflows'
import AnswerBlockToolbar, {
  AnswerBlockToolbarAction,
} from 'components/assistant/workflows/components/answer-block-toolbar/answer-block-toolbar'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import RenderBlockToolbar from 'components/assistant/workflows/components/render-block-toolbar/render-block-toolbar'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import { useToolbarActions } from 'components/assistant/workflows/hooks/use-toolbar-actions'
import Markdown from 'components/common/markdown/markdown'
import { Button } from 'components/ui/button'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowDraftRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.DRAFT
> = ({
  blockParams,
  loadingStates,
  onDownload,
  workflowName,
  paramStatus,
  feedback,
  stepId,
}) => {
  const { id: workflowId, eventId: eventIdParam } = useParams()
  const draftRef = React.useRef<HTMLDivElement>(null)
  const { draftId, draftRevision } = blockParams
  const content = draftRevision.response
  const { getHrvyInfoMetadata, handleSourceClick } = useSourceUtils({
    sources: draftRevision.sources as Source[],
  })
  const { tryDownload, copyToClipboard } = useToolbarActions({
    onDownload,
    metadata: draftRevision.metadata,
    workflowName,
    content,
  })

  const draftUrl = `/assistant/workflows/draft/${workflowId}/${eventIdParam}/${draftId}`

  const buttonDisabled = paramStatus !== 'COMPLETED'

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (buttonDisabled) {
      e.preventDefault()
    }
  }

  const toolbarActions: AnswerBlockToolbarAction[] = [
    {
      icon: CopyIcon,
      tooltip: 'Copy',
      onClick: copyToClipboard,
      disabled: buttonDisabled,
    },
    {
      icon: DownloadIcon,
      tooltip: 'Download',
      onClick: tryDownload,
      disabled: buttonDisabled,
    },
    {
      icon: Maximize2Icon,
      tooltip: 'View Draft',
      to: draftUrl,
      disabled: buttonDisabled,
    },
  ]

  const Sidebar = draftRevision.sources.length ? (
    <AssistantSources
      allDocuments={[]}
      sources={draftRevision.sources as Maybe<Source[]>}
      isStreaming={draftRevision.progress !== 100}
      onSetActiveFileId={handleSourceClick}
      // 42px makes it align with the content of the block
      className="sticky left-0 top-[68px] ml-4 mt-[42px] sm:ml-4"
    />
  ) : undefined

  const isCompleted = paramStatus === 'COMPLETED'

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <LoadingState isCompleted={isCompleted} states={loadingStates} />
        {content.length > 0 && (
          <Button
            variant="unstyled"
            className={cn(
              'group mt-5 h-auto w-full max-w-full p-0',
              buttonDisabled && 'cursor-not-allowed'
            )}
            to={draftUrl}
            onClick={handleClick}
          >
            <div
              ref={draftRef}
              className="relative rounded-lg border bg-primary transition group-hover:bg-secondary"
            >
              <AnswerBlockToolbar title="Draft" actions={toolbarActions} />
              <Markdown
                content={content}
                getHrvyInfoMetadata={getHrvyInfoMetadata}
                className="p-6"
                width="100%"
              />
            </div>
          </Button>
        )}
        {isCompleted && (
          <div className="mt-6">
            <RenderBlockToolbar
              feedback={feedback}
              workflowStepId={stepId ?? ''}
            />
          </div>
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowDraftRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.DRAFT
> = () => {
  return null
}

export const AssistantWorkflowDraftRendererExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowRenderComponentBlocks.DRAFT
> = ({ blockParams }) => {
  const content = blockParams.draftRevision.response
  return <Markdown content={content} />
}

export const draftSourceExtractor: AssistantWorkflowSourceExtractor<
  typeof WorkflowRenderComponentBlocks.DRAFT
> = (blockParams) => {
  return blockParams.draftRevision.sources as Source[]
}
