import React from 'react'

import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'
import WorkspaceRolesTableV2 from 'components/settings/workspace/workspace-details/workspace-details-roles-v2'

const WorkspaceRolesPage = () => {
  const settingsUser = useSettingsState((state) => state.settingsUser)
  const workspace = settingsUser?.workspace

  return (
    <>
      <SettingsAppHeader />
      <SettingsLayout>
        {workspace ? (
          <div className="">
            <WorkspaceRolesTableV2 isClientAdminView workspace={workspace} />
          </div>
        ) : null}
      </SettingsLayout>
    </>
  )
}

export default WorkspaceRolesPage
