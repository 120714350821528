import React from 'react'

import { Row } from '@tanstack/react-table'
import { ChevronRight } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'

interface ExpanderProps<TData> {
  row: Row<TData>
}

const Expander = <TData,>({ row }: ExpanderProps<TData>) => (
  <Button
    variant="ghost"
    size="smIcon"
    onClick={(e) => {
      e.stopPropagation()
      row.toggleExpanded()
    }}
    className="size-4 shrink-0 rounded"
  >
    <ChevronRight
      className={cn('size-4 transition-transform', {
        'rotate-90': row.getIsExpanded(),
      })}
    />
  </Button>
)

export default Expander
