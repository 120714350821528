import React, { useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { formatDistanceToNow } from 'date-fns'
import _ from 'lodash'
import { Trash2 } from 'lucide-react'

import { Perm, PermSource } from 'models/perms'
import { Permission } from 'models/user-info'
import { instanceOfBetaPermission } from 'openapi/models/BetaPermission'

import { parseIsoString } from 'utils/utils'

import BetaPermBadge from 'components/settings/workspace/permissions/beta-perm-badge'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

interface UserPermissionsTableProps {
  perms: Perm[]
  onDeletePerm: (permId: Permission) => Promise<void>
}

const READABLE_SOURCE_NAME: Record<PermSource, string> = {
  [PermSource.USER]: 'User',
}

const UserPermissionsTable: React.FC<UserPermissionsTableProps> = ({
  perms,
  onDeletePerm,
}) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ])

  const columns: ColumnDef<Perm>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Permission name" />
      ),
      cell: ({ getValue }) => (
        <span className="text-sm font-semibold">{String(getValue())}</span>
      ),
    },
    {
      accessorKey: 'permId',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Permission ID" />
      ),
      cell: ({ getValue }) => (
        <span className="flex items-center gap-2 text-sm">
          {String(getValue())}
          {instanceOfBetaPermission(String(getValue())) && <BetaPermBadge />}
        </span>
      ),
    },
    {
      accessorKey: 'desc',
      header: 'Description',
      cell: ({ getValue }) => (
        <span className="text-sm">{String(getValue())}</span>
      ),
    },
    {
      accessorKey: 'permSources',
      header: 'Source',
      cell: ({ getValue }) => {
        const sources = getValue() as string[]
        return (
          <div className="flex flex-wrap">
            {sources.map((source, idx) => {
              const readableSource =
                READABLE_SOURCE_NAME[source as PermSource] || source
              return (
                <div key={idx} className="m-0.5">
                  <Badge variant="secondary">{readableSource}</Badge>
                </div>
              )
            })}
          </div>
        )
      },
    },
    {
      accessorKey: 'expiresAt',
      header: ({ column }) => (
        <DataTableSortHeader column={column} header="Expires At" />
      ),
      cell: ({ getValue }) => {
        const expiresAt = getValue() as string
        if (!expiresAt) {
          return 'Never'
        }
        const expiresAtDate = parseIsoString(expiresAt)
        const timeDifference = _.startCase(
          formatDistanceToNow(expiresAtDate, { addSuffix: true })
        )
        return <div className="w-20 text-sm">{timeDifference}</div>
      },
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => {
        const isUserPerm = (row.original.permSources ?? []).includes(
          PermSource.USER
        )

        if (!isUserPerm) {
          return (
            <Tooltip>
              <TooltipTrigger>
                <Button variant="ghost" size="icon" disabled>
                  <Trash2 className="size-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="left">
                Cannot revoke non user perm
              </TooltipContent>
            </Tooltip>
          )
        }

        return (
          <Button
            variant="ghost"
            size="icon"
            onClick={() => {
              void onDeletePerm(row.original.permId as Permission)
            }}
          >
            <Trash2 className="size-4" />
          </Button>
        )
      },
    },
  ]

  const table = useReactTable({
    data: perms,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  })

  return (
    <div>
      <DataTable table={table} />
      <div className="mt-2 flex justify-end text-sm text-muted">
        {perms.length} permissions
      </div>
    </div>
  )
}

export default UserPermissionsTable
