import * as React from 'react'

import { ArrowLeftIcon } from 'lucide-react'

import { cn } from 'utils/utils'

import Link from 'components/ui/link/link'

interface AssistantWorkflowDraftHeaderProps {
  workflowId: string
  workflowEventId: string
  actions: React.ReactNode
  disabled?: boolean
}

export const AssistantWorkflowDraftHeader = ({
  workflowId,
  workflowEventId,
  actions,
  disabled = false,
}: AssistantWorkflowDraftHeaderProps) => {
  return (
    <div className={cn('z-40 w-full shrink-0 border-b bg-primary py-4')}>
      <div className="flex w-full items-center justify-between px-6">
        <div className="text-xl font-medium">
          <Link
            className={cn('flex items-center gap-1 text-sm', {
              'pointer-events-none opacity-50': disabled,
            })}
            to={`/assistant/workflows/${workflowId}/${workflowEventId}`}
          >
            <ArrowLeftIcon className="size-4" />
            Back to workflow
          </Link>
        </div>
        <div className={cn('shrink-0', { 'opacity-50': disabled })}>
          {actions}
        </div>
      </div>
    </div>
  )
}
