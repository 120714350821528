import React, { useCallback, useEffect, useState, memo } from 'react'

import saveAs from 'file-saver'
import { isEmpty, isNil, isNumber } from 'lodash'
import { AlertCircle, ZoomOut, ZoomIn, Download } from 'lucide-react'
import { Instance } from 'pspdfkit'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { useGeneralStore } from 'stores/general-store'
import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { loadPDF, unloadPDF } from 'utils/pspdfkit'
import { displayWarningMessage } from 'utils/toast'
import { downloadFileFromUrl } from 'utils/utils'

import { Progress } from 'components/ui/progress'
import Toolbelt, {
  ToolbeltButton,
  ToolbeltDivider,
  ToolbeltCombobox,
} from 'components/ui/toolbelt'

type PdfViewerPushSheetProps = {
  document: UploadedFile | null
  isLoadingUrl?: boolean
  height?: string
  containerRef: React.MutableRefObject<HTMLDivElement | null>
  isToolbarHidden?: boolean
}

const Toolbar = ({ document }: { document: UploadedFile | File | null }) => {
  const instance = usePDFViewerStore((s) => s.instance)

  const [zoomLevel, setZoomLevel] = useState<number>(
    instance?.viewState.zoom && typeof instance.viewState.zoom === 'number'
      ? instance.viewState.zoom
      : 1
  )

  const numPages = instance?.totalPageCount ?? 1

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(
    (instance?.viewState.currentPageIndex ?? 0) + 1
  )

  const handleDownload = async () => {
    if (!document) {
      displayWarningMessage('No document to download', 5)
      return
    }

    if (document instanceof File) {
      const buffer = await document.arrayBuffer()
      const blob = new Blob([buffer])
      saveAs(blob, document.name)
      return
    }

    if (!document.url) {
      console.error('Document does not have URL to download')
      displayWarningMessage('No document to download')
      return
    }

    downloadFileFromUrl(document.url, document.name).catch((error) => {
      console.error('Error downloading file', error)
      displayWarningMessage('Error downloading file')
    })
  }

  const handleZoomIn = () => {
    if (!instance) return
    instance.setViewState((viewState) => {
      const currentZoom = isNumber(viewState.zoom) ? viewState.zoom : 1
      const nextZoom = currentZoom + 0.1
      setZoomLevel(nextZoom)
      return viewState.set('zoom', nextZoom)
    })
  }

  const handleZoomOut = () => {
    if (!instance) return
    instance.setViewState((viewState) => {
      const currentZoom = isNumber(viewState.zoom) ? viewState.zoom : 1
      const nextZoom = Math.max(0.5, currentZoom - 0.1)
      setZoomLevel(nextZoom)
      return viewState.set('zoom', nextZoom)
    })
  }

  const jumpToPage = (newPageNumber: number) => {
    if (!instance) return
    instance.setViewState((viewState) => {
      return viewState.set('currentPageIndex', newPageNumber)
    })
  }

  const pageChangeHandler = (value: string) => {
    const newPageNumber = parseInt(value, 10)
    jumpToPage(newPageNumber - 1)
    setCurrentPageNumber(newPageNumber)
  }

  useEffect(() => {
    if (!instance) return undefined
    setCurrentPageNumber(1)
    instance.addEventListener('viewState.currentPageIndex.change', () => {
      setCurrentPageNumber(instance.viewState.currentPageIndex + 1)
    })
    return () => {
      instance.removeEventListener('viewState.currentPageIndex.change', () => {
        setCurrentPageNumber(1)
      })
    }
  }, [instance])

  return (
    <Toolbelt>
      <ToolbeltCombobox
        shouldFocusOption={false}
        align="center"
        defaultText={`Page ${currentPageNumber}`}
        className="max-w-28"
        popoverClassName="max-w-28"
        value={currentPageNumber.toString()}
        setValue={pageChangeHandler}
        options={Array.from({ length: numPages }, (_, i) => i + 1).map(
          (page) => ({
            value: page.toString(),
            label: `Page ${page.toString()}`,
          })
        )}
      />
      <ToolbeltDivider />
      <div className="flex items-center gap-1">
        <ToolbeltButton
          id="download"
          data-testid="download"
          icon={Download}
          aria-label="Download PDF"
          onClick={handleDownload}
        />
        <ToolbeltButton
          id="zoom-out"
          data-testid="zoom-out"
          icon={ZoomOut}
          onClick={handleZoomOut}
          aria-label="Zoom out"
        />
        <ToolbeltButton
          id="zoom-in"
          data-testid="zoom-in"
          icon={ZoomIn}
          onClick={handleZoomIn}
          aria-label="Zoom in"
        />
      </div>
      {/* Hidden live region for screen readers */}
      <div role="status" aria-live="polite" className="sr-only">
        Zoom level: {zoomLevel.toFixed(1)}
      </div>
    </Toolbelt>
  )
}

export const PdfViewerPushSheet: React.FC<PdfViewerPushSheetProps> = memo(
  ({ document, isLoadingUrl, containerRef, isToolbarHidden = false }) => {
    const setInstance = usePDFViewerStore((s) => s.setInstance)
    const setIsPdfLoading = usePDFViewerStore((s) => s.setIsPdfLoading)

    const theme = useGeneralStore((s) => s.theme)
    const applyTheme = useCallback(() => {
      if (!containerRef.current) return
      const iframe = containerRef.current.querySelector('iframe')
      const root =
        iframe?.contentWindow?.document.querySelector('.PSPDFKit-Root')
      if (root) root.classList.add(theme)
    }, [containerRef, theme])

    const unloadInstance = useCallback(() => {
      setInstance(null)
      unloadPDF(containerRef)
      setIsPdfLoading(false)
    }, [setInstance, setIsPdfLoading, containerRef])

    const loadInstance = useCallback(
      async (
        document: UploadedFile,
        containerRef: React.MutableRefObject<HTMLDivElement | null>
      ) => {
        unloadInstance()
        const pdfInstance = await loadPDF({
          document,
          containerRef,
          theme,
        })
        applyTheme()
        setInstance(pdfInstance)
        setIsPdfLoading(false)
        selectSearchTextOnSearch(pdfInstance)
      },
      [applyTheme, theme, setInstance, setIsPdfLoading, unloadInstance]
    )

    const selectSearchTextOnSearch = (pdfInstance?: Instance | null) => {
      // Selects search input on CMD+F so user can override search term without clicking
      if (!pdfInstance) return
      pdfInstance.contentWindow.document.addEventListener('keydown', (e) => {
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyF') {
          const search = pdfInstance.contentWindow.document.querySelector(
            '.PSPDFKit-Search-Form-Input'
          ) as HTMLInputElement | null
          search?.focus()
          search?.select()
        }
      })
    }

    useEffect(() => {
      // After opening and closing PDF, some part of the library/instance continues to
      // steal CMD+F events, so manually add an override to stop propagating them to
      // allow native browser behavior to take over
      const overrideFindOverride = (e: KeyboardEvent) => {
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyF') {
          e.stopImmediatePropagation()
        }
      }
      window.removeEventListener('keydown', overrideFindOverride, true)

      return () => {
        window.addEventListener('keydown', overrideFindOverride, true)
      }
    }, [])

    useEffect(() => {
      if (document && !isEmpty(document.url)) {
        void loadInstance(document, containerRef)
      }
      return () => {
        unloadInstance()
      }
    }, [document, containerRef, loadInstance, unloadInstance])

    const emptyState = () =>
      isLoadingUrl ? (
        <div className="flex h-full flex-col items-center justify-center bg-secondary">
          {/* XXX: To match the style with PSPDFKit Progress  */}
          <Progress value={50} className="my-2 h-2 w-[330px]" />
          <p className="text-[1rem] leading-[1.4rem] text-primary">Loading</p>
        </div>
      ) : (
        <div className="flex h-full items-center justify-center gap-2">
          <AlertCircle className="h-5 w-5 text-muted" />
          <p className="text-lg font-semibold text-muted">
            No preview available
          </p>
        </div>
      )

    // The only thing we need to do to render the pdf is create the container and assign the ref
    // the loadPDF function we have will take care of using the ref to render the pdf
    // we are going to show a loading state until the pdf is loading
    const isEmptyShowing = !isNil(document) && isEmpty(document.url)
    const showToolbar =
      !isToolbarHidden && !isNil(document) && !isEmpty(document.url)
    return (
      <div className="relative h-full">
        <div
          className="h-full"
          ref={containerRef}
          data-testid="pdf-viewer"
          id="pspdfkit-container"
        >
          {isEmptyShowing && emptyState()}
        </div>
        {showToolbar && <Toolbar document={document} />}
      </div>
    )
  }
)

PdfViewerPushSheet.displayName = 'PdfViewerPushSheet'
