import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMount } from 'react-use'

import isPast from 'date-fns/isPast'
import _ from 'lodash'
import { Loader2, X } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import Services from 'services'
import { useIntegrationsStore } from 'stores/integrations-store'

import {
  downloadGoogleDriveFiles,
  GOOGLE_DRIVE_NATIVE_FILE_MIME_TYPE_MAPPING,
} from 'utils/file-utils'
import { googleDriveIntegrationAppId } from 'utils/server-data'
import { googleDriveIntegrationApiKey } from 'utils/server-data'
import { displayErrorMessage } from 'utils/toast'
import { parseIsoString } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import {
  IN_PRODUCT,
  INTEGRATION_CONNECTED_METRIC,
  INTEGRATION_FILE_PICKER_CLICKED_METRIC,
  INTEGRATION_FILE_PICKER_OPENED_METRIC,
  INTEGRATION_FILES_DOWNLOADED_METRIC,
} from 'components/settings/integrations/constants'
import {
  OauthConnectState,
  useOauthConnect,
} from 'components/settings/integrations/use-oauth-connect'
import { fetchIntegrationToken } from 'components/settings/integrations/utils'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'

const integrationType = IntegrationType.GOOGLE_DRIVE
const GOOGLE_API_SCRIPT_ID = 'google-api-script'
const GOOGLE_API_SCRIPT_SRC = '/lib/external/google-api.js'

interface PickerState {
  instance: google.picker.Picker | null
  shouldResetBodyPointerEvents: boolean
}

const ensureGoogleApiLoaded = () => {
  if (!document.getElementById(GOOGLE_API_SCRIPT_ID)) {
    const script = document.createElement('script')
    script.id = GOOGLE_API_SCRIPT_ID
    script.src = GOOGLE_API_SCRIPT_SRC
    script.async = true
    script.defer = true
    script.onload = () => {
      gapi.load('picker', () => {})
    }
    document.body.appendChild(script)
  }
}

const GoogleDriveFilePicker = () => {
  useMount(() => {
    ensureGoogleApiLoaded()
  })

  const [
    integrationFilePickerOpenState,
    setIntegrationFilePickerOpenState,
    getIntegrationToken,
    setIntegrationToken,
  ] = useIntegrationsStore(
    useShallow((state) => [
      state.integrationFilePickerOpenState,
      state.setIntegrationFilePickerOpenState,
      state.getIntegrationToken,
      state.setIntegrationToken,
    ])
  )

  const pickerRef = useRef<PickerState>({
    instance: null,
    shouldResetBodyPointerEvents: false,
  })

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const onConnectCallback = useCallback(() => {
    Services.HoneyComb.Record({
      metric: INTEGRATION_CONNECTED_METRIC,
      user_id: userInfo.id,
      workspace: userInfo.workspace.id,
      integration: integrationType,
      product_area: IN_PRODUCT,
    })
    trackEvent(`${integrationType} connected`)
  }, [userInfo.id, userInfo.workspace.id, trackEvent])

  const { connectionState, handleConnect, handleCancelConnect } =
    useOauthConnect({
      integrationType,
      onConnectCallback,
    })

  const shouldShowPicker =
    !_.isNil(integrationFilePickerOpenState) &&
    integrationFilePickerOpenState.integrationType === integrationType

  // should be safe to call multiple times in a row
  const closePicker = useCallback(() => {
    if (pickerRef.current.instance) {
      pickerRef.current.instance.dispose()
      pickerRef.current.instance = null
      if (pickerRef.current.shouldResetBodyPointerEvents) {
        document.body.style.pointerEvents = 'none'
        pickerRef.current.shouldResetBodyPointerEvents = false
      }
    }

    setIntegrationFilePickerOpenState(null)
  }, [setIntegrationFilePickerOpenState])

  useEffect(() => {
    if (shouldShowPicker) {
      Services.HoneyComb.Record({
        metric: INTEGRATION_FILE_PICKER_CLICKED_METRIC,
        user_id: userInfo.id,
        workspace: userInfo.workspace.id,
        integration: integrationType,
      })
      trackEvent('Google Drive File Picker Clicked')
    }
  }, [shouldShowPicker, trackEvent, userInfo.id, userInfo.workspace.id])

  useEffect(() => {
    if (connectionState === OauthConnectState.CONNECTION_TIMED_OUT) {
      closePicker()
    } else if (connectionState === OauthConnectState.CONNECTION_FAILED) {
      displayErrorMessage(
        'We encountered an error while connecting to Google Drive. Please try again.'
      )
    }
  }, [connectionState, closePicker])

  const [loading, setLoading] = useState(false)
  // gdrive picker closes prior to downloading files, unlike sharepoint
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(false)

  const fetchGoogleDriveAccessToken = useCallback(
    async (silent: boolean = false) => {
      try {
        const response = await fetchIntegrationToken(integrationType)
        if (_.isNil(response)) {
          return null
        }
        setIntegrationToken(integrationType, response)
        return response.accessToken
      } catch (error) {
        console.error('Error fetching access token', error)
        if (!silent) {
          displayErrorMessage('Error fetching access token.')
        }
        return null
      }
    },
    [setIntegrationToken]
  )

  const getToken = useCallback(async () => {
    const integrationToken = getIntegrationToken(integrationType)
    if (
      _.isNil(integrationToken) ||
      isPast(parseIsoString(integrationToken.expiresAt))
    ) {
      return await fetchGoogleDriveAccessToken(true)
    }
    return integrationToken.accessToken
  }, [getIntegrationToken, fetchGoogleDriveAccessToken])

  const onResults = useCallback(
    async (results: google.picker.ResponseObject) => {
      // NOTE: picker is closed automatically when onResults is called

      const action = results[google.picker.Response.ACTION]
      if (action === google.picker.Action.PICKED) {
        try {
          setIsDownloadingFiles(true)
          const integrationToken = getIntegrationToken(integrationType)
          if (
            _.isNil(integrationToken) ||
            _.isNil(integrationFilePickerOpenState) ||
            _.isNil(integrationFilePickerOpenState.onUploadFromIntegration)
          ) {
            throw new Error('Integration configuration failed.')
          }
          const files = await downloadGoogleDriveFiles(
            results[google.picker.Response.DOCUMENTS] ?? [],
            integrationToken.accessToken,
            integrationFilePickerOpenState.acceptedFileTypes
          )
          if (_.isEmpty(files)) {
            throw new Error('Error downloading files from Google Drive.')
          }
          await integrationFilePickerOpenState.onUploadFromIntegration(files)
          Services.HoneyComb.Record({
            metric: INTEGRATION_FILES_DOWNLOADED_METRIC,
            user_id: userInfo.id,
            workspace: userInfo.workspace.id,
            integration: integrationType,
            filesCount: files.length,
          })
          trackEvent(`${integrationType} files downloaded`)
        } catch (error) {
          console.error('Error downloading Google Drive files', error)
          displayErrorMessage('An unexpected error occurred with Google Drive.')
        } finally {
          setIsDownloadingFiles(false)
          closePicker() // just to be safe, but picker is closed when onResults is called here
        }
      } else if (action === google.picker.Action.CANCEL) {
        closePicker()
      } else if (action === google.picker.Action.ERROR) {
        displayErrorMessage('An unexpected error occurred with Google Drive.')
        closePicker()
      }
      // ignore other actions like LOADED
    },
    [
      getIntegrationToken,
      integrationFilePickerOpenState,
      userInfo.id,
      userInfo.workspace.id,
      closePicker,
      trackEvent,
    ]
  )

  // TODO fix prevent default when scrolling inside picker
  useEffect(() => {
    const maybeInitializePicker = async () => {
      if (!shouldShowPicker) {
        return
      }

      setLoading(true)
      try {
        ensureGoogleApiLoaded()
        let accessToken = await getToken()
        if (_.isNil(accessToken)) {
          await handleConnect()
          accessToken = await getToken()
        }

        if (_.isNil(accessToken)) {
          closePicker()
          return
        }

        const googleDriveNativeFileTypes = Object.keys(
          GOOGLE_DRIVE_NATIVE_FILE_MIME_TYPE_MAPPING
        ).filter((key) => {
          const value = GOOGLE_DRIVE_NATIVE_FILE_MIME_TYPE_MAPPING[key]
          return integrationFilePickerOpenState.acceptedFileTypes.includes(
            value.valueOf()
          )
        })

        const acceptedFileTypes = new Set([
          ...integrationFilePickerOpenState.acceptedFileTypes,
          ...googleDriveNativeFileTypes,
        ])

        const pickerBuilder = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setOAuthToken(accessToken)
          // use google.picker.ViewId.DOCS view for all documents
          .addView(
            new google.picker.DocsView()
              .setMimeTypes(Array.from(acceptedFileTypes).join(','))
              .setMode(google.picker.DocsViewMode.LIST)
              .setIncludeFolders(true)
              .setSelectFolderEnabled(false)
          )
          .setAppId(googleDriveIntegrationAppId)
          .setDeveloperKey(googleDriveIntegrationApiKey)
          .setCallback(onResults)
          .setTitle('Select files')
        // don't set size so that picker will automatically size itself

        if (!_.isNil(integrationFilePickerOpenState.maxFileCount)) {
          pickerBuilder.setMaxItems(integrationFilePickerOpenState.maxFileCount)
        }

        const picker = pickerBuilder.build()
        pickerRef.current.instance = picker
        picker.setVisible(true)

        if (document.body.style.pointerEvents === 'none') {
          // when dialog is open, body component has pointer-events: none;
          // this is set automatically by radix dialog
          // that needs to be reset so that picker can be used
          document.body.style.pointerEvents = ''
          pickerRef.current.shouldResetBodyPointerEvents = true
        }

        Services.HoneyComb.Record({
          metric: INTEGRATION_FILE_PICKER_OPENED_METRIC,
          user_id: userInfo.id,
          workspace: userInfo.workspace.id,
          integration: integrationType,
        })
        trackEvent('Google Drive File Picker Opened')
      } catch (error) {
        console.error('Error initializing Google Drive picker', error)
      } finally {
        setLoading(false)
      }
    }
    void maybeInitializePicker()
  }, [
    closePicker,
    getToken,
    handleConnect,
    integrationFilePickerOpenState,
    onResults,
    shouldShowPicker,
    trackEvent,
    userInfo.id,
    userInfo.workspace.id,
  ])

  // not 100% necessary but just to be safe
  // at the moment, this component never unmounts, so this is how we ensure picker is closed / cleaned up
  useEffect(() => {
    if (
      _.isNil(integrationFilePickerOpenState) &&
      !_.isNil(pickerRef.current.instance)
    ) {
      closePicker()
    }
  }, [integrationFilePickerOpenState, closePicker])

  const showLoader = loading || isDownloadingFiles

  return shouldShowPicker || showLoader ? (
    <>
      <div className="pointer-events-auto fixed inset-0 z-[100] flex items-center justify-center bg-primary/80 px-4 py-4 backdrop-blur-sm">
        {(_.isNil(getIntegrationToken(integrationType)) || showLoader) && (
          <div
            className={
              loading
                ? 'pointer-events-auto relative flex items-center justify-center rounded-lg bg-secondary'
                : ''
            }
            style={
              loading
                ? {
                    height: '30vw',
                    width: '50vw',
                    maxWidth: '1051px',
                    maxHeight: '650px',
                  }
                : {}
            }
          >
            {loading && (
              <Button
                onClick={() => {
                  setLoading(false)
                  closePicker()
                  handleCancelConnect()
                }}
                className="ring-offset-background absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100"
                aria-label="Close"
                variant="unstyled"
              >
                <Icon icon={X} />
              </Button>
            )}
            <div className="flex flex-col items-center gap-6">
              <Loader2 className="size-8 animate-spin" />
              {loading && (
                <p className="text-white text-sm">Authenticating...</p>
              )}
              {isDownloadingFiles && (
                <p className="text-white text-sm">Downloading files...</p>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  ) : null
}

export default GoogleDriveFilePicker
