import React, { useCallback } from 'react'

import { HTTPError } from 'ky'
import { useShallow } from 'zustand/react/shallow'

import { displayErrorMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { useVaultCreateProjectStore } from 'components/vault/utils/vault-create-project-store'
import { CreateVaultFolder } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'

import { VaultDialogHeader } from './vault-dialog-header'

const VaultCreateFolderDialog: React.FC = () => {
  const { trackEvent } = useAnalytics()
  const userInfo = useAuthUser()

  const [
    folderIdToVaultFolder,
    currentCreateFolderFolderId,
    isCreateFolderDialogOpen,
    currentProject,
    setIsCreateFolderDialogOpen,
    upsertVaultFolders,
  ] = useVaultStore(
    useShallow((s) => [
      s.folderIdToVaultFolder,
      s.currentCreateFolderFolderId,
      s.isCreateFolderDialogOpen,
      s.currentProject,
      s.setIsCreateFolderDialogOpen,
      s.upsertVaultFolders,
    ])
  )

  const [newFolderName, setNewFolderName, isSubmitting, setIsSubmitting] =
    useVaultCreateProjectStore(
      useShallow((s) => [
        s.newFolderName,
        s.setNewFolderName,
        s.isSubmitting,
        s.setIsSubmitting,
      ])
    )

  const currentFolder = currentCreateFolderFolderId
    ? folderIdToVaultFolder[currentCreateFolderFolderId]
    : undefined

  const dismissDialog = useCallback(() => {
    setIsSubmitting(false)
    setIsCreateFolderDialogOpen(false)
    setNewFolderName('')
  }, [setIsSubmitting, setIsCreateFolderDialogOpen, setNewFolderName])

  const handleCreateFolder = async () => {
    if (newFolderName.includes('/') || newFolderName.includes('\\')) {
      displayErrorMessage(
        'Folder name is not valid: it cannot contain “/” or “\\”'
      )
      return
    }
    trackEvent('Vault Folder Created', {
      is_subfolder: !!currentFolder?.parentId,
    })
    setIsSubmitting(true)
    try {
      const newFolder = await CreateVaultFolder(
        newFolderName.trim(),
        currentCreateFolderFolderId
      )
      upsertVaultFolders([newFolder], userInfo.dbId, false, currentProject?.id)
      dismissDialog()
    } catch (e) {
      if (e instanceof HTTPError && e.response.status === 409) {
        displayErrorMessage('Folder name already exists.')
      } else if (e instanceof HTTPError) {
        displayErrorMessage('Something went wrong. Could not create folder.')
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Dialog open={isCreateFolderDialogOpen}>
      <DialogContent showCloseIcon={false}>
        <VaultDialogHeader
          dialogTitle="Create new folder"
          dialogDescription="Add a new sub-folder to"
          currentFolder={currentFolder || null}
        />
        <div className="flex flex-col space-y-2">
          <Label className="font-normal">Folder name</Label>
          <Input
            value={newFolderName}
            disabled={isSubmitting}
            onChange={(e) => setNewFolderName(e.target.value)}
            maxLength={64}
            data-testid="vault-create-folder-dialog--folder-name-input"
          />
        </div>
        <div className="mt-6 space-y-2 self-end">
          <div className="flex justify-end space-x-2">
            <Button
              variant="ghost"
              disabled={isSubmitting}
              onClick={dismissDialog}
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitting || newFolderName.trim().length === 0}
              onClick={handleCreateFolder}
              data-testid="vault-create-folder-dialog--create-button"
            >
              {isSubmitting ? 'Creating…' : 'Create'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultCreateFolderDialog
