export interface Step {
  stepIdx: number
  [key: string]: any
}

/**
 * Merges `newSteps` into `existingSteps` by matching on `stepIdx`.
 * - Existing steps are updated if they share the same `stepIdx`.
 * - New steps that are not in existingSteps are appended.
 * - The result is sorted by `stepIdx`.
 */
export const mergeSteps = (existingSteps: Step[], newSteps: Step[]): Step[] => {
  // Update existing steps if there's a matching step
  const mergedSteps = existingSteps.map((oldStep) => {
    const updatedStep = newSteps.find((s) => s.stepIdx === oldStep.stepIdx)
    return updatedStep ? { ...oldStep, ...updatedStep } : oldStep
  })

  // Append any new steps that don't exist yet
  newSteps.forEach((step) => {
    const exists = mergedSteps.some((s) => s.stepIdx === step.stepIdx)
    if (!exists) {
      mergedSteps.push({ ...step })
    }
  })

  // Sort by stepIdx
  mergedSteps.sort((a, b) => (a.stepIdx ?? 0) - (b.stepIdx ?? 0))

  return mergedSteps
}
