import * as React from 'react'

import { RelatedQuestion } from 'openapi/models/RelatedQuestion'

import { useAnalytics } from 'components/common/analytics/analytics-context'

interface FollowUpsProps {
  followUps: RelatedQuestion[]
  onSelectQuestion: (text: string) => void
  disabled?: boolean
}

const FollowUps: React.FC<FollowUpsProps> = ({
  followUps,
  onSelectQuestion,
  disabled,
}) => {
  const { trackEvent } = useAnalytics()
  const handleSelectQuestion = (text: string) => {
    if (disabled) return
    trackEvent('Follow-up Selected', {
      query_length: text.length,
    })
    onSelectQuestion(text)
  }
  return (
    <div className="flex w-full flex-col items-start">
      <p className="flex h-12 items-center font-medium">Follow-ups</p>
      {followUps.map((followUp) => (
        <button
          key={followUp.text}
          onClick={() => handleSelectQuestion(followUp.text)}
          className="group relative -mx-2 flex w-full items-center justify-start rounded-sm px-2 transition hover:bg-button-secondary disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-transparent"
          disabled={disabled}
        >
          <p className="py-[10px] text-left text-sm">{followUp.text}</p>
          <hr className="absolute inset-x-2 bottom-0 group-last:hidden" />
        </button>
      ))}
    </div>
  )
}

export default FollowUps
