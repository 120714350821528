import React, { useState } from 'react'

import { Pencil } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { Button } from 'components/ui/button'
import { projectAsItem } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface VaultProjectTitleProps {
  title: string
  canEditTitle: boolean
}
const VaultProjectTitle = ({ title, canEditTitle }: VaultProjectTitleProps) => {
  const [hovered, setHovered] = useState(false)

  const [currentProject, setIsRenameDialogOpen, setRenameRecord] =
    useVaultStore(
      useShallow((s) => [
        s.currentProject,
        s.setIsRenameDialogOpen,
        s.setRenameRecord,
      ])
    )

  const handleEditTitle = () => {
    if (!currentProject) return
    const projectItem = projectAsItem(currentProject, 0, 0)
    setRenameRecord(projectItem)
    setIsRenameDialogOpen(true)
  }

  return (
    <div className="flex items-center gap-1">
      <p className="line-clamp-2 text-xl font-medium">{title}</p>
      {canEditTitle && (
        <Button
          variant="ghost"
          size="xsIcon"
          onClick={handleEditTitle}
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          <Pencil
            size={12}
            stroke={hovered ? 'currentColor' : 'hsl(var(--neutral-500))'}
          />
        </Button>
      )}
    </div>
  )
}

export default VaultProjectTitle
