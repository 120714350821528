import * as React from 'react'
import { useRef } from 'react'
import { Control, Controller } from 'react-hook-form'

import { LanguageCode } from 'openapi/models/LanguageCode'

import { getLanguageText } from 'utils/user-profile-helpers'

import Combobox from 'components/ui/combobox/combobox'
import { Input } from 'components/ui/input'

interface ProfileLanguageFormProps {
  control: Control<any>
}

const LANGUAGE_OPTIONS = Object.values(LanguageCode).map((code) => ({
  label: getLanguageText(code),
  value: code,
}))

const SPECIAL_OPTIONS = LANGUAGE_OPTIONS.filter(
  (code) => code.label.startsWith('English') || code.label.startsWith('Spanish')
).sort((a, b) => a.label.localeCompare(b.label))

const REST_OPTIONS = LANGUAGE_OPTIONS.filter(
  (code) => !SPECIAL_OPTIONS.includes(code)
).sort((a, b) => a.label.localeCompare(b.label))

const OTHER_OPTION = {
  label: 'Other',
  value: 'other',
}

const OPTIONS = [...SPECIAL_OPTIONS, ...REST_OPTIONS, OTHER_OPTION]

export const FORM_HEADING = 'What is your preferred language?'
export const FORM_DESCRIPTION =
  'Harvey replies in the language of your prompt but will use a specific dialect (e.g., American English, British English) if specified.'

const ProfileLanguageForm: React.FC<ProfileLanguageFormProps> = ({
  control,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={containerRef}>
      <Controller
        name="preferredLanguage"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div className="space-y-2">
            <Controller
              name="showOther"
              control={control}
              defaultValue={false}
              render={({
                field: { value: showOther, onChange: onShowOtherChange },
              }) => (
                <>
                  <Combobox
                    className="w-80"
                    maxHeight="max-h-96"
                    value={showOther ? OTHER_OPTION.value : value}
                    setValue={(newValue) => {
                      if (newValue === OTHER_OPTION.value) {
                        onShowOtherChange(true)
                        if (
                          LANGUAGE_OPTIONS.some((opt) => opt.value === value)
                        ) {
                          onChange('')
                        }
                      } else {
                        onShowOtherChange(false)
                        onChange(newValue)
                      }
                    }}
                    options={OPTIONS}
                    defaultText="Select your primary language"
                    emptyStateText="Language not found"
                    popoverMenuItemTextClassName="truncate"
                    ariaLabel="Preferred language"
                    id="preferred-language-select"
                    containerRef={containerRef}
                  />
                  {showOther && (
                    <Input
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      id="other-value"
                      value={value || ''}
                      onChange={(e) => onChange(e.target.value)}
                      placeholder="Enter your language"
                      className="mt-2 w-80"
                    />
                  )}
                </>
              )}
            />
            {error && (
              <p className="text-sm font-medium text-destructive">
                {error.message}
              </p>
            )}
          </div>
        )}
      />
    </div>
  )
}

export default ProfileLanguageForm
