import React from 'react'

import { BookOpen } from 'lucide-react'

import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import Link from 'components/ui/link/link'
import { newProjectPath } from 'components/vault/utils/vault'

interface Props {
  id?: string
  isKnowledgeBaseProject?: boolean
  className?: string
  height?: number
  size?: 'small' | 'default'
  source?: string // Source for analytics
  onClick?: () => void
}

export const VaultCreateProjectCard = ({
  id,
  isKnowledgeBaseProject = false,
  className,
  height,
  size = 'default',
  source,
  onClick,
}: Props) => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const isKnowledgeBaseProjectUser = userInfo.IsKnowledgeBaseProjectUser

  const isFromWorkflowModal = source === 'workflow-modal'

  const projectFilesCountLimitDisplayText = `Each project can contain up to ${userInfo.workspace
    .getVaultFilesCountLimit(userInfo.vaultFeature)
    .toLocaleString()} files`

  const cardIcon = isKnowledgeBaseProject ? (
    <Icon icon={BookOpen} size="large" className="text-muted" />
  ) : (
    <FolderShieldIcon className="size-6 text-muted" />
  )

  const cardHeader = (() => {
    if (isKnowledgeBaseProject) {
      return 'Create knowledge base'
    } else if (userInfo.IsCreateKnowledgeBaseProjectUser) {
      return 'Create project'
    } else {
      return 'New project'
    }
  })()

  const cardDescription = (() => {
    if (isKnowledgeBaseProject) {
      return 'Distribute a repository of files to your organization'
    } else if (userInfo.IsCreateKnowledgeBaseProjectUser) {
      return 'Upload a new collection of files'
    } else {
      return projectFilesCountLimitDisplayText
    }
  })()

  if (isKnowledgeBaseProjectUser) {
    return (
      <Button
        id={id}
        variant="outline"
        to={
          isFromWorkflowModal
            ? undefined
            : `${BaseAppPath.Vault}${newProjectPath}${
                isKnowledgeBaseProject ? '?isKnowledgeBaseProject=true' : ''
              }`
        }
        onClick={
          onClick ??
          (() => {
            trackEvent('Vault New Project Button Clicked', {
              source: source ?? 'new-project-card',
            })
          })
        }
        data-testid="vault-new-project-card-link"
        className={cn(
          'relative flex h-36 w-full flex-col items-start justify-between rounded-lg border p-4 hover:cursor-pointer hover:border-input-focused',
          className
        )}
      >
        {cardIcon}
        <div className="text-start">
          <p className="text-sm font-medium">{cardHeader}</p>
          <p className="text-xs text-muted">{cardDescription}</p>
        </div>
      </Button>
    )
  }

  return (
    <div
      id={id}
      style={{
        height,
      }}
      className={cn(
        'relative flex w-full flex-col items-center justify-center rounded-lg border border-dashed p-6 shadow-sm hover:cursor-pointer hover:border-input-focused',
        className
      )}
    >
      {cardIcon}
      <Link
        to={`${BaseAppPath.Vault}${newProjectPath}${
          isKnowledgeBaseProject ? '?isKnowledgeBaseProject=true' : ''
        }`}
        onClick={() => {
          trackEvent('Vault New Project Button Clicked', {
            source: source ?? 'new-project-card',
          })
        }}
        className={cn('apply-click-on-parent mt-2', {
          'text-sm': size === 'small',
        })}
        data-testid="vault-new-project-card-link"
      >
        {cardHeader}
      </Link>
      <p className="mt-1 text-center text-xs text-muted">{cardDescription}</p>
    </div>
  )
}
