import React from 'react'

interface KnowledgeSourceFeedbackUrlProps {
  url: string
}

const KnowledgeSourceFeedbackUrl: React.FC<KnowledgeSourceFeedbackUrlProps> = ({
  url,
}) => (
  <p>
    If you would like to provide more detailed feedback or suggest new sources,
    please use{' '}
    <a
      href={url}
      className="text-sm underline"
      rel="noreferrer"
      target="_blank"
    >
      this form
    </a>
    .
  </p>
)

export default KnowledgeSourceFeedbackUrl
