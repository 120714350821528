import React from 'react'

import { FileType, FileTypeToIcon } from 'types/file'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'

interface DocumentCardProps {
  fileType?: FileType
  title: string
  description: string
  onClick?: () => void
  isLoading?: boolean
  selected?: boolean
}

const DocumentCard: React.FC<DocumentCardProps> = ({
  fileType,
  title,
  description,
  onClick,
  isLoading,
  selected,
}) => {
  let FileIcon = FileTypeToIcon.Unknown
  if (fileType && Object.keys(FileTypeToIcon).includes(fileType)) {
    FileIcon = FileTypeToIcon[fileType as keyof typeof FileTypeToIcon]
  }

  const disableClick = !onClick || isLoading

  return (
    <Button
      className={cn(
        'group flex h-auto justify-start rounded-lg border bg-primary py-[10px] pl-3 pr-7 transition',
        selected && 'border-selected bg-secondary',
        !disableClick && 'hover:bg-secondary disabled:hover:bg-primary',
        !disableClick &&
          !selected &&
          'hover:border-ring disabled:hover:border-primary',
        disableClick && 'cursor-default'
      )}
      onClick={!disableClick ? onClick : undefined}
      variant="unstyled"
      disabled={disableClick}
    >
      <div
        className={cn(
          'flex items-center gap-[10px] transition duration-75',
          !disableClick && 'group-active:opacity-70'
        )}
      >
        <div className="flex size-10 shrink-0 items-center justify-center">
          {isLoading ? (
            <Spinner size="sm" className="mr-0 fill-primary" />
          ) : (
            <FileIcon className="size-10" />
          )}
        </div>
        <div className="flex max-w-[240px] flex-col items-start gap-0.5">
          <p className="line-clamp-2 text-left text-xs font-medium">{title}</p>
          <p className="line-clamp-1 text-left text-2xs text-muted">
            {description}
          </p>
        </div>
      </div>
    </Button>
  )
}

export default DocumentCard
