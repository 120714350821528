/* eslint-disable */
import React, { useState } from 'react'

import * as _ from 'lodash'
import { ChevronDown, ChevronUp } from 'lucide-react'

import { ResearchFilter } from 'openapi/models/ResearchFilter'
import { TaxCorpusFilterIDs } from 'openapi/models/TaxCorpusFilterIDs'
import Services from 'services'

import { useAuthUser } from 'components/common/auth-context'
import FeedbackSubmittedBanner from 'components/common/feedback/feedback-submitted-banner'
import { anyNodeHasL0Ancestor } from 'components/research/research-helpers'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import * as Card from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import { Label } from 'components/ui/label'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { Textarea } from 'components/ui/text-area'

import KnowledgeSourceFeedbackUrl from './knowledgesource-feedback-url'

enum Helpfulness {
  FULLY = 'fully_helpful',
  VERY = 'very_helpful',
  PARTIAL = 'partially_helpful',
  NOT = 'not_helpful',
}

const HELPFULNESS_LABEL: Record<Helpfulness, string> = {
  [Helpfulness.FULLY]:
    'Fully helpful – The answer fully addressed my question.',
  [Helpfulness.VERY]:
    'Very helpful – The answer saved me significant time with a comprehensive starting point.',
  [Helpfulness.PARTIAL]:
    'Partially helpful – The answer saved me some time with useful information to get started.',
  [Helpfulness.NOT]: 'Not helpful – The answer was not helpful.',
}

interface FeedbackKnowledgeSourceMeta {
  comments: string
  helpfulness: Helpfulness | null
  positiveReferences: string
}

export interface FeedbackKnowledgeSourceProps {
  queryId: string
  selectedFilters: ResearchFilter[]
  allFilterNodes: ResearchFilter[]
}

const FeedbackKnowledgeSource: React.FC<FeedbackKnowledgeSourceProps> = ({
  queryId,
  selectedFilters,
  allFilterNodes,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [meta, setMeta] = useState<FeedbackKnowledgeSourceMeta>({
    comments: '',
    helpfulness: null,
    positiveReferences: '',
  })
  const userInfo = useAuthUser()

  const handleSubmit = async (): Promise<void> => {
    const sentiment =
      meta.helpfulness !== Helpfulness.NOT && meta.helpfulness !== null ? 1 : -1
    const { comments, ...feedbackMeta } = meta
    const data = {
      sentiment,
      comments,
      feedbackMeta,
    }

    setIsSubmitted(true)
    await Services.Backend.Post<any>(`feedback/${queryId}`, data, {
      throwOnError: true,
    })
      .then((res) => res !== true && setError(true))
      .catch(() => setError(true))
  }

  const collapse = () => {
    setIsExpanded((prev) => !prev)
    setMeta({
      comments: '',
      helpfulness: null,
      positiveReferences: '',
    })
  }
  // don't show more extensive feedback options to downstream clients of PWC
  const isInternalOrPwcUser = userInfo.IsInternalUser || userInfo.IsPwcUser
  // TODO: UI would change if the user changes their selected filters, so we should freeze the filters that were submitted with the query
  const isUkTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.UK_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isJapanTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.JAPAN_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isAustraliaTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.AUSTRALIA_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isPillarTwoTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.PILLAR_TWO_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isWWTSTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.WWTS,
    selectedFilters,
    allFilterNodes
  )
  const isIndiaTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.INDIA_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isIrelandTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.IRELAND_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isSwitzerlandTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.SWITZERLAND_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isSwedenTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.SWEDEN_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isKoreaTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.KOREA_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isItalyTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.ITALY_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isUaeTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.UAE_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isKsaTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.KSA_TAX,
    selectedFilters,
    allFilterNodes
  )
  const isHongKongTaxFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.HONG_KONG_TAX,
    selectedFilters,
    allFilterNodes
  )

  const isLefebvreFilterSelected = anyNodeHasL0Ancestor(
    TaxCorpusFilterIDs.LEFEBVRE,
    selectedFilters,
    allFilterNodes
  )

  // BE SURE TO UPDATE RETAIN_FOREVER_FOR_PWC in backend. We can only accept written feedback from the frontend if we're actually storing the data in backend.
  const canStoreWrittenFeedback =
    isJapanTaxFilterSelected ||
    isWWTSTaxFilterSelected ||
    isIndiaTaxFilterSelected ||
    isIrelandTaxFilterSelected ||
    isSwitzerlandTaxFilterSelected ||
    isSwedenTaxFilterSelected ||
    isPillarTwoTaxFilterSelected ||
    isKoreaTaxFilterSelected ||
    isItalyTaxFilterSelected ||
    isUaeTaxFilterSelected ||
    isKsaTaxFilterSelected ||
    isHongKongTaxFilterSelected

  const feedbackUrls = [
    {
      filter: isUkTaxFilterSelected,
      url: 'https://forms.gle/BTp3deRDApHcwRRBA',
    },
    {
      filter: isAustraliaTaxFilterSelected,
      url: 'https://forms.office.com/r/9FpSdQR7Ev',
    },
    {
      filter: isPillarTwoTaxFilterSelected,
      url: 'https://forms.office.com/pages/responsepage.aspx?id=oJQyUSA-skGpcG0wvxVG-qed4MlCKIlOouGIdRbDGHNUM0RVTFRPV0JMRk43MkhHUjRIMjRTVVhCNCQlQCN0PWcu&route=shorturl',
    },
    {
      filter: isItalyTaxFilterSelected,
      url: 'https://docs.google.com/forms/d/e/1FAIpQLSe6fzZHdoFpeQ2MbOh5dJxX77F4ir8eyDEUt6NvxuW6Ko5z2Q/viewform',
    },
    {
      filter: isKsaTaxFilterSelected,
      url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=oJQyUSA-skGpcG0wvxVG-rqR5PhlOcZOnXYsrG0ePR9UQjdJVEs5VDJaQk1SQUlJVUNTQ0k2S0EzUC4u',
    },
    {
      filter: isHongKongTaxFilterSelected,
      url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=oJQyUSA-skGpcG0wvxVG-rqR5PhlOcZOnXYsrG0ePR9UMFdSWDVFOE80WjY0T1VSV0ZTTjJTRDJBUC4u',
    },
    {
      filter: isLefebvreFilterSelected,
      url: 'https://docs.google.com/forms/d/1iBd4m8GvAQBU8ufWkK8a1hAoqU-CFes9lbxoo32f8Yw/viewform',
    },
  ]

  return (
    <Card.Card
      className="relative space-y-2 p-4 transition"
      data-testid="input-panel--tax-feedback-card"
    >
      {!isSubmitted && (
        <>
          <div
            className="flex w-full cursor-pointer items-center justify-between"
            onClick={collapse}
          >
            <h3 className="text-sm">What did you think of the response?</h3>
            <Button
              variant="ghost"
              size="xsIcon"
              data-testid="tax-expand-feedback-btn"
            >
              <Icon icon={isExpanded ? ChevronUp : ChevronDown} />
            </Button>
          </div>
          <BasicTransition
            className="flex flex-col space-y-2"
            show={isExpanded}
          >
            <RadioGroup data-testid="tax-feedback-checkbox">
              {Object.values(Helpfulness).map((helpfulnessValue) => (
                <RadioGroupItem
                  className="h-6 space-x-2"
                  key={helpfulnessValue}
                  id={helpfulnessValue}
                  label={HELPFULNESS_LABEL[helpfulnessValue]}
                  value={helpfulnessValue}
                  checked={meta.helpfulness === helpfulnessValue}
                  onClick={() =>
                    setMeta({ ...meta, helpfulness: helpfulnessValue })
                  }
                />
              ))}
            </RadioGroup>

            {canStoreWrittenFeedback && (
              <div className="space-y-2">
                <Label htmlFor="feedback-explain" className="font-normal">
                  Explain why you chose your answer
                </Label>
                <Textarea
                  id="feedback-explain"
                  placeholder="Explain why you chose your answer"
                  onChange={(e) => {
                    setMeta({ ...meta, comments: e.target.value })
                  }}
                />
              </div>
            )}

            {canStoreWrittenFeedback && (
              <div className="space-y-2">
                <Label
                  htmlFor="feedback-positive-references"
                  className="font-normal"
                >
                  If there are any references you would have expected to see in
                  your answer, please paste the links here.
                </Label>
                <Textarea
                  id="feedback-positive-references"
                  placeholder="Please paste links to the relevant sources, one per line."
                  onChange={(e) => {
                    setMeta({ ...meta, positiveReferences: e.target.value })
                  }}
                />
              </div>
            )}
            {isInternalOrPwcUser &&
              feedbackUrls.map(
                ({ filter, url }) =>
                  filter && <KnowledgeSourceFeedbackUrl url={url} />
              )}
            <div className="flex w-full justify-end">
              <Button
                onClick={() => {
                  void handleSubmit()
                }}
              >
                Submit
              </Button>
            </div>
          </BasicTransition>
        </>
      )}
      {isSubmitted && <FeedbackSubmittedBanner error={error} />}
    </Card.Card>
  )
}

export default FeedbackKnowledgeSource
