import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ReactTyped } from 'react-typed'

import { User } from 'lucide-react'

import { EventStatus } from 'openapi/models/EventStatus'

import { cn } from 'utils/utils'

import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import HarveyLogo from 'components/common/harvey-logo'
import { Icon } from 'components/ui/icon/icon'

export const AssistantWorkflowLayoutContainer: React.FC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  return (
    <div className={cn('mr-9 flex items-start space-x-3', className)}>
      {children}
    </div>
  )
}

export const AssistantWorkflowLayoutIconComponent: React.FC<{
  children?: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'flex size-6 shrink-0 items-center justify-center rounded-full',
        className
      )}
    >
      {children}
    </div>
  )
}

export const AssistantWorkflowYouComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <AssistantWorkflowLayoutContainer>
      <AssistantWorkflowLayoutIconComponent className="border">
        <Icon icon={User} className="size-4 text-inactive" />
      </AssistantWorkflowLayoutIconComponent>
      <div className="flex-1">{children}</div>
    </AssistantWorkflowLayoutContainer>
  )
}

export const AssistantWorkflowHarveyComponent: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <AssistantWorkflowLayoutContainer>
      <AssistantWorkflowLayoutIconComponent className="bg-accent">
        <HarveyLogo className="size-3" />
      </AssistantWorkflowLayoutIconComponent>
      <div className="flex-1">{children}</div>
    </AssistantWorkflowLayoutContainer>
  )
}

export const AssistantWorkflowThreadText: React.FC<{
  text: string
  completionStatus: EventStatus
}> = ({ text, completionStatus }) => {
  const [hasDoneInitialTypewriter, setHasDoneInitialTypewriter] =
    useState(false)

  // TODO: PENDING isn't in EventStatus?
  const doTypewriter =
    ['PENDING', EventStatus.IN_PROGRESS].includes(completionStatus) &&
    !hasDoneInitialTypewriter

  return doTypewriter ? (
    <ReactTyped
      className="text-sm text-primary *:text-sm"
      strings={[text]}
      typeSpeed={2}
      showCursor={!hasDoneInitialTypewriter}
      onComplete={() => setHasDoneInitialTypewriter(true)}
    />
  ) : (
    <span className="text-sm text-primary">{text}</span>
  )
}

export const AssistantWorkflowThreadBlock: React.FC<{
  children: React.ReactNode
  sidebar?: React.ReactNode
}> = ({ children, sidebar }) => {
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)
  // 324x is 288 (width of citations) + 36px (margin)
  const sideColumnWidth = fileId ? 'minmax(24px, 1fr)' : 'minmax(324px, 1fr)'
  return (
    <div
      className="grid"
      style={{
        gridTemplateColumns: `${sideColumnWidth} minmax(676px, 1000px) ${sideColumnWidth}`,
      }}
    >
      <div />
      {/* can remove the zindex when layout is fixed */}
      <div className="z-10 mx-auto w-full space-y-4">{children}</div>
      <div className="relative">{sidebar}</div>
    </div>
  )
}
