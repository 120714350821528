/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoadingStateStatus } from './LoadingStateStatus';
import {
    LoadingStateStatusFromJSON,
    LoadingStateStatusFromJSONTyped,
    LoadingStateStatusToJSON,
    LoadingStateStatusToJSONTyped,
} from './LoadingStateStatus';

/**
 * 
 * @export
 * @interface LoadingState
 */
export interface LoadingState {
    /**
     * 
     * @type {string}
     * @memberof LoadingState
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof LoadingState
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LoadingState
     */
    text?: string;
    /**
     * 
     * @type {LoadingStateStatus}
     * @memberof LoadingState
     */
    status?: LoadingStateStatus;
}



/**
 * Check if a given object implements the LoadingState interface.
 */
export function instanceOfLoadingState(value: object): value is LoadingState {
    if (!('uuid' in value) || value['uuid'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function LoadingStateFromJSON(json: any): LoadingState {
    return LoadingStateFromJSONTyped(json, false);
}

export function LoadingStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoadingState {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : LoadingStateStatusFromJSON(json['status']),
    };
}

export function LoadingStateToJSON(json: any): LoadingState {
    return LoadingStateToJSONTyped(json, false);
}

export function LoadingStateToJSONTyped(value?: LoadingState | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'uuid': value['uuid'],
        'name': value['name'],
        'text': value['text'],
        'status': LoadingStateStatusToJSON(value['status']),
    };
}

