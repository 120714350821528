import React, { useCallback, useEffect, useState } from 'react'

import _ from 'lodash'
import { Info } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import {
  setIntegrationEnablement,
  updateIntegrationResourceUrl,
} from 'models/workspace'
import { IntegrationType } from 'openapi/models/IntegrationType'
import { instanceOfSynclyIntegration } from 'openapi/models/SynclyIntegration'
import Services from 'services'
import { useIntegrationsStore } from 'stores/integrations-store'
import { Maybe } from 'types'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useSettingsState } from 'components/settings/settings-store'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import { Separator } from 'components/ui/separator'
import { Switch } from 'components/ui/switch'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import {
  INTEGRATION_ENABLEMENT_METRIC,
  INTEGRATION_UPDATE_RESOURCE_URL_METRIC,
  INTEGRATION_DISCONNECTED_METRIC,
  INTEGRATION_CONNECTED_METRIC,
  INTEGRATION_CONNECTION_INITIATED_METRIC,
} from './constants'
import { IntegrationDefinition } from './integration-definitions'
import IntegrationDisableConfirmationDialog from './integration-disable-confirmation-dialog'
import IntegrationEnableConfirmationDialog from './integration-enable-confirmation-dialog'
import IntegrationResourceUrlDialog from './integration-resourceurl-dialog'
import { authenticateSynclyAuthClient } from './syncly-utils'
import { OauthConnectState, useOauthConnect } from './use-oauth-connect'
import { disconnectIntegration } from './utils'

interface IntegrationAdminCardProps {
  integrationType: IntegrationType
  enabled: boolean
  connected: boolean
  workspaceId: number
  integrationDefinition: IntegrationDefinition
}

const IntegrationAdminCard: React.FC<IntegrationAdminCardProps> = ({
  integrationType,
  enabled,
  workspaceId,
  connected,
  integrationDefinition,
}) => {
  const settingsUser = useSettingsState((s) => s.settingsUser)
  const [isEnabled, setIsEnabled] = useState(enabled)
  const [isConnected, setIsConnected] = useState(connected)
  const [isEnableConfirmationOpen, setIsEnableConfirmationOpen] =
    useState(false)
  const [isDisableConfirmationOpen, setIsDisableConfirmationOpen] =
    useState(false)
  const [isResourceUrlDialogOpen, setIsResourceUrlDialogOpen] = useState(false)
  const currentResourceUrl =
    settingsUser?.workspace.getIntegrationResourceUrl(integrationType)
  const [resourceUrl, setResourceUrl] = useState(currentResourceUrl || '')
  const { trackEvent } = useAnalytics()
  const [setSynclySettingsOpenState, clearIntegrationToken] =
    useIntegrationsStore(
      useShallow((state) => [
        state.setSynclySettingsOpenState,
        state.clearIntegrationToken,
      ])
    )

  useEffect(() => {
    setIsConnected(connected)
  }, [connected])

  const handleEnablement = useCallback(
    async (enable: boolean, resourceUrl?: Maybe<string>) => {
      try {
        let synclyClientAuthToken: Maybe<string> = null
        if (instanceOfSynclyIntegration(integrationType)) {
          synclyClientAuthToken = await authenticateSynclyAuthClient(
            settingsUser?.id
          )
        }
        await setIntegrationEnablement({
          integration: integrationType,
          enabled: enable,
          workspaceId: workspaceId,
          resourceUrl: resourceUrl,
          authToken: synclyClientAuthToken,
        })
        if (!enable) {
          clearIntegrationToken(integrationType)
          setIsConnected(false)
          setResourceUrl('')
        } else {
          setResourceUrl(currentResourceUrl || '')
        }
        Services.HoneyComb.Record({
          metric: INTEGRATION_ENABLEMENT_METRIC,
          user_id: settingsUser?.id,
          workspace: settingsUser?.workspace.id,
          integration: integrationType,
          enabled: enable,
          is_admin: true,
        })
        trackEvent(
          `${integrationDefinition.name} ${enable ? 'enabled' : 'disabled'}`
        )
      } catch (e) {
        console.error(e)
        displayErrorMessage(
          `Failed to ${enable ? 'enable' : 'disable'} ${
            integrationDefinition.name
          }`
        )
        return
      }
      displaySuccessMessage(
        `${integrationDefinition.name} ${
          enable ? 'enabled' : 'disabled'
        } for your workspace`
      )
      setIsEnabled(enable)
    },
    [
      clearIntegrationToken,
      currentResourceUrl,
      integrationDefinition.name,
      integrationType,
      settingsUser?.id,
      settingsUser?.workspace.id,
      trackEvent,
      workspaceId,
    ]
  )

  const handleToggle = () => {
    if (isEnabled) {
      if (integrationDefinition.onSynclyDisable) {
        integrationDefinition.onSynclyDisable()
      } else {
        setIsDisableConfirmationOpen(true)
      }
    } else {
      if (integrationDefinition.onSynclyEnable) {
        integrationDefinition.onSynclyEnable(
          setSynclySettingsOpenState,
          onSynclyEnableSuccess
        )
      } else {
        setIsEnableConfirmationOpen(true)
      }
    }
  }

  const onConnectCallback = useCallback(() => {
    Services.HoneyComb.Record({
      metric: INTEGRATION_CONNECTED_METRIC,
      user_id: settingsUser?.id,
      workspace: settingsUser?.workspace.id,
      integration: integrationType,
      is_admin: true,
    })
    trackEvent(`${integrationType} connected`)
  }, [settingsUser, integrationType, trackEvent])

  const {
    connectionState,
    isConnected: isOauthConnected,
    handleConnect,
  } = useOauthConnect({ integrationType, onConnectCallback })

  const onSynclyConnectSuccess = () => {
    setSynclySettingsOpenState(null)
    setIsConnected(true)
  }

  const onSynclyEditSuccess = () => {
    setSynclySettingsOpenState(null)
  }

  const onSynclyEnableSuccess = async () => {
    await handleEnablement(true)
    setSynclySettingsOpenState(null)
  }

  const handleConnectClick = async () => {
    Services.HoneyComb.Record({
      metric: INTEGRATION_CONNECTION_INITIATED_METRIC,
      user_id: settingsUser?.id,
      workspace: settingsUser?.workspace.id,
      integration: integrationType,
      is_admin: true,
    })
    trackEvent(`${integrationDefinition.name} connection initiated`)
    if (integrationDefinition.onSynclyConnect) {
      integrationDefinition.onSynclyConnect(
        setSynclySettingsOpenState,
        onSynclyConnectSuccess
      )
    } else {
      await handleConnect()
    }
  }

  const handleDisconnect = async () => {
    try {
      let synclyClientAuthToken: Maybe<string> = null
      if (instanceOfSynclyIntegration(integrationType)) {
        synclyClientAuthToken = await authenticateSynclyAuthClient(
          settingsUser?.id
        )
      }
      await disconnectIntegration(integrationType, synclyClientAuthToken)
      clearIntegrationToken(integrationType)
      Services.HoneyComb.Record({
        metric: INTEGRATION_DISCONNECTED_METRIC,
        user_id: settingsUser?.id,
        workspace: settingsUser?.workspace.id,
        integration: integrationType,
        is_admin: true,
      })
      trackEvent(`${integrationDefinition.name} disconnected`)
      setIsConnected(false)
    } catch (e) {
      console.error(e)
      displayErrorMessage(
        `Failed to disconnect from ${integrationDefinition.name}`
      )
      return
    }
    displaySuccessMessage(`Disconnected from ${integrationDefinition.name}`)
  }

  const handleResourceUrlUpdate = async (updatedResourceUrl: string) => {
    try {
      await updateIntegrationResourceUrl(integrationType, updatedResourceUrl)
      displaySuccessMessage(
        `Updated resource URL for ${integrationDefinition.name}`
      )
      clearIntegrationToken(integrationType)
      setIsConnected(false)
      setIsResourceUrlDialogOpen(false)
      setResourceUrl(updatedResourceUrl)
      Services.HoneyComb.Record({
        metric: INTEGRATION_UPDATE_RESOURCE_URL_METRIC,
        user_id: settingsUser?.id,
        workspace: settingsUser?.workspace.id,
        integration: integrationType,
        is_admin: true,
      })
      trackEvent(`${integrationDefinition.name} resource url updated`)
    } catch (e) {
      console.error(e)
      displayErrorMessage(
        `Failed to update resource URL for ${integrationDefinition.name}`
      )
    }
  }

  return (
    <>
      <Card className="mt-4">
        <div className="flex items-center justify-between p-3 ">
          <div className="ml-4 flex items-center space-x-4">
            <integrationDefinition.icon className="size-8" />
            <div>
              {React.createElement(integrationDefinition.title)}
              <p>{integrationDefinition.description}</p>
              {integrationDefinition.resourceUrlProps &&
                !_.isEmpty(resourceUrl) && (
                  <div className="mt-0.5 flex items-center">
                    <p>Resource URL:</p>
                    <Badge className="ml-2 font-normal" variant="secondary">
                      {resourceUrl}
                    </Badge>
                    <Button
                      className=""
                      variant="link"
                      size="sm"
                      onClick={() => setIsResourceUrlDialogOpen(true)}
                    >
                      Edit
                    </Button>
                  </div>
                )}
            </div>
          </div>
          <div className="space-x-4 pr-3">
            <Switch checked={isEnabled} onCheckedChange={handleToggle} />
          </div>
        </div>
        {isEnabled && (
          <>
            <Separator />
            <div className="space-y-2 px-3 py-4">
              <div className="flex items-center justify-between ">
                <div className="flex items-center space-x-1">
                  <p className="pl-5">Link to your work account</p>
                  <Tooltip>
                    <TooltipTrigger>
                      <Icon icon={Info} size="small" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-44">
                      <p>
                        All accounts must use the same email address you use to
                        log into Harvey
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  {!_.isNil(integrationDefinition.onSynclyViewEdit) && (
                    <Button
                      variant="outline"
                      className="mr-3"
                      onClick={() =>
                        integrationDefinition.onSynclyViewEdit?.(
                          setSynclySettingsOpenState,
                          onSynclyEditSuccess
                        )
                      }
                    >
                      View/Edit
                    </Button>
                  )}
                  {connectionState === OauthConnectState.CONNECTING ? (
                    <Button variant="outline" className="mr-3" disabled>
                      Loading...
                    </Button>
                  ) : isConnected || isOauthConnected ? (
                    <Button
                      variant="destructive"
                      className="mr-3"
                      onClick={handleDisconnect}
                    >
                      Disconnect
                    </Button>
                  ) : (
                    <Button
                      variant="outline"
                      className="mr-3"
                      onClick={handleConnectClick}
                    >
                      Connect
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Card>
      <IntegrationEnableConfirmationDialog
        open={isEnableConfirmationOpen}
        onOpenChange={setIsEnableConfirmationOpen}
        onConfirm={handleEnablement}
        integrationDefinition={integrationDefinition}
      />
      <IntegrationDisableConfirmationDialog
        open={isDisableConfirmationOpen}
        onOpenChange={setIsDisableConfirmationOpen}
        onConfirm={handleEnablement}
        title={integrationDefinition.name}
      />
      <IntegrationResourceUrlDialog
        open={isResourceUrlDialogOpen}
        onOpenChange={setIsResourceUrlDialogOpen}
        onConfirm={handleResourceUrlUpdate}
        resourceUrl={resourceUrl}
        integrationType={integrationType}
      />
    </>
  )
}

export default IntegrationAdminCard
