import React, { useEffect, useState } from 'react'

import {
  FetchAllPermBundles,
  FetchAllPermBundlesInternalAdmin,
  FetchWorkspacePermBundles,
  FetchWorkspacePermBundlesInternalAdmin,
  PermBundle,
} from 'models/perms'
import { isSensitiveCustomerDataPerm } from 'models/user-info'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import { PERMISSION_CATEGORIES } from 'components/settings/workspace/workspace-details/workspace-details-roles-v2'
import { TagInput } from 'components/ui/tag-input/tag-input'

interface PermBundlesMultiSelectProps {
  /** If provided, will only show bundles that are enabled for this workspace */
  filterForWorkspaceId?: number
  selectedPermissions: PermissionBundleId[]
  setSelectedPermissions: (permissions: PermissionBundleId[]) => void
  excludedBundles?: PermissionBundleId[]
  disabled?: boolean
  size?: 'sm' | 'md'
  footerMessage?: string
  isInternalAdmin?: boolean
}

const PermBundlesMultiSelectInline: React.FC<PermBundlesMultiSelectProps> = ({
  filterForWorkspaceId,
  selectedPermissions,
  setSelectedPermissions,
  excludedBundles,
  disabled = false,
  size = 'md',
  isInternalAdmin = false,
  footerMessage,
}) => {
  const [permBundles, setPermBundles] = useState<PermBundle[]>([])

  useEffect(() => {
    const excludedBundleIdSet = new Set(excludedBundles ?? [])

    const fetchPermBundles = async () => {
      const baseFilter = (bundles: PermBundle[]) => {
        return bundles.filter((bundle) => {
          if (bundle.permissions.some(isSensitiveCustomerDataPerm)) {
            // Never show these - granted in a very bespoke way to a few users
            return false
          }
          if (excludedBundleIdSet.has(bundle.id)) {
            return false
          }
          return true
        })
      }
      if (filterForWorkspaceId) {
        const fetchFn = isInternalAdmin
          ? FetchWorkspacePermBundlesInternalAdmin
          : FetchWorkspacePermBundles
        const bundles = (await fetchFn(filterForWorkspaceId)).filter(
          (bundle) => bundle.enabledPerms.length > 0
        ) // Only show bundles that have at least one enabled perm
        setPermBundles(baseFilter(bundles))
      } else {
        const fetchFn = isInternalAdmin
          ? FetchAllPermBundlesInternalAdmin
          : FetchAllPermBundles
        const bundles = await fetchFn()
        setPermBundles(baseFilter(bundles))
      }
    }
    void fetchPermBundles()
  }, [excludedBundles, filterForWorkspaceId, isInternalAdmin])

  // Group permission bundles by category
  const sortedGroups = PERMISSION_CATEGORIES.map((category) => ({
    label: category,
    tags: permBundles
      .filter((bundle) => bundle.category === category)
      .map((bundle) => ({
        value: bundle.id,
        badgeDisplayText: `${bundle.name} (${bundle.category})`,
      })),
  })).filter((group) => group.tags.length > 0) // Remove empty categories

  return (
    <TagInput
      size={size}
      disabled={disabled}
      sortedGroups={sortedGroups}
      placeholder="Start typing or select permissions"
      footerMessage={footerMessage}
      shouldShowSuggestedTags
      allowCreatingNewTags={false}
      selectedTagValues={selectedPermissions}
      setSelectedTagValues={(values) =>
        setSelectedPermissions(values as PermissionBundleId[])
      }
    />
  )
}

export default PermBundlesMultiSelectInline
