import React from 'react'

import { Building2, Link } from 'lucide-react'

import {
  PermissionLevel,
  PermissionLevelDefinition,
  PermissionsByUser,
  PermissionsByWorkspace,
  ShareType,
} from 'types/sharing'

import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Badge } from 'components/ui/badge'
import Icon from 'components/ui/icon/icon'
import { UpdatedWorkspaceAndUserSharingInfo } from 'components/vault/utils/vault-fetcher'

import PermissionLevelDropdown from './permission-level-dropdown'

interface SharedUserAndWorkspaceListProps {
  isReadOnly: boolean
  ownerUserEmail: string | null
  permissionsByWorkspace: PermissionsByWorkspace
  permissionsByUser: PermissionsByUser
  onUpdateSharePermissions: (
    params: UpdatedWorkspaceAndUserSharingInfo
  ) => Promise<void>
  shareType: ShareType
  isEveryoneAtWorkspaceEnabled: boolean
}

enum ListItemType {
  OWNER = 'owner',
  SHARED_USER = 'shared-user',
  WORKSPACE = 'workspace',
}

const SharedUserAndWorkspaceList = ({
  isReadOnly,
  ownerUserEmail,
  permissionsByWorkspace,
  permissionsByUser,
  onUpdateSharePermissions,
  shareType,
  isEveryoneAtWorkspaceEnabled,
}: SharedUserAndWorkspaceListProps) => {
  const userInfo = useAuthUser()
  const ownerIsSelf = userInfo.id === ownerUserEmail
  const ownerText = ownerIsSelf
    ? `${ownerUserEmail} (you)`
    : ownerUserEmail || 'Owner'

  return (
    // TODO(stella): figure out how to use ScrollArea component here
    <div className="my-2 max-h-60 overflow-y-auto pr-1">
      <ListItem
        shareType={shareType}
        isReadOnly={isReadOnly}
        key="owner-sharing-perm"
        listItemType={ListItemType.OWNER}
        // TODO(stella): fill the real value in here
        displayText={ownerText}
        onUpdateSharePermissions={onUpdateSharePermissions}
        isEveryoneAtWorkspaceEnabled={isEveryoneAtWorkspaceEnabled}
      />
      {permissionsByWorkspace
        .filter(
          (workspacePermission) =>
            workspacePermission.permissionLevel &&
            workspacePermission.workspaceId
        )
        .map((workspacePermission) => (
          <ListItem
            shareType={shareType}
            key={`workspace-${workspacePermission.workspaceId}-sharing-perm`}
            isReadOnly={isReadOnly}
            listItemType={ListItemType.WORKSPACE}
            displayText={
              isEveryoneAtWorkspaceEnabled
                ? `Everyone at ${workspacePermission.workspaceName}`
                : `Anyone at ${workspacePermission.workspaceName} with the link`
            }
            currentPermissionLevel={workspacePermission.permissionLevel!}
            workspaceId={workspacePermission.workspaceId!}
            onUpdateSharePermissions={onUpdateSharePermissions}
            isEveryoneAtWorkspaceEnabled={isEveryoneAtWorkspaceEnabled}
          />
        ))}
      {permissionsByUser
        .filter(
          (userPermission) =>
            userPermission.permissionLevel && userPermission.userId
        )
        .map((userPermission) => (
          <ListItem
            shareType={shareType}
            key={`user-${userPermission.userId}-sharing-perm`}
            isReadOnly={isReadOnly}
            listItemType={ListItemType.SHARED_USER}
            displayText={userPermission.userEmail ?? ''}
            currentPermissionLevel={userPermission.permissionLevel!}
            userId={userPermission.userId!}
            onUpdateSharePermissions={onUpdateSharePermissions}
            isEveryoneAtWorkspaceEnabled={isEveryoneAtWorkspaceEnabled}
          />
        ))}
    </div>
  )
}

type ListItemProps = (
  | {
      listItemType: ListItemType.OWNER
      displayText: string
    }
  | {
      listItemType: ListItemType.WORKSPACE
      displayText: string
      currentPermissionLevel: PermissionLevel
      workspaceId: number
    }
  | {
      listItemType: ListItemType.SHARED_USER
      displayText: string
      currentPermissionLevel: PermissionLevel
      userId: string
    }
) & {
  isReadOnly: boolean
  onUpdateSharePermissions: (
    params: UpdatedWorkspaceAndUserSharingInfo
  ) => Promise<void>
  shareType: ShareType
  isEveryoneAtWorkspaceEnabled: boolean
}

const ListItem = ({
  shareType,
  listItemType,
  displayText,
  isReadOnly,
  isEveryoneAtWorkspaceEnabled,
  ...props
}: ListItemProps) => {
  const currentPermissionLevel =
    'currentPermissionLevel' in props ? props.currentPermissionLevel : undefined
  const workspaceId = 'workspaceId' in props ? props.workspaceId : undefined
  const userId = 'userId' in props ? props.userId : undefined

  const workspaceListItemIcon = isEveryoneAtWorkspaceEnabled ? Building2 : Link

  return (
    <div className="flex items-center justify-between py-2">
      <div className="flex items-center gap-x-2">
        <Badge variant="secondary" isPill className="h-6 w-6 rounded-full px-0">
          {listItemType === ListItemType.WORKSPACE ? (
            <Icon
              size="small"
              icon={workspaceListItemIcon}
              className="w-6 text-center"
            />
          ) : (
            <span className="w-6 text-center text-xs">
              {displayText[0]?.toUpperCase() || 'Owner'}
            </span>
          )}
        </Badge>
        <p className="line-clamp-2">{displayText}</p>
      </div>
      <div
        className={cn('flex w-24 justify-end', {
          'w-24': !isReadOnly,
          'w-16': isReadOnly,
        })}
      >
        {listItemType === ListItemType.OWNER && (
          <span className="text-xs text-muted">Owner</span>
        )}
        {listItemType !== ListItemType.OWNER &&
          (isReadOnly ? (
            <span className="text-xs text-muted">
              {PermissionLevelDefinition[currentPermissionLevel!].name}
            </span>
          ) : (
            <UserPermissionLevelDropdown
              shareType={shareType}
              currentPermissionLevel={currentPermissionLevel!}
              workspaceId={workspaceId}
              userId={userId}
              onUpdateSharePermissions={props.onUpdateSharePermissions}
            />
          ))}
      </div>
    </div>
  )
}

const UserPermissionLevelDropdown = ({
  shareType,
  currentPermissionLevel,
  workspaceId,
  userId,
  onUpdateSharePermissions,
}: {
  shareType: ShareType
  currentPermissionLevel: PermissionLevel
  workspaceId?: number
  userId?: string
  onUpdateSharePermissions: (
    params: UpdatedWorkspaceAndUserSharingInfo
  ) => Promise<void>
}) => {
  const { trackEvent } = useAnalytics()

  const onPermissionLevelChange = async (
    updatedPermissionLevel: PermissionLevel
  ) => {
    if (updatedPermissionLevel !== currentPermissionLevel) {
      const updatedSharingInfo: UpdatedWorkspaceAndUserSharingInfo = {}
      if (userId) {
        updatedSharingInfo.updateShareWithUsers = [
          {
            userId: userId,
            permissionLevel: updatedPermissionLevel,
          },
        ]
      } else if (workspaceId) {
        updatedSharingInfo.updateShareWithWorkspaces = [
          {
            workspaceId: workspaceId,
            permissionLevel: updatedPermissionLevel,
          },
        ]
      }
      trackEvent(
        shareType === ShareType.VAULT_PROJECT
          ? 'Vault project sharing permission level changed'
          : 'Assistant thread sharing permission level changed',
        {
          currentPermissionLevel: currentPermissionLevel,
          updatedPermissionLevel: updatedPermissionLevel,
          sharedUserId: userId,
          sharedWorkspaceId: workspaceId,
        }
      )
      try {
        await onUpdateSharePermissions(updatedSharingInfo)
      } catch (error) {
        displayErrorMessage('Failed to update permission level')
      }
    }
  }

  const onRemoveUserOrWorkspace = async () => {
    const updatedSharingInfo: UpdatedWorkspaceAndUserSharingInfo = {}
    if (userId) {
      updatedSharingInfo.removeShareWithUsers = [userId]
    } else if (workspaceId) {
      updatedSharingInfo.removeShareWithWorkspaces = [workspaceId]
    }
    trackEvent(
      shareType === ShareType.VAULT_PROJECT
        ? 'Vault project sharing permission removed'
        : 'Assistant thread sharing permission removed',
      {
        currentPermissionLevel: currentPermissionLevel,
        sharedUserId: userId,
        sharedWorkspaceId: workspaceId,
      }
    )
    try {
      await onUpdateSharePermissions(updatedSharingInfo)
    } catch (error) {
      displayErrorMessage('Failed to remove user or workspace')
    }
  }

  return (
    <PermissionLevelDropdown
      currentPermissionLevel={currentPermissionLevel}
      showRemoveOption
      onPermissionLevelChange={onPermissionLevelChange}
      onRemoveUserOrWorkspace={onRemoveUserOrWorkspace}
      shareType={shareType}
    />
  )
}

export default SharedUserAndWorkspaceList
