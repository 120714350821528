/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DefaultBasePermission = {
    VIEWHELP_CENTER: 'view:help_center',
    CREATECOMPLETION: 'create:completion',
    CREATEDOCUMENT_QA: 'create:document_qa',
    CREATEADVANCED_DOC_QA: 'create:advanced_doc_qa',
    CREATEMULTI_DOC_QA: 'create:multi_doc_qa',
    USEASSISTANT_V2: 'use:assistant_v2',
    CREATEASSISTANT_DRAFT: 'create:assistant_draft',
    READHISTORY: 'read:history',
    CREATEFEEDBACK: 'create:feedback',
    CREATEPRIVATE_LIBRARY_PROMPTS: 'create:private_library_prompts',
    USELIBRARY_EXAMPLES: 'use:library_examples',
    CREATEUSER_LIBRARY_ITEMS: 'create:user_library_items',
    CREATETRANSLATION: 'create:translation',
    CREATEREDLINES: 'create:redlines',
    CREATESEC_EDGAR_QA: 'create:sec_edgar_qa',
    USEASSISTANT_RESEARCH_KS: 'use:assistant_research_ks',
    CREATEUSER_PROFILES: 'create:user_profiles',
    CREATEUSER_PROFILES_OPTIONAL_STEPS: 'create:user_profiles_optional_steps',
    USEASSISTANT_LOCALIZATION: 'use:assistant_localization',
    USESHAREPOINT_INTEGRATION: 'use:sharepoint_integration',
    USEGOOGLE_DRIVE_INTEGRATION: 'use:google_drive_integration',
    USEHARVEY_FOR_WORD: 'use:harvey_for_word',
    USEASSISTANT_COMPOSER: 'use:assistant_composer',
    CREATEVAULT_SHARES: 'create:vault_shares',
    USEVAULT_SHARING: 'use:vault_sharing',
    CREATEEVENT_SHARES: 'create:event_shares',
    USEEVENT_SHARING: 'use:event_sharing'
} as const;
export type DefaultBasePermission = typeof DefaultBasePermission[keyof typeof DefaultBasePermission];


export function instanceOfDefaultBasePermission(value: any): boolean {
    for (const key in DefaultBasePermission) {
        if (Object.prototype.hasOwnProperty.call(DefaultBasePermission, key)) {
            if (DefaultBasePermission[key as keyof typeof DefaultBasePermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DefaultBasePermissionFromJSON(json: any): DefaultBasePermission {
    return DefaultBasePermissionFromJSONTyped(json, false);
}

export function DefaultBasePermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultBasePermission {
    return json as DefaultBasePermission;
}

export function DefaultBasePermissionToJSON(value?: DefaultBasePermission | null): any {
    return value as any;
}

export function DefaultBasePermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): DefaultBasePermission {
    return value as DefaultBasePermission;
}

