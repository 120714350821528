import React, { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { RelatedQuestion } from 'openapi/models/RelatedQuestion'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { displayWarningMessage } from 'utils/toast'
import { QUERY_MUST_HAVE_X_LEN_HELP_TEXT } from 'utils/tooltip-texts'
import { cn } from 'utils/utils'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useAssistantMidThreadFileUpload } from 'components/assistant/hooks/use-assistant-mid-thread-file-upload'
import { AssistantWorkflowDraftStreamHandler } from 'components/assistant/hooks/use-assistant-workflow-draft-stream'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import {
  getQueryLimit,
  isQueryValid,
} from 'components/assistant/utils/assistant-helpers'
import { MIN_QUERY_LENGTH } from 'components/assistant/utils/constants'
import AskHarveyButton from 'components/common/ask-harvey-button'
import QueryLimitTooltip from 'components/common/query-limit-tooltip/query-limit-tooltip'
import { Card, CardContent } from 'components/ui/card'
import { Label } from 'components/ui/label'
import { Textarea, TextareaProps } from 'components/ui/text-area'

import AssistantFollowUps from './assistant-follow-ups'
import { AssistantLoadPromptButton } from './assistant-load-prompt-button'
import { AssistantMode } from './assistant-mode-select'

interface Props extends TextareaProps {
  inputRef?: React.MutableRefObject<HTMLTextAreaElement | null>
  isStreaming?: boolean
  prevMessageId?: string
  relatedQuestions?: RelatedQuestion[]
  createDraftEditRequest: AssistantWorkflowDraftStreamHandler['createDraftEditRequest']
  isFinalizingStream?: boolean
  isRevision?: boolean
  onAsk?: () => void
}

const AssistantWorkflowReplyInput = ({
  inputRef,
  isStreaming,
  onBlur,
  prevMessageId,
  relatedQuestions,
  createDraftEditRequest,
  isFinalizingStream,
  isRevision,
  onAsk,
}: Props) => {
  const mode = AssistantMode.DRAFT
  const { draftId } = useParams()
  const trackEvent = useAssistantAnalytics()

  const [
    pendingMessage,
    query,
    documentsUploading,
    documents,
    setQuery,
    messages,
    getCurrentThreadMessages,
    setPendingMessage,
    isReadOnly,
  ] = useAssistantStore(
    useShallow((s) => [
      s.pendingMessage,
      s.query,
      s.documentsUploading,
      s.documents,
      s.setQuery,
      s.messages,
      s.getCurrentThreadMessages,
      s.setPendingMessage,
      s.isReadOnly,
    ])
  )

  const { hasUploadedFiles } = useAssistantMidThreadFileUpload(
    AssistantMode.DRAFT
  )

  const navigate = useNavigateWithQueryParams()
  const [queryPreview, setQueryPreview] = React.useState<string | null>(null)

  const authCallback = useCallback(
    (eventId: string) => {
      if (eventId && eventId !== draftId) {
        navigate(`/assistant/${mode}/${eventId}`)
      }
      setQuery('')
    },
    [mode, navigate, draftId, setQuery]
  )

  const handleSetQuery = (q: string) => {
    if (q.length > queryLimit && q.length >= query.length) {
      displayWarningMessage(
        `Query length cannot exceed ${queryLimit} characters`
      )
    } else {
      setQuery(q)
    }
  }

  const handleAsk = useCallback(
    async (prompt: string) => {
      if (isRevision) {
        trackEvent('Add Revision Confirmed', {
          query_length: query.length,
          thread_number: getCurrentThreadMessages().length + 1,
          message_number: messages.length + 1,
        })
      }
      if (!draftId) return

      createDraftEditRequest(prompt, prevMessageId, draftId, authCallback)
      if (onAsk) onAsk()
    },
    [
      authCallback,
      createDraftEditRequest,
      draftId,
      getCurrentThreadMessages,
      isRevision,
      messages.length,
      onAsk,
      prevMessageId,
      query.length,
      trackEvent,
    ]
  )

  useEffect(() => {
    // when we finish streaming and there is a pending message
    if (!isStreaming && pendingMessage) {
      const { query } = pendingMessage
      setPendingMessage(null)
      // initialize next query
      void handleAsk(query!)
    }
  }, [isStreaming, pendingMessage, handleAsk, setPendingMessage])

  const handleAskClick = () => {
    handleSubmitQuery(query)
  }

  const handleSubmitQuery = (prompt: string) => {
    if (isFinalizingStream) {
      const pendingMessage = {
        query: prompt,
        isLoading: true,
        sources: [],
      }
      setPendingMessage(pendingMessage)
    } else {
      void handleAsk(prompt)
    }
  }

  const hasDocuments = !!(documents.length || documentsUploading.length)
  const queryLimit = getQueryLimit(hasDocuments, true)
  const isAskHarveyDisabled = !isQueryValid(query, queryLimit)

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)
  const mergedRefs = (ref: HTMLTextAreaElement) => {
    textareaRef.current = ref
    if (inputRef) inputRef.current = ref
  }

  // This is a workaround to prevent the textarea from blurring when the load prompt popover is opened
  const blurInput = useRef(true)
  const handleTextareaBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!blurInput.current) return
    const isPromptButtonClick = e.relatedTarget?.id === 'load-prompt'
    if (isPromptButtonClick) blurInput.current = false
    else onBlur?.(e)
  }
  const handleLoadPromptPopoverChange = (isOpen: boolean) => {
    if (!isOpen) {
      blurInput.current = true
      textareaRef.current?.focus()
    }
  }

  const placeholder = 'Describe how to change the draft'

  const getDisabledText = () => {
    if (query.trim().length < MIN_QUERY_LENGTH) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`at least ${MIN_QUERY_LENGTH}`)
    } else if (query.trim().length > queryLimit) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`fewer than ${queryLimit}`)
    } else {
      return ''
    }
  }

  const textareaDisabled = isStreaming && !isFinalizingStream

  const inputComponent = (
    <Card className="border-none" id="assistant-input">
      <CardContent
        className={cn(
          'relative rounded p-0 focus-within:ring-1 focus-within:ring-ring',
          'before:pointer-events-none before:absolute before:inset-0 before:z-10 before:rounded before:ring-ring focus-within:before:ring-1'
        )}
        id="assistant-text-input"
      >
        <Label className="sr-only" htmlFor="assistant-follow-up-input">
          Follow up
        </Label>
        <Textarea
          id="assistant-follow-up-input"
          className={cn(
            'max-h-60 min-h-0 resize-none rounded-[3px] rounded-b-none border-b-0 p-6 pb-0 ring-inset focus-visible:ring-0',
            'p-2 pb-1 text-xs placeholder:text-xs'
          )}
          disabled={textareaDisabled}
          isFluid
          onChange={(e) => handleSetQuery(e.target.value)}
          onBlur={handleTextareaBlur}
          placeholder={queryPreview || placeholder}
          ref={mergedRefs}
          value={queryPreview ? '' : query}
        />
        <div className="absolute right-2 -mt-4 ">
          <QueryLimitTooltip query={query} queryLimit={queryLimit} />
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={cn(
            'flex cursor-text justify-end rounded-b border border-t-0 border-input p-2 pt-5',
            {
              'rounded-b-none': hasUploadedFiles,
            }
          )}
          onClick={() => textareaRef.current?.focus()}
        >
          <AssistantLoadPromptButton
            setQuery={handleSetQuery}
            setQueryPreview={setQueryPreview}
            eventKind={EventKind.ASSISTANT_DRAFT}
            handleLoadPromptPopoverChange={handleLoadPromptPopoverChange}
            disabled={isStreaming}
          />
          <AskHarveyButton
            className={cn(
              'pointer-events-auto ml-auto w-auto shrink-0 whitespace-nowrap text-xs font-semibold'
            )}
            id="assistant-submit"
            handleSubmit={handleAskClick}
            tooltip={getDisabledText()}
            disabled={isAskHarveyDisabled}
            size="sm"
            inputRef={textareaRef}
            // don't enforce client matter selection for replies
            shouldEnforceClientMatterSelection={false}
          />
        </div>
      </CardContent>
    </Card>
  )

  if (isReadOnly) return null

  return (
    <div id="assistant-follow-up">
      {relatedQuestions && (
        <AssistantFollowUps
          questions={relatedQuestions}
          onSelectQuestion={handleSubmitQuery}
        />
      )}
      {inputComponent}
    </div>
  )
}

export default AssistantWorkflowReplyInput
