import React from 'react'

import { Playbook } from 'openapi/models/Playbook'

import Banner from 'components/ui/banner'
import { Button } from 'components/ui/button'
import { Dialog, DialogContent } from 'components/ui/dialog'
import { Spinner } from 'components/ui/spinner'

interface PlaybookDeleteDialogProps {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  playbook: Playbook
  onDelete: () => Promise<void>
  isPending: boolean
}

const PlaybookDeleteDialog: React.FC<PlaybookDeleteDialogProps> = ({
  modalOpen,
  setModalOpen,
  playbook,
  isPending,
  onDelete,
}) => {
  return (
    <Dialog
      open={modalOpen}
      onOpenChange={(open) => {
        setModalOpen(open)
      }}
    >
      <DialogContent showCloseIcon={false}>
        <div>
          <p className="text-xl">Delete playbook</p>
          <Banner
            title="Are you sure?"
            variant="destructive"
            description={
              <p>
                This permanently deletes the playbook &quot;{playbook.name}
                &quot;. Once deleted, it cannot be recovered.
              </p>
            }
            className="mt-4"
          />
          <div className="mt-6 flex flex-row justify-end space-x-2">
            <Button
              variant="ghost"
              onClick={() => {
                setModalOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              disabled={isPending}
              onClick={onDelete}
              className="*:text-sm"
            >
              {isPending ? (
                <div className="flex items-center">
                  <Spinner size="xxs" className="top-3 mr-2" />
                  Deleting…
                </div>
              ) : (
                <span>Permanently delete</span>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PlaybookDeleteDialog
