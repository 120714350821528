import * as React from 'react'
import { Control, Controller, ControllerRenderProps } from 'react-hook-form'

import {
  Calculator,
  Users,
  Coins,
  Briefcase,
  Scale,
  CircleHelp,
} from 'lucide-react'

import { Profession } from 'openapi/models/Profession'

import { toTitleCase } from 'utils/string'

import {
  RadioCardGroup,
  RadioCardGroupItem,
} from 'components/ui/radio-card-group/radio-card-group'

interface ProfileProfessionFormProps {
  control: Control<any>
  onChange?: (value: Profession | null) => void
  showUnspecified?: boolean
}

const ICONS = {
  [Profession.LEGAL]: Scale,
  [Profession.TAX]: Coins,
  [Profession.FINANCE]: Calculator,
  [Profession.ACCOUNTING]: Briefcase,
  [Profession.CONSULTING]: Users,
}

const UNSPECIFIED_VALUE = 'UNSPECIFIED'

const OPTIONS = [
  ...Object.values(Profession).map((profession) => ({
    label: toTitleCase(profession),
    value: profession,
  })),
]

export const FORM_HEADING = 'What is your primary profession?'
export const FORM_DESCRIPTION =
  'Select one that best matches your current position. This will help us provide tailored content.'

const ProfileProfessionForm: React.FC<ProfileProfessionFormProps> = ({
  control,
  onChange,
  showUnspecified = false,
}) => {
  const handleChange = (
    field: ControllerRenderProps<any, 'primaryProfession'>,
    value: string | null
  ) => {
    if (value === UNSPECIFIED_VALUE) {
      onChange?.(null)
      field.onChange(null)
    } else {
      onChange?.(value as Profession)
      field.onChange(value)
    }
  }

  return (
    <Controller
      name="primaryProfession"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <RadioCardGroup
          className="grid grid-cols-3"
          value={field.value ?? UNSPECIFIED_VALUE}
          onValueChange={(value) => handleChange(field, value)}
        >
          {OPTIONS.map((option) => (
            <RadioCardGroupItem
              key={option.value}
              value={option.value}
              id={option.value}
              label={option.label}
              Icon={ICONS[option.value]}
              tabIndex={0}
            />
          ))}
          {showUnspecified && (
            <RadioCardGroupItem
              className="bg-secondary [&_div]:!text-muted"
              key={UNSPECIFIED_VALUE}
              value={UNSPECIFIED_VALUE}
              label="Unspecified"
              id={UNSPECIFIED_VALUE}
              Icon={CircleHelp}
              tabIndex={0}
            />
          )}
        </RadioCardGroup>
      )}
    />
  )
}

export default ProfileProfessionForm
