import React from 'react'

import pluralize from 'pluralize'

import { useAuthUser } from 'components/common/auth-context'
import { getVaultProjects } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultHomeHeader = () => {
  const userInfo = useAuthUser()
  const allFolderIdToVaultFolder = useVaultStore(
    (state) => state.allFolderIdToVaultFolder
  )
  const rootVaultFolderIds = useVaultStore((s) => s.rootVaultFolderIds)

  const isVaultSharingEnabled = userInfo.IsVaultViewSharesUser

  const showProjectDisplayText =
    !isVaultSharingEnabled && !userInfo.IsKnowledgeBaseProjectUser

  const numProjects = getVaultProjects({
    allFolderIdToVaultFolder,
    rootVaultFolderIds,
    userId: userInfo.dbId,
  }).length

  const projectDisplayText =
    numProjects === 0
      ? 'No active projects'
      : `${numProjects} ${pluralize('project', numProjects)} created`

  return (
    <div>
      <p className="line-clamp-2 text-xl font-medium">Vault</p>
      <p className="truncate text-xs text-muted">
        Store and analyze thousands of documents
      </p>
      <div className="line-clamp-2 text-xs text-muted">
        {showProjectDisplayText && projectDisplayText}
      </div>
    </div>
  )
}

export default VaultHomeHeader
