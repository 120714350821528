import { PracticeAreaTaxonomy } from 'openapi/models/PracticeAreaTaxonomy'
import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'

import { useLoadWorkflowsQuery } from 'components/assistant/workflows/workflow-loader'

const PracticeAreas = {
  GENERAL: 'GENERAL',
  TRANSACTIONAL: PracticeAreaTaxonomy.TRANSACTIONAL,
  LITIGATION: PracticeAreaTaxonomy.LITIGATION,
  ALLEN_AND_OVERY: 'ALLEN_AND_OVERY',
  FINANCIAL_SERVICES: 'FINANCIAL_SERVICES',
} as const

type PracticeAreas = (typeof PracticeAreas)[keyof typeof PracticeAreas]
type CategorizedHarveyEvents = Record<
  Lowercase<PracticeAreas>,
  WorkflowDefinitionListItem[]
>

export const useWorkflowData = () => {
  const { data: workflows, isLoading } = useLoadWorkflowsQuery()

  const categorizedWorkflows = (
    workflows ?? []
  ).reduce<CategorizedHarveyEvents>(
    (acc, workflow) => {
      workflow.tags.forEach((tag) => {
        const tagKey = tag.toLowerCase() as keyof CategorizedHarveyEvents
        if (tagKey in acc) {
          acc[tagKey].push(workflow)
        }
      })
      return acc
    },
    {
      allen_and_overy: [],
      transactional: [],
      litigation: [],
      general: [],
      financial_services: [],
    }
  )

  return {
    workflows,
    categorizedWorkflows,
    isLoading,
  }
}
