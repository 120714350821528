import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

import { Maybe } from 'types'

import { Source } from 'utils/task'

import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'

export const calculateNegativeMargin = (sources: Maybe<Source[]>) => {
  const windowWidth = document.querySelector('#thread')?.clientWidth || 0
  const sourcesExist = (sources?.length || 0) > 0
  // 288 is the width of the sources sidebar
  const sourcesWidth = sourcesExist ? 288 : 0
  // 16px for each side
  const sourcesWidthAndMargin = sourcesWidth + 32
  // 1000 is the width of the main thread content
  const eachSideRemainingWidth = (windowWidth - 1000) / 2
  const oneSideRemainingWidth = eachSideRemainingWidth - sourcesWidthAndMargin
  // 108 is an arbitrary number that works well for the design
  // can be adjusted if needed
  const maxNegativeMargin = 108
  const negativeMargin = Math.min(maxNegativeMargin, oneSideRemainingWidth)
  if (!windowWidth || oneSideRemainingWidth < 0) {
    return 0
  }
  return negativeMargin
}

export const useCalcTableMargin = (sources: Maybe<Source[]>) => {
  const [negativeMargin, setNegativeMargin] = React.useState(0)
  const [searchParams] = useSearchParams()
  const isFileViewerOpen = searchParams.get(FILE_ID_PARAM) !== null

  React.useEffect(() => {
    const handleResize = () => {
      const negativeMargin = calculateNegativeMargin(sources)
      setNegativeMargin(negativeMargin)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [sources, isFileViewerOpen])

  return negativeMargin
}
