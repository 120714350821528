import React from 'react'

import { ChevronRight } from 'lucide-react'

import { WorkflowDefinitionListItem } from 'openapi/models/WorkflowDefinitionListItem'

import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import WorkflowCard, {
  WorkflowCardPlaceholder,
  WorkflowCategory,
} from 'components/workflows/workflow-card'
import { WorkflowSource } from 'components/workflows/workflows-helpers'
import { WorkflowTab } from 'components/workflows/workflows-list'

interface ExampleCardListProps {
  title: string
  items: WorkflowDefinitionListItem[]
}

const ExampleCardList = ({ title, items }: ExampleCardListProps) => {
  if (!items.length) return null

  const itemsSlice = items.slice(0, 4)
  const itemCount = itemsSlice.length
  const placeholdersNeeded = itemCount < 4 ? 4 - itemCount : 0

  return (
    <div>
      <div className="flex justify-between">
        {title && <h3 className="mb-3 text-sm font-medium">{title}</h3>}
        <Button
          variant="ghost"
          size="sm"
          to={`/workflows?tab=${WorkflowTab.ASSISTANT}`}
        >
          <span className="mr-1 text-xs">See all</span>
          <Icon icon={ChevronRight} size="small" />
        </Button>
      </div>

      <ul className="grid grid-cols-4 gap-4">
        {itemsSlice.map((item) => (
          <Link
            className="group rounded-lg"
            key={item.name}
            to={`/assistant/workflows/${item.slug}`}
          >
            <WorkflowCard
              key={item.id}
              workflowInfo={{
                ...item,
                category: WorkflowCategory.ASSISTANT,
                path: `/assistant/workflows/${item.slug}`,
                numSteps: item.userInputStepCount,
              }}
              source={WorkflowSource.ASSISTANT_PAGE}
              onClick={() => {}}
            />
          </Link>
        ))}

        {/* Render placeholder cards if needed */}
        {Array.from({ length: placeholdersNeeded }).map((_, idx) => (
          <WorkflowCardPlaceholder key={idx} />
        ))}
      </ul>
    </div>
  )
}

export default ExampleCardList
