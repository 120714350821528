import { TranscriptsDocument } from 'openapi/models/TranscriptsDocument'
import { TranscriptsQuestionAnswer } from 'openapi/models/TranscriptsQuestionAnswer'
import { TaskType } from 'types/task'

import { WordSectionType } from 'utils/docx'
import { exportWordWithSections } from 'utils/markdown'

export const exportTranscripts = async (
  documents: TranscriptsDocument[],
  qa: TranscriptsQuestionAnswer[]
) => {
  const documentSections = documents.flatMap((document) => {
    return [
      {
        content: `## Document: ${document.file.name}`,
        type: 'markdown',
      },
      ...document.topics.map((t) => ({
        content: `### ${t.topic}\n#### Witness: ${t.witness}\n${t.summary}`,
        type: 'markdown',
      })),
    ]
  })

  const questionAnswerSections = qa.map((qa) => ({
    content: `## ${qa.question}\n\n${qa.answer.text}`,
    type: 'markdown',
  }))

  await exportWordWithSections({
    title: 'Transcripts',
    taskType: TaskType.TRANSCRIPTS,
    includeAnnotation: false,
    queryId: String(0),
    sections: [
      {
        content: '# Documents',
        type: WordSectionType.MARKDOWN,
      },
      ...(documentSections as {
        content: string
        type: WordSectionType.MARKDOWN
      }[]),
      {
        content: '# Questions and answers',
        type: WordSectionType.MARKDOWN,
      },
      ...(questionAnswerSections as {
        content: string
        type: WordSectionType.MARKDOWN
      }[]),
    ],
  })
}
