import React, { useEffect } from 'react'

import {
  ChevronDownIcon,
  ChevronUpIcon,
  LoaderCircleIcon,
  CheckIcon,
} from 'lucide-react'
import pluralize from 'pluralize'

import { LoadingState as LoadingStateType } from 'openapi/models/LoadingState'
import { LoadingStateStatus } from 'openapi/models/LoadingStateStatus'

import { cn } from 'utils/utils'

import Icon from 'components/ui/icon/icon'

type LoadingStateProps = {
  states: LoadingStateType[]
  isCompleted: boolean
}

export const LoadingState: React.FC<LoadingStateProps> = ({
  states,
  isCompleted,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(true)

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    if (isCompleted) {
      setIsExpanded(false)
    }
  }, [isCompleted])

  if (states.length === 0) {
    return null
  }

  return (
    <div data-testid="workflow-loading-states">
      {isCompleted ? (
        <button
          onClick={handleToggle}
          className="flex w-full items-center space-x-1 py-0.5 focus:outline-none"
        >
          <span className="text-sm text-secondary">
            Finished in {states.length} {pluralize('step', states.length)}
          </span>
          {isExpanded ? (
            <Icon icon={ChevronUpIcon} size="default" />
          ) : (
            <Icon icon={ChevronDownIcon} size="default" />
          )}
        </button>
      ) : (
        <span className={cn('text-sm text-secondary', isExpanded && 'pb-1')}>
          Working…
        </span>
      )}

      {/* Expanded View */}
      {isExpanded && (
        <div>
          {states.map((state, index) => (
            <div key={index} className="flex items-start space-x-2 py-1">
              <div className="flex h-5 shrink-0 items-center">
                {state.status === LoadingStateStatus.IN_PROGRESS ? (
                  <Icon
                    icon={LoaderCircleIcon}
                    size="small"
                    className="animate-spin"
                  />
                ) : (
                  <Icon icon={CheckIcon} size="small" />
                )}
              </div>
              <span className="text-sm text-secondary">
                {state.text || state.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
