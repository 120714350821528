import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { BookOpen, Users, Lock, Building2 } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { VaultFolder } from 'openapi/models/VaultFolder'

import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import Link from 'components/ui/link/link'
import { SkeletonBlock } from 'components/ui/skeleton'
import { MenuDropdown } from 'components/vault/components/file-explorer/vault-cells'
import VaultProjectEditClientMatterDialog from 'components/vault/dialogs/vault-project-edit-client-matter-dialog'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { useVaultProjectHistoryStats } from 'components/vault/utils/use-vault-project-history-stats'
import { projectsPath } from 'components/vault/utils/vault'
import { VaultProjectMetadata } from 'components/vault/utils/vault-fetcher'
import {
  getProjectMetadataFromVaultFolderMetadata,
  isEmptyMetadata,
  projectAsItem,
} from 'components/vault/utils/vault-helpers'
import { useVaultSharingStore } from 'components/vault/utils/vault-sharing-store'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

export const DEFAULT_PROJECT_CARD_HEIGHT = 180

const VaultProjectCard = ({
  vaultProject,
  isShared,
  isLoadingMetadata,
  projectMetadata,
  disabled,
  disabledTooltip,
  onClick,
}: {
  vaultProject: VaultFolder
  isShared?: boolean
  isLoadingMetadata: boolean
  projectMetadata?: VaultProjectMetadata
  disabled?: boolean
  disabledTooltip?: string
  onClick?: (project: VaultFolder) => void
}) => {
  const location = useLocation()
  const { trackEvent } = useAnalytics()
  const [
    isEditClientMatterDialogOpen,
    exampleProjectIds,
    setIsEditClientMatterDialogOpen,
    setCurrentProject,
    setShowProcessingProgress,
  ] = useVaultStore(
    useShallow((s) => [
      s.isEditClientMatterDialogOpen,
      s.exampleProjectIds,
      s.setIsEditClientMatterDialogOpen,
      s.setCurrentProject,
      s.setShowProcessingProgress,
    ])
  )

  const projectRow = projectAsItem(
    vaultProject,
    projectMetadata?.totalSize,
    projectMetadata?.filesCount
  )

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  const isExampleProject = useMemo(
    () => exampleProjectIds.has(vaultProject.id),
    [vaultProject.id, exampleProjectIds]
  )
  const isKnowledgeBaseProject = vaultProject.isKnowledgeBaseProject
  const { doesCurrentUserHaveFullAccessPermission } = useSharingPermissions({
    projectId: vaultProject.id,
  })

  const destination = useMemo(() => {
    return {
      pathname: `${BaseAppPath.Vault}${projectsPath}${vaultProject.id}`,
      search: location.search,
    }
  }, [location.search, vaultProject.id])

  const shouldShowMenuDropdown =
    (isHovered || isDropdownOpen) &&
    !isExampleProject &&
    doesCurrentUserHaveFullAccessPermission &&
    !disabled &&
    !onClick // onClick means we're using the button variant, which doesn't allow for editing

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        height: DEFAULT_PROJECT_CARD_HEIGHT,
      }}
      className={cn(
        'relative flex w-full flex-col rounded-lg bg-secondary p-4',
        {
          'hover:bg-secondary-hover': !disabled,
          'cursor-pointer border-input-focused':
            (isHovered || isDropdownOpen) && !disabled,
        }
      )}
    >
      {onClick ? (
        <Button
          onClick={() => onClick(vaultProject)}
          variant="unstyled"
          className="flex h-full flex-col justify-end"
          disabled={disabled}
          tooltip={disabledTooltip}
        >
          <ProjectCardContents
            vaultProject={vaultProject}
            isHovered={isHovered}
            isKnowledgeBaseProject={isKnowledgeBaseProject}
            isLoadingMetadata={isLoadingMetadata}
            projectMetadata={projectMetadata}
          />
        </Button>
      ) : (
        <Link
          to={destination}
          onClick={() => {
            setCurrentProject(vaultProject)
            if (
              projectMetadata &&
              (projectMetadata.failedFilesCount !== 0 ||
                projectMetadata.completedFilesCount !==
                  projectMetadata.filesCount)
            ) {
              setShowProcessingProgress(vaultProject.id, true)
            }
            trackEvent('Vault Project Loaded', {
              is_example_project: isExampleProject,
              is_shared_project: isShared,
            })
          }}
          className="apply-click-on-parent flex h-full flex-col justify-end"
        >
          <ProjectCardContents
            vaultProject={vaultProject}
            isHovered={isHovered}
            isKnowledgeBaseProject={isKnowledgeBaseProject}
            isLoadingMetadata={isLoadingMetadata}
            projectMetadata={projectMetadata}
          />
        </Link>
      )}
      {shouldShowMenuDropdown && (
        <div className="absolute right-2 top-2">
          <MenuDropdown
            dropdownAlign="start"
            row={projectRow}
            onMenuDropdownChangeHandler={setIsDropdownOpen}
          />
          <VaultProjectEditClientMatterDialog
            modalOpen={isEditClientMatterDialogOpen}
            setModalOpen={setIsEditClientMatterDialogOpen}
            projectId={projectRow.data.id}
          />
        </div>
      )}
    </div>
  )
}

const ProjectCardContents = ({
  vaultProject,
  isHovered,
  isKnowledgeBaseProject,
  isLoadingMetadata,
  projectMetadata,
}: {
  vaultProject: VaultFolder
  isHovered: boolean
  isKnowledgeBaseProject?: boolean
  isLoadingMetadata?: boolean
  projectMetadata?: VaultProjectMetadata
}) => {
  const { historyStats, isLoadingHistoryStats } = useVaultProjectHistoryStats(
    vaultProject.id
  )

  const [foldersMetadata] = useVaultStore(
    useShallow((s) => [s.foldersMetadata])
  )

  const [permissionsByProjectId] = useVaultSharingStore(
    useShallow((s) => [s.permissionsByProjectId])
  )

  const sizeDisplayText = useMemo(() => {
    // Read folder metadata if it's loaded, otherwise read cached project metadata
    const folderMetadata = foldersMetadata[vaultProject.id]
    const metadata =
      !!folderMetadata && !isEmptyMetadata(folderMetadata)
        ? getProjectMetadataFromVaultFolderMetadata(folderMetadata)
        : projectMetadata ?? undefined
    const numProjectFiles = metadata?.filesCount || 0
    if (numProjectFiles === 0) {
      return `No files uploaded`
    }
    return pluralizeFiles(numProjectFiles)
  }, [projectMetadata, foldersMetadata, vaultProject.id])

  const queryCountDisplayText = useMemo(() => {
    if (!historyStats) {
      return ''
    }
    return `${historyStats.totalCount} ${pluralize(
      'query',
      historyStats.totalCount
    )}`
  }, [historyStats])

  const isSharedWithWorkspace =
    (permissionsByProjectId[vaultProject.id]?.permissionsByWorkspace?.length ??
      0) > 0

  const isSharedWithUser =
    (permissionsByProjectId[vaultProject.id]?.permissionsByUser?.length ?? 0) >
    0

  const sharingStatusIcon = (() => {
    if (isKnowledgeBaseProject) {
      if (isSharedWithWorkspace) {
        return Building2
      } else if (isSharedWithUser) {
        return Users
      } else {
        return Lock
      }
    } else if (isSharedWithWorkspace) {
      return Users
    } else {
      return Lock
    }
  })()

  const isMetadataLoaded = !isLoadingMetadata && !isLoadingHistoryStats

  const hasQueries = historyStats && historyStats.totalCount > 0

  return (
    <div className="flex w-full flex-col gap-y-4 self-end">
      {isKnowledgeBaseProject ? (
        <Icon
          icon={BookOpen}
          className={cn('size-[72px] self-center stroke-[1.5px]', {
            'stroke-[hsl(var(--neutral-300))]': !isHovered,
            'stroke-[hsl(var(--neutral-400))]': isHovered,
          })}
        />
      ) : (
        <FolderShieldIcon
          className="size-[72px] self-center"
          strokeWidth={1.5}
          strokeColor={cn({
            'hsl(var(--neutral-300))': !isHovered,
            'hsl(var(--neutral-400))': isHovered,
          })}
        />
      )}
      <div>
        <p className="line-clamp-3 w-full text-left text-sm font-medium">
          {vaultProject.name}
        </p>
        <div className="flex w-full items-center justify-between text-muted">
          <div>
            {isMetadataLoaded ? (
              hasQueries ? (
                <p className="text-xs">
                  {sizeDisplayText} ⋅ {queryCountDisplayText}
                </p>
              ) : (
                <p className="text-xs">{sizeDisplayText}</p>
              )
            ) : (
              <SkeletonBlock className="h-4 w-24" />
            )}
          </div>
          <Icon
            icon={sharingStatusIcon}
            size="default"
            className="text-muted"
          />
        </div>
      </div>
    </div>
  )
}

export default VaultProjectCard
