import React, { useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import { Trash } from 'lucide-react'

import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { ListPlaybooksResponse } from 'openapi/models/ListPlaybooksResponse'
import { Playbook } from 'openapi/models/Playbook'
import Services from 'services'

import { displayErrorMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import PlaybookDeleteDialog from 'components/settings/workspace/workspace-details/playbook-management/playbook-delete-dialog'
import { Alert } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Spinner } from 'components/ui/spinner'

const PlaybookManagement = () => {
  const { id: workspaceId } = useParams()
  const userInfo = useAuthUser()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [playbookName, setPlaybookName] = useState('')
  const [sorting, setSorting] = useState<SortingState>([])
  const queryClient = useQueryClient()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [selectedPlaybook, setSelectedPlaybook] = useState<Playbook | null>(
    null
  )

  const {
    data: playbooks,
    isPending: isPlaybooksPending,
    error: listPlaybooksError,
  } = useWrappedQuery({
    queryKey: ['playbooks', workspaceId],
    queryFn: async () => {
      if (userInfo.IsInternalAdminReader) {
        return (
          await Services.Backend.Get<ListPlaybooksResponse>(
            `playbook/admin/list/${workspaceId}`,
            {
              throwOnError: true,
            }
          )
        ).playbooks
      }
      return (
        await Services.Backend.Get<ListPlaybooksResponse>(`playbook/list`, {
          throwOnError: true,
        })
      ).playbooks
    },
  })

  const { mutateAsync: deletePlaybook, isPending: isDeleting } = useMutation({
    mutationFn: async (id: string) => {
      return await Services.Backend.Delete(`playbook/admin/${id}`)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['playbooks'] })
    },
  })

  const {
    mutateAsync: uploadPlaybook,
    isPending,
    isError,
    error,
  } = useMutation({
    mutationFn: async (formData: FormData) => {
      // Internal admins can add playbooks to any workspace.
      if (userInfo.IsInternalAdminReader && workspaceId) {
        formData.append('workspace_id', workspaceId)
        return await Services.Backend.Post(`playbook/admin`, formData, {
          throwOnError: true,
        })
      }
      return await Services.Backend.Post('playbook', formData, {
        throwOnError: true,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['playbooks'] })
      setPlaybookName('')
      setSelectedFile(null)
    },
  })

  const handleDelete = useCallback((playbook: Playbook) => {
    setSelectedPlaybook(playbook)
    setShowDeleteDialog(true)
  }, [])

  const confirmDelete = useCallback(async () => {
    if (!selectedPlaybook) return
    try {
      await deletePlaybook(selectedPlaybook.id)
      setShowDeleteDialog(false)
      setSelectedPlaybook(null)
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Unknown error'
      displayErrorMessage(`Failed to delete playbook: ${errorMessage}`, 5)
    }
  }, [selectedPlaybook, deletePlaybook])

  // Memoize the table columns and add a deletion column if the user is an internal admin
  const columns: ColumnDef<Playbook>[] = useMemo(() => {
    const baseColumns: ColumnDef<Playbook>[] = [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'createdAt',
        header: ({ column }) => (
          <DataTableSortHeader column={column} header="Created" />
        ),
        cell: ({ row }) => (
          <div className="ml-3 w-1/2 text-sm">
            {format(new Date(row.original.createdAt), 'MMM d, yyyy')}
          </div>
        ),
      },
      {
        accessorKey: 'modifiedAt',
        header: ({ column }) => (
          <DataTableSortHeader column={column} header="Last Modified" />
        ),
        cell: ({ row }) => (
          <div className="ml-3 w-1/2 text-sm">
            {format(new Date(row.original.modifiedAt), 'MMM d, yyyy')}
          </div>
        ),
      },
      {
        accessorKey: 'rules',
        header: 'Number of Rules',
        cell: ({ row }) => row.original.rules.length,
      },
    ]
    if (userInfo.IsInternalAdminReader) {
      baseColumns.push({
        id: 'delete',
        header: 'Delete',
        cell: ({ row }) => (
          <Button
            variant="destructive"
            onClick={() => handleDelete(row.original)}
          >
            <Trash className="size-4 text-destructive" />
          </Button>
        ),
        enableSorting: false,
        enableHiding: false,
        size: 36,
      })
    }
    return baseColumns
  }, [userInfo, handleDelete])

  const tableData = useMemo(() => playbooks || [], [playbooks])
  const table = useReactTable<Playbook>({
    data: tableData,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setSelectedFile(event.target.files[0])
    }
  }

  const handleSubmit = useCallback(async () => {
    if (!selectedFile || !playbookName.trim()) {
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append('name', playbookName)

    try {
      await uploadPlaybook(formData)
    } catch (error) {
      displayErrorMessage(`Failed to upload playbook: ${error}`, 5)
      console.error(error)
    }
  }, [selectedFile, playbookName, uploadPlaybook])

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <div className="relative space-y-4">
          <h3 className="text-lg font-medium">Upload New Playbook</h3>
          <form
            onSubmit={async (e) => {
              e.preventDefault()
              await handleSubmit()
            }}
            className="space-y-4"
          >
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="playbook-name">Playbook Name</Label>
              <Input
                type="text"
                id="playbook-name"
                name="playbook-name"
                value={playbookName}
                onChange={(e) => setPlaybookName(e.target.value)}
                placeholder="Enter playbook name"
                disabled={isPending}
              />
            </div>
            <div className="grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="playbook-file">Playbook File (JSON)</Label>
              <Input
                type="file"
                id="playbook-file"
                name="playbook-file"
                accept=".json"
                onChange={handleFileChange}
                disabled={isPending}
              />
            </div>
            <Button
              type="submit"
              disabled={!selectedFile || !playbookName || isPending}
              tooltip="Upload a playbook for use in this workspace."
            >
              Upload Playbook
            </Button>
          </form>
          {isPending && (
            // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
            <div className="bg-white absolute inset-0 flex items-center justify-center bg-opacity-50">
              <Spinner />
            </div>
          )}
          {isError && (
            <div className="text-sm text-destructive">{error.message}</div>
          )}
        </div>
      </Card>

      <Card className="p-6">
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Existing Playbooks</h3>
          {listPlaybooksError ? (
            <Alert variant="destructive">
              {listPlaybooksError instanceof Error
                ? listPlaybooksError.message
                : 'Error loading playbooks'}
            </Alert>
          ) : (
            <DataTable table={table} isLoading={isPlaybooksPending} />
          )}
        </div>
      </Card>

      {selectedPlaybook && (
        <PlaybookDeleteDialog
          modalOpen={showDeleteDialog}
          setModalOpen={setShowDeleteDialog}
          playbook={selectedPlaybook}
          onDelete={confirmDelete}
          isPending={isDeleting}
        />
      )}
    </div>
  )
}

export default PlaybookManagement
