import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { useUserWorkspacesQuery } from 'models/queries/use-workspace-query'
import { UserInfo } from 'models/user-info'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { BaseAppPath, SettingsPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import {
  getAdminSettingsNavItems,
  getUserSettingsNavItems,
} from 'components/settings/settings-sidebar'

import { useSettingsState } from './settings-store'
import './settings.css'

const SettingsIndex = () => {
  const userInfo = useAuthUser()
  const [settingsUser, setSettingsUser, setAdministrableWorkspaces] =
    useSettingsState(
      useShallow((s) => [
        s.settingsUser,
        s.setSettingsUser,
        s.setAdministrableWorkspaces,
      ])
    )
  const selectedWorkspace = settingsUser?.workspace

  const workspaceDropdownWillRender =
    userInfo.administrableWorkspaces.length > 1
  // NOTE(1/23/25): useUserWorkspacesQuery BE request requires internal admin read or client admin read
  // which should be the case for any user with multiple administrable workspaces
  const { data: workspaceData } = useUserWorkspacesQuery(
    workspaceDropdownWillRender
  )

  useEffect(() => {
    if (!_.isNil(workspaceData)) {
      setAdministrableWorkspaces(workspaceData)
    }
  }, [workspaceData, setAdministrableWorkspaces])

  const navigate = useNavigateWithQueryParams()
  const location = useLocation()
  const settingsBasePath = BaseAppPath.Settings

  useEffect(() => {
    const newUser = new UserInfo(userInfo)
    setSettingsUser(newUser)
  }, [userInfo, setSettingsUser])

  useEffect(() => {
    if (
      location.pathname === settingsBasePath ||
      location.pathname === `${settingsBasePath}/`
    ) {
      for (const item of [
        ...getUserSettingsNavItems(settingsUser),
        ...getAdminSettingsNavItems(settingsUser),
      ]) {
        if (item.available) {
          navigate(item.href, { replace: true })
          return
        }
      }
      if (userInfo.IsInternalAdminReader) {
        navigate(SettingsPath.InternalAdminWorkspaces, {
          replace: true,
        })
      } else {
        navigate(BaseAppPath.Assistant, { replace: true })
      }
    }
  }, [
    location,
    location.pathname,
    userInfo,
    navigate,
    settingsBasePath,
    settingsUser,
  ])

  return (
    <React.Fragment key={selectedWorkspace?.id}>
      <Outlet />
    </React.Fragment>
  )
}

export default SettingsIndex
