import React, { useCallback, useEffect, useState } from 'react'

import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import { useIntegrationsStore } from 'stores/integrations-store'
import { Maybe } from 'types'

import { displayErrorMessage } from 'utils/toast'

import {
  authenticateSynclyAuthClient,
  getSynclyConnectorId,
  getSynclyTenantUrl,
} from 'components/settings/integrations/syncly-utils'
import { useSettingsState } from 'components/settings/settings-store'

// this will expand to more syncly integrations in the future
const integrationType = IntegrationType.I_MANAGE
const synclySettingsInstanceUrlSuffix = 'integration-plugin'

const SynclySettings = () => {
  const settingsUser = useSettingsState((s) => s.settingsUser)
  const [requestId, setRequestId] = useState<string>('')
  const [iframeHeight, setIframeHeight] = useState<number>(500)

  const [synclyAuth0Token, setSynclyAuth0Token] = useState<Maybe<string>>(null)

  const [synclySettingsOpenState, setSynclySettingsOpenState] =
    useIntegrationsStore(
      useShallow((state) => [
        state.synclySettingsOpenState,
        state.setSynclySettingsOpenState,
      ])
    )

  const shouldShowPicker =
    !_.isNil(synclySettingsOpenState) &&
    synclySettingsOpenState.integrationType === integrationType

  const cleanup = useCallback(() => {
    setSynclySettingsOpenState(null)
    setSynclyAuth0Token(null)
    setRequestId('')
  }, [setSynclySettingsOpenState])

  const handleIframeLoad = useCallback(
    async (instanceURL: string) => {
      if (_.isNil(synclySettingsOpenState)) return
      const token = await authenticateSynclyAuthClient(settingsUser?.id)
      setSynclyAuth0Token(token)
      const newRequestId = uuidv4()
      setRequestId(newRequestId)

      const connectorId = await getSynclyConnectorId(integrationType, token)

      const iframe = document.getElementById(
        'SynclySettingsEmbed'
      ) as HTMLIFrameElement
      if (iframe) {
        iframe.src = `${instanceURL}/${synclySettingsOpenState.settingsType}-connector?requestId=${newRequestId}&connectorId=${connectorId}`
      }
    },
    [settingsUser?.id, synclySettingsOpenState]
  )

  useEffect(() => {
    const loadIframe = async () => {
      if (shouldShowPicker) {
        if (_.isNil(settingsUser?.workspace.id)) {
          displayErrorMessage(`Unable to get tenant URL for ${integrationType}`)
          cleanup()
          return
        }
        let tenantUrl = settingsUser.workspace.getSynclyTenantUrl()
        if (_.isNil(tenantUrl)) {
          tenantUrl = await getSynclyTenantUrl(settingsUser.workspace.id)
        }
        if (_.isNil(tenantUrl)) {
          displayErrorMessage(`Unable to get tenant URL for ${integrationType}`)
          cleanup()
          return
        }
        await handleIframeLoad(`${tenantUrl}${synclySettingsInstanceUrlSuffix}`)
      }
    }
    void loadIframe()
  }, [handleIframeLoad, shouldShowPicker, settingsUser?.workspace, cleanup])

  const getLoginUserTokenObject = useCallback(
    (reqId: string, tokenType: string) => {
      if (_.isNil(synclyAuth0Token)) {
        displayErrorMessage(`Unable to authenticate with ${integrationType}`)
        cleanup()
        return null
      }
      return {
        type: `response:${tokenType}:${reqId}`,
        jwtToken: synclyAuth0Token,
      }
    },
    [cleanup, synclyAuth0Token]
  )

  useEffect(() => {
    if (!shouldShowPicker) return
    const handleMessage = async (event: MessageEvent) => {
      const dataType = event.data?.type

      if (typeof dataType !== 'string' || !dataType.includes(requestId)) return

      if (dataType === `request:cancel:${requestId}`) {
        cleanup()
        return
      }
      if (dataType === `request:close:${requestId}`) {
        // TODO: ask if syncly can send a distinct message on successful connection
        if (synclySettingsOpenState?.onSuccess) {
          synclySettingsOpenState.onSuccess()
        }
        cleanup()
      }

      if (
        dataType === `request:logintoken:${requestId}` ||
        dataType === `request:renewlogintoken:${requestId}`
      ) {
        const iframe = document.getElementById(
          'SynclySettingsEmbed'
        ) as HTMLIFrameElement
        const tokenType = dataType.split(':')[1]
        iframe?.contentWindow?.postMessage(
          getLoginUserTokenObject(requestId, tokenType),
          '*'
        )
      } else if (dataType === `request:iframesize:${requestId}`) {
        const height = parseInt(event.data?.data?.iFrameHeight) || 0
        setIframeHeight(Math.max(height, 500))
      } else if (dataType === `request:success:${requestId}`) {
        cleanup()
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [
    cleanup,
    getLoginUserTokenObject,
    requestId,
    shouldShowPicker,
    synclySettingsOpenState,
  ])
  if (!shouldShowPicker) {
    return null
  }

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center">
      <div className="fixed inset-0 bg-primary/80 backdrop-blur-sm" />
      <div className="relative z-[101] w-[500px]">
        <div className="flex justify-center">
          <iframe
            title="SynclySettingsEmbed"
            id="SynclySettingsEmbed"
            src="about:blank"
            width="100%"
            height={iframeHeight}
            style={{ border: 0 }}
            className="bg-white rounded-b-lg border border-primary shadow-lg"
          />
        </div>
      </div>
    </div>
  )
}

export default SynclySettings
