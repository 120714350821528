import { enableMapSet } from 'immer'
import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { VaultFolderMetadata } from 'openapi/models/VaultFolderMetadata'

import { SafeRecord } from 'utils/safe-types'
import { AnnotationById, Source, TaskType } from 'utils/task'
import { HarveySocketTask } from 'utils/use-harvey-socket'

import { ErrorPageTitle } from 'components/common/error/error'

import {
  VaultItem,
  GenerateNNResponseMetadata,
  QueryQuestion,
  ReviewAnswer,
  ColumnDataType,
} from './vault'
import {
  generateEmptyMetadata,
  generateEmptyVaultCurrentStreamingState,
  generateEmptyVaultReviewSocketState,
  updateProjectMetadata,
} from './vault-helpers'

// Enable the MapSet plugin for Immer
enableMapSet()

export interface VaultState {
  isAgGridEnterpriseLicenseRegistered: boolean
  isLayoutLoading: boolean
  isProjectLayoutLoading: boolean
  currentFolderId: string | null
  currentProject: VaultFolder | null

  // stores the file and folder info of the current project
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  parentIdToVaultFolderIds: SafeRecord<string, string[]>
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  folderIdToVaultFileIds: SafeRecord<string, string[]>

  // stores the file and folder info of all projects
  allFolderIdToVaultFolder: SafeRecord<string, VaultFolder>
  allParentIdToVaultFolderIds: SafeRecord<string, string[]>
  allFileIdToVaultFile: SafeRecord<string, VaultFile>
  allFolderIdToVaultFileIds: SafeRecord<string, string[]>

  // stores the file and folder info of the current project
  projectIdToFileIds: SafeRecord<string, string[]>
  projectIdToFolderIds: SafeRecord<string, string[]>

  rootVaultFolderIds: Set<string>
  localFileIds: Set<string>
  currentProjectMetadata: VaultFolderMetadata

  // stores the metadata only of the current project and its descendant folders
  foldersMetadata: SafeRecord<string, VaultFolderMetadata>

  // stores the metadata of all projects and their descendant folders
  allFoldersMetadata: SafeRecord<string, VaultFolderMetadata>

  queryId: string
  isNewVaultReviewQuery: boolean
  showProcessingProgress: SafeRecord<string, boolean>
  showRecentQueries: boolean
  recentlyUploadedFileIds: string[]
  requiresProjectDataRefetch: boolean
  exampleProjectIds: Set<string>
  areExampleProjectsLoaded: boolean
  sharedProjectIds: Set<string>
}

interface VaultAction {
  setAgGridEnterpriseLicenseRegistered: (
    agGridEnterpriseLicenseRegistered: boolean
  ) => void
  setIsLayoutLoading: (isLayoutLoading: boolean) => void
  setIsProjectLayoutLoading: (isProjectLayoutLoading: boolean) => void
  setCurrentFolderId: (id: string | null) => void
  setCurrentProject: (
    project: VaultFolder | null,
    shouldUpdateProjectMetadata?: boolean
  ) => void
  // updateVaultFile: updates a single file in the store with new data
  updateVaultFile: (
    fileId: string,
    newData: Partial<VaultFile>,
    projectId: string | undefined
  ) => void
  // upsertVaultFile: determines if the file needs to be updated and merges the new data with the existing data
  upsertVaultFiles: (files: VaultFile[], projectId: string | undefined) => void
  upsertVaultFolders: (
    folders: VaultFolder[],
    currentUserId: string,
    isExampleProject?: boolean,
    projectId?: string
  ) => void
  deleteVaultFiles: (fileIds: string[], projectId: string | undefined) => void
  deleteVaultFolders: (
    folderIds: string[],
    projectId: string | undefined
  ) => void
  addToProjectsMetadata: (
    projectMetadata: Record<string, VaultFolderMetadata>
  ) => void
  updateProjectMetadata: () => void
  updateProjectMetadataLastFileUploadedAt: (lastFileUploadedAt: string) => void
  updateProjectMetadataClientMatterId: (clientMatterId: string) => void
  setQueryId: (queryId: string) => void
  setIsNewVaultReviewQuery: (isNewVaultReviewQuery: boolean) => void
  setShowProcessingProgress: (
    projectId: string,
    showProcessingProgress: boolean
  ) => void
  setShowRecentQueries: (showRecentQueries: boolean) => void
  setRecentlyUploadedFileIds: (fileIds: string[]) => void
  setRequiresProjectDataRefetch: (requiresProjectDataRefetch: boolean) => void
  setExampleProjectIds: (exampleProjectIds: Set<string>) => void
  setAreExampleProjectsLoaded: (areExampleProjectsLoaded: boolean) => void
  markInProgressReviewTask: (queryId: string) => void
  updateQueryIdToStateTitle: (queryId: string, title: string) => void
}

interface VaultQueryBoxState {
  pendingQuery: string
}

interface VaultQueryBoxAction {
  setPendingQuery: (pendingQuery: string) => void
}

interface VaultSheetState {
  activeDocument: UploadedFile | null
}

interface VaultSheetAction {
  setActiveDocument: (activeDocument: UploadedFile | null) => void
}

interface VaultDialogState {
  areUploadButtonsDisabled: boolean
  isCreateFolderDialogOpen: boolean
  currentCreateFolderFolderId: string | null
  isUploadFilesDialogOpen: boolean
  currentUploadFilesFolderId: string | null
  isDeleteDialogOpen: boolean
  deleteRecords: VaultItem[]
  isRenameDialogOpen: boolean
  renameRecord: VaultItem | null
  isMoveDialogOpen: boolean
  moveRecords: VaultItem[]
  isReviewQuerySelectionDialogOpen: boolean
  isExportDialogOpen: boolean
  isAddFilesDialogOpen: boolean
  isVaultAssistantModalOpen: boolean
  isEditClientMatterDialogOpen: boolean
  isDuplicateModalOpen: boolean
  isColumnBuilderDialogOpen: boolean
  isEditQueryTitleDialogOpen: boolean
}

interface VaultDialogAction {
  setAreUploadButtonsDisabled: (areDisabled: boolean) => void
  setIsCreateFolderDialogOpen: (isOpen: boolean) => void
  setCurrentCreateFolderFolderId: (folderId: string | null) => void
  setIsUploadFilesDialogOpen: (isOpen: boolean) => void
  setCurrentUploadFilesFolderId: (folderId: string | null) => void
  setIsDeleteDialogOpen: (isOpen: boolean) => void
  setDeleteRecords: (records: VaultItem[]) => void
  setIsRenameDialogOpen: (isOpen: boolean) => void
  setRenameRecord: (record: VaultItem | null) => void
  setIsMoveDialogOpen: (isOpen: boolean) => void
  setMoveRecords: (records: VaultItem[]) => void
  setIsReviewQuerySelectionDialogOpen: (isOpen: boolean) => void
  setIsExportDialogOpen: (isOpen: boolean) => void
  setIsAddFilesDialogOpen: (isOpen: boolean) => void
  setIsVaultAssistantModalOpen: (isOpen: boolean) => void
  setIsEditClientMatterDialogOpen: (isOpen: boolean) => void
  setIsDuplicateModalOpen: (isOpen: boolean) => void
  setIsColumnBuilderDialogOpen: (isOpen: boolean) => void
  setIsEditQueryTitleDialogOpen: (isOpen: boolean) => void
}

export type VaultExtraSocketState = {
  isFromHistory: boolean
  title: string
  taskType: TaskType | null
  vaultFolderId: string | null
  numFiles: number
  numQuestions: number
  fileIds: string[]
  sourcedFileIds: string[]
  // When the query is first created
  createdAt: Date | null
  // When the query is started recently (could be a retry)
  startedAt: Date | null
  completedAt: Date | null
  pausedAt: Date | null
  failedAt: Date | null
  maxTokenLimitReached: boolean
  creatorUserEmail: string | null
}

export type VaultReviewSocketState = {
  followUpQueries: { query: string; questionIds: string[] }[]
  questions: QueryQuestion[]
  questionsNotAnswered: QueryQuestion[]
  maxQuestionCharacterLength: number
  minQuestionCharacterLength: number
  columnHeaders: { id: string; text: string; columnDataType?: ColumnDataType }[]
  answers: SafeRecord<string, ReviewAnswer[]>
  errors: SafeRecord<string, { columnId: string; text: string }[]>
  fileIdToSources: SafeRecord<string, Source[]>
  processedFileIds: string[]
  suppressedFileIds: string[]
  eta: string | null
  columnOrder: string[]
  isWorkflowRepsWarranties: boolean
}

// This state is for either the query that VaultQueryDetail is serving right
// now, or the one that is being generated by the VaultQueryBox.
export type VaultCurrentStreamingState = VaultExtraSocketState & {
  query: string
  isLoading: boolean
  headerText: string
  response: string
  queryId: string
  progress: number
  sources: Source[]
  annotations: AnnotationById
}

// This state is for all the queries that are pending, or the cached result
// of the queries that have been completed and fetched.
interface VaultStreamingState {
  queryIdToState: SafeRecord<string, VaultCurrentStreamingState>
  queryIdToReviewState: SafeRecord<string, VaultReviewSocketState>
}

export type VaultSocketTask = HarveySocketTask &
  VaultExtraSocketState &
  VaultReviewSocketState

export type VaultSocketSetter = (task: Partial<VaultSocketTask>) => void

interface VaultStreamingAction {
  setTask: VaultSocketSetter
  setReviewTask: VaultSocketSetter
}

interface VaultError {
  title?: ErrorPageTitle
  message: string
  cta: {
    message: string
    redirectUri: string
  }
}

interface VaultErrorState {
  error: VaultError | null
}

interface VaultErrorAction {
  setError: (error: VaultError | null) => void
}

export interface AddColumnPopoverPosition {
  left: number
  top: number
  question: string
  header: string
  columnDataType: ColumnDataType | undefined
  colId?: string
}

interface VaultStartFromScratchStore {
  addColumnPopoverPosition: AddColumnPopoverPosition | null
}

interface VaultStartFromScratchActions {
  setAddColumnPopoverPosition: (
    popoverPosition: AddColumnPopoverPosition | null
  ) => void
}

function fileEquals(file1: VaultFile, file2: VaultFile): boolean {
  return (
    file1.id === file2.id ||
    (file1.name === file2.name && file1.vaultFolderId === file2.vaultFolderId)
  )
}

function shouldUpdateFile(
  existingFile: VaultFile,
  newFile: VaultFile
): boolean {
  if (!fileEquals(existingFile, newFile)) {
    return false
  }
  // If the file has a newer updatedAt time, update it
  return (
    newFile.updatedAt > existingFile.updatedAt ||
    // If the file has a new URL, update it
    (!_.isEmpty(newFile.url) && newFile.url !== existingFile.url) ||
    (!_.isEmpty(newFile.docAsPdfUrl) &&
      newFile.docAsPdfUrl !== existingFile.docAsPdfUrl)
  )
}

function shouldUpdateFolder(
  existingFolder: VaultFolder,
  newFolder: VaultFolder
): boolean {
  // Update folder if it has been 1) updated or 2) recently opened
  return (
    newFolder.updatedAt > existingFolder.updatedAt ||
    (!!newFolder.lastOpenedAt &&
      !!existingFolder.lastOpenedAt &&
      newFolder.lastOpenedAt > existingFolder.lastOpenedAt) ||
    (!!newFolder.lastOpenedAt && !existingFolder.lastOpenedAt)
  )
}

function updatedFile(newFile: VaultFile, existingFile: VaultFile): VaultFile {
  if (!shouldUpdateFile(existingFile, newFile)) {
    return existingFile
  }
  let updatedFile = { ...existingFile }
  if (newFile.updatedAt > existingFile.updatedAt) {
    updatedFile = { ...newFile }
  }
  updatedFile.url = !_.isEmpty(newFile.url) ? newFile.url : existingFile.url
  updatedFile.docAsPdfUrl = !_.isEmpty(newFile.docAsPdfUrl)
    ? newFile.docAsPdfUrl
    : existingFile.docAsPdfUrl
  return updatedFile
}

function updatedFolder(
  newFolder: VaultFolder,
  existingFolder: VaultFolder
): VaultFolder {
  if (!shouldUpdateFolder(existingFolder, newFolder)) {
    return existingFolder
  }
  let updatedFolder = { ...existingFolder }
  if (newFolder.updatedAt > existingFolder.updatedAt) {
    updatedFolder = { ...newFolder }
  }
  updatedFolder.lastOpenedAt = newFolder.lastOpenedAt
    ? newFolder.lastOpenedAt
    : existingFolder.lastOpenedAt
  return updatedFolder
}

export const useVaultStore = create(
  devtools(
    immer<
      VaultState &
        VaultAction &
        VaultQueryBoxState &
        VaultQueryBoxAction &
        VaultDialogState &
        VaultDialogAction &
        VaultSheetState &
        VaultSheetAction &
        VaultStreamingState &
        VaultStreamingAction &
        VaultErrorState &
        VaultErrorAction &
        VaultStartFromScratchStore &
        VaultStartFromScratchActions
    >((set) => ({
      // VaultState
      isAgGridEnterpriseLicenseRegistered: false,
      isLayoutLoading: true,
      isProjectLayoutLoading: false,
      currentFolderId: null,
      currentProject: null,
      folderIdToVaultFolder: {},
      parentIdToVaultFolderIds: {},
      rootVaultFolderIds: new Set(),
      fileIdToVaultFile: {},
      folderIdToVaultFileIds: {},
      allFolderIdToVaultFolder: {},
      allParentIdToVaultFolderIds: {},
      allFileIdToVaultFile: {},
      allFolderIdToVaultFileIds: {},
      projectIdToFileIds: {},
      projectIdToFolderIds: {},
      localFileIds: new Set(),
      currentProjectMetadata: generateEmptyMetadata(''),
      foldersMetadata: {},
      allFoldersMetadata: {},
      queryId: '',
      isNewVaultReviewQuery: false,
      showProcessingProgress: {},
      showRecentQueries: true,
      recentlyUploadedFileIds: [],
      requiresProjectDataRefetch: false,
      exampleProjectIds: new Set(),
      areExampleProjectsLoaded: false,
      sharedProjectIds: new Set(),
      // VaultQueryBoxState
      pendingQuery: '',
      // VaultDialogState
      areUploadButtonsDisabled: true,
      isCreateFolderDialogOpen: false,
      currentCreateFolderFolderId: null,
      isUploadFilesDialogOpen: false,
      currentUploadFilesFolderId: null,
      isDeleteDialogOpen: false,
      deleteRecords: [],
      isRenameDialogOpen: false,
      renameRecord: null,
      isMoveDialogOpen: false,
      moveRecords: [],
      isReviewQuerySelectionDialogOpen: false,
      isExportDialogOpen: false,
      isAddFilesDialogOpen: false,
      isVaultAssistantModalOpen: false,
      isEditClientMatterDialogOpen: false,
      isDuplicateModalOpen: false,
      isColumnBuilderDialogOpen: false,
      isEditQueryTitleDialogOpen: false,
      // VaultSheetState
      activeDocument: null,
      // VaultStreamingState
      queryIdToState: {},
      queryIdToReviewState: {},
      // VaultErrorState
      error: null,
      // VaultStartFromScratchStore
      addColumnPopoverPosition: null,
      // VaultAction
      setAgGridEnterpriseLicenseRegistered: (
        isAgGridEnterpriseLicenseRegistered: boolean
      ) => set({ isAgGridEnterpriseLicenseRegistered }),
      setIsLayoutLoading: (isLayoutLoading: boolean) =>
        set({ isLayoutLoading }),
      setIsProjectLayoutLoading: (isProjectLayoutLoading: boolean) =>
        set({ isProjectLayoutLoading }),
      setCurrentFolderId: (folderId) => set({ currentFolderId: folderId }),
      setCurrentProject: (
        project,
        shouldUpdateProjectMetadataAndUserId = false
      ) =>
        set((state) => {
          if (!project) {
            return {
              currentProject: null,
              currentProjectMetadata: generateEmptyMetadata(''),
            }
          }

          const projectMetadata =
            state.allFoldersMetadata[project.id] ||
            generateEmptyMetadata(project.id)

          if (shouldUpdateProjectMetadataAndUserId && !projectMetadata.userId) {
            // Read the userId from the project object directly if it doesn't already
            // exist in the project metadata (e.g. when creating a new project)
            projectMetadata.userId = project.userId
            projectMetadata.userEmail = project.userEmail
          }

          state.currentProject = project
          state.currentProjectMetadata = projectMetadata

          if (shouldUpdateProjectMetadataAndUserId) {
            state.allFoldersMetadata[project.id] = projectMetadata
          }

          // update files and folders
          const fileIdToVaultFile: SafeRecord<string, VaultFile> = {}
          const folderIdToVaultFolder: SafeRecord<string, VaultFolder> = {}
          const folderIdToVaultFileIds: SafeRecord<string, string[]> = {}
          const parentIdToVaultFolderIds: SafeRecord<string, string[]> = {}

          const descendantFileIds =
            state.projectIdToFileIds[state.currentProject.id] ?? []
          const descendantFolderIds =
            state.projectIdToFolderIds[state.currentProject.id] ?? []

          descendantFileIds
            .filter((fileId) => !!state.allFileIdToVaultFile[fileId])
            .forEach((fileId) => {
              const file = state.allFileIdToVaultFile[fileId]!
              fileIdToVaultFile[fileId] = file
              folderIdToVaultFileIds[file.vaultFolderId] ??= []
              folderIdToVaultFileIds[file.vaultFolderId]!.push(fileId)
            })

          descendantFolderIds
            .filter((folderId) => !!state.allFolderIdToVaultFolder[folderId])
            .forEach((folderId) => {
              const folder = state.allFolderIdToVaultFolder[folderId]!
              folderIdToVaultFolder[folderId] = folder
              if (folder.parentId) {
                parentIdToVaultFolderIds[folder.parentId] ??= []
                parentIdToVaultFolderIds[folder.parentId]!.push(folder.id)
              }
            })
          folderIdToVaultFolder[project.id] = project

          state.fileIdToVaultFile = fileIdToVaultFile
          state.folderIdToVaultFolder = folderIdToVaultFolder
          state.folderIdToVaultFileIds = folderIdToVaultFileIds
          state.parentIdToVaultFolderIds = parentIdToVaultFolderIds

          // update foldersMetadata
          state.foldersMetadata = Object.fromEntries(
            descendantFolderIds.map((folderId) => [
              folderId,
              state.allFoldersMetadata[folderId] ||
                generateEmptyMetadata(folderId),
            ])
          )
          state.foldersMetadata[project.id] = projectMetadata
        }),
      updateVaultFile: (
        fileId: string,
        newData: Partial<VaultFile>,
        projectId: string | undefined
      ) =>
        set((state) => {
          const file = state.allFileIdToVaultFile[fileId]
          if (!file) return
          state.allFileIdToVaultFile[fileId] = { ...file, ...newData }

          if (projectId === state.currentProject?.id) {
            state.fileIdToVaultFile[fileId] = { ...file, ...newData }
          }
        }),
      upsertVaultFiles: (files, projectId) =>
        set((state) => {
          if (projectId) {
            const project = state.folderIdToVaultFolder[projectId]
            if (project && !!project.parentId) {
              throw new Error(
                `${projectId} is not a root folder. Please fix the logic.`
              )
            }
          }
          const isCurrentProject = projectId === state.currentProject?.id
          const updatedLocalFileIds = state.localFileIds
          files.forEach((file) => {
            // The existing file might be local file that has not been uploaded to the server yet
            // The id format is vaultFolderId-fileName
            const localFileId = `${file.vaultFolderId}-${file.name}`
            const existingFile =
              state.allFileIdToVaultFile[file.id] ??
              state.allFileIdToVaultFile[localFileId]
            if (localFileId in state.allFileIdToVaultFile) {
              // Remove the local file id from dictionary
              delete state.allFileIdToVaultFile[localFileId]
              if (isCurrentProject) {
                delete state.fileIdToVaultFile[localFileId]
              }
            }
            if (existingFile && shouldUpdateFile(existingFile, file)) {
              // Update the file
              state.allFileIdToVaultFile[file.id] = updatedFile(
                file,
                existingFile
              )
              if (isCurrentProject) {
                state.fileIdToVaultFile[file.id] = updatedFile(
                  file,
                  existingFile
                )
              }
              if (
                file.vaultFolderId !== existingFile.vaultFolderId ||
                file.id !== existingFile.id
              ) {
                state.allFolderIdToVaultFileIds[existingFile.vaultFolderId] =
                  state.allFolderIdToVaultFileIds[
                    existingFile.vaultFolderId
                  ]!.filter((id) => id !== existingFile.id)
                state.allFolderIdToVaultFileIds[file.vaultFolderId] ??= []
                state.allFolderIdToVaultFileIds[file.vaultFolderId]!.push(
                  file.id
                )
                if (file.id !== existingFile.id) {
                  state.projectIdToFileIds[file.vaultProjectId] ??= []
                  state.projectIdToFileIds[file.vaultProjectId]!.push(file.id)
                }

                if (isCurrentProject) {
                  state.folderIdToVaultFileIds[existingFile.vaultFolderId] =
                    state.folderIdToVaultFileIds[
                      existingFile.vaultFolderId
                    ]!.filter((id) => id !== existingFile.id)
                  state.folderIdToVaultFileIds[file.vaultFolderId] ??= []
                  state.folderIdToVaultFileIds[file.vaultFolderId]!.push(
                    file.id
                  )
                }
              }
            } else if (
              !existingFile ||
              !(file.id in state.allFileIdToVaultFile)
            ) {
              // Add the file if it's not in the dictionary
              state.allFileIdToVaultFile[file.id] = file
              state.allFolderIdToVaultFileIds[file.vaultFolderId] ??= []
              state.allFolderIdToVaultFileIds[file.vaultFolderId]!.push(file.id)
              state.projectIdToFileIds[file.vaultProjectId] ??= []
              state.projectIdToFileIds[file.vaultProjectId]!.push(file.id)

              if (isCurrentProject) {
                state.fileIdToVaultFile[file.id] = file
                state.folderIdToVaultFileIds[file.vaultFolderId] ??= []
                state.folderIdToVaultFileIds[file.vaultFolderId]!.push(file.id)
              }
            }
            // Update the local file ids
            if (file.id === localFileId) {
              updatedLocalFileIds.add(localFileId)
            } else {
              updatedLocalFileIds.delete(localFileId)
            }
          })

          state.localFileIds = updatedLocalFileIds
        }),
      // eslint-disable-next-line max-params
      upsertVaultFolders: (
        folders,
        currentUserId,
        isExampleProject,
        projectId
      ) =>
        set((state) => {
          if (projectId) {
            const project = state.folderIdToVaultFolder[projectId]
            if (project && !!project.parentId) {
              throw new Error(
                `${projectId} is not a root folder. Please fix the logic.`
              )
            }
          }
          const isCurrentProject = projectId === state.currentProject?.id
          const updatedRootVaultFolderIds = state.rootVaultFolderIds
          const updatedSharedProjectIds = state.sharedProjectIds
          folders.forEach((folder) => {
            const existingFolder = state.allFolderIdToVaultFolder[folder.id]
            if (existingFolder && shouldUpdateFolder(existingFolder, folder)) {
              const updatedFolderObject = updatedFolder(folder, existingFolder)
              state.allFolderIdToVaultFolder[folder.id] = updatedFolderObject
              // update for current project
              if (isCurrentProject) {
                state.folderIdToVaultFolder[folder.id] = updatedFolderObject
                state.currentProject = updatedFolderObject
                state.currentProjectMetadata = {
                  ...state.currentProjectMetadata,
                  name: updatedFolderObject.name,
                }
              }
              if (folder.parentId !== existingFolder.parentId) {
                if (existingFolder.parentId) {
                  state.allParentIdToVaultFolderIds[existingFolder.parentId] =
                    state.allParentIdToVaultFolderIds[
                      existingFolder.parentId
                    ]!.filter((id) => id !== existingFolder.id)
                  if (isCurrentProject) {
                    state.parentIdToVaultFolderIds[existingFolder.parentId] =
                      state.parentIdToVaultFolderIds[
                        existingFolder.parentId
                      ]!.filter((id) => id !== existingFolder.id)
                  }
                  if (state.folderIdToVaultFolder[existingFolder.parentId]) {
                    state.parentIdToVaultFolderIds[
                      existingFolder.parentId
                    ]!.filter((id) => id !== existingFolder.id)
                    if (isCurrentProject) {
                      state.parentIdToVaultFolderIds[
                        existingFolder.parentId
                      ]!.filter((id) => id !== existingFolder.id)
                    }
                  }
                } else {
                  updatedRootVaultFolderIds.delete(existingFolder.id)
                }
                if (folder.parentId) {
                  state.allParentIdToVaultFolderIds[folder.parentId] ??= []
                  state.allParentIdToVaultFolderIds[folder.parentId]!.push(
                    folder.id
                  )
                  // update for current project
                  if (isCurrentProject) {
                    state.parentIdToVaultFolderIds[folder.parentId] ??= []
                    state.parentIdToVaultFolderIds[folder.parentId]!.push(
                      folder.id
                    )
                  }
                } else {
                  updatedRootVaultFolderIds.add(folder.id)
                }
              }
            } else if (!existingFolder) {
              state.allFolderIdToVaultFolder[folder.id] = folder
              state.projectIdToFolderIds[folder.vaultProjectId] ??= []
              state.projectIdToFolderIds[folder.vaultProjectId]!.push(folder.id)
              if (isCurrentProject) {
                state.folderIdToVaultFolder[folder.id] = folder
              }
              if (folder.parentId) {
                state.allParentIdToVaultFolderIds[folder.parentId] ??= []
                state.allParentIdToVaultFolderIds[folder.parentId]!.push(
                  folder.id
                )
                // update for current project
                if (isCurrentProject) {
                  state.parentIdToVaultFolderIds[folder.parentId] ??= []
                  state.parentIdToVaultFolderIds[folder.parentId]!.push(
                    folder.id
                  )
                }
              } else {
                updatedRootVaultFolderIds.add(folder.id)
              }
            }

            // mark as shared project if user id is not the current user id
            if (folder.userId !== currentUserId && !isExampleProject) {
              updatedSharedProjectIds.add(folder.id)
            }
          })

          state.rootVaultFolderIds = updatedRootVaultFolderIds
          state.sharedProjectIds = updatedSharedProjectIds
        }),
      deleteVaultFiles: (fileIds, projectId) =>
        set((state) => {
          const isCurrentProject = projectId === state.currentProject?.id
          const updatedAllFileIdToVaultFile = { ...state.allFileIdToVaultFile }
          fileIds.forEach((id) => {
            delete updatedAllFileIdToVaultFile[id]
          })
          const updatedFileIdToVaultFile = { ...state.fileIdToVaultFile }
          if (isCurrentProject) {
            fileIds.forEach((id) => {
              delete updatedFileIdToVaultFile[id]
            })
          }
          return {
            allFileIdToVaultFile: updatedAllFileIdToVaultFile,
            fileIdToVaultFile: updatedFileIdToVaultFile,
          }
        }),
      deleteVaultFolders: (folderIds, projectId) =>
        set((state) => {
          const isCurrentProject = projectId === state.currentProject?.id
          const updatedAllFolderIdToVaultFolder = {
            ...state.allFolderIdToVaultFolder,
          }
          folderIds.forEach((id) => {
            delete updatedAllFolderIdToVaultFolder[id]
          })
          const updatedFolderIdToVaultFolder = {
            ...state.folderIdToVaultFolder,
          }
          if (isCurrentProject) {
            folderIds.forEach((id) => {
              delete updatedFolderIdToVaultFolder[id]
            })
          }
          return {
            allFolderIdToVaultFolder: updatedAllFolderIdToVaultFolder,
            folderIdToVaultFolder: updatedFolderIdToVaultFolder,
          }
        }),
      addToProjectsMetadata: (projectsMetadata) => {
        set((state) => {
          state.allFoldersMetadata = {
            ...state.allFoldersMetadata,
            ...projectsMetadata,
          }
          if (state.currentProject) {
            state.projectIdToFolderIds[state.currentProject.id]?.forEach(
              (folderId) => {
                if (projectsMetadata[folderId]) {
                  state.foldersMetadata[folderId] = projectsMetadata[folderId]
                }
              }
            )
          }
          updateProjectMetadata(state)
        })
      },
      updateProjectMetadata: () => {
        set((state) => updateProjectMetadata(state))
      },
      updateProjectMetadataLastFileUploadedAt: (lastFileUploadedAt: string) => {
        set((state) => ({
          currentProjectMetadata: {
            ...state.currentProjectMetadata,
            lastFileUploadedAt: lastFileUploadedAt,
          },
        }))
      },
      updateProjectMetadataClientMatterId: (clientMatterId: string) => {
        set((state) => ({
          currentProjectMetadata: {
            ...state.currentProjectMetadata,
            clientMatterId: clientMatterId,
          },
        }))
      },
      setQueryId: (queryId) => set({ queryId }),
      setIsNewVaultReviewQuery: (isNewVaultReviewQuery) =>
        set({ isNewVaultReviewQuery }),
      setShowProcessingProgress: (projectId, showProcessingProgress) =>
        set((state) => ({
          showProcessingProgress: {
            ...state.showProcessingProgress,
            [projectId]: showProcessingProgress,
          },
        })),
      setShowRecentQueries: (showRecentQueries: boolean) =>
        set({ showRecentQueries }),
      setRecentlyUploadedFileIds: (fileIds: string[]) =>
        set({ recentlyUploadedFileIds: fileIds }),
      setRequiresProjectDataRefetch: (requiresProjectDataRefetch) =>
        set({ requiresProjectDataRefetch }),
      setExampleProjectIds: (exampleProjectIds: Set<string>) =>
        set({ exampleProjectIds }),
      setAreExampleProjectsLoaded: (areExampleProjectsLoaded: boolean) =>
        set({ areExampleProjectsLoaded }),
      markInProgressReviewTask: (queryId: string) =>
        set((state) => {
          if (state.queryIdToState[queryId]) {
            state.queryIdToState[queryId]!.isLoading = true
          }
        }),
      updateQueryIdToStateTitle: (queryId: string, title: string) =>
        set((state) => {
          if (state.queryIdToState[queryId]) {
            state.queryIdToState[queryId]!.title = title
          }
        }),
      // VaultQueryBoxAction
      setPendingQuery: (pendingQuery: string) => set({ pendingQuery }),
      // VaultSheetAction
      setActiveDocument: (activeDocument) => set({ activeDocument }),
      // VaultDialogAction
      setAreUploadButtonsDisabled: (areDisabled) =>
        set({ areUploadButtonsDisabled: areDisabled }),
      setIsCreateFolderDialogOpen: (isOpen) =>
        set({ isCreateFolderDialogOpen: isOpen }),
      setCurrentCreateFolderFolderId: (folderId) =>
        set({ currentCreateFolderFolderId: folderId }),
      setIsUploadFilesDialogOpen: (isOpen) =>
        set({ isUploadFilesDialogOpen: isOpen }),
      setCurrentUploadFilesFolderId: (folderId) =>
        set({ currentUploadFilesFolderId: folderId }),
      setIsDeleteDialogOpen: (isOpen) => set({ isDeleteDialogOpen: isOpen }),
      setDeleteRecords: (records) => set({ deleteRecords: records }),
      setIsRenameDialogOpen: (isOpen) => set({ isRenameDialogOpen: isOpen }),
      setRenameRecord: (record) => set({ renameRecord: record }),
      setIsMoveDialogOpen: (isOpen) => set({ isMoveDialogOpen: isOpen }),
      setMoveRecords: (records) => set({ moveRecords: records }),
      setIsReviewQuerySelectionDialogOpen: (isOpen) =>
        set({ isReviewQuerySelectionDialogOpen: isOpen }),
      setIsExportDialogOpen: (isOpen) => set({ isExportDialogOpen: isOpen }),
      setIsAddFilesDialogOpen: (isOpen) =>
        set({ isAddFilesDialogOpen: isOpen }),
      setIsVaultAssistantModalOpen: (isOpen) =>
        set({ isVaultAssistantModalOpen: isOpen }),
      setIsEditClientMatterDialogOpen: (isOpen) =>
        set({ isEditClientMatterDialogOpen: isOpen }),
      setIsDuplicateModalOpen: (isOpen) =>
        set({ isDuplicateModalOpen: isOpen }),
      setIsColumnBuilderDialogOpen: (isOpen) =>
        set({ isColumnBuilderDialogOpen: isOpen }),
      setIsEditQueryTitleDialogOpen: (isOpen) =>
        set({ isEditQueryTitleDialogOpen: isOpen }),
      // VaultStreamingAction
      setTask: (socketState: Partial<VaultSocketTask>) =>
        set((state) => {
          const queryId = socketState.queryId ?? state.queryId
          if (
            state.queryIdToState[queryId] &&
            (socketState.isFromHistory ?? false) !==
              state.queryIdToState[queryId]!.isFromHistory
          ) {
            // If the new state data source is not the same as the existing state, we don't
            // want to override the existing state with the new state.
            return
          }

          state.queryIdToState[queryId] ??=
            generateEmptyVaultCurrentStreamingState(queryId)

          if (
            _.isNil(socketState.metadata) ||
            !('type' in socketState.metadata)
          ) {
            // When we have metadata in the socket state but don't have type, it means
            // it's end of the streaming and we are getting the final response, or we
            // are getting the history event of a N:1 query.
            state.queryIdToState[queryId] = {
              ...state.queryIdToState[queryId]!,
              ...socketState,
              ...socketState.metadata,
            }
            return
          }

          // Reaching this condition means the metadata type is not empty
          // In theory, N:1 query metadata only includes title, but we re-use N:N metadata
          // because linter will complain of unnecessary check if property type is a single literal
          const metadata = socketState.metadata as GenerateNNResponseMetadata

          if (metadata.type === 'title' && socketState.response !== undefined) {
            state.queryIdToState[queryId]!.title = socketState.response
            return
          }

          if (metadata.type === 'max_token_limit_reached') {
            state.queryIdToState[queryId]!.maxTokenLimitReached = true
            return
          }

          if (
            metadata.type === 'sourced_file_ids' &&
            socketState.response !== undefined
          ) {
            // Update the sourced file ids of the query
            state.queryIdToState[queryId]!.sourcedFileIds =
              socketState.response.split(',')
            return
          }

          console.info('Socket state', socketState)
          console.error(
            `Unhandled socket state from Vault ask query during ${socketState.headerText}`
          )
        }),
      setReviewTask: (socketState: Partial<VaultSocketTask>) =>
        set((state) => {
          const queryId = socketState.queryId ?? state.queryId
          if (
            state.queryIdToState[queryId] &&
            socketState.isFromHistory !== undefined &&
            socketState.isFromHistory !==
              state.queryIdToState[queryId]!.isFromHistory
          ) {
            // If the new state data source is not the same as the existing state, we don't
            // want to override the existing state with the new state.
            return
          }

          state.queryIdToState[queryId] ??=
            generateEmptyVaultCurrentStreamingState(queryId)
          state.queryIdToReviewState[queryId] ??=
            generateEmptyVaultReviewSocketState()

          if (
            _.isNil(socketState.metadata) ||
            !('type' in socketState.metadata)
          ) {
            // When we have metadata in the socket state but don't have type, it means
            // it's end of the streaming and we are getting the final response, or we
            // are getting the history event of a review query.
            state.queryIdToState[queryId] = {
              ...state.queryIdToState[queryId]!,
              ...socketState,
              ...socketState.metadata,
            }
            state.queryIdToReviewState[queryId] = {
              ...state.queryIdToReviewState[queryId]!,
              ...socketState,
              ...socketState.metadata,
              fileIdToSources: {
                ...state.queryIdToReviewState[queryId]!.fileIdToSources,
                ..._.groupBy(
                  socketState.sources,
                  (source) => source.documentId
                ),
              },
            }
            return
          }

          const metadata = socketState.metadata as GenerateNNResponseMetadata

          if (metadata.type === 'title' && socketState.response !== undefined) {
            // Update the title of the query
            // TODO: setTask already handles this, but since setTask and setReviewTask are mutually exclusive,
            // we need to duplicate the code to handle this here.
            state.queryIdToState[queryId]!.title = socketState.response
            return
          }

          if (
            metadata.type === 'header' &&
            socketState.response !== undefined &&
            metadata.columnId
          ) {
            // Update the columns of the query
            state.queryIdToReviewState[queryId]!.columnHeaders.push({
              id: metadata.columnId,
              text: socketState.response,
              columnDataType: metadata.columnDataType,
            })
            return
          }

          if (
            metadata.type === 'cell' &&
            socketState.response !== undefined &&
            metadata.fileId &&
            metadata.columnId
          ) {
            // Update the response of a cell in the query
            state.queryIdToReviewState[queryId]!.answers[metadata.fileId] ??= []
            state.queryIdToReviewState[queryId]!.answers[metadata.fileId]!.push(
              {
                columnId: metadata.columnId,
                long: metadata.longResponse ?? false,
                text: socketState.response,
                columnDataType: metadata.columnDataType,
              }
            )
            // Clear error for this cell if it exists
            if (
              state.queryIdToReviewState[queryId]!.errors[
                metadata.fileId
              ]?.find((error) => error.columnId === metadata.columnId)
            ) {
              const updatedErrors = state.queryIdToReviewState[queryId]!.errors[
                metadata.fileId
              ]!.filter((error) => error.columnId !== metadata.columnId)
              if (updatedErrors.length === 0) {
                delete state.queryIdToReviewState[queryId]!.errors[
                  metadata.fileId
                ]
              } else {
                state.queryIdToReviewState[queryId]!.errors[metadata.fileId] =
                  updatedErrors
              }
            }
            return
          }

          if (
            metadata.type === 'cell_error' &&
            socketState.response !== undefined &&
            metadata.fileId &&
            metadata.columnId
          ) {
            // Update the error of a cell in the query
            state.queryIdToReviewState[queryId]!.errors[metadata.fileId] ??= []
            state.queryIdToReviewState[queryId]!.errors[metadata.fileId]!.push({
              columnId: metadata.columnId,
              text: socketState.response,
            })
            return
          }

          if (metadata.type === 'sources' && metadata.fileId) {
            // Update the sources of a file in the query
            state.queryIdToReviewState[queryId]!.fileIdToSources[
              metadata.fileId
            ] = socketState.sources
            return
          }

          if (metadata.type === 'eta' && socketState.response !== undefined) {
            // Update the ETA of the query
            state.queryIdToReviewState[queryId]!.eta = socketState.response
            return
          }

          if (
            metadata.type === 'processed_file_ids' &&
            socketState.response !== undefined
          ) {
            // Update the processed file ids of the query
            state.queryIdToReviewState[queryId]!.processedFileIds =
              socketState.response.split(',')
            return
          }

          console.info('Socket state', socketState)
          console.error(
            `Unhandled socket state from Vault review query during ${socketState.headerText}`
          )
        }),
      // VaultErrorAction
      setError: (error) => set({ error }),
      // VaultStartFromScratchActions
      setAddColumnPopoverPosition: (
        popoverPosition: AddColumnPopoverPosition | null
      ) => set({ addColumnPopoverPosition: popoverPosition }),
    }))
  )
)
