import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import { isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { useFileCache } from 'stores/file-cache'

import { WordSection, WordSectionType } from 'utils/docx'
import {
  getHrvyInfoMetadata as HrvyInfoMetadataType,
  getSourceClicked,
  HrvyInfoElement,
} from 'utils/source'
import { AnnotationById, Source, TaskType } from 'utils/task'
import { displaySuccessMessage } from 'utils/toast'
import { cn, findScrollingContainer, isAzureBlobPdf } from 'utils/utils'

import AssistantLoadingState from 'components/assistant/components/assistant-loading-state'
import {
  AssistantThreadContent,
  AssistantThreadLayout,
} from 'components/assistant/components/assistant-thread-layout'
import { useIsCuatrecasas } from 'components/assistant/hooks/use-is-cuatrecasas'
import { useIsPwcTax } from 'components/assistant/hooks/use-is-pwc-tax'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { AssistantMessage } from 'components/assistant/types'
import { handleExport as handleExportHelper } from 'components/assistant/utils/assistant-export'
import {
  FILE_ID_PARAM,
  MESSAGE_ID_PARAM,
  RESET_PARAM,
  REVISION_ID_PARAM,
  SOURCE_ID_PARAM,
  getSourceDocumentId,
  incrementReset,
  sourceUrlModifier,
} from 'components/assistant/utils/assistant-helpers'
import { isVaultKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import ExportDialog from 'components/common/export/export-dialog'
import {
  ExportOptionGroup,
  ExportOptionValues,
} from 'components/common/export/types'
import FullscreenLoading from 'components/common/fullscreen-loading'
import PdfPushSheet from 'components/common/pdf-viewer/pdf-push-sheet'
import SourcePopover from 'components/common/source-popover'
import {
  CUATRECASAS_HELP,
  PWC_DISCLAIMER_TEXT,
} from 'components/research/constants'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { getVaultFileIds } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import AssistantShareMenu from './assistant-share-menu'
import { AssistantWorkflowDraftHeader } from './assistant-workflow-draft-header'

interface Props {
  children: React.ReactNode
  messages: AssistantMessage[]
  // The sources associated with the current message(s) to match
  // the citations in the push sheet header
  sources: Source[]
  title?: React.ReactNode
  useDiffView?: boolean
}

enum ExportRevision {
  SELECTED = 'selected',
  ALL = 'all',
}

const AssistantWorkflowDraftThread = ({
  children,
  messages,
  sources,
  useDiffView,
}: Props) => {
  const userInfo = useAuthUser()
  const { mode, workflowId, workflowEventId, draftId } = useParams()
  const getFile = useFileCache(useShallow((s) => s.getFile))
  const { recordExport } = useQueryAnalytics(mode ?? EventKind.ASSISTANT)

  const { documents, userCaption, knowledgeSource, streamingMessage } =
    useAssistantStore(
      useShallow((s) => ({
        documents: s.documents,
        documentsUploading: s.documentsUploading,
        userCaption: s.userCaption,
        knowledgeSource: s.knowledgeSource,
        eventOwnerUserEmail: s.eventOwnerUserEmail,
        streamingMessage: s.streamingMessage,
      }))
    )
  const isPwcTax = useIsPwcTax()
  const isCuatrecasas = useIsCuatrecasas()
  const isVaultSource = isVaultKnowledgeSource(knowledgeSource)

  const [folderIdToVaultFileIds, fileIdToVaultFile, parentIdToVaultFolderIds] =
    useVaultStore(
      useShallow((s) => [
        s.folderIdToVaultFileIds,
        s.fileIdToVaultFile,
        s.parentIdToVaultFolderIds,
      ])
    )

  const sourceDocuments = useMemo(() => {
    if (isVaultSource && knowledgeSource.folderId) {
      const vaultSourceFileIds = new Set(knowledgeSource.fileIds)
      const vaultFolderFileIds = getVaultFileIds({
        folderId: knowledgeSource.folderId,
        parentIdToVaultFolderIds,
        folderIdToVaultFileIds,
      })
      return vaultFolderFileIds
        .map((fileId) => fileIdToVaultFile[fileId] as UploadedFile)
        .filter((file) => vaultSourceFileIds.has(file.id))
    }
    return documents
  }, [
    documents,
    folderIdToVaultFileIds,
    fileIdToVaultFile,
    parentIdToVaultFolderIds,
    knowledgeSource,
    isVaultSource,
  ])

  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)
  const revisionId = searchParams.get(REVISION_ID_PARAM)

  const selectedMessage = messages.find((m) => m.messageId === revisionId)
  const exportTitle = userCaption || messages[0]?.caption
  const handleExport = async (exportValues: ExportOptionValues) => {
    const includeAnnotation = !!exportValues.includeAnnotation
    const showEdits = !!exportValues.showEdits
    const additionalSections: WordSection[] = (() => {
      if (isPwcTax) {
        return [
          { content: `<br/>`, type: WordSectionType.HTML },
          {
            content: `## Disclaimer\n\n${PWC_DISCLAIMER_TEXT}`,
            type: WordSectionType.MARKDOWN,
          },
        ]
      }
      if (isCuatrecasas) {
        return [
          { content: `<br/>`, type: WordSectionType.HTML },
          {
            content: `<h2>Disclaimer</h2>${CUATRECASAS_HELP}`,
            type: WordSectionType.HTML,
          },
        ]
      }
      return []
    })()

    await handleExportHelper({
      eventId: draftId || '',
      messages: messages,
      documents: sourceDocuments,
      includeAnnotation,
      taskType: TaskType.ASSISTANT_DRAFT,
      exportTitle: `${exportTitle}${
        exportValues.revisions === ExportRevision.ALL ? ' All Revisions' : ''
      }`,
      additionalSections,
      exportMessageIndex:
        exportValues.revisions === ExportRevision.SELECTED && selectedMessage
          ? messages.indexOf(selectedMessage)
          : undefined,
      showEdits: showEdits,
    })
    recordExport(draftId || '', 'word', includeAnnotation)
  }
  const exportOptions: ExportOptionGroup[] = []
  if (messages.length > 1) {
    exportOptions.push({
      id: 'revisions',
      name: 'Revisions',
      options: [
        {
          label: 'Selected revision',
          value: ExportRevision.SELECTED,
        },
        {
          label: 'All revisions',
          value: ExportRevision.ALL,
        },
      ],
      type: 'radio',
      defaultValue: ExportRevision.SELECTED,
    })
    exportOptions.push({
      id: 'showEdits',
      name: 'Edits',
      options: [{ label: 'Show edits', value: !!useDiffView }],
      type: 'toggle',
      defaultValue: !!useDiffView,
    })
  }

  const isStreaming = !!streamingMessage
  const isHistoryLoading = !isStreaming && !messages.length

  const { state: locationState } = useLocation()
  const isCopied = locationState?.isCopied
  useEffect(() => {
    if (isCopied && !isHistoryLoading) {
      displaySuccessMessage(`Draft copied`, 5)
      window.history.replaceState(null, '')
    }
  }, [isCopied, isHistoryLoading])

  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)
  const defaultResizablePanelSizes = [50, 50]
  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
  }

  const getDocument = useCallback(
    (_: string | null, fileId: string) => getFile(fileId),
    [getFile]
  )

  const pushSheet = useMemo(
    () =>
      fileId && (
        <PdfPushSheet
          documents={documents}
          eventId={workflowEventId}
          fileId={fileId}
          getDocument={getDocument}
          messages={messages.map((m) => ({
            messageId: m.messageId,
            sources: m.sources,
          }))}
          sources={sources}
        />
      ),
    [fileId, sources, getDocument, messages, documents, workflowEventId]
  )

  const headerActions = draftId ? (
    <div className="inline-flex space-x-2">
      {userInfo.IsEventCreateSharesUser && (
        <AssistantShareMenu eventId={draftId} />
      )}
      <ExportDialog
        hasSources={false}
        optionGroups={exportOptions}
        onExport={handleExport}
        disabled={!messages.length || !!streamingMessage}
      />
    </div>
  ) : null

  return (
    <AppMain>
      {workflowId && workflowEventId && (
        <AssistantWorkflowDraftHeader
          workflowId={workflowId}
          workflowEventId={workflowEventId}
          actions={headerActions}
          disabled={!!streamingMessage}
        />
      )}
      {isHistoryLoading && <FullscreenLoading isLoading />}
      <AssistantThreadLayout>
        <ResizablePanelGroup
          direction="horizontal"
          ref={resizablePanelGroupRef}
        >
          <ResizablePanel
            id="thread"
            defaultSize={fileId ? defaultResizablePanelSizes[0] : 100}
            minSize={20}
            order={1}
          >
            {children}
          </ResizablePanel>
          {fileId && (
            <>
              <ResizableHandle withHandle onDoubleClick={resetLayout} />
              <ResizablePanel
                id="viewer"
                // Move behind resizable handle
                className="z-0"
                defaultSize={defaultResizablePanelSizes[1]}
                minSize={20}
                order={2}
              >
                {pushSheet}
              </ResizablePanel>
            </>
          )}
        </ResizablePanelGroup>
      </AssistantThreadLayout>
    </AppMain>
  )
}

export default AssistantWorkflowDraftThread

interface ThreadMessageProps {
  children: (fn: HrvyInfoMetadataType) => React.ReactNode
  className?: string
  footer?: React.ReactNode
  message?: AssistantMessage
  onCancel?: () => void
  sidebar?: React.ReactNode
  allAnnotations?: AnnotationById
  lastLoadingMessage?: string
}

export const ThreadMessage = React.forwardRef<
  HTMLDivElement,
  ThreadMessageProps
>(
  (
    { children, className, message, onCancel, sidebar, allAnnotations },
    ref
  ) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const fileId = searchParams.get(FILE_ID_PARAM)
    const sourceId = searchParams.get(SOURCE_ID_PARAM)
    const resetSource = searchParams.get(RESET_PARAM)

    const [documents, eventId] = useAssistantStore(
      useShallow((s) => [s.documents, s.eventId])
    )

    const handleSetActiveFileId = (
      messageId: string,
      fileId: string,
      sourceId?: string
    ) => {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams)
        if (fileId) {
          newParams.set(MESSAGE_ID_PARAM, messageId)
          newParams.set(FILE_ID_PARAM, fileId)
          if (sourceId) newParams.set(SOURCE_ID_PARAM, sourceId)
        } else {
          newParams.delete(MESSAGE_ID_PARAM)
          newParams.delete(FILE_ID_PARAM)
          newParams.delete(SOURCE_ID_PARAM)
        }
        if (sourceId === newParams.get(SOURCE_ID_PARAM)) {
          newParams.set(RESET_PARAM, incrementReset(resetSource))
        } else {
          newParams.delete(RESET_PARAM)
        }
        return newParams
      })
    }

    const { annotations, isLoading, response } = message || {
      annotations: {},
      isLoading: true,
      response: '',
    }

    const citationRefs = useRef<{
      [documentId: string]: { [sourceId: string]: HrvyInfoElement }
    }>({})

    // Restore scroll area position when file viewer is opened
    const scrollPosition = useRef({
      scrollHeight: 0,
      scrollPercent: 0,
    })

    useEffect(() => {
      // TODO: Investigate why the citation refs seem to reset; remove setTimeout
      setTimeout(() => {
        if (fileId && sourceId) {
          const citationEl = citationRefs.current[fileId][sourceId] ?? null
          const scrollingContainer = findScrollingContainer(citationEl)

          if (
            scrollingContainer &&
            scrollingContainer.scrollHeight !==
              scrollPosition.current.scrollHeight
          ) {
            // Calculate new scroll position as percentage of new height
            const newPosition =
              scrollPosition.current.scrollPercent *
              scrollingContainer.scrollHeight

            // Center it within the scrolling container
            const newScrollTop =
              newPosition - scrollingContainer.offsetHeight / 2

            scrollingContainer.scrollTo(0, newScrollTop)
          }
        }
      })
    }, [fileId, sourceId, resetSource])

    const getHrvyInfoMetadata = (identifier: string) => {
      const source = getSourceClicked(identifier, allAnnotations ?? annotations)
      if (!source || !message) {
        return
      }

      const sourceDocumentId = getSourceDocumentId(source)

      const isPdfUrl = isAzureBlobPdf(source.documentUrl)
      const sourceUrl = isPdfUrl
        ? ''
        : source.documentUrl
        ? sourceUrlModifier(source.documentUrl)
        : ''

      const onClick = (e: React.MouseEvent) => {
        // Prevent opening the source document when clicking on a url source
        if (source.documentUrl && !isPdfUrl) return

        const currentTarget = e.currentTarget as HTMLElement
        const scrollingContainer = findScrollingContainer(currentTarget)
        if (scrollingContainer) {
          const scrollRect = scrollingContainer.getBoundingClientRect()
          const clickTop =
            e.pageY + scrollingContainer.scrollTop - scrollRect.top
          scrollPosition.current = {
            scrollHeight: scrollingContainer.scrollHeight,
            scrollPercent: clickTop / scrollingContainer.scrollHeight,
          }
        }
        handleSetActiveFileId(message.messageId, sourceDocumentId, source.id)
      }

      const hoverContent = (
        <SourcePopover source={source} onClick={onClick} url={sourceUrl} />
      )

      const citationRef = (el: HrvyInfoElement) => {
        if (!sourceDocumentId || !source.id) return
        if (!citationRefs.current[sourceDocumentId]) {
          citationRefs.current[sourceDocumentId] = {}
        }
        citationRefs.current[sourceDocumentId][source.id] = el
      }

      return {
        onClick,
        hoverContent,
        isSelected: sourceDocumentId === fileId && source.id === sourceId,
        ref: citationRef,
        eventData: {
          event_id: eventId,
          event_kind: EventKind.ASSISTANT_DRAFT,
          num_docs: documents.length,
        },
        url: sourceUrl,
      }
    }

    return (
      <div
        className={cn(
          'border-t py-12 first-of-type:border-0 first-of-type:pt-8 last-of-type:pb-16',
          className
        )}
        id={message?.messageId}
        ref={ref}
      >
        <AssistantThreadContent
          contentId={`assistant-message-content-${message?.messageId}`}
          hasSidebar={!!sidebar}
          sidebar={sidebar}
        >
          {isLoading && isEmpty(response) ? (
            <AssistantLoadingState onCancel={onCancel} />
          ) : (
            children(getHrvyInfoMetadata)
          )}
        </AssistantThreadContent>
      </div>
    )
  }
)
ThreadMessage.displayName = 'ThreadMessage'
