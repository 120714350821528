import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { AbortControllerProvider } from 'providers/abort-controller-provider'
import { ModalFlowNavigator } from 'providers/modal-flow-provider/modal-flow-navigator'
import PSPDFKit from 'pspdfkit'

import useAzureWebPubSub from 'hooks/use-azure-web-pub-sub'
import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { getPdfKitConfig } from 'utils/pspdfkit'

import { FLOWS } from 'components/common/flows'
import { TooltipProvider } from 'components/ui/tooltip'

import { AnalyticsProvider } from './common/analytics/analytics-context'
import { useAuthUser } from './common/auth-context'
import ClientMattersDisallowedInterstitial from './common/client-matters-disallowed-interstitial'
import GoogleDriveFilePicker from './common/integrations/google-drive-file-picker'
import SharepointFilePicker from './common/integrations/sharepoint-file-picker'
import SynclyPicker from './common/integrations/syncly-picker'
import WelcomeInterstitial from './common/welcome-interstitial'
import WelcomeMessage from './common/welcome-message'
import Sidebar from './sidebar/sidebar'
import { Toaster } from './ui/toaster'

interface AppLayoutProps {
  basePath: string
}

const AppLayout: React.FC<AppLayoutProps> = ({ basePath }) => {
  const navigate = useNavigateWithQueryParams()
  const location = useLocation()
  const userInfo = useAuthUser()

  // setup azure web pub/sub
  useAzureWebPubSub()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(basePath)
    }
  }, [basePath, location, navigate])

  useEffect(() => {
    const config = getPdfKitConfig()
    void PSPDFKit.preloadWorker(config)
  }, [])

  return (
    <AbortControllerProvider>
      <AnalyticsProvider>
        <TooltipProvider>
          <ModalFlowNavigator flows={FLOWS}>
            <div className="safe-h-screen flex items-stretch overflow-hidden">
              <Sidebar />
              <main className="flex grow flex-col">
                <Outlet />
              </main>
              {!userInfo.isCreateUserProfilesUser && <WelcomeMessage />}
              <WelcomeInterstitial />
              <ClientMattersDisallowedInterstitial />
              <Toaster
                position="bottom-right"
                expand
                closeButton
                visibleToasts={5}
                className="pointer-events-auto list-none"
              />
              <SharepointFilePicker />
              <GoogleDriveFilePicker />
              <SynclyPicker />
              {/* need target to load pspdfkit document against for validation */}
              <div
                id="pspdfkit-container"
                className="fixed bottom-0 right-0 hidden size-px"
              />
            </div>
          </ModalFlowNavigator>
        </TooltipProvider>
      </AnalyticsProvider>
    </AbortControllerProvider>
  )
}

export default AppLayout
