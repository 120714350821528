import React, { useEffect, useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import {
  DialogHeader,
  DialogTitle,
  DialogContent,
  Dialog,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Switch } from 'components/ui/switch'
import { TagInput } from 'components/ui/tag-input/tag-input'
import { SimpleDocumentClassificationPill } from 'components/vault/components/vault-document-classification'
import { useDocumentClassificationStore } from 'components/vault/utils/use-document-classification-store'
import {
  CreateDraftWorkflow,
  UpdateDraftWorkflow,
  DeleteDraftVaultWorkflow,
  UpdateDraftWorkflowParams,
} from 'components/vault/utils/vault-fetcher'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

const VaultEditWorkflow: React.FC = () => {
  const [documentClassificationTags] = useDocumentClassificationStore(
    useShallow((state) => [state.documentClassificationTags])
  )
  const [
    selectedWorkflow,
    workflowModalState,
    setWorkflowModalState,
    setWorkflow,
    removeWorkflow,
  ] = useVaultWorkflowStore(
    useShallow((state) => [
      state.selectedWorkflow,
      state.workflowModalState,
      state.setWorkflowModalState,
      state.setWorkflow,
      state.removeWorkflow,
    ])
  )

  // TODO: This only works because the workspace document classification tags are the same as the workflow document classification tags
  // because the workspace that created the workflow is the same as the workspace that is currently selected
  const [isSubmittingUpdateWorkflow, setIsSubmittingUpdateWorkflow] =
    useState(false)
  const [newStrictDocumentType, setNewStrictDocumentType] = useState(false)
  const [newWorkflowName, setNewWorkflowName] = useState('')
  const [newWorkflowDescription, setNewWorkflowDescription] = useState('')
  const [newWorkflowCsvFile, setNewWorkflowCsvFile] = useState<File | null>(
    null
  )
  const [newWorkflowCsvFileName, setNewWorkflowCsvFileName] = useState<
    string | null
  >(null)
  const [
    selectedDocumentClassificationTags,
    setSelectedDocumentClassificationTags,
  ] = useState<string[]>([])

  useEffect(() => {
    if (selectedWorkflow) {
      setSelectedDocumentClassificationTags(
        selectedWorkflow.tags?.map((tag) => tag.id) ?? []
      )
      setNewStrictDocumentType(selectedWorkflow.strictDocumentType)
      setNewWorkflowName(selectedWorkflow.name)
      setNewWorkflowDescription(selectedWorkflow.description)
    } else {
      setSelectedDocumentClassificationTags([])
      setNewStrictDocumentType(false)
      setNewWorkflowName('')
      setNewWorkflowDescription('')
    }
  }, [selectedWorkflow])

  const onSubmit = async () => {
    if (!selectedWorkflow) return
    setIsSubmittingUpdateWorkflow(true)

    // If we have a csv file, we want to delete and create a new workflow
    if (newWorkflowCsvFile) {
      try {
        await DeleteDraftVaultWorkflow(selectedWorkflow.id)
        removeWorkflow(selectedWorkflow.id)
        const response = await CreateDraftWorkflow({
          kind: selectedWorkflow.kind,
          cobrand: selectedWorkflow.cobrand,
          name: newWorkflowName,
          description: newWorkflowDescription,
          csvFile: newWorkflowCsvFile!,
          documentClassificationTagsIds: selectedDocumentClassificationTags,
          shouldBeStrictDocumentClassification: newStrictDocumentType,
        })
        displaySuccessMessage('Workflow updated successfully')
        setWorkflow(response.workflow)
        setNewWorkflowCsvFile(null)
        setNewWorkflowCsvFileName(null)
        setWorkflowModalState(WorkflowModalState.Selector)
      } catch (e) {
        displayErrorMessage('Failed to update workflow')
      }
    } else {
      const existingWorkflowTags = selectedWorkflow.tags ?? []
      const existingWorkflowTagIds = existingWorkflowTags.map((tag) => tag.id)
      const tagsToDelete = existingWorkflowTags.filter(
        (tag) => !selectedDocumentClassificationTags.includes(tag.id)
      )
      const tagsToAdd = selectedDocumentClassificationTags.filter(
        (tagId) => !existingWorkflowTagIds.includes(tagId)
      )
      try {
        const params: UpdateDraftWorkflowParams = {
          workflowId: selectedWorkflow.id,
          reviewWorkflowTagIdsToDelete: tagsToDelete.map((tag) => tag.id),
          reviewWorkflowTagsToAdd: tagsToAdd,
          name: newWorkflowName,
          description: newWorkflowDescription,
        }
        if (newStrictDocumentType !== selectedWorkflow.strictDocumentType) {
          params.strictDocumentType = newStrictDocumentType
        }
        const response = await UpdateDraftWorkflow(params)
        if (response && 'workflow' in response) {
          displaySuccessMessage('Workflow updated successfully')
          setWorkflow(response.workflow)
          setWorkflowModalState(WorkflowModalState.Selector)
        }
      } catch (e) {
        displayErrorMessage('Failed to update workflow')
      }
    }
    setIsSubmittingUpdateWorkflow(false)
  }

  return (
    <Dialog
      open={workflowModalState === WorkflowModalState.Edit}
      onOpenChange={(open) => {
        if (!open) {
          setWorkflowModalState(WorkflowModalState.None)
        }
      }}
    >
      <DialogContent className="min-h-64] bg-secondary sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Edit Workflow</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="flex flex-col">
            <p className="mb-1 text-xs font-medium">Workflow name</p>
            <Input
              value={newWorkflowName}
              onChange={(e) => setNewWorkflowName(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <p className="mb-1 text-xs font-medium">Workflow description</p>
            <Input
              value={newWorkflowDescription}
              onChange={(e) => setNewWorkflowDescription(e.target.value)}
            />
          </div>

          <div className="flex items-center justify-between gap-2">
            <p className="mb-1 text-xs font-medium">
              Strict Document Classification
            </p>
            <Switch
              id="shouldBeStrictDocumentClassification"
              checked={newStrictDocumentType}
              onCheckedChange={setNewStrictDocumentType}
            />
          </div>
          <div>
            <label
              htmlFor="newWorkflowCsvFile"
              className="block text-xs font-medium"
            >
              CSV File
            </label>
            <div className="flex items-center gap-2">
              <Button
                size="sm"
                variant="outline"
                onClick={() =>
                  document.getElementById('newWorkflowCsvFile')?.click()
                }
              >
                Select CSV
              </Button>
              {newWorkflowCsvFileName && (
                <p className="text-muted-foreground text-sm">
                  {newWorkflowCsvFileName}
                </p>
              )}
              <Input
                id="newWorkflowCsvFile"
                type="file"
                accept=".csv"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setNewWorkflowCsvFile(e.target.files[0])
                    setNewWorkflowCsvFileName(e.target.files[0].name)
                  }
                }}
              />
            </div>
          </div>
          <div className="flex min-h-64 flex-col">
            <p className="mb-1 text-xs font-medium">
              Document Classification Tags
            </p>
            <TagInput
              placeholder="Select document classification tags"
              shouldShowSuggestedTags
              allowCreatingNewTags={false}
              selectedTagValues={selectedDocumentClassificationTags}
              setSelectedTagValues={setSelectedDocumentClassificationTags}
              sortedTags={documentClassificationTags.map((tag) => {
                return {
                  badgeDisplayText: tag.name,
                  value: tag.id,
                  component: (
                    <SimpleDocumentClassificationPill
                      tagName={tag.name}
                      tagId={tag.id}
                    />
                  ),
                }
              })}
            />
          </div>

          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={() => setWorkflowModalState(WorkflowModalState.Selector)}
            >
              Cancel
            </Button>
            <Button onClick={onSubmit} isLoading={isSubmittingUpdateWorkflow}>
              {isSubmittingUpdateWorkflow ? 'Updating…' : 'Update Workflow'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultEditWorkflow
