import React, { useState, useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAssistantAnalytics } from 'components/assistant/hooks/use-assistant-analytics'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import * as API from 'components/assistant/utils/assistant-api'
import { getMessageQuery } from 'components/assistant/utils/assistant-helpers'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'

interface SetCaptionDialogProps {
  eventId: string | null
  showEditCaption: boolean
  setShowEditCaption: (show: boolean) => void
}

const SetCaptionDialog = ({
  eventId,
  showEditCaption,
  setShowEditCaption,
}: SetCaptionDialogProps) => {
  const MAX_CAPTION_LENGTH = 100
  const [getCurrentThreadMessages, userCaption] = useAssistantStore(
    useShallow((s) => [s.getCurrentThreadMessages, s.userCaption])
  )
  const trackEvent = useAssistantAnalytics()
  const currentThreadMessages = getCurrentThreadMessages()

  const defaultTitle = useMemo(() => {
    return (
      currentThreadMessages[0]?.caption ||
      getMessageQuery(currentThreadMessages[0]) ||
      ''
    )
  }, [currentThreadMessages])
  const [caption, setCaption] = useState(userCaption || defaultTitle)

  const saveCaption = async (caption: string) => {
    if (!eventId || !caption.trim().length) return
    trackEvent('Edit Caption Saved')
    useAssistantStore.setState({ userCaption: caption.trim() })
    try {
      await API.setCaption(eventId, caption.trim())
      displaySuccessMessage('Thread title updated')
    } catch (e) {
      displayErrorMessage('Failed to update thread title')
      setCaption(defaultTitle)
    }
  }

  const handleCancel = () => {
    trackEvent('Edit Caption Canceled')
    setShowEditCaption(false)
  }

  return (
    <Dialog open={showEditCaption} onOpenChange={setShowEditCaption}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit title</DialogTitle>
        </DialogHeader>
        <Input
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              saveCaption(e.currentTarget.value)
              setShowEditCaption(false)
            }
          }}
          placeholder="New title"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          maxLength={MAX_CAPTION_LENGTH}
        />
        <DialogFooter>
          <Button onClick={handleCancel} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              saveCaption(caption)
              setShowEditCaption(false)
            }}
            disabled={!caption.length}
          >
            Update
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default SetCaptionDialog
