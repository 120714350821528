/* tslint:disable */
/* eslint-disable */
/**
 * Workflows
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SocketMessageResponseFiles } from './SocketMessageResponseFiles';
import {
    SocketMessageResponseFilesFromJSON,
    SocketMessageResponseFilesFromJSONTyped,
    SocketMessageResponseFilesToJSON,
    SocketMessageResponseFilesToJSONTyped,
} from './SocketMessageResponseFiles';

/**
 * 
 * @export
 * @interface WorkflowFilesRenderBlockBlockParams
 */
export interface WorkflowFilesRenderBlockBlockParams {
    /**
     * 
     * @type {SocketMessageResponseFiles}
     * @memberof WorkflowFilesRenderBlockBlockParams
     */
    answer: SocketMessageResponseFiles;
}

/**
 * Check if a given object implements the WorkflowFilesRenderBlockBlockParams interface.
 */
export function instanceOfWorkflowFilesRenderBlockBlockParams(value: object): value is WorkflowFilesRenderBlockBlockParams {
    if (!('answer' in value) || value['answer'] === undefined) return false;
    return true;
}

export function WorkflowFilesRenderBlockBlockParamsFromJSON(json: any): WorkflowFilesRenderBlockBlockParams {
    return WorkflowFilesRenderBlockBlockParamsFromJSONTyped(json, false);
}

export function WorkflowFilesRenderBlockBlockParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowFilesRenderBlockBlockParams {
    if (json == null) {
        return json;
    }
    return {
        
        'answer': SocketMessageResponseFilesFromJSON(json['answer']),
    };
}

export function WorkflowFilesRenderBlockBlockParamsToJSON(json: any): WorkflowFilesRenderBlockBlockParams {
    return WorkflowFilesRenderBlockBlockParamsToJSONTyped(json, false);
}

export function WorkflowFilesRenderBlockBlockParamsToJSONTyped(value?: WorkflowFilesRenderBlockBlockParams | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'answer': SocketMessageResponseFilesToJSON(value['answer']),
    };
}

