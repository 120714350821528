import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useMount } from 'react-use'

import { startCase } from 'lodash'
import { MoreHorizontal } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { UserInfo } from 'models/user-info'

import AssistantCustomTips, {
  hasCustomTips,
} from 'components/assistant/components/assistant-custom-tips'
import AssistantExamples from 'components/assistant/components/assistant-examples'
import AssistantInput from 'components/assistant/components/assistant-input'
import { AssistantMode } from 'components/assistant/components/assistant-mode-select'
import AssistantQueries from 'components/assistant/components/assistant-queries'
import AssistantSharedTable from 'components/assistant/components/assistant-shared-table'
import AssistantWorkflowExamples from 'components/assistant/components/assistant-workflow-examples'
import AssistantInputNew from 'components/assistant/features/composer'
import { AssistantChatStreamHandler } from 'components/assistant/hooks/use-assistant-chat'
import { AssistantDraftStreamHandler } from 'components/assistant/hooks/use-assistant-draft'
import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { HOMEPAGE_TAB_PARAM } from 'components/assistant/utils/assistant-helpers'
import { useLoadWorkflowsQuery } from 'components/assistant/workflows/workflow-loader'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import { useNavigationQueryState } from 'components/common/use-navigation-query-state'
import { useExampleItemsStore } from 'components/library/library-items-store'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { ScrollArea } from 'components/ui/scroll-area'
import { Tabs, TabsList, TabsTrigger, TabsContent } from 'components/ui/tabs'

type Props = {
  useChat: AssistantChatStreamHandler
  useDraft: AssistantDraftStreamHandler
}

enum HomePageTabs {
  TIPS = 'tips',
  DISCOVER = 'discover',
  RECENT = 'recent',
  EXAMPLES = 'examples',
  SHARED = 'shared',
}

const HomePageTabPerm: Record<HomePageTabs, (userInfo: UserInfo) => boolean> = {
  [HomePageTabs.DISCOVER]: (userInfo) => userInfo.isDiscoverTabUser,
  [HomePageTabs.TIPS]: (userInfo) => hasCustomTips(userInfo),
  [HomePageTabs.RECENT]: (userInfo) => userInfo.IsHistoryUser,
  [HomePageTabs.EXAMPLES]: (userInfo) =>
    userInfo.IsLibraryUser && !userInfo.isDiscoverTabUser,
  [HomePageTabs.SHARED]: (userInfo) =>
    userInfo.workspace.sharingSettings.assistant.enabled &&
    userInfo.IsHistoryUser &&
    userInfo.IsEventCreateSharesUser /* TEMP: We're temporarily hiding this from users w/o the perm */,
}

const AssistantHome = ({ useChat, useDraft }: Props) => {
  const userInfo = useAuthUser()
  const assistantReset = useAssistantStore((s) => s.reset)
  const [showRecentQueries, setShowRecentQueries] = useAssistantStore(
    useShallow((s) => [s.showRecentQueries, s.setShowRecentQueries])
  )
  const [isExamplesLoading, lastUpdatedExamplesTime] = useExampleItemsStore(
    useShallow((s) => [s.isLoading, s.lastUpdatedTime])
  )
  const [isExamplesInitialLoading, setExamplesInitialLoading] = useState(true)
  const { data: workflows } = useLoadWorkflowsQuery()
  useEffect(() => {
    setExamplesInitialLoading((prevLoading) => {
      if (!isExamplesLoading && lastUpdatedExamplesTime) return false
      return prevLoading
    })
  }, [isExamplesLoading, lastUpdatedExamplesTime])

  const { state: locationState } = useLocation()
  const navigationState = useNavigationQueryState()
  const [searchParams, setSearchParams] = useSearchParams()
  const { trackEvent } = useAnalytics()

  const tabRef = useRef<HTMLDivElement | null>(null)
  const defaultTab = Object.values(HomePageTabs).find((tab) =>
    HomePageTabPerm[tab](userInfo)
  )
  const tabParam = searchParams.get(HOMEPAGE_TAB_PARAM)
  const selectedTab = tabParam ?? defaultTab
  const setSelectedTab = (tab: HomePageTabs) => {
    if (
      isExamplesInitialLoading &&
      tab === HomePageTabs.EXAMPLES &&
      tabRef.current
    ) {
      tabRef.current.style.minHeight = `${tabRef.current.scrollHeight}px`
    }
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set(HOMEPAGE_TAB_PARAM, tab)
        return newParams
      },
      { replace: true }
    )
    trackEvent('Assistant Tab Selected', {
      selected_tab: tab,
    })
  }

  useEffect(() => {
    if (!isExamplesInitialLoading && tabRef.current) {
      tabRef.current.style.minHeight = ''
    }
  }, [isExamplesInitialLoading])

  const clientName = userInfo.workspace.clientName

  useMount(() => {
    // XXX: This is meh, ideally we reset on specific page unmounts
    // but unmount is unreliable
    useChat.sendCancelRequest()
    useDraft.sendCancelRequest()

    if (!locationState?.skipReset) {
      assistantReset()
    } else {
      // We don't want to skip reset again if we navigate away and click 'Back'
      window.history.replaceState(null, '')
    }
  })

  const locationMode = navigationState?.mode
  const locationKnowledgeSource = navigationState?.knowledge

  const handleTabChange = (value: string) => {
    setSelectedTab(value as HomePageTabs)
  }

  const getTabText = (tab: HomePageTabs) => {
    if (tab === HomePageTabs.TIPS) {
      return `${clientName} guidance`
    } else if (tab === HomePageTabs.SHARED) {
      return `Shared with you`
    }
    return startCase(tab)
  }

  return (
    <AppMain>
      <AppHeader
        title="Assistant"
        subtitle="Ask complex questions to your professional AI assistant"
      />
      <ScrollArea className="h-full">
        <div className="container mx-auto space-y-6 pb-6 pt-4">
          {userInfo.isNewAssistantComposerUser ? (
            <AssistantInputNew useChat={useChat} useDraft={useDraft} />
          ) : (
            <AssistantInput
              defaultMode={
                locationMode ? (locationMode as AssistantMode) : undefined
              }
              defaultKnowledgeSource={locationKnowledgeSource}
              useChat={useChat}
              useDraft={useDraft}
            />
          )}
          {selectedTab && (
            <div className="py-2" ref={tabRef}>
              <Tabs value={selectedTab} onValueChange={handleTabChange}>
                <div className="flex justify-between">
                  <TabsList variant="minimal" className="w-full">
                    {Object.values(HomePageTabs).map((tab) => {
                      if (!HomePageTabPerm[tab](userInfo)) return null
                      return (
                        <TabsTrigger
                          key={tab}
                          variant="minimal"
                          value={tab}
                          className="px-1 pb-2 font-medium"
                        >
                          {getTabText(tab)}
                        </TabsTrigger>
                      )
                    })}
                  </TabsList>
                  {selectedTab === HomePageTabs.RECENT && (
                    <div className="border-b">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" size="xsIcon">
                            <Icon icon={MoreHorizontal} />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem
                            onClick={() => {
                              setShowRecentQueries(!showRecentQueries)
                            }}
                          >
                            {showRecentQueries ? 'Hide' : 'Show'} recent queries
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  )}
                </div>
                <TabsContent value={HomePageTabs.TIPS}>
                  <AssistantCustomTips />
                </TabsContent>
                <TabsContent value={HomePageTabs.DISCOVER}>
                  {(workflows?.length || 0) > 0 ? (
                    <AssistantWorkflowExamples />
                  ) : (
                    <AssistantExamples isLoading={isExamplesInitialLoading} />
                  )}
                </TabsContent>
                <TabsContent value={HomePageTabs.RECENT}>
                  <AssistantQueries />
                </TabsContent>
                <TabsContent value={HomePageTabs.EXAMPLES}>
                  <AssistantExamples isLoading={isExamplesInitialLoading} />
                </TabsContent>
                <TabsContent value={HomePageTabs.SHARED}>
                  <AssistantSharedTable isPreview />
                </TabsContent>
              </Tabs>
            </div>
          )}
        </div>
      </ScrollArea>
    </AppMain>
  )
}

export default AssistantHome
