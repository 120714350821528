import React, { useMemo, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DialogFooter, DialogHeader, DialogTitle } from 'components/ui/dialog'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import VaultProgress from 'components/vault/components/vault-progress'
import useVaultUploadFiles from 'components/vault/hooks/use-vault-upload-files'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import { QueryQuestion } from 'components/vault/utils/vault'
import {
  columnToQueryQuestion,
  isFileFinishProcessing,
  runReviewQueryFromWorkflow,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import useVaultWorkflowStore from 'components/vault/workflows/vault-workflow-store'
import { WorkflowCategory } from 'components/workflows/workflow-card'

const VaultWorkflowFilesProgress = ({
  onBackButtonClicked,
}: {
  onBackButtonClicked: () => void
}) => {
  useVaultUploadFiles()
  const navigate = useNavigateWithQueryParams()
  const queryClient = useQueryClient()
  const { recordQuerySubmitted } = useQueryAnalytics(EventKind.VAULT_REVIEW)
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  const [
    currentProject,
    currentProjectMetadata,
    fileIdToVaultFile,
    folderIdToVaultFolder,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentProject,
      state.currentProjectMetadata,
      state.fileIdToVaultFile,
      state.folderIdToVaultFolder,
    ])
  )

  const [selectedWorkflow, setWorkflowModalState] = useVaultWorkflowStore(
    useShallow((state) => [state.selectedWorkflow, state.setWorkflowModalState])
  )

  const selectedClientMatter = useClientMattersStore(
    (s) => s.selectedClientMatter
  )

  const [
    setWorkflow,
    setQueryId,
    setIsRunButtonLoading,
    setPendingQueryQuestions,
    setPendingQueryFileIds,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.setWorkflow,
      state.setQueryId,
      state.setIsRunButtonLoading,
      state.setPendingQueryQuestions,
      state.setPendingQueryFileIds,
    ])
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const areAllFilesProcessed = useMemo(() => {
    if (!currentProject) {
      return false
    }
    return Object.values(fileIdToVaultFile)
      .filter((file): file is NonNullable<typeof file> => file !== undefined)
      .every((file) => isFileFinishProcessing(file))
  }, [currentProject, fileIdToVaultFile])

  const handleRunReviewQuery = async () => {
    setIsSubmitting(true)

    if (!selectedWorkflow || !currentProject) {
      return
    }

    const pendingQueryFileIds = Object.values(fileIdToVaultFile)
      .filter(
        (file): file is NonNullable<typeof file> =>
          file !== undefined && !!file.readyToQuery
      )
      .map((file) => file.id)
    setPendingQueryFileIds(pendingQueryFileIds)

    const pendingQueryQuestions = [...selectedWorkflow.columns]
      .sort((a, b) => a.displayId - b.displayId)
      .map(columnToQueryQuestion) as QueryQuestion[]

    setPendingQueryQuestions(pendingQueryQuestions)

    const clientMatterId: string | undefined =
      userInfo.IsVaultProjectClientMatterUser
        ? currentProjectMetadata.clientMatterId
        : userInfo.isClientMattersReadUser
        ? selectedClientMatter?.id
        : undefined

    trackEvent('Workflow Confirmed', {
      workflow_id: selectedWorkflow.id,
      workflow_name: selectedWorkflow.name,
      workflow_type: WorkflowCategory.VAULT,
    })

    await runReviewQueryFromWorkflow({
      currentProject,
      currentProjectMetadata,
      folderIdToVaultFolder,
      fileIdToVaultFile,
      selectedRows: [],
      pendingQueryFileIds,
      pendingQueryQuestions,
      workflow: selectedWorkflow,
      clientMatterId,
      queryClient,
      setIsRunButtonLoading,
      recordQuerySubmitted,
      setQueryId,
      setWorkflow,
      setWorkflowModalState,
      setIsSubmitting,
      navigate,
    })
  }

  if (!currentProject) {
    return null
  }
  return (
    <div className="flex h-full min-h-0 flex-col gap-y-4">
      <DialogHeader className="h-9 flex-none shrink-0 border-b border-b-primary px-6">
        <DialogTitle className="flex items-center">Create project</DialogTitle>
      </DialogHeader>
      <div className="min-h-0 grow px-6">
        <VaultProgress inModal />
        <VaultFileExplorer
          selectedRows={[]}
          setSelectedRows={() => {}}
          className="flex min-h-0 grow flex-col"
          projectId={currentProject.id}
          isDisplayingFilesProgress
          isAddingFilesToQuery
        />
      </div>
      <DialogFooter className="flex w-full shrink-0 flex-row justify-end self-end border-t px-6 pt-4">
        <Button variant="outline" onClick={onBackButtonClicked}>
          Back
        </Button>
        <AskHarveyButton
          buttonTitle="Continue"
          disabled={!areAllFilesProcessed || isSubmitting}
          isLoading={isSubmitting}
          handleSubmit={handleRunReviewQuery}
          className="w-fit"
        />
      </DialogFooter>
    </div>
  )
}

export default VaultWorkflowFilesProgress
