import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import { useRunReview } from 'components/vault/components/vault-app-header/use-run-review'
import {
  addFilesToGrid,
  GridTransactionAction,
} from 'components/vault/query-detail/data-grid-helpers'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getSelectedFiles,
  getSortedFilesBasedOnReviewQueryOrder,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

export const VaultAddFilesDialog = () => {
  const { projectId } = useParams()

  const [selectedRows, setSelectedRows] = useVaultFileExplorerStore(
    useShallow((s) => [s.selectedRows, s.setSelectedRows])
  )

  const [isAddFilesDialogOpen, folderIdToVaultFolder, setIsAddFilesDialogOpen] =
    useVaultStore(
      useShallow((s) => [
        s.isAddFilesDialogOpen,
        s.folderIdToVaultFolder,
        s.setIsAddFilesDialogOpen,
      ])
    )

  const [gridApi, historyItem, pendingQueryFileIds, addToPendingQueryFileIds] =
    useVaultQueryDetailStore(
      useShallow((s) => [
        s.gridApi,
        s.historyItem,
        s.pendingQueryFileIds,
        s.addToPendingQueryFileIds,
      ])
    )
  const [setDisplayedRows] = useVaultDataGridFilterStore(
    useShallow((state) => [state.setDisplayedRows])
  )

  const { handleRun } = useRunReview()

  const [isAddingToQuery, setIsAddingToQuery] = useState(false)

  const reviewEvent = historyItem as ReviewHistoryItem | undefined
  const reviewEventColumns = reviewEvent
    ? reviewEvent.columns.filter((column) => !column.isHidden)
    : []
  const existingFileIds = useMemo(() => {
    const fileIds = reviewEvent ? reviewEvent.rows.map((row) => row.fileId) : []
    const pendingFileIds = pendingQueryFileIds ?? []
    return new Set([...fileIds, ...pendingFileIds])
  }, [reviewEvent, pendingQueryFileIds])
  const selectedFiles = useMemo(() => {
    const files = getSelectedFiles(selectedRows, existingFileIds)
    return getSortedFilesBasedOnReviewQueryOrder(files, folderIdToVaultFolder)
  }, [selectedRows, folderIdToVaultFolder, existingFileIds])

  const isAddFilesDisabled = selectedFiles.length === 0 || isAddingToQuery

  const getAddFilesDisabledTooltip = () => {
    if (selectedFiles.length === 0) {
      return 'Please select at least one file to add'
    }
    return undefined
  }

  const onSubmitExtraFiles = async () => {
    if (gridApi) {
      setIsAddingToQuery(true)
      const newPendingQueryFileIds = selectedFiles.map((file) => file.id)
      addToPendingQueryFileIds(newPendingQueryFileIds)
      addFilesToGrid({
        gridApi,
        setDisplayedRows: setDisplayedRows,
        files: selectedFiles,
        folderIdToVaultFolder,
        isLoading: false,
        gridAction: GridTransactionAction.ADD,
      })
      if (reviewEventColumns.length > 0) {
        // Only run if there are questions in the review event
        await handleRun({
          pendingFileIds: [
            ...(pendingQueryFileIds ?? []),
            ...newPendingQueryFileIds,
          ],
        })
      }
      setIsAddingToQuery(false)
    }
    setIsAddFilesDialogOpen(false)
  }

  return (
    <Dialog open={isAddFilesDialogOpen} onOpenChange={setIsAddFilesDialogOpen}>
      <DialogContent hasContainer={false} innerClassName="flex flex-col p-0">
        <DialogHeader className="px-6 pt-6">
          <DialogTitle>Add Files</DialogTitle>
          <DialogDescription>
            Select files to add to the query
          </DialogDescription>
        </DialogHeader>
        <VaultFileExplorer
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          isAddingFilesToQuery
          existingSelectedFileIds={existingFileIds}
          projectId={projectId}
        />
        <div className="flex items-center justify-between px-6 pb-6">
          <p>{pluralizeFiles(selectedFiles.length)} selected</p>
          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              onClick={() => setIsAddFilesDialogOpen(false)}
              disabled={isAddingToQuery}
            >
              Cancel
            </Button>
            <Button
              onClick={onSubmitExtraFiles}
              disabled={isAddFilesDisabled}
              tooltip={getAddFilesDisabledTooltip()}
              isLoading={isAddingToQuery}
              data-testid="vault-add-files-dialog--add-button"
            >
              Add
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
