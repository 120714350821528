import React from 'react'

import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'

import { AssistantWorkflowComponent } from 'components/assistant/workflows'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowLoadingStateRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.LOADING_STATE
> = ({ loadingStates, paramStatus }) => {
  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <LoadingState
          isCompleted={paramStatus === 'COMPLETED'}
          states={loadingStates}
        />
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowLoadingStateInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.LOADING_STATE
> = ({ paramStatus }) => {
  const renderedText = paramStatus !== 'COMPLETED' ? 'Working…' : ''

  return (
    <WorkflowInput>
      <WorkflowInputFooter footer={renderedText} />
    </WorkflowInput>
  )
}
