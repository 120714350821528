import { convertMillimetersToTwip } from 'docx'
import saveAs from 'file-saver'

import { WordSection } from './docx'
import { exportWordWithSections, logExport } from './markdown'
import { TaskType } from './task'
import { backendFormat } from './utils'

export const createExportFilename = (base: string) =>
  `${base}_${backendFormat(new Date())}.docx`

export const exportTaskDocumentFromBackend = async (
  url: string,
  taskType: TaskType,
  queryId: string
) => {
  const response = await fetch(url)
  const blob = await response.blob()
  saveAs(blob, createExportFilename(taskType))
  logExport('docx', taskType, queryId)
}

export function exportAsFormattedWordDocx({
  title,
  sections,
  onSuccess,
  onError,
}: {
  title: string
  sections: WordSection[]
  onSuccess?: () => void
  onError?: (message: string) => void
}): Promise<void> {
  return exportWordWithSections({
    defaultStyleOverrides: {
      heading1: {
        paragraph: {
          spacing: {
            before: convertMillimetersToTwip(5),
          },
        },
      },
      heading2: {
        paragraph: {
          spacing: {
            before: convertMillimetersToTwip(2),
          },
        },
      },
      heading3: {
        paragraph: {
          spacing: {
            before: convertMillimetersToTwip(2),
          },
        },
      },
    },
    filePrefixOverride: title,
    includeAnnotation: false,
    queryId: '0',
    sections,
    taskType: TaskType.ASSISTANT,
    title,
  })
    .then(() => onSuccess?.())
    .catch((error) => onError?.(error.message))
}
