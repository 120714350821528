import React, { useCallback, useMemo, useRef, useState } from 'react'

import {
  DEFAULT_VAULT_FILES_COUNT_LIMIT,
  setVaultEnablement,
  setVaultSettings,
  VaultSettingsObject,
  Workspace,
} from 'models/workspace'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'
import { Maybe } from 'types'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { intervalToReadable } from 'utils/utils'

import { Button } from 'components/ui/button'
import Combobox from 'components/ui/combobox/combobox'
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Switch } from 'components/ui/switch'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

import VaultEditOption from './vault-edit-option'

interface VaultAddonDialogProps {
  type: 'add' | 'edit'
  open: boolean
  onOpenChange: (open: boolean) => void
  workspace: Workspace
  onEnable: () => Promise<void>
}

const retentionOptions = [
  { value: '0', label: intervalToReadable(0, true) },
  { value: '172800', label: intervalToReadable(172800, true) },
  { value: '604800', label: intervalToReadable(604800, true) },
  { value: '1209600', label: intervalToReadable(1209600, true) },
  { value: '2592000', label: intervalToReadable(2592000, true) },
  { value: '15768000', label: intervalToReadable(15768000, true) },
  { value: 'unlimited', label: 'Unlimited' },
  { value: 'custom', label: 'Custom' },
]

interface RetentionPeriodSelectorProps {
  label: string
  optionValue: string
  setOptionValue: (value: string) => void
  customValue: number
  setCustomValue: (value: number) => void
}

const RetentionPeriodSelector = ({
  label,
  optionValue,
  setOptionValue,
  customValue,
  setCustomValue,
}: RetentionPeriodSelectorProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={containerRef} className="space-y-1">
      <Label htmlFor="value">{label}</Label>
      <Combobox
        value={optionValue}
        setValue={setOptionValue}
        defaultText="Select retention period"
        options={retentionOptions}
        containerRef={containerRef}
      />
      {optionValue === 'custom' && (
        <div className="flex items-center space-x-2">
          <Input
            id="value"
            type="number"
            value={customValue}
            onChange={(e) => setCustomValue(parseInt(e.target.value))}
            placeholder="Enter value"
            min={0}
            max={31536000}
            className="mt-1 w-[200px]"
          />
          <p className="text-sm text-muted">
            Equals to {intervalToReadable(customValue || 0, true)}
          </p>
        </div>
      )}
    </div>
  )
}

const VaultAddonDialog = ({
  type,
  open,
  onOpenChange,
  workspace,
  onEnable,
}: VaultAddonDialogProps) => {
  const vaultSettings: VaultSettingsObject = workspace.vaultAddOnSettings

  const [vaultUsersCountLimit, setVaultUsersCountLimit] = useState<
    number | null
  >(workspace.vaultUsersCountLimit)
  const [
    customVaultEventsRetentionPeriod,
    setCustomVaultEventsRetentionPeriod,
  ] = useState<number>(workspace.vaultEventsRetentionPeriod ?? 0)
  const [
    vaultEventsRetentionPeriodOptionValue,
    setVaultEventsRetentionPeriodOptionValue,
  ] = useState<string>(
    workspace.vaultEventsRetentionPeriod
      ? retentionOptions.some(
          (option) =>
            option.value === workspace.vaultEventsRetentionPeriod?.toString()
        )
        ? workspace.vaultEventsRetentionPeriod.toString()
        : 'custom'
      : 'unlimited'
  )
  const [
    customVaultProjectsRetentionPeriod,
    setCustomVaultProjectsRetentionPeriod,
  ] = useState<number>(workspace.vaultProjectsRetentionPeriod ?? 0)
  const [
    vaultProjectsRetentionPeriodOptionValue,
    setVaultProjectsRetentionPeriodOptionValue,
  ] = useState<string>(
    workspace.vaultProjectsRetentionPeriod
      ? retentionOptions.some(
          (option) =>
            option.value === workspace.vaultProjectsRetentionPeriod?.toString()
        )
        ? workspace.vaultProjectsRetentionPeriod.toString()
        : 'custom'
      : 'unlimited'
  )
  const [vaultFilesCountLimitPerProject, setVaultFilesCountLimitPerProject] =
    useState<number>(
      vaultSettings.vault_files_count_limit_per_project ??
        DEFAULT_VAULT_FILES_COUNT_LIMIT
    )

  const [vaultProjectsRetentionOnUpdate, setVaultProjectsRetentionOnUpdate] =
    useState<boolean>(workspace.vaultProjectsRetentionOnUpdate || false)

  const vaultSettingSetter = useCallback(async () => {
    const vaultSettings: Record<string, Maybe<number | boolean>> = {
      vaultUsersCountLimit,
      vaultEventsRetentionPeriod:
        vaultEventsRetentionPeriodOptionValue === 'unlimited'
          ? null
          : vaultEventsRetentionPeriodOptionValue === 'custom'
          ? customVaultEventsRetentionPeriod
          : parseInt(vaultEventsRetentionPeriodOptionValue),
      vaultProjectsRetentionPeriod:
        vaultProjectsRetentionPeriodOptionValue === 'unlimited'
          ? null
          : vaultProjectsRetentionPeriodOptionValue === 'custom'
          ? customVaultProjectsRetentionPeriod
          : parseInt(vaultProjectsRetentionPeriodOptionValue),
      vaultProjectsRetentionOnUpdate,
      vaultFilesCountLimitPerProject,
      // TODO: Remove these fallback values once we don't need to support these limit any more.
      vaultReviewFilesCountLimitPerUserPerMonth: null,
      vaultReviewQueriesCountLimitPerUserPerMonth: null,
      vaultReviewFilesCountUnlimitedPerUserPerMonth: 1, // 1 means true
    }
    return setVaultSettings(
      workspace.id,
      vaultSettings,
      WorkspaceFeature.VAULT_ADD_ON
    )
  }, [
    vaultUsersCountLimit,
    vaultEventsRetentionPeriodOptionValue,
    customVaultEventsRetentionPeriod,
    vaultProjectsRetentionPeriodOptionValue,
    customVaultProjectsRetentionPeriod,
    vaultProjectsRetentionOnUpdate,
    vaultFilesCountLimitPerProject,
    workspace.id,
  ])

  const onAdd = async () => {
    // API call to enable vault add on
    const enabled = await setVaultEnablement(
      WorkspaceFeature.VAULT_ADD_ON,
      workspace.id,
      true
    )

    if (enabled) {
      const updated = await onSetVaultSettings(true)
      if (!updated) {
        const disabled = await setVaultEnablement(
          WorkspaceFeature.VAULT_ADD_ON,
          workspace.id,
          false
        )
        if (!disabled) {
          displayErrorMessage('Failed to disable vault add on')
        }
      }
    } else {
      displayErrorMessage('Failed to enable vault add on')
    }
  }

  const onSetVaultSettings = async (initialAdd: boolean) => {
    try {
      await vaultSettingSetter()
      await onEnable()
      onOpenChange(false)
      displaySuccessMessage(
        initialAdd
          ? 'Vault add on enabled successfully'
          : 'Vault add on updated successfully'
      )
      return true
    } catch (e) {
      displayErrorMessage(
        e instanceof Error ? e.message : 'Failed to set vault settings'
      )
      return false
    }
  }

  const buttonDisabledReason = useMemo(() => {
    if (vaultUsersCountLimit !== null && isNaN(vaultUsersCountLimit))
      return 'Vault users count limit is not a number'
    if (isNaN(vaultFilesCountLimitPerProject))
      return 'Vault files count limit per project is not a number'
    if (vaultUsersCountLimit !== null && vaultUsersCountLimit === 0)
      return 'Vault users count limit should be a positive integer'
    return undefined
  }, [vaultUsersCountLimit, vaultFilesCountLimitPerProject])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent showCloseIcon={false}>
        {type === 'add' ? (
          <p className="text-xl">
            Enable vault add on for {workspace.friendlyName}
          </p>
        ) : (
          <p className="text-xl">
            Edit vault add on settings for {workspace.friendlyName}
          </p>
        )}

        {type === 'add' && (
          <p>
            This will create a new vault add on role for the workspace with
            appropriate permission
          </p>
        )}

        <div className="flex items-center">
          <Switch
            checked={vaultUsersCountLimit === null}
            onCheckedChange={(value) => {
              setVaultUsersCountLimit(value ? null : 0)
            }}
            id="unlimited-vault-users-count"
          />
          <Label
            className="cursor-pointer pl-2 font-normal text-muted"
            htmlFor="unlimited-vault-users-count"
          >
            Unlimited vault users count per workspace
          </Label>
        </div>

        {vaultUsersCountLimit !== null && (
          <VaultEditOption
            label="Vault users count limit per workspace"
            value={vaultUsersCountLimit}
            setValue={(value) => {
              setVaultUsersCountLimit(value)
            }}
            initialEditing
          />
        )}
        <RetentionPeriodSelector
          label="Vault queries retention period"
          optionValue={vaultEventsRetentionPeriodOptionValue}
          setOptionValue={setVaultEventsRetentionPeriodOptionValue}
          customValue={customVaultEventsRetentionPeriod}
          setCustomValue={setCustomVaultEventsRetentionPeriod}
        />
        <VaultEditOption
          label="Vault files count limit per project"
          value={vaultFilesCountLimitPerProject}
          setValue={setVaultFilesCountLimitPerProject}
        />

        <RetentionPeriodSelector
          label="Vault projects retention period"
          optionValue={vaultProjectsRetentionPeriodOptionValue}
          setOptionValue={setVaultProjectsRetentionPeriodOptionValue}
          customValue={customVaultProjectsRetentionPeriod}
          setCustomValue={setCustomVaultProjectsRetentionPeriod}
        />

        {vaultProjectsRetentionPeriodOptionValue !== 'unlimited' && (
          <Tabs
            value={vaultProjectsRetentionOnUpdate ? 'update' : 'create'}
            onValueChange={(value) =>
              setVaultProjectsRetentionOnUpdate(value === 'update')
            }
          >
            <TabsList>
              <TabsTrigger value="create">On Project Creation</TabsTrigger>
              <TabsTrigger value="update">On Project Update</TabsTrigger>
            </TabsList>
            <TabsContent value="update">
              <p className="mt-1 text-sm text-muted">
                Project retention period will start counting from the last
                update to the project
              </p>
            </TabsContent>
            <TabsContent value="create">
              <p className="mt-1 text-sm text-muted">
                Project retention period will start counting from when the
                project is first created
              </p>
            </TabsContent>
          </Tabs>
        )}

        <DialogFooter>
          <Button variant="secondary" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button
            disabled={!!buttonDisabledReason}
            tooltip={buttonDisabledReason}
            onClick={async () => {
              if (type === 'add') {
                await onAdd()
              } else {
                await onSetVaultSettings(false)
              }
            }}
          >
            {type === 'add' ? 'Enable' : 'Update'} Vault Add On
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultAddonDialog
