import * as React from 'react'

import {
  useFlowControls,
  useFlowStepInput,
} from 'providers/modal-flow-provider/modal-flow-controls'
import { Step } from 'providers/modal-flow-provider/modal-flow-types'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { useSkipOnboarding } from 'components/common/flows/profile-setup/hooks/use-skip-onboarding'
import {
  NUM_TOTAL_STEPS,
  ProfileSetupFlowType,
} from 'components/common/flows/profile-setup/profile-setup'
import { EntryPointType } from 'components/common/flows/profile-setup/utils/entry-point-types'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import HarveyLogo from 'components/common/harvey-logo'
import { Button } from 'components/ui/button'

export type WelcomeStepType = Step<
  { entryPointType: EntryPointType },
  {
    stepCounter: number
    totalSteps: number
  }
>

export const WELCOME_STEP_TEXTS = {
  [EntryPointType.NEW_USER]: {
    TITLE: 'Welcome to Harvey',
    TOP_SUBTITLE:
      'Professionals in legal, tax, and finance use Harvey every day to do complex work, faster.',
    BOTTOM_SUBTITLE:
      'Create your profile to set your preferred language and personalize library recommendations to your practice area. Harvey’s response quality and style won’t change.',
  },
  [EntryPointType.EXISTING_USER]: {
    TITLE: 'Create your profile',
    TOP_SUBTITLE:
      'Harvey now adapts to you. Create your profile to set your preferred language and personalize library recommendations to your practice area. Harvey’s response quality and style won’t change.',
    BOTTOM_SUBTITLE: 'Completion time: Less than a minute',
  },
  [EntryPointType.SETTINGS_PAGE]: {
    TITLE: 'Create your profile',
    TOP_SUBTITLE:
      'Harvey now adapts to you. Create your profile to set your preferred language and personalize library recommendations to your practice area. Harvey’s response quality and style won’t change.',
    BOTTOM_SUBTITLE: 'Completion time: Less than a minute',
  },
}

const WelcomeStep: React.FC = () => {
  const { navigateToStep, exit } = useFlowControls<ProfileSetupFlowType>()
  const stepInput = useFlowStepInput<WelcomeStepType>()
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const handleSkipOnboarding = useSkipOnboarding({ stepInput, exit })
  const canSkipSteps = userInfo.isCreateUserProfilesOptionalStepsUser

  React.useEffect(() => {
    const preparedEventProperties = prepareEventProperties(stepInput)
    trackEvent('profile_setup_welcome_step_viewed', preparedEventProperties)
  }, [stepInput, trackEvent])

  const handleContinue = () => {
    if (
      canSkipSteps &&
      userInfo.workspace.industry &&
      userInfo.workspace.industry !== 'MULTIPLE'
    ) {
      navigateToStep('practice-area-step', {
        stepCounter: 1,
        totalSteps: NUM_TOTAL_STEPS - 1,
        primaryProfession: userInfo.workspace.industry,
      })
    } else {
      navigateToStep('profession-step', {
        stepCounter: 1,
        totalSteps: NUM_TOTAL_STEPS,
      })
    }
  }

  const { TITLE, TOP_SUBTITLE, BOTTOM_SUBTITLE } =
    WELCOME_STEP_TEXTS[stepInput.entryPointType]

  return (
    <div
      className={cn(
        'mx-auto flex h-full max-w-96 flex-col items-center justify-between pt-[90px]',
        userInfo.isCreateUserProfilesOptionalUser ? 'pb-[30px]' : 'pb-[60px]'
      )}
    >
      <div className="flex flex-col items-center">
        <div className="mb-4 flex size-16 items-center justify-center rounded-full border">
          <HarveyLogo className="h-7" />
        </div>
        <h1 className="mb-2 text-xl font-medium">{TITLE}</h1>
        <p className="w-[325px] text-center text-sm text-secondary">
          {TOP_SUBTITLE}
        </p>
      </div>
      <div className="flex flex-col items-center">
        <p className="mb-2 text-center text-sm text-secondary">
          {BOTTOM_SUBTITLE}
        </p>
        <p className="mb-4 text-center text-[10px] text-inactive">
          By clicking continue, you agree to Harvey&rsquo;s{' '}
          <a
            href="https://www.harvey.ai/legal/privacy-policy"
            className="underline outline-none transition hover:text-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>
        <Button
          className={cn({ 'mb-2': userInfo.isCreateUserProfilesOptionalUser })}
          onClick={handleContinue}
        >
          Continue
        </Button>
        {userInfo.isCreateUserProfilesOptionalUser ? (
          <Button
            onClick={handleSkipOnboarding}
            variant="link"
            className="text-xs"
          >
            Remind me later
          </Button>
        ) : null}
      </div>
    </div>
  )
}

export default WelcomeStep
