import React, { useMemo, useState } from 'react'
import { useEffect } from 'react'

import {
  ColumnDef,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table'
import { Plus } from 'lucide-react'
import pluralize from 'pluralize'
import { useShallow } from 'zustand/react/shallow'

import { TableSelect } from 'openapi/models/TableSelect'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { Maybe } from 'types'

import { getHrvyInfoMetadata } from 'utils/source'
import { Source } from 'utils/task'

import AssistantSources from 'components/assistant/components/assistant-sources'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import TableSelectTable, {
  generateColumns,
} from 'components/assistant/workflows/components/table-select/table-select'
import { RowActions } from 'components/assistant/workflows/components/table-select/table-select-row-actions'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useCalcTableMargin } from 'components/assistant/workflows/hooks/use-calc-table-margin'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import { useTableSelectActions } from 'components/assistant/workflows/hooks/use-table-select-actions'
import { useTableSelectStore } from 'components/assistant/workflows/stores/table-select-store'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import Icon from 'components/ui/icon/icon'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

type RowData = TableSelect['rows'][number]
const columnHelper = createColumnHelper<RowData>()

export const AssistantWorkflowTableSelectThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TABLE_SELECT
> = ({
  blockParams,
  loadingStates,
  stepIdx,
  outputData,
  paramStatus,
  completionStatus,
}) => {
  const { headerText } = blockParams
  const { handleSourceClick, getHrvyInfoMetadata } = useSourceUtils({
    sources: (blockParams.sources || []) as Source[],
  })
  const [
    tableData,
    setTableData,
    setTempTableData,
    selectedRows,
    setSelectedRows,
    setAllSelectedRows,
  ] = useTableSelectStore(stepIdx)(
    useShallow((state) => [
      state.tableData,
      state.setTableData,
      state.setTempTableData,
      state.selectedRows,
      state.setSelectedRows,
      state.setAllSelectedRows,
    ])
  )

  const {
    handleEditRow,
    handleRemoveRow,
    handleSaveRow,
    handleCancelRow,
    handleRowChange,
    handleAddRow,
  } = useTableSelectActions(useTableSelectStore(stepIdx))

  useEffect(() => {
    if (!blockParams.table) return

    const rows = blockParams.table.rows
    setTableData({ ...blockParams.table, rows })
    const selectedRowData = outputData ? outputData.table.rows : rows
    setTempTableData({ ...blockParams.table, rows })
    setSelectedRows(
      selectedRowData.reduce((acc, row, idx) => {
        acc[idx] = typeof row.isSelected === 'boolean' ? row.isSelected : true
        return acc
      }, {} as RowSelectionState)
    )
  }, [
    blockParams.table,
    setTableData,
    setSelectedRows,
    outputData,
    setTempTableData,
  ])

  const negativeMargin = useCalcTableMargin(
    blockParams.sources as Maybe<Source[]>
  )

  const Sidebar = useMemo(
    () =>
      (blockParams.sources?.length || 0) > 0 ? (
        <AssistantSources
          allDocuments={[]}
          sources={blockParams.sources as Maybe<Source[]>}
          onSetActiveFileId={handleSourceClick}
          // 42px makes it align with the content of the block
          className="absolute left-0 top-[42px] ml-4 w-72 sm:ml-4 sm:w-72"
          style={{ left: negativeMargin }}
        />
      ) : undefined,
    [blockParams.sources, handleSourceClick, negativeMargin]
  )

  const canEdit = blockParams.table?.orderedColumnNames?.some(
    (column) => column.editable
  )

  const canAddRemoveRows = blockParams.table?.canAddRemoveRows

  const showActions = !outputData && (canEdit || canAddRemoveRows)

  const columns: ColumnDef<RowData>[] = !tableData
    ? []
    : [
        ...(!canAddRemoveRows
          ? [
              columnHelper.display({
                id: 'select',
                header: ({ table }) => (
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={
                        table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && 'indeterminate')
                      }
                      onCheckedChange={(value) => {
                        table.toggleAllPageRowsSelected(!!value)
                        setAllSelectedRows(!!value)
                      }}
                      disabled={!!outputData}
                      aria-label="Select all"
                    />
                  </div>
                ),
                cell: ({ row }) => (
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={row.getIsSelected()}
                      onCheckedChange={(value) => {
                        row.toggleSelected(!!value)
                        setSelectedRows({ [row.index]: !!value })
                      }}
                      disabled={!!outputData}
                      aria-label="Select row"
                    />
                  </div>
                ),
                enableSorting: false,
                enableHiding: false,
                size: 36,
              }),
            ]
          : []),
        ...generateColumns(tableData, getHrvyInfoMetadata, handleRowChange),
        ...(showActions
          ? [
              columnHelper.display({
                id: 'actions',
                cell: ({ row }) => (
                  <RowActions
                    canEdit={canEdit}
                    canAddRemoveRows={canAddRemoveRows}
                    handleEditRow={handleEditRow}
                    handleRemoveRow={handleRemoveRow}
                    handleSaveRow={handleSaveRow}
                    handleCancelRow={handleCancelRow}
                    isEditing={row.original.isEditing}
                    rowIdx={row.index}
                  />
                ),
                size: 0,
                minSize: 0,
              }),
            ]
          : []),
      ]

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <LoadingState
          isCompleted={paramStatus === 'COMPLETED'}
          states={loadingStates}
        />
        {(tableData?.rows.length || 0) > 0 && (
          <>
            <AssistantWorkflowThreadText
              completionStatus={completionStatus}
              text={headerText}
            />
            <div
              className="mt-5"
              style={{
                marginLeft: `-${negativeMargin}px`,
                marginRight: `-${negativeMargin}px`,
              }}
            >
              <TableSelectTable
                data={outputData?.table.rows ?? tableData!.rows}
                columns={columns}
                selectedRows={selectedRows}
                expandedChildren={
                  !outputData &&
                  canAddRemoveRows && (
                    <div className="fixed bottom-5 right-5">
                      <div className="flex items-center space-x-4">
                        <Button
                          variant="default"
                          size="xl"
                          onClick={handleAddRow}
                        >
                          <Icon icon={Plus} />
                          Add row
                        </Button>
                      </div>
                    </div>
                  )
                }
              />
            </div>
          </>
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTableSelectInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TABLE_SELECT
> = ({ blockParams, onCompleted, stepIdx, outputData, paramStatus }) => {
  const [addRowIsHovered, setAddRowIsHovered] = useState(false)
  const [selectedRows, tableData, isLoading, setIsLoading] =
    useTableSelectStore(stepIdx)(
      useShallow((state) => [
        state.selectedRows,
        state.tableData,
        state.isLoading,
        state.setIsLoading,
      ])
    )

  const { handleAddRow } = useTableSelectActions(useTableSelectStore(stepIdx))

  const numSelected = Object.values(selectedRows).filter(Boolean).length

  const handleSubmit = () => {
    if (!tableData) return

    setIsLoading(true)
    const newTableData = {
      ...tableData,
      rows: tableData.rows.map((row, idx) => {
        return {
          ...row,
          isSelected: selectedRows[idx],
        }
      }),
    }
    onCompleted({ table: newTableData })
  }

  const disabled =
    isLoading ||
    !!outputData ||
    tableData?.rows.some((row) => row.isEditing) ||
    paramStatus !== 'COMPLETED'

  return (
    <div className="flex w-full">
      <div className="size-6" />
      <div className="flex grow flex-col gap-2 pl-4">
        {!outputData && blockParams.table?.canAddRemoveRows && (
          <Button
            variant="dashed"
            isLoading={isLoading || !!outputData}
            size="sm"
            onMouseEnter={() => setAddRowIsHovered(true)}
            onMouseLeave={() => setAddRowIsHovered(false)}
            onClick={handleAddRow}
          >
            {addRowIsHovered ? '+ Add Row' : '+'}
          </Button>
        )}
        <WorkflowInput>
          <div className="flex items-center justify-between gap-2 bg-transparent">
            <p className="pl-[10px] text-xs text-inactive">
              {!blockParams.table?.canAddRemoveRows
                ? `${numSelected} ${pluralize('row', numSelected)} selected`
                : ''}
            </p>
            <Button
              isLoading={isLoading || !!outputData}
              onClick={handleSubmit}
              disabled={disabled}
            >
              Submit
            </Button>
          </div>
        </WorkflowInput>
      </div>
    </div>
  )
}

export const AssistantWorkflowTableSelectExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowInputComponentBlocks.TABLE_SELECT
> = ({ blockParams, outputData }) => {
  const { headerText, table } = blockParams

  const canAddRemoveRows = table?.canAddRemoveRows

  const columns: ColumnDef<RowData>[] = table
    ? [
        ...(!canAddRemoveRows
          ? [
              columnHelper.display({
                id: 'select',
                header: ({ table }) => (
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={
                        table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && 'indeterminate')
                      }
                      onCheckedChange={() => null}
                    />
                  </div>
                ),
                cell: ({ row }) => (
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={row.getIsSelected()}
                      onCheckedChange={() => null}
                    />
                  </div>
                ),
                enableSorting: false,
                enableHiding: false,
                size: 36,
              }),
            ]
          : []),
        ...generateColumns(table, {} as getHrvyInfoMetadata, () => null),
      ]
    : []

  const selectedRowData = table?.rows ?? []
  const selectedRows = selectedRowData.reduce((acc, row, idx) => {
    acc[idx] = typeof row.isSelected === 'boolean' ? row.isSelected : true
    return acc
  }, {} as RowSelectionState)

  return (
    <>
      <div>{headerText}</div>
      <div className="mt-5">
        <TableSelectTable
          data={outputData?.table.rows ?? []}
          columns={columns}
          selectedRows={selectedRows}
        />
      </div>
    </>
  )
}
