import React, { useCallback, useEffect, useState } from 'react'

import { Info } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { IntegrationType } from 'openapi/models/IntegrationType'
import { instanceOfSynclyIntegration } from 'openapi/models/SynclyIntegration'
import Services from 'services'
import { useIntegrationsStore } from 'stores/integrations-store'
import { Maybe } from 'types'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useSettingsState } from 'components/settings/settings-store'
import { Button } from 'components/ui/button'
import { Card } from 'components/ui/card'
import Icon from 'components/ui/icon/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import {
  INTEGRATION_CONNECTED_METRIC,
  INTEGRATION_DISCONNECTED_METRIC,
  SETTINGS,
} from './constants'
import { IntegrationDefinition } from './integration-definitions'
import { authenticateSynclyAuthClient } from './syncly-utils'
import { OauthConnectState, useOauthConnect } from './use-oauth-connect'
import { disconnectIntegration } from './utils'

interface IntegrationConnectionCardProps {
  integrationType: IntegrationType
  integrationDefinition: IntegrationDefinition
  connected: boolean
}

const IntegrationConnectionCard: React.FC<IntegrationConnectionCardProps> = ({
  integrationType,
  integrationDefinition,
  connected,
}) => {
  const [isConnected, setIsConnected] = useState(connected)

  useEffect(() => {
    setIsConnected(connected)
  }, [connected])

  const { trackEvent } = useAnalytics()
  const userInfo = useSettingsState((s) => s.settingsUser)

  const onConnectCallback = useCallback(() => {
    Services.HoneyComb.Record({
      metric: INTEGRATION_CONNECTED_METRIC,
      user_id: userInfo?.id,
      workspace: userInfo?.workspace.id,
      integration: integrationType,
      product_area: SETTINGS,
    })
    trackEvent(`${integrationType} connected`)
  }, [userInfo, integrationType, trackEvent])

  const {
    connectionState,
    isConnected: isOauthConnected,
    handleConnect,
  } = useOauthConnect({ integrationType, onConnectCallback })

  const [clearIntegrationToken, setSynclySettingsOpenState] =
    useIntegrationsStore(
      useShallow((state) => [
        state.clearIntegrationToken,
        state.setSynclySettingsOpenState,
      ])
    )

  const onSynclyConnectSuccess = () => {
    setSynclySettingsOpenState(null)
    setIsConnected(true)
  }

  const handleIntegrationConnect = async () => {
    if (integrationDefinition.onSynclyConnect) {
      await integrationDefinition.onSynclyConnect(
        setSynclySettingsOpenState,
        onSynclyConnectSuccess
      )
    } else {
      await handleConnect()
    }
  }

  const handleDisconnect = async () => {
    try {
      let synclyClientAuthToken: Maybe<string> = null
      if (instanceOfSynclyIntegration(integrationType)) {
        synclyClientAuthToken = await authenticateSynclyAuthClient(userInfo?.id)
      }
      await disconnectIntegration(integrationType, synclyClientAuthToken)
      clearIntegrationToken(integrationType)
      Services.HoneyComb.Record({
        metric: INTEGRATION_DISCONNECTED_METRIC,
        user_id: userInfo?.id,
        workspace: userInfo?.workspace.id,
        integration: integrationType,
      })
      trackEvent(`${integrationType} disconnected`)
      setIsConnected(false)
      displaySuccessMessage(`Disconnected from ${integrationDefinition.name}`)
    } catch (e) {
      console.error(e)
      displayErrorMessage(
        `Failed to disconnect from ${integrationDefinition.name}`
      )
      return
    }
  }

  return (
    <Card className="mt-3 flex items-center justify-between p-3">
      <div className="ml-4 flex items-center space-x-4">
        {React.createElement(integrationDefinition.icon, {
          className: 'size-8',
        })}
        <div>
          {React.createElement(integrationDefinition.title)}
          <div className="flex items-center space-x-1">
            <p>{integrationDefinition.description}</p>
            <Tooltip>
              <TooltipTrigger>
                <Icon icon={Info} size="small" />
              </TooltipTrigger>
              <TooltipContent className="max-w-44">
                <p>
                  Your account must use the same email address you log in with
                  on Harvey.
                </p>
              </TooltipContent>
            </Tooltip>
          </div>
        </div>
      </div>
      <div>
        {connectionState === OauthConnectState.CONNECTING ? (
          <Button variant="outline" className="mr-3" disabled>
            Loading...
          </Button>
        ) : isConnected || isOauthConnected ? (
          <Button
            variant="destructive"
            className="mr-3"
            onClick={handleDisconnect}
          >
            Disconnect
          </Button>
        ) : (
          <Button
            variant="outline"
            className="mr-3"
            onClick={handleIntegrationConnect}
          >
            Connect
          </Button>
        )}
      </div>
    </Card>
  )
}

export default IntegrationConnectionCard
