export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
}

interface SSEStreamIteratorProps {
  requestUrl: string
  abortController: AbortController
  method: RequestMethod
  extraHeaders: Record<string, string>
  requestBody?: { stream: boolean }
}

async function* sseStreamIterator({
  requestUrl,
  abortController,
  method,
  extraHeaders,
  requestBody,
}: SSEStreamIteratorProps) {
  const response = await fetch(requestUrl, {
    method,
    signal: abortController.signal,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
      ...extraHeaders,
    },
    ...(method !== RequestMethod.GET
      ? { body: JSON.stringify(requestBody) }
      : {}),
  })

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  // If the response has no body, just exit.
  if (!response.body) return

  const reader = response.body.getReader()
  const decoder = new TextDecoder('utf-8')
  let buffer = ''
  try {
    while (true) {
      const { done, value } = await reader.read()
      if (done) break

      buffer += decoder.decode(value, { stream: true })
      const events = buffer.split('\n\n')
      // Keep any partial event data in `buffer`, so it can be completed by the next chunk.
      buffer = events.pop() ?? ''
      for (const event of events) {
        if (!event.trim()) continue

        // Check if line starts with "data: " and extract the data part
        if (event.startsWith('data: ')) {
          try {
            const data = event.slice(6) // Remove "data: " prefix (6 characters)
            const parsedData = JSON.parse(data)
            yield parsedData
          } catch (error) {
            console.error('Failed to parse SSE data:', error)
          }
        }
      }
    }
  } catch (error) {
    console.error('Error in SSE stream:', error)
  } finally {
    reader.releaseLock()
  }
}

export { sseStreamIterator }
