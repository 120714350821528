/* tslint:disable */
/* eslint-disable */
/**
 * Vault API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReviewEventStreamType = {
    ERROR: 'ERROR',
    EVENT_STATUS: 'EVENT_STATUS',
    EVENT_SKELETON: 'EVENT_SKELETON',
    EVENT_DATA: 'EVENT_DATA',
    EVENT_COMPLETED: 'EVENT_COMPLETED'
} as const;
export type ReviewEventStreamType = typeof ReviewEventStreamType[keyof typeof ReviewEventStreamType];


export function instanceOfReviewEventStreamType(value: any): boolean {
    for (const key in ReviewEventStreamType) {
        if (Object.prototype.hasOwnProperty.call(ReviewEventStreamType, key)) {
            if (ReviewEventStreamType[key as keyof typeof ReviewEventStreamType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ReviewEventStreamTypeFromJSON(json: any): ReviewEventStreamType {
    return ReviewEventStreamTypeFromJSONTyped(json, false);
}

export function ReviewEventStreamTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewEventStreamType {
    return json as ReviewEventStreamType;
}

export function ReviewEventStreamTypeToJSON(value?: ReviewEventStreamType | null): any {
    return value as any;
}

export function ReviewEventStreamTypeToJSONTyped(value: any, ignoreDiscriminator: boolean): ReviewEventStreamType {
    return value as ReviewEventStreamType;
}

