import React, { useState } from 'react'

import { UserPermBundle } from 'models/users'
import { instanceOfBetaPermission } from 'openapi/models/BetaPermission'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import BetaPermBadge from 'components/settings/workspace/permissions/beta-perm-badge'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import AlertIcon from 'components/ui/icons/alert-icon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

interface ExpandablePermissionsCellProps {
  permissions: UserPermBundle[]
  rowId: string
  isClientAdminView?: boolean
  // TODO: Refactor this prop - makes component very unusable.
  rolePermBundleIds: Set<PermissionBundleId>
}

const ExpandablePermissionsCell: React.FC<ExpandablePermissionsCellProps> = ({
  permissions,
  rowId,
  rolePermBundleIds,
  isClientAdminView = false,
}) => {
  const [expandedPermRows, setExpandedPermRows] = useState<Set<string>>(
    new Set()
  )
  const isExpanded = expandedPermRows.has(rowId)
  const shouldShowMore = permissions.length > 2

  const displayedPerms = isExpanded ? permissions : permissions.slice(0, 2)

  const toggleExpand = (e: React.MouseEvent) => {
    e.stopPropagation()
    const newExpanded = new Set(expandedPermRows)
    if (isExpanded) {
      newExpanded.delete(rowId)
    } else {
      newExpanded.add(rowId)
    }
    setExpandedPermRows(newExpanded)
  }

  return (
    <div className="flex flex-col text-sm">
      {permissions.length === 0 && '-'}
      {displayedPerms.map((perm) => (
        <Tooltip
          key={perm.permBundleName}
          open={isClientAdminView ? false : undefined}
        >
          <TooltipTrigger>
            <div className="flex items-center gap-1 text-start text-sm">
              {!isClientAdminView &&
                perm.excludedPerms.length > 0 &&
                !rolePermBundleIds.has(perm.permBundleId) && (
                  <AlertIcon size="small" />
                )}
              {perm.permBundleName}
              {!isClientAdminView &&
                perm.includedPerms.some((perm) =>
                  instanceOfBetaPermission(perm)
                ) && <BetaPermBadge />}
            </div>
          </TooltipTrigger>
          <TooltipContent side="left" className="flex flex-col gap-1 py-3">
            <div className="text-sm font-semibold">Included</div>
            {perm.includedPerms.map((perm) => (
              <Badge key={perm} className="flex items-center gap-2 font-mono">
                {perm}
                {!isClientAdminView && instanceOfBetaPermission(perm) && (
                  <BetaPermBadge />
                )}
              </Badge>
            ))}
            {perm.excludedPerms.length > 0 && (
              <>
                <div className="text-sm font-semibold">Missing</div>
                {perm.excludedPerms.map((perm) => (
                  <Badge key={perm} className="font-mono">
                    {perm}
                    {!isClientAdminView && instanceOfBetaPermission(perm) && (
                      <BetaPermBadge />
                    )}
                  </Badge>
                ))}
              </>
            )}
          </TooltipContent>
        </Tooltip>
      ))}
      {shouldShowMore && (
        <Button
          variant="link"
          size="sm"
          className="w-fit p-0 text-sm text-muted underline"
          onClick={toggleExpand}
        >
          {isExpanded ? 'Show less' : 'Show more'}
        </Button>
      )}
    </div>
  )
}

export default ExpandablePermissionsCell
