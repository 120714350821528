import React from 'react'

import _ from 'lodash'
import { UploadCloud } from 'lucide-react'

import { cn } from 'utils/utils'

import Icon from 'components/ui/icon/icon'
import { Spinner } from 'components/ui/spinner'

interface Props {
  isLoading: boolean
  isDisabled?: boolean
  dropzone: {
    getRootProps: () => any
    getInputProps: () => any
  }
  description: React.ReactNode
  descriptionClassName?: string
  processingDescription?: string
  className?: string
  applySpacing?: boolean
  integrations?: React.ReactNode[]
  iconSize?: 'small' | 'default' | 'large'
  hasNetdocsExperiment?: boolean
}

export const Dropzone = (props: Props): JSX.Element => {
  const applySpacing = props.applySpacing ?? true

  return (
    <div
      {...props.dropzone.getRootProps()}
      className={cn(
        'relative flex h-full cursor-pointer items-center justify-center rounded-lg border border-dashed px-2 py-8 transition hover:border-ring hover:bg-secondary-hover focus:outline-none focus:ring-offset-0 focus-visible:ring focus-visible:ring-ring',
        props.className,
        {
          'cursor-not-allowed': props.isDisabled,
        }
      )}
      data-testid="dropzone"
    >
      <input {...props.dropzone.getInputProps()} />
      {props.isLoading ? (
        <div className="flex flex-col items-center justify-center text-center">
          <Spinner />
          <p className="mt-4 text-muted">
            {props.processingDescription || 'Uploading'}
          </p>
        </div>
      ) : (
        <div
          className={cn(
            'flex flex-col items-center justify-center text-center',
            {
              // We set this to make sure the click event is triggered on the dropzone container
              // instead of inner elements, which is expected by the Netdocs extension.
              'pointer-events-none [&_button]:pointer-events-auto':
                props.hasNetdocsExperiment,
            }
          )}
          role="button"
          tabIndex={0}
        >
          <Icon icon={UploadCloud} size={props.iconSize || 'large'} />
          <p
            className={cn(
              '',
              {
                'mt-4': applySpacing,
              },
              props.descriptionClassName
            )}
          >
            Drag and drop files here
          </p>
          <p
            className={cn('text-xs text-muted', props.descriptionClassName, {
              'mt-2': applySpacing,
            })}
          >
            {props.description}
          </p>
          {!_.isNil(props.integrations) && props.integrations.length > 0 && (
            <>
              <p
                className={cn(
                  'text-xs text-muted',
                  props.descriptionClassName,
                  {
                    'mt-2': applySpacing,
                  }
                )}
              >
                Or upload from
              </p>
              <div
                className="mt-4 flex flex-col gap-2"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                role="button"
                tabIndex={0}
              >
                {props.integrations.map((integration, index) => (
                  <div key={index}>{integration}</div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}
