import React from 'react'
import { useState, useEffect } from 'react'

import { Copy, ListPlusIcon } from 'lucide-react'

import { EventKind } from 'openapi/models/EventKind'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'

import { displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { ActionIcon } from 'components/assistant/components/assistant-question'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import FollowUps from 'components/assistant/workflows/components/follow-ups/follow-ups'
import TextInput from 'components/assistant/workflows/components/text-input/text-input'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'
import {
  emitter,
  useWorkflowEmitterEvents,
} from 'components/assistant/workflows/hooks/use-workflow-emitter-events'
import { useAuthUser } from 'components/common/auth-context'
import Markdown from 'components/common/markdown/markdown'
import { SavePromptButton } from 'components/library/save-prompt-button'
import Icon from 'components/ui/icon/icon'

import {
  AssistantWorkflowLayoutContainer,
  AssistantWorkflowLayoutIconComponent,
  AssistantWorkflowThreadBlock,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowFollowUpsThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FOLLOW_UPS
> = ({ blockParams, outputData, completionStatus }) => {
  const [followUpListenerRegistered, setFollowUpListenerRegistered] =
    useState(false)
  const { relatedQuestions } = blockParams
  const isCompleted = !!outputData
  const hasFollowupsReady = (relatedQuestions?.length || 0) > 1
  const listeners = emitter.all.get('followUpClicked')
  const hasListeners = listeners && listeners.length > 0
  const userInfo = useAuthUser()
  const query = outputData?.value
  const toolbarActionIconSize = 'sm'

  const followUpsDisabled = React.useMemo(() => {
    return !hasFollowupsReady || !hasListeners
  }, [hasFollowupsReady, hasListeners])

  const handleSelectQuestion = (text: string) => {
    if (followUpsDisabled) return
    emitter.emit('followUpClicked', text)
  }

  const handleCopy = () => {
    if (!query) return
    navigator.clipboard
      .writeText(query)
      .then(() => {
        displaySuccessMessage('Copied query to clipboard')
      })
      .catch(() => {
        displaySuccessMessage('Failed to copy query to clipboard')
      })
  }

  useEffect(() => {
    // Forces a rerender after the followUpHandlerRegistered event is emitted,
    // which guarantees that hasListeners will have up-to-date state.
    emitter.on('followUpHandlerRegistered', () => {
      setFollowUpListenerRegistered(true)
    })
    return () => {
      emitter.off('followUpHandlerRegistered')
    }
  }, [hasListeners, followUpListenerRegistered])

  if (!hasFollowupsReady) return null

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowLayoutContainer className="border-none">
        <AssistantWorkflowLayoutIconComponent />

        <FollowUps
          followUps={relatedQuestions!}
          onSelectQuestion={handleSelectQuestion}
          disabled={followUpsDisabled}
        />
      </AssistantWorkflowLayoutContainer>

      {isCompleted && (
        <AssistantWorkflowYouComponent>
          <div className="flex flex-col">
            <AssistantWorkflowThreadText
              completionStatus={completionStatus}
              text={(query?.length || 0) > 0 ? query! : 'N/A'}
            />

            {/* Toolbar */}
            {query && (
              <div className="mt-2 flex items-center">
                <ActionIcon
                  onClick={handleCopy}
                  size={toolbarActionIconSize}
                  tooltip="Copy query"
                >
                  <Icon icon={Copy} />
                </ActionIcon>
                {userInfo.IsLibraryPrivatePromptUser && (
                  <SavePromptButton
                    query={query}
                    triggerComponent={(
                      disabled,
                      buttonTooltipText,
                      onMouseEnter
                    ) => (
                      <ActionIcon
                        disabled={disabled}
                        onMouseEnter={onMouseEnter}
                        size={toolbarActionIconSize}
                        tooltip={buttonTooltipText}
                        tooltipClassName={cn({
                          'cursor-default': disabled,
                        })}
                      >
                        <Icon icon={ListPlusIcon} />
                      </ActionIcon>
                    )}
                    eventKind={EventKind.ASSISTANT}
                  />
                )}
              </div>
            )}
          </div>
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowFollowUpsInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FOLLOW_UPS
> = ({ blockParams, onCompleted }) => {
  const { placeholder, optional } = blockParams
  const [text, setText] = React.useState('')

  const onSubmit = (passed_text?: string) => {
    const value = passed_text ? passed_text : text
    onCompleted({ value })
    setText('')
  }

  useWorkflowEmitterEvents('followUpClicked', (text) => {
    onSubmit(text)
  })

  return (
    <WorkflowInput>
      <TextInput
        onSubmit={onSubmit}
        value={text}
        placeholder={placeholder}
        optional={optional}
        onChange={setText}
      />
    </WorkflowInput>
  )
}

export const AssistantWorkflowFollowUpsExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowInputComponentBlocks.FOLLOW_UPS
> = ({ outputData }) => {
  if (!outputData) return null
  return <Markdown content={`<h2>${outputData.value}</h2>`} />
}
