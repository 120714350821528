import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { RotateCw, Trash } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  clearReviewErrors,
  retryReviewErrors,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const ErrorPopover = ({ fileId }: { fileId: string }) => {
  const { recordQuerySubmitted } = useQueryAnalytics(EventKind.VAULT_REVIEW)
  const queryClient = useQueryClient()
  const [
    isRunButtonLoading,
    historyItem,
    setQueryId,
    setIsRunButtonLoading,
    setHistoryItem,
  ] = useVaultQueryDetailStore(
    useShallow((s) => [
      s.isRunButtonLoading,
      s.historyItem,
      s.setQueryId,
      s.setIsRunButtonLoading,
      s.setHistoryItem,
    ])
  )
  const [currentProjectMetadata] = useVaultStore(
    useShallow((state) => [state.currentProjectMetadata])
  )

  const [isOpen, setIsOpen] = useState(false)

  const reviewEvent = historyItem as ReviewHistoryItem

  const clearErrors = async (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsOpen(false)
    await clearReviewErrors({
      queryId: reviewEvent.id,
      fileIds: [fileId],
      historyItem: reviewEvent,
      setIsRunButtonLoading,
      setHistoryItem,
      queryClient,
    })
  }

  const retryErrors = async (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsOpen(false)
    await retryReviewErrors({
      queryFileIds: [fileId],
      reviewEvent,
      currentProjectMetadata,
      setIsRunButtonLoading,
      setQueryId,
      recordQuerySubmitted,
      queryClient,
    })
  }

  const isDisabled = isRunButtonLoading

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          size="xsIcon"
          variant="ghost"
          onClick={(e) => {
            e.stopPropagation()
          }}
          className="flex shrink-0 items-center transition hover:bg-transparent"
        >
          <AlertIcon size="small" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-96" align="start">
        <div>
          <p className="font-semibold">Something went wrong</p>
          <p className="mt-1">
            There was an error generating an answer for one of your questions on
            this file. You can choose to try again or clear this error.
          </p>
          <div className="mt-3 flex items-center justify-end gap-2">
            <Button
              variant="ghost"
              size="sm"
              className="text-xs"
              disabled={isDisabled}
              tooltip={
                isDisabled
                  ? 'You can clear the error after the query is finished processing'
                  : undefined
              }
              onClick={clearErrors}
            >
              <Icon icon={Trash} size="small" className="mr-1" />
              Clear error
            </Button>
            <Button
              variant="ghost"
              size="sm"
              className="text-xs"
              disabled={isDisabled}
              tooltip={
                isDisabled
                  ? 'You can retry after the query is finished processing'
                  : undefined
              }
              onClick={retryErrors}
            >
              <Icon icon={RotateCw} size="small" className="mr-1" />
              Retry
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default ErrorPopover
