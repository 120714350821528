import { Step } from 'providers/modal-flow-provider/modal-flow-types'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { prepareEventProperties } from 'components/common/flows/profile-setup/utils/prepare-event-properties'
import { useUserProfileStore } from 'components/common/user-profile-store'

export const PROFILE_SETUP_SKIPPED_EVENT = 'profile_setup_skipped'
export const PROFILE_SETUP_STEP_SKIPPED_EVENT = 'profile_setup_step_skipped'

type UseSkipOnboardingProps = {
  stepInput: Step<any, any>['stepInput']
  exit: () => void
}

export const useSkipOnboarding = ({
  stepInput,
  exit,
}: UseSkipOnboardingProps) => {
  const preparedEventProperties = prepareEventProperties(stepInput)
  const skipOnboarding = useUserProfileStore((s) => s.skipOnboarding)
  const { trackEvent } = useAnalytics()
  return () => {
    trackEvent(PROFILE_SETUP_SKIPPED_EVENT, preparedEventProperties)
    skipOnboarding()
    exit()
  }
}
