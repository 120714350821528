import React from 'react'

import useWorkspaceUsersEnriched from 'components/settings/hooks/use-workspace-users-enriched'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { useSettingsState } from 'components/settings/settings-store'
import WorkspaceUsersTableV2 from 'components/settings/workspace/workspace-details/workspace-users-table-v2'

const WorkspaceUsersPageV2 = () => {
  const settingsUser = useSettingsState((state) => state.settingsUser)
  const workspace = settingsUser?.workspace

  const { workspaceUsersEnriched, fetchWorkspaceUsersEnriched, isLoading } =
    useWorkspaceUsersEnriched({
      workspaceId: workspace?.id ?? null,
      isInternalAdmin: false,
    })

  return (
    <>
      <SettingsAppHeader />
      <SettingsLayout>
        {workspace ? (
          <WorkspaceUsersTableV2
            isClientAdminView
            isLoading={isLoading}
            users={workspaceUsersEnriched}
            fetchData={fetchWorkspaceUsersEnriched}
            workspace={workspace}
          />
        ) : null}
      </SettingsLayout>
    </>
  )
}

export default WorkspaceUsersPageV2
