import React, { useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { TodayOption, readableFormat } from 'utils/date-utils'
import { cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'
import useRecentQueries from 'components/vault/hooks/use-recent-queries'
import { NUM_ALL_QUERIES_TO_FETCH } from 'components/vault/utils/vault'
import { RerunVaultReviewQueries } from 'components/vault/utils/vault-fetcher'
import { getExistingCompletedVaultReviewQueries } from 'components/vault/utils/vault-helpers'
import {
  VaultExtraSocketState,
  useVaultStore,
} from 'components/vault/utils/vault-store'

const VaultReviewQuerySelectionDialog: React.FC = () => {
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))
  const recentlyUploadedFileIds = useVaultStore(
    (s) => s.recentlyUploadedFileIds
  )
  const currentProject = useVaultStore((s) => s.currentProject)
  const currentProjectMetadata = useVaultStore((s) => s.currentProjectMetadata)

  const isReviewQuerySelectionDialogOpen = useVaultStore(
    (s) => s.isReviewQuerySelectionDialogOpen
  )

  const setIsReviewQuerySelectionDialogOpen = useVaultStore(
    (s) => s.setIsReviewQuerySelectionDialogOpen
  )
  const markInProgressReviewTask = useVaultStore(
    (s) => s.markInProgressReviewTask
  )
  const setReviewTask = useVaultStore((s) => s.setReviewTask)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedQueries, setSelectedQueries] = useState<string[]>([])

  const handleCheckboxChange = (queryId: string) => {
    if (selectedQueries.includes(queryId)) {
      setSelectedQueries(selectedQueries.filter((q) => q !== queryId))
    } else {
      setSelectedQueries([...selectedQueries, queryId])
    }
  }

  const onDismiss = () => {
    setIsReviewQuerySelectionDialogOpen(false)
    setSelectedQueries([])
  }

  const handleRunQueries = async () => {
    setIsSubmitting(true)
    selectedQueries.forEach((queryId) => {
      const queryState = queryIdToState[queryId]
      const newFileIds = [
        ...(queryState?.fileIds || []),
        ...recentlyUploadedFileIds,
      ]
      markInProgressReviewTask(queryId)
      // Follow similar logic as generateNNResponse to update the review task
      setReviewTask({
        queryId: queryId,
        isLoading: true,
        headerText: 'Processing',
        eta: null,
        numFiles: newFileIds.length,
        fileIds: newFileIds,
        // Showing new start time to indicate that the task is in progress
        startedAt: new Date(),
        // Clear the paused and failed times
        pausedAt: null,
        failedAt: null,
      })
    })
    await RerunVaultReviewQueries({
      requestType: 'extra_files',
      queryIds: selectedQueries,
      fileIds: recentlyUploadedFileIds,
      projectId: currentProject!.id,
    })
    setIsSubmitting(false)
    onDismiss()
  }

  const { historyData } = useRecentQueries({
    projectId: currentProjectMetadata.id,
    maxQueries: NUM_ALL_QUERIES_TO_FETCH,
    hasInProgressHistoryEvents: false,
  })

  const reviewQueries = getExistingCompletedVaultReviewQueries(
    historyData?.events || [],
    new Set(recentlyUploadedFileIds)
  )

  const isRunQueriesDisabled = selectedQueries.length === 0 || isSubmitting

  const getRunQueriesDisabledTooltip = () => {
    if (selectedQueries.length === 0) {
      return 'Please select at least one query to run'
    }
    return undefined
  }

  if (reviewQueries.length === 0) {
    return null
  }

  return (
    <Dialog
      open={isReviewQuerySelectionDialogOpen}
      onOpenChange={setIsReviewQuerySelectionDialogOpen}
    >
      <DialogContent showCloseIcon>
        <DialogHeader>
          <DialogTitle>Add to existing queries</DialogTitle>
          <DialogDescription>
            Select existing review queries to add these files to
          </DialogDescription>
        </DialogHeader>
        <div className="mt-2 space-y-2">
          <ScrollArea maxHeight="max-h-64 pr-2">
            {reviewQueries.map((query, index) => {
              return (
                <div
                  key={`${query.id}-container`}
                  className="mt-1 flex cursor-pointer items-center justify-between space-x-2"
                  data-testid={`vault-review-query-selection-dialog--option-${index}`}
                >
                  <Checkbox
                    id={query.id.toString()}
                    checked={selectedQueries.includes(query.id.toString())}
                    onCheckedChange={() => {
                      handleCheckboxChange(query.id.toString())
                    }}
                    checkboxClassName="disabled:cursor-default"
                    label={(query.metadata as VaultExtraSocketState).title}
                  />
                  <Badge
                    key={`${query.id}-badge`}
                    variant="secondary"
                    className={cn('h-6 rounded-full border-0 text-muted')}
                  >
                    <p className="text-xs">
                      {readableFormat(
                        new Date(query.updatedAt),
                        TodayOption.showTime
                      )}
                    </p>
                  </Badge>
                </div>
              )
            })}
          </ScrollArea>
        </div>
        <div className="mt-4 flex items-center justify-between">
          <Button
            variant="default"
            disabled={isRunQueriesDisabled}
            onClick={handleRunQueries}
            tooltip={getRunQueriesDisabledTooltip()}
          >
            {isSubmitting ? (
              <div className="flex items-center">
                <Spinner size="xxs" className="top-3 mr-2" />
                <p>Adding to query…</p>
              </div>
            ) : (
              <p>Run queries</p>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VaultReviewQuerySelectionDialog
