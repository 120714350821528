import React, { useState, useMemo } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'

import {
  BulkGrantPermsToUsers,
  BulkManagePermsUsersResult,
  BulkRevokePermsFromUsers,
  BulkGrantPermBundlesToUsers,
  BulkRevokePermBundlesFromUsers,
} from 'models/perms'
import { Permission } from 'models/user-info'
import { BetaPermission } from 'openapi/models/BetaPermission'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import PermBundlesMultiSelectInline from 'components/settings/workspace/permissions/perm-bundles-multi-select-inline'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { Label } from 'components/ui/label'
import { Tabs, TabsList, TabsTrigger, TabsContent } from 'components/ui/tabs'
import { TagInput } from 'components/ui/tag-input/tag-input'

enum Tab {
  add = 'add',
  remove = 'remove',
}

const WorkspacePermissionsDialog = ({
  isInternalAdmin = false,
  addOnly = false,
  workspaceId,
  isOpen,
  onClose,
  onComplete,
  selectedUsers,
  excludedBundles,
  includeBetaPermissions = false,
  excludedBetaPermissions,
  skipResults = false,
}: {
  isInternalAdmin?: boolean
  addOnly?: boolean
  workspaceId: number
  isOpen: boolean
  onClose: () => void
  onComplete: () => void
  selectedUsers: string[]
  excludedBundles?: PermissionBundleId[]
  includeBetaPermissions?: boolean
  excludedBetaPermissions?: string[]
  skipResults?: boolean
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState<
    PermissionBundleId[]
  >([])
  const [selectedBetaPermissions, setSelectedBetaPermissions] = useState<
    Permission[]
  >([])
  const [tab, setTab] = useState<Tab>(Tab.add)

  const footerMessage =
    tab === Tab.add
      ? 'This list displays only the permissions available to your selected users based on their assigned role. Permissions already granted to them are excluded.'
      : 'Permissions that none of the selected users have are not displayed in this list.'

  const [result, setResult] = useState<BulkManagePermsUsersResult>()
  const hasResult =
    !_.isEmpty(result?.userManagedPerms) ||
    !_.isEmpty(result?.errorUser) ||
    !_.isEmpty(result?.userNoAction)

  const handleSave = async () => {
    let bundleResponse: BulkManagePermsUsersResult | undefined
    let betaResponse: BulkManagePermsUsersResult | undefined

    if (tab === Tab.add) {
      if (selectedPermissions.length > 0) {
        bundleResponse = await BulkGrantPermBundlesToUsers(
          workspaceId,
          selectedUsers,
          selectedPermissions
        )
      }
      if (selectedBetaPermissions.length > 0) {
        betaResponse = await BulkGrantPermsToUsers(
          selectedUsers,
          selectedBetaPermissions
        )
      }
    } else {
      if (selectedPermissions.length > 0) {
        bundleResponse = await BulkRevokePermBundlesFromUsers(
          workspaceId,
          selectedUsers,
          selectedPermissions
        )
      }
      if (selectedBetaPermissions.length > 0) {
        betaResponse = await BulkRevokePermsFromUsers(
          selectedUsers,
          selectedBetaPermissions
        )
      }
    }

    const mergedResponse: BulkManagePermsUsersResult = {
      userNoAction: _.uniq([
        ...(bundleResponse?.userNoAction || []),
        ...(betaResponse?.userNoAction || []),
      ]),
      userManagedPerms: _.uniq([
        ...(bundleResponse?.userManagedPerms || []),
        ...(betaResponse?.userManagedPerms || []),
      ]),
      errorUser: _.uniq([
        ...(bundleResponse?.errorUser || []),
        ...(betaResponse?.errorUser || []),
      ]),
    }

    if (skipResults) {
      if (mergedResponse.userManagedPerms.length > 0) {
        displaySuccessMessage(
          `Successfully ${
            tab === Tab.add ? 'added' : 'removed'
          } permissions for ${mergedResponse.userManagedPerms.length} user(s)`
        )
      }
      if (mergedResponse.errorUser.length > 0) {
        displayErrorMessage(
          `Failed to ${tab === Tab.add ? 'add' : 'remove'} permissions for ${
            mergedResponse.errorUser.length
          } user(s)`
        )
      }
      onComplete()
      return
    }

    setResult(mergedResponse)
  }

  const renderPrimaryButton = () => {
    if (hasResult) {
      return <Button onClick={onComplete}>Close</Button>
    }
    if (tab === Tab.add) {
      return <Button onClick={handleSave}>Add permissions</Button>
    }
    return (
      <Button variant="destructive" onClick={handleSave}>
        Remove permissions
      </Button>
    )
  }

  const betaPermissionTags = useMemo(
    () =>
      Object.values(BetaPermission)
        .filter(
          (perm) => !excludedBetaPermissions?.includes(perm as Permission)
        )
        .map((perm) => ({
          value: perm,
          badgeDisplayText: perm,
        })),
    [excludedBetaPermissions]
  )

  const dropdowns = (
    <div className="space-y-4">
      <div>
        <Label>All permissions</Label>
        <PermBundlesMultiSelectInline
          size="md"
          isInternalAdmin={isInternalAdmin}
          filterForWorkspaceId={workspaceId}
          selectedPermissions={selectedPermissions}
          setSelectedPermissions={setSelectedPermissions}
          excludedBundles={excludedBundles}
          footerMessage={footerMessage}
          disabled={hasResult}
        />
      </div>

      {includeBetaPermissions && (
        <div>
          <Label>Beta permissions</Label>
          <TagInput
            size="md"
            placeholder="Start typing or select permissions"
            sortedTags={betaPermissionTags}
            selectedTagValues={selectedBetaPermissions}
            setSelectedTagValues={(values) =>
              setSelectedBetaPermissions(values as Permission[])
            }
            shouldShowSuggestedTags
            allowCreatingNewTags={false}
            disabled={hasResult}
          />
        </div>
      )}
    </div>
  )

  const resultCard = hasResult && !skipResults && (
    <Card className="mt-4">
      <CardHeader>
        <CardTitle>Results</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        <div className="text-sm">
          Permissions {tab === Tab.add ? 'granted' : 'revoked'}
          <div className="mt-2 flex flex-wrap gap-2">
            {result?.userManagedPerms.map((email) => (
              <Badge key={email}>{email}</Badge>
            ))}
          </div>
        </div>
        <div className="text-sm">
          Permissions {tab === Tab.add ? 'already exist' : 'do not exist'}
          <div className="mt-2 flex flex-wrap gap-2">
            {result?.userNoAction.map((email) => (
              <Badge variant="secondary" key={email}>
                {email}
              </Badge>
            ))}
          </div>
        </div>
        <div className="text-sm">
          Invalid users
          <div className="mt-2 flex flex-wrap gap-2">
            {result?.errorUser.map((email) => (
              <Badge variant="destructive" key={email}>
                {email}
              </Badge>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  )

  const content = (
    <div className="min-h-32">
      {dropdowns}
      {resultCard || <div className="h-24" />}
    </div>
  )

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent hasContainer={false}>
        <DialogHeader>
          <DialogTitle>
            {addOnly ? 'Add permissions' : 'Manage permissions'}
          </DialogTitle>
          <DialogDescription>
            {addOnly ? 'Add permissions for' : 'Add or remove permissions for'}{' '}
            {selectedUsers.length === 1
              ? selectedUsers[0]
              : pluralize('selected user', selectedUsers.length, true)}
            {isInternalAdmin &&
              tab === Tab.add &&
              `. Note: Within each bundle, only the permissions enabled for this
                workspace will be granted.`}
          </DialogDescription>
        </DialogHeader>

        {addOnly ? (
          content
        ) : (
          <Tabs value={tab} onValueChange={(value) => setTab(value as Tab)}>
            <TabsList variant="minimal" className="w-full">
              <TabsTrigger
                variant="minimal"
                value={Tab.add}
                disabled={hasResult}
              >
                Add
              </TabsTrigger>
              <TabsTrigger
                variant="minimal"
                value={Tab.remove}
                disabled={hasResult}
              >
                Remove
              </TabsTrigger>
            </TabsList>
            <TabsContent value={Tab.add}>{content}</TabsContent>
            <TabsContent value={Tab.remove}>{content}</TabsContent>
          </Tabs>
        )}

        <DialogFooter>
          <DialogClose asChild>
            {!hasResult && (
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            )}
          </DialogClose>
          {renderPrimaryButton()}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default WorkspacePermissionsDialog
