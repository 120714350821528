import React, { useState } from 'react'

import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'

import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import TextInput from 'components/assistant/workflows/components/text-input/text-input'
import WorkflowInput from 'components/assistant/workflows/components/workflow-input/workflow-input'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowTextThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TEXT
> = ({ blockParams, completionStatus, outputData }) => {
  const { agentText } = blockParams
  const isCompleted = !!outputData

  return (
    <AssistantWorkflowThreadBlock>
      {agentText && (
        <AssistantWorkflowHarveyComponent>
          <AssistantWorkflowThreadText
            completionStatus={completionStatus}
            text={agentText}
          />
        </AssistantWorkflowHarveyComponent>
      )}

      {isCompleted && (
        <AssistantWorkflowYouComponent>
          <AssistantWorkflowThreadText
            completionStatus={completionStatus}
            text={outputData.value.length > 0 ? outputData.value : 'N/A'}
          />
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowTextInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.TEXT
> = ({ blockParams, onCompleted, outputData }) => {
  const { placeholder, optional } = blockParams
  const [value, setValue] = useState(outputData?.value ?? '')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)
    onCompleted({ value })
    setIsLoading(false)
  }

  return (
    <WorkflowInput>
      <TextInput
        onSubmit={handleSubmit}
        isLoading={isLoading}
        value={value}
        placeholder={placeholder}
        optional={optional}
        onChange={setValue}
        isCompleted={!!outputData}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    </WorkflowInput>
  )
}

export const AssistantWorkflowTextInputExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowInputComponentBlocks.TEXT
> = ({ blockParams, outputData }) => {
  const { agentText } = blockParams
  const output =
    (outputData?.value ?? '').length > 0 ? outputData?.value : 'N/A'
  return (
    <>
      <div>{agentText}</div>
      <div>{output}</div>
    </>
  )
}
