import React from 'react'

import { Badge } from 'components/ui/badge'

const BetaPermBadge = () => {
  return (
    <Badge isPill variant="secondary">
      Beta
    </Badge>
  )
}

export default BetaPermBadge
