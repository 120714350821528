import React, { useState } from 'react'

import { Pencil } from 'lucide-react'

import { Button } from 'components/ui/button'

interface AssistantHeaderTitleProps {
  title: string
  setShowEditCaption: (show: boolean) => void
}

const AssistantHeaderTitle = ({
  title,
  setShowEditCaption,
}: AssistantHeaderTitleProps) => {
  const [hovered, setIsHovered] = useState(false)
  const shouldShowEditTitleButton = !!title
  return (
    <div className="flex items-center gap-1">
      <h1 className="line-clamp-2 whitespace-pre-wrap text-xl font-medium">
        {title}
      </h1>
      {shouldShowEditTitleButton && (
        <Button
          variant="ghost"
          size="xsIcon"
          onClick={() => setShowEditCaption(true)}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <Pencil
            size={12}
            stroke={hovered ? 'currentColor' : 'hsl(var(--neutral-500))'}
          />
        </Button>
      )}
    </div>
  )
}

export default AssistantHeaderTitle
