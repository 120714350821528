import { TaxCorpusFilterIDs } from 'openapi/models/TaxCorpusFilterIDs'

import { useAssistantStore } from 'components/assistant/stores/assistant-store'
import { isResearchKnowledgeSource } from 'components/assistant/utils/assistant-knowledge-sources'

import { useIsPwcTax } from './use-is-pwc-tax'

export const useIsRTL = () => {
  const isPwcTax = useIsPwcTax()
  const knowledgeSource = useAssistantStore((s) => s.knowledgeSource)

  return (
    isPwcTax &&
    isResearchKnowledgeSource(knowledgeSource) &&
    knowledgeSource.filterIds.some((id) =>
      id.startsWith(TaxCorpusFilterIDs.KSA_TAX)
    )
  )
}
