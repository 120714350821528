import { GridApi, IRowNode } from 'ag-grid-community'
import { HeadingLevel, convertMillimetersToTwip } from 'docx'
import saveAs from 'file-saver'
import { isEmpty } from 'lodash'
import * as XLSX from 'xlsx'

import { VaultFile } from 'openapi/models/VaultFile'

import { WordSection, WordSectionType } from 'utils/docx'
import { downloadUploadedFiles } from 'utils/download'
import { exportWordWithSections, logExport } from 'utils/markdown'
import { OrderedSet } from 'utils/ordered-set'
import { SafeRecord } from 'utils/safe-types'
import { removeSpecialCharacters } from 'utils/string'
import { Source, TaskType } from 'utils/task'
import {
  displayErrorMessage,
  displayInfoMessage,
  displayWarningMessage,
} from 'utils/toast'
import { backendFormat, EM_DASH, s2ab } from 'utils/utils'

import {
  QuestionColumnDef,
  fetchSourcesForFileId,
} from 'components/vault/query-detail/data-grid-helpers'
import { ReviewHistoryItem } from 'components/vault/query-detail/vault-query-detail-store'
import { EXPIRATION_URL_KEY, ReviewSource } from 'components/vault/utils/vault'
import { FetchVaultFiles } from 'components/vault/utils/vault-fetcher'
import {
  getDisplayAnswer,
  isUrlExpired,
} from 'components/vault/utils/vault-helpers'

const MAX_CELL_LENGTH = 32767

export enum ExportAnswerType {
  SHORT = 'short',
  LONG = 'long',
  BOTH = 'both',
}

export const exportWordWithReviewState = async ({
  queryId,
  visibleRows,
  taskType,
  reviewState,
  shouldExportAdditionalContext,
  shouldIncludeQuestion,
  fileIdToVaultFile,
  fileIdToSources,
}: {
  queryId: string
  visibleRows: IRowNode[]
  taskType: TaskType
  reviewState: ReviewHistoryItem
  shouldExportAdditionalContext: boolean
  shouldIncludeQuestion: boolean
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  fileIdToSources: SafeRecord<string, ReviewSource[]>
}) => {
  const title = reviewState.title || reviewState.query || 'Untitled'
  const columns = reviewState.columns

  const fileSectionPromises = visibleRows.map(async (row, rowIndex) => {
    let file
    if (row.data && row.data.file) {
      file = row.data.file
    } else if (row.id) {
      file = fileIdToVaultFile[row.id]
    }
    if (!file) return null

    const fileId = file.id
    const backingReviewRowId = row.data.backingReviewRowId
    const fileName = file.name
    const fileNameWithoutExtension = fileName.split('.')[0]
    let fileSources =
      reviewState.fileIdToSources[fileId] ?? fileIdToSources[fileId] ?? []

    if (isEmpty(fileSources)) {
      fileSources = await fetchSourcesForFileId(
        fileId,
        queryId,
        backingReviewRowId
      )
    }

    // 1. Create the table
    let headerRow = `| Header |`
    let separatorRow = `| :--- |`

    if (shouldIncludeQuestion && shouldExportAdditionalContext) {
      headerRow += ` Question | Summary | Additional Context |`
      separatorRow += ` :--- | :--- | :--- |`
    } else if (shouldIncludeQuestion) {
      headerRow += ` Question | Summary |`
      separatorRow += ` :--- | :--- |`
    } else if (shouldExportAdditionalContext) {
      headerRow += ` Summary | Additional Context |`
      separatorRow += ` :--- | :--- |`
    } else {
      headerRow += ` Summary |`
      separatorRow += ` :--- |`
    }

    const markedFileSources = fileSources.map((source) => {
      return {
        ...source,
        documentName:
          'documentName' in source
            ? source.documentName
            : fileNameWithoutExtension,
        page: 'pageNumber' in source ? source.pageNumber : source.page,
        text: `${fileNameWithoutExtension} <mark>${source.text}</mark>`,
      }
    })

    return {
      index: rowIndex,
      sections: [
        {
          type: WordSectionType.MARKDOWN,
          options: {
            pageBreakBefore: true,
          },
          content: `## ${
            row.data.file.name
          }\n${headerRow}\n${separatorRow}\n${columns
            .filter((c) => !c.isHidden)
            .sort((a, b) => a.displayId - b.displayId)
            .map((column) => {
              const columnId = String(column.displayId)
              const header = column.header.replace(/\n/g, '<br>')
              const question = column.fullText.replace(/\n/g, '<br>')

              const sourcesForAnswerText = fileSources
                .filter((s) => s.questionId === columnId)
                .map((s) => `[${s.footnote}]`)
                .join('')

              const summaryAnswer = reviewState.answers[fileId]?.find(
                (a) => a.columnId === columnId && !a.long
              )
              const additionalContextAnswer = reviewState.answers[fileId]?.find(
                (a) => a.columnId === columnId && a.long
              )

              const summaryDisplayAnswer = getDisplayAnswer(
                summaryAnswer
              ).replace(/\n/g, ' ')
              const additionalContextDisplayAnswer =
                additionalContextAnswer?.text === summaryAnswer?.text
                  ? EM_DASH
                  : getDisplayAnswer(additionalContextAnswer).replace(
                      /\n/g,
                      ' '
                    )

              const outputText = `| ${header} ${sourcesForAnswerText} |`
              if (shouldIncludeQuestion && shouldExportAdditionalContext) {
                return (
                  outputText +
                  ` ${question} | ${summaryDisplayAnswer} | ${additionalContextDisplayAnswer} |`
                )
              } else if (shouldIncludeQuestion) {
                return outputText + ` ${question} | ${summaryDisplayAnswer} |`
              } else if (shouldExportAdditionalContext) {
                return (
                  outputText +
                  ` ${summaryDisplayAnswer} | ${additionalContextDisplayAnswer} |`
                )
              } else {
                return outputText + ` ${summaryDisplayAnswer} |`
              }
            })
            .join('\n')}`,
        },
        {
          type: WordSectionType.SOURCES,
          content: markedFileSources as Source[],
          options: {
            pageBreakBefore: true,
            heading: HeadingLevel.HEADING_3,
            spacing: {
              before: convertMillimetersToTwip(1),
              after: convertMillimetersToTwip(1),
            },
          },
        },
      ],
    }
  })

  // Wait for all the sections to be resolved and sort them by index
  const resolvedSections = (await Promise.all(fileSectionPromises))
    .filter((result): result is NonNullable<typeof result> => result !== null)
    .sort((a, b) => a.index - b.index)

  // Flatten the sections array while maintaining the order
  // Flatten the sections array while maintaining order
  const fileSections: WordSection[] = resolvedSections.flatMap(
    (result) => result.sections
  )

  await exportWordWithSections({
    title,
    taskType: taskType,
    queryId: queryId,
    includeAnnotation: true,
    useRemark: true,
    addTitleToSections: false,
    includeTitleInFileName: true,
    sections: fileSections,
  })
}

export const exportExcelWithReviewState = async ({
  gridApi,
  queryId,
  visibleRowIds,
  taskType,
  sheetName,
  reviewState,
  fileIdToVaultFile,
  shouldExportAdditionalContext,
  shouldIncludeQuestion,
  isCSV,
}: {
  gridApi: GridApi
  queryId: string
  visibleRowIds: OrderedSet<string>
  taskType: TaskType
  sheetName: string
  reviewState: ReviewHistoryItem
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  shouldExportAdditionalContext: boolean
  shouldIncludeQuestion: boolean
  isCSV: boolean
}) => {
  const standardWidth = 20
  const dateTime = backendFormat(new Date())
  const queryTitle = reviewState.title || reviewState.query || 'Untitled'
  const fileName = `${taskType}_${queryTitle}_${dateTime}.${
    isCSV ? 'csv' : 'xlsx'
  }`
  const wb = XLSX.utils.book_new()

  let shouldIncludeFileFolderPath = false
  try {
    shouldIncludeFileFolderPath = gridApi.getRowGroupColumns().length > 0
  } catch (e) {
    console.error('Error getting row group columns', e)
    displayErrorMessage('Error exporting Excel. Please try again.')
    return
  }

  // unfortunately we cannot use the gridApi to export excel/csv because
  // the long answer is not in the grid anymore
  // instead we have to manually build the data
  const data = Object.entries(reviewState.answers)
    .filter(([fileId]) => visibleRowIds.has(fileId))
    .sort(
      ([fileIdA], [fileIdB]) =>
        visibleRowIds.indexOf(fileIdA) - visibleRowIds.indexOf(fileIdB)
    )
    .map(([fileId, answers]) => {
      const row: any = {
        Name: fileIdToVaultFile[fileId]?.name || 'N/A',
      }

      if (shouldIncludeFileFolderPath) {
        const gridNode = gridApi.getRowNode(fileId)
        const folderPath = gridNode?.data?.folderPath
        if (folderPath) {
          row.Folder = folderPath
        } else {
          row.Folder = EM_DASH
        }
      }

      // the true order of the columns is in the grid
      // when columns are reorderd, the order is preserved in the datagrid
      const columnDefs = gridApi.getColumnDefs() ?? []
      const columnOrderMap = new Map()
      columnDefs.forEach((c) => {
        const isGroup = 'group' in c && c.group
        const isMovable = 'suppressMovable' in c && c.suppressMovable
        if (isGroup || isMovable) return
        const colDef = c as QuestionColumnDef
        const hasBackingReviewColumn =
          'backingReviewColumn' in colDef && colDef.backingReviewColumn
        if (hasBackingReviewColumn) {
          const colDefOrder = colDef.backingReviewColumn!.order
          const colDefDisplayId = String(colDef.backingReviewColumn!.displayId)
          columnOrderMap.set(colDefDisplayId, colDefOrder)
        }
      })
      reviewState.columns
        .filter((c) => !c.isHidden)
        .sort(
          (a, b) =>
            (columnOrderMap.get(String(a.displayId)) ?? 0) -
            (columnOrderMap.get(String(b.displayId)) ?? 0)
        )
        .forEach((column, idx) => {
          const header = column.header
          const question = column.fullText
          const columnId = String(column.displayId)
          let columnHeader = `${idx + 1}. ${header} `

          if (shouldIncludeQuestion) {
            columnHeader = `${idx + 1}. ${header} (${question})`
          }

          const summaryAnswer = answers?.find(
            (a) => a.columnId === columnId && !a.long
          )
          const additionalContextAnswer = answers?.find(
            (a) => a.columnId === columnId && a.long
          )
          const summaryDisplayAnswer = getDisplayAnswer(summaryAnswer)
            .replace(/\n/g, ' ')
            .slice(0, MAX_CELL_LENGTH)

          const additionalContextDisplayAnswer =
            additionalContextAnswer?.text === summaryAnswer?.text
              ? EM_DASH
              : getDisplayAnswer(additionalContextAnswer)
                  .replace(/\n/g, ' ')
                  .slice(0, MAX_CELL_LENGTH)

          row[columnHeader] = summaryDisplayAnswer

          if (shouldExportAdditionalContext) {
            row[`${columnHeader} (Additional Context)`] =
              additionalContextDisplayAnswer
          }
          return row
        })
      return row
    })

  const ws = XLSX.utils.json_to_sheet(data)
  ws['!cols'] = Array.from(
    { length: reviewState.columnHeaders.length * 2 + 1 },
    () => ({
      wch: standardWidth,
    })
  )

  const maxSheetNameLength = 31
  const sanitizedSheetName = removeSpecialCharacters(sheetName).slice(
    0,
    maxSheetNameLength
  )
  XLSX.utils.book_append_sheet(wb, ws, sanitizedSheetName)

  try {
    const wbout = XLSX.write(wb, {
      bookType: isCSV ? 'csv' : 'xlsx',
      type: 'binary',
    })
    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      fileName
    )
  } catch (e) {
    console.error('Error exporting Excel', e)
    displayErrorMessage('Error exporting Excel. Please try again.')
    return
  }

  await logExport(isCSV ? 'csv' : 'excel', taskType, queryId)
}

export const downloadFiles = async ({
  fileIdsToDownload,
  fileIdToVaultFile,
  downloadFileName,
  projectId,
  upsertVaultFiles,
}: {
  fileIdsToDownload: string[]
  fileIdToVaultFile: SafeRecord<string, VaultFile>
  downloadFileName: string
  projectId: string | undefined
  upsertVaultFiles: (files: VaultFile[], projectId: string | undefined) => void
}) => {
  displayInfoMessage('Your download will start shortly.', 5)
  const filesToDownload: { id: string; name: string; url: string }[] = []
  const filesNotReadyToDownload: string[] = []
  const fileUrlsNeeded: VaultFile[] = []
  fileIdsToDownload.forEach((fileId) => {
    const file = fileIdToVaultFile[fileId]
    if (!file) return
    const isPdfFile = file.name.endsWith('.pdf')
    const isNotPdfFile = !isPdfFile

    // if the file is a temporary file, we need to wait for it to finish uploading to the system
    const localFileId = `${file.vaultFolderId}-${file.name}`
    if (localFileId === file.id) {
      filesNotReadyToDownload.push(fileId)
    } else if (
      isNotPdfFile &&
      file.url &&
      !isUrlExpired(file.url, EXPIRATION_URL_KEY)
    ) {
      filesToDownload.push({ id: file.id, name: file.name, url: file.url })
    } else if (
      isPdfFile &&
      file.docAsPdfUrl &&
      !isUrlExpired(file.docAsPdfUrl, EXPIRATION_URL_KEY)
    ) {
      filesToDownload.push({
        id: file.id,
        name: file.name,
        url: file.docAsPdfUrl,
      })
    } else {
      fileUrlsNeeded.push(file)
    }
  })
  if (fileUrlsNeeded.length > 0) {
    const newFileData = await FetchVaultFiles(fileUrlsNeeded.map((f) => f.id))
    if (isEmpty(newFileData)) return
    const newFileDataFiles = newFileData.files
    upsertVaultFiles(newFileDataFiles, projectId)
    newFileDataFiles.forEach((f) => {
      if (f.url) {
        filesToDownload.push({ id: f.id, name: f.name, url: f.url })
      }
    })
  }
  if (filesNotReadyToDownload.length > 0) {
    displayWarningMessage(
      `There are currently ${filesNotReadyToDownload.length} files that are not ready to download. Skipping these files. Please try again later.`
    )
  }

  await downloadUploadedFiles({
    uploadedFiles: filesToDownload,
    zippedFileName: downloadFileName,
    shouldZipSingleFile: true,
  })
}
