import * as React from 'react'

import {
  getSortedRowModel,
  getCoreRowModel,
  SortingState,
  ColumnDef,
  useReactTable,
  RowSelectionState,
  createColumnHelper,
  Row,
} from '@tanstack/react-table'

import { TableSelect as TableSelectType } from 'openapi/models/TableSelect'
import { TableSelectOrderedColumnNamesInner } from 'openapi/models/TableSelectOrderedColumnNamesInner'

import { getHrvyInfoMetadata } from 'utils/source'
import { toTitleCase } from 'utils/string'
import { snakeToCamel } from 'utils/utils'

import Markdown from 'components/common/markdown/markdown'
import Table from 'components/common/markdown/table'
import { DataTable } from 'components/ui/data-table/data-table'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Textarea } from 'components/ui/text-area'

interface TableSelectProps<T> {
  columns: ColumnDef<T>[]
  data: T[]
  selectedRows?: RowSelectionState
  expandedChildren?: React.ReactNode
  onRowChange?: (value: string) => void
}
interface RenderedCellProps {
  camelCaseKey: string
  row: Row<TableSelectType['rows'][number]>
  column: TableSelectOrderedColumnNamesInner
  onChange?: (rowIdx: number, key: string, value: string) => void
  getHrvyInfoMetadata: getHrvyInfoMetadata
}

const RenderedCell: React.FC<RenderedCellProps> = React.memo(function Rec({
  camelCaseKey,
  row,
  column,
  onChange,
  getHrvyInfoMetadata,
}) {
  const [value, setValue] = React.useState(row.original.data[camelCaseKey])

  // Determine if this field should be rendered as a select
  const isSelectField =
    column.inputType === 'select' && Array.isArray(column.options)

  if (!column.editable || !row.original.isEditing) {
    return (
      <div className="text-xs text-primary">
        <Markdown
          getHrvyInfoMetadata={getHrvyInfoMetadata}
          content={value}
          className="!prose-xs"
          width="100%"
        />
      </div>
    )
  }

  if (isSelectField && column.options) {
    return (
      <div className="text-xs text-primary">
        <Select
          value={value}
          onValueChange={(newValue) => {
            onChange?.(row.index, camelCaseKey, newValue)
            setValue(newValue)
          }}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select option" />
          </SelectTrigger>
          <SelectContent>
            {column.options.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    )
  }

  return (
    <div className="text-xs text-primary">
      <Textarea
        className="w-full"
        value={value}
        onChange={(e) => {
          onChange?.(row.index, camelCaseKey, e.target.value)
          setValue(e.target.value)
        }}
      />
    </div>
  )
})

export const generateColumns = (
  tableData: TableSelectType,
  getHrvyInfoMetadata: getHrvyInfoMetadata,
  onRowChange?: (rowIdx: number, key: string, value: string) => void
) => {
  const columnHelper = createColumnHelper<TableSelectType['rows'][number]>()

  if (tableData.rows.length === 0) {
    return []
  }

  if (tableData.orderedColumnNames?.length) {
    return tableData.orderedColumnNames.reduce(
      (acc: ColumnDef<TableSelectType['rows'][number]>[], column) => {
        const { key, name } = column
        if (!key || !name) return acc
        const camelCaseKey = snakeToCamel(key)
        acc.push(
          columnHelper.accessor(`data.${camelCaseKey}`, {
            header: () => <TableSelectHeader>{name}</TableSelectHeader>,
            cell: ({ row }) => {
              return (
                <RenderedCell
                  camelCaseKey={camelCaseKey}
                  row={row}
                  column={column}
                  onChange={onRowChange}
                  getHrvyInfoMetadata={getHrvyInfoMetadata}
                />
              )
            },
            // required to make cells adaptive width, otherwise will default to 150px
            size: 0,
            minSize: 0,
          })
        )
        return acc
      },
      []
    )
  } else {
    return Object.keys(tableData.rows[0].data).reduce(
      (acc: ColumnDef<TableSelectType['rows'][number]>[], key) => {
        acc.push(
          columnHelper.accessor(`data.${key}`, {
            header: () => (
              <TableSelectHeader>{toTitleCase(key)}</TableSelectHeader>
            ),
            cell: ({ row }) => (
              <TableSelectCell>{row.original.data[key]}</TableSelectCell>
            ),
            size: 60,
          })
        )
        return acc
      },
      []
    )
  }
}

const TableSelectHeader: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className="text-xs font-semibold tracking-wide text-primary">
      {children}
    </div>
  )
}

const TableSelectCell: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="text-xs text-primary">{children}</div>
}

const TableSelect = <T,>({
  data,
  columns,
  selectedRows,
  expandedChildren,
}: TableSelectProps<T>) => {
  const [sorting, setSorting] = React.useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      rowSelection: selectedRows || {},
    },
  })

  if (data.length === 0) {
    return null
  }

  return (
    <Table
      expandedChildren={expandedChildren}
      dialogClassName="flex flex-col"
      hasContainer
    >
      <DataTable
        table={table}
        className="w-full"
        tableBodyCellClassName="align-top"
        renderWithoutTableContainer
        hideTableBorder
      />
    </Table>
  )
}

export { TableSelectHeader, TableSelectCell }
export default TableSelect
