import React, { useCallback, useEffect } from 'react'

import { getWorkspaceRolesInternalAdmin, WorkspaceRole } from 'models/roles'
import { Workspace } from 'models/workspace'

import WorkspaceRolesTable from './workspace-roles-table'

interface WorkspaceDetailsRolesProps {
  workspace: Workspace
}

const WorkspaceDetailsRoles = ({ workspace }: WorkspaceDetailsRolesProps) => {
  const [workspaceRoles, setWorkspaceRoles] = React.useState<WorkspaceRole[]>(
    []
  )
  const fetchWorkspaceRoles = useCallback(async () => {
    const roles = await getWorkspaceRolesInternalAdmin(workspace.id)
    setWorkspaceRoles(roles)
  }, [workspace.id])

  useEffect(() => {
    fetchWorkspaceRoles()
  }, [fetchWorkspaceRoles])

  return (
    <div className="p-3">
      <WorkspaceRolesTable
        workspaceRoles={workspaceRoles}
        workspaceName={workspace.clientName ?? ''}
        workspaceId={workspace.id}
        workspaceSlug={workspace.slug}
        refetchRoles={fetchWorkspaceRoles}
      />
    </div>
  )
}

export default WorkspaceDetailsRoles
